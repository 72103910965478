import React, { ReactElement, useContext } from "react";
import groupBy from "lodash/groupBy";
import { Collapse } from "@mui/material";
import { AnnotationContext } from "../../../provider";

interface Props {
  // @ts-ignore
  region;
  // @ts-ignore
  objectTypes;
  // @ts-ignore
  saveObject;
  // @ts-ignore
  index;
}

export default function EditDefectType({
  region,
  saveObject,
  objectTypes,
  index,
}: Props): ReactElement {
  const { setSavedDefectTypeId } = useContext(AnnotationContext);
  const shouldBeVisible =
    (region.editType === "defect" && region.editIndex === index) ||
    (!!region.highlighted &&
      !!!region.types[index] &&
      !!region.is_defect[index]);

  let newObjectTypes = groupBy(objectTypes, "client_object_type_id");
  // @ts-ignore
  newObjectTypes = Object.values(newObjectTypes).map((n) => n[0]);
  // @ts-ignore
  let defectCategoriesNew = newObjectTypes?.filter((t) => !!t.issue);

  defectCategoriesNew = groupBy(defectCategoriesNew, "category_id");

  defectCategoriesNew = Object.values(defectCategoriesNew);
  // @ts-ignore
  defectCategoriesNew = defectCategoriesNew.map((e) => e[0]);

  // @ts-ignore
  let sortedCategories = defectCategoriesNew.sort((a, b) => {
    if (a.category_sorting < b.category_sorting) {
      return 1;
    }
    if (a.category_sorting > b.category_sorting) {
      return -1;
    }
    return 0;
  });

  // @ts-ignore
  let defectCatIds = sortedCategories.map((e) => e.category_id);

  let groupedByIssueCategory = groupBy(
    // @ts-ignore
    newObjectTypes?.filter(
      // @ts-ignore
      (ot) => ot.category_id && defectCatIds.includes(ot.category_id)
    ),
    "category_id"
  );

  let groupedKeys = Object.keys(groupedByIssueCategory).map((k) => parseInt(k));

  // @ts-ignore
  sortedCategories = sortedCategories.filter((e) =>
    groupedKeys.includes(e.category_id) ? true : false
  );

  return (
    <Collapse in={shouldBeVisible}>
      <div className="issueTypeContainer">
        <div className="issueCategoryContainer">
          {shouldBeVisible &&
            sortedCategories
              // @ts-ignore
              .map((k) => k.category_id)
              // @ts-ignore
              .map((category, categoryIndex) => {
                return (
                  <div
                    key={categoryIndex}
                    style={{
                      backgroundColor:
                        region.categories[index] === category ? "#FDD" : "",
                    }}
                    className="issueCategoryItem"
                    onClick={() => {
                      const newRegion = {
                        ...region,
                        // @ts-ignore
                        categories: region.categories.map((value, i) =>
                          i === index ? category : value
                        ),
                        // @ts-ignore
                        types: region.types.map((value, i) =>
                          i === index ? null : value
                        ),
                      };
                      saveObject(newRegion, "defect");
                    }}
                  >
                    <p>
                      {category
                        ? sortedCategories.find(
                            // @ts-ignore
                            (item) => item.category_id === category
                          )?.category_name
                        : ""}
                    </p>
                  </div>
                );
              })}
        </div>

        <div className="issueItemContainer">
          {shouldBeVisible &&
            Object.keys(groupedByIssueCategory)
              .filter(
                (category) => parseInt(category) === region.categories[index]
              )
              .map((category) => {
                return groupedByIssueCategory[category]
                  .sort((a, b) => {
                    const nameA = a.name;
                    const nameB = b.name;
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((obj, i) => (
                    <div
                      key={i}
                      style={{
                        backgroundColor:
                          region.types[index] === obj.id ? "#FDD" : "",
                      }}
                      className="issueTypeItem"
                      onClick={() => {
                        // Do not change type if it already exists
                        if (region.types.includes(obj.id)) {
                          alert("Classification already exists");
                          return;
                        }

                        setSavedDefectTypeId(obj.id);
                        saveObject(
                          {
                            ...region,
                            // @ts-ignore
                            types: region.types.map((value, i) =>
                              i === index ? obj.id : value
                            ),
                            isNew: false,
                          },
                          "defect"
                        );
                      }}
                    >
                      <p> {obj.name}</p>
                    </div>
                  ));
              })}
        </div>
      </div>
    </Collapse>
  );
}
