import { ReactNode } from "react";
import { Paper, Skeleton, Stack, Typography } from "@mui/material";

type Props = {
  title: string;
  subtitle: string;
  selected?: boolean;
  loading?: boolean;
  disableHover?: boolean;
  children?: ReactNode;
};

export default function DomainCard({
  title,
  subtitle,
  selected,
  disableHover,
  loading,
  children,
}: Props) {
  if (loading) {
    return (
      <Paper>
        <Stack px={2} py={1}>
          <Skeleton width={200} variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton width={100} variant="text" sx={{ fontSize: "1rem" }} />
        </Stack>
      </Paper>
    );
  }
  return (
    <Paper
      sx={{
        cursor: disableHover ? "inherit" : "pointer",
        backgroundColor: selected ? "rgba(0, 0, 0, 0.05)" : "white",
        "&:hover": {
          backgroundColor: disableHover ? "white" : "rgba(0, 0, 0, 0.05)",
        },
      }}
    >
      <Stack px={2} py={1}>
        <Typography>{title}</Typography>
        <Typography color="text.secondary">{subtitle}</Typography>
        {children}
      </Stack>
    </Paper>
  );
}
