import React from "react";
import CMSParagraph from "./CMSParagraph";
import CMSBlok from "./CMSBlok";
import CMSHeading from "./CMSHeading";
import CMSQuote from "./CMSQuote";
import CMSOrderedList from "./CMSOrderedList";
import CMSHorizontalRule from "./CMSHorizontalRule";
import CMSBulletList from "./CMSBulletList";
import CMSListItem from "./CMSListItem";
import CMSText from "./CMSText";
import CMSImage from "./CMSImage";
import CMSEmoji from "./CMSEmoji";
import CMSHardBreak from "./CMSHardBreak";
import CMSCodeBlock from "./CMSCodeBlock";
type Props = {
  content: any;
};

export default function CMSRichTextContent({ content }: Props) {
  let Component = null;
  switch (content.type) {
    case "paragraph":
      Component = CMSParagraph;
      break;
    case "blok":
      Component = CMSBlok;
      break;
    case "heading":
      Component = CMSHeading;
      break;
    case "blockquote":
      Component = CMSQuote;
      break;
    case "horizontal_rule":
      Component = CMSHorizontalRule;
      break;
    case "bullet_list":
      Component = CMSBulletList;
      break;
    case "ordered_list":
      Component = CMSOrderedList;
      break;
    case "list_item":
      Component = CMSListItem;
      break;
    case "text":
      Component = CMSText;
      break;
    case "image":
      Component = CMSImage;
      break;
    case "emoji":
      Component = CMSEmoji;
      break;
    case "hard_break":
      Component = CMSHardBreak;
      break;
    case "code_block":
      Component = CMSCodeBlock;
      break;
  }
  if (
    ["text", "image", "emoji", "heading", "blok", "code_block"].includes(
      content.type
    )
  ) {
    // @ts-ignore
    return <Component content={content} />;
  }
  if (Component) {
    return <Component content={content.content} />;
  }
  return <div>{content.type}</div>;
}
