import { connect } from "react-redux";
import ImageSection from "./ImageSection";
import { RootState } from "state/store";
import { setListViewExpandImageId } from "state/actions";

interface IStateProps {
  // @ts-ignore
  objectTypes;
  // @ts-ignore
  expandImageId;
  imageId: number | null;
  gmap: google.maps.Map;
}

interface IDispatchProps {
  setListViewExpandImageId: Function;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    objectTypes: state.objects.objectTypes,
    expandImageId: state.map.listViewExpandImageId,
    imageId: state.image?.current?.id,
    gmap: state.map.gmap,
  };
};

const mapDispatchToProps = {
  setListViewExpandImageId,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(ImageSection);
