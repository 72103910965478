import { connect } from "react-redux";
import Component from "./PoleCounter";
import { get_poles_statistics } from "state/actions";
import { RootState } from "state/store";

interface IStateProps {
  nPoles: number;
}

interface IDispatchProps {
  get_poles_statistics: (callback?: Function, onError?: Function) => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    nPoles: state.statistics.totalPoles,
  };
};

const mapDispatchToProps = {
  get_poles_statistics,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
