import { Divider, Stack, Typography } from "@mui/material";
import MenuItem from "components/MenuItem";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useLanguage, useSelector, useSidebar } from "hooks";
import { AccessKeys } from "interfaces";
import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { default as DomainManagerLink } from "features/DomainManager/MenuButton";
import { Link } from "react-router-dom";
import translations from "translations";
import sidebarTranslations from "views/Sidebar/translations";
import ArrowBackItem from "../ArrowBackItem";

interface ToolEntry {
  translation: { SE: string; EN: string };
  tooltipTranslation: { SE: string; EN: string };
  link: string;
  role: AccessKeys;
}

export default function ToolsList(): ReactElement {
  const customerExists = useSelector((state) => state.mission?.id ?? -1 > 0);
  const customerId = useSelector((state) => state.mission?.id ?? -1);
  const { closeSidebar } = useSidebar();
  const { language } = useLanguage();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  if (urlParams.has("menu")) {
    urlParams.delete("menu");
  }

  const pointerEventsForProjectTools = customerExists ? "inherit" : "none";
  const textColorForProjectTools = customerExists ? "inherit" : "grey";

  const projectTools: ToolEntry[] = [
    {
      translation: translations.Menu.Tools.BlurImages,
      tooltipTranslation: translations.Menu.Tools.BlurImages.Tooltip,
      link: `${customerId}/blur${location.search}`,
      role: "blurAllImages",
    },
    {
      translation: translations.Menu.Tools.Powerlines,
      tooltipTranslation: translations.Menu.Tools.Powerlines.Tooltip,
      link: `${customerId}/powerline`,
      role: "powerlinePanel",
    },
    {
      translation: translations.Menu.Tools.Reports,
      tooltipTranslation: translations.Menu.Tools.Reports.Tooltip,
      link: customerId
        ? `/${customerId}/report/defect${location.search}`
        : `report/defect?${location.search}`,
      // @ts-ignore
      role: null,
    },
    {
      translation: translations.Menu.Tools.Upload,
      tooltipTranslation: translations.Menu.Tools.Upload.Tooltip,
      link: `${customerId}/upload/images`,
      role: "upload",
    },
  ];

  const imageReviewTools: ToolEntry[] = [
    {
      translation: translations.ImageViewer.MLReview,
      tooltipTranslation: translations.ImageViewer.MLReview,
      link: `${customerId}/0/verify-ml${location.search}`,
      role: "machineReview",
    },
    {
      translation: translations.ImageViewer.FalsePositiveReview,
      tooltipTranslation: translations.ImageViewer.FalsePositiveReview,
      link: `${customerId}/0/super-verify-fp-images${location.search}`,
      role: "falsePositiveReview",
    },
    {
      translation: translations.ImageViewer.AnnotatorReview,
      tooltipTranslation: translations.ImageViewer.AnnotatorReview,
      link: `${customerId}/0/super-verify-tp-bbs${location.search}`,
      role: "supervisorApprove",
    },
    {
      translation: translations.ImageViewer.SuperFalseReview,
      tooltipTranslation: translations.ImageViewer.SuperFalseReview,
      link: `${customerId}/0/super-verify-fp-bbs${location.search}`,
      role: "supervisorFalseReview",
    },
  ];
  // @ts-ignore
  const renderToolLink = (tool) => {
    const menuItem = (
      <MenuItem
        dataTestID={`link.${tool.role}`}
        tooltip={tool.tooltipTranslation?.[language]}
      >
        <p>{tool.translation[language]}</p>
      </MenuItem>
    );

    const link = (
      <Link
        onClick={closeSidebar}
        to={tool.link}
        style={{
          textDecoration: "none",
          color: textColorForProjectTools,
          pointerEvents: pointerEventsForProjectTools,
        }}
      >
        {menuItem}
      </Link>
    );

    const child = tool.role ? (
      <RoleWrapper
        keyName={tool.role}
        fallback={
          <div
            style={{
              color: "grey",
            }}
          >
            {menuItem}
          </div>
        }
      >
        {link}
      </RoleWrapper>
    ) : (
      link
    );

    return tool.role ? (
      <RoleWrapper keyName={tool.role} treatAsMissionAgnostic={true}>
        {child}
      </RoleWrapper>
    ) : (
      <>{child}</>
    );
  };

  return (
    <Stack>
      <ArrowBackItem />
      <Stack direction="column" alignItems="center" width="100%">
        <Typography variant="h5">
          {translations.Menu.Tools[language]}
        </Typography>
      </Stack>

      <Stack spacing={1} width="100%">
        <Divider>
          <Typography fontWeight="bold">
            {translations.Menu.Tools.ProjectTools[language]}
          </Typography>
        </Divider>
      </Stack>
      {projectTools.map(renderToolLink)}

      <Stack spacing={1} width="100%">
        <Divider>
          <Typography fontWeight="bold">
            {translations.Menu.Tools.ImageReviewTools[language]}
          </Typography>
        </Divider>
      </Stack>
      {imageReviewTools.map(renderToolLink)}

      <Stack spacing={1} width="100%">
        <Divider>
          <Typography fontWeight="bold">
            {translations.Menu.Tools.CustomerTools[language]}
          </Typography>
        </Divider>
      </Stack>

      <RoleWrapper keyName="addClientIssueSeverities">
        <Link
          onClick={closeSidebar}
          to="/client/settings"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem>
            <p>{translations.Menu.Settings.CustomizeDefectTypes[language]}</p>
          </MenuItem>
        </Link>
      </RoleWrapper>
      <Link
        onClick={closeSidebar}
        to="/options/pole/status/template"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <MenuItem id="poleStatus">
          <p>{translations.Menu.Tools.PoleStatus[language]}</p>
        </MenuItem>
      </Link>

      <Stack spacing={1} width="100%">
        <Divider>
          <Typography fontWeight="bold">
            {translations.Menu.Tools.GlobalTools[language]}
          </Typography>
        </Divider>
      </Stack>
      <DomainManagerLink closeSidebar={closeSidebar}>
        <MenuItem>
          <p>{sidebarTranslations.DomainManagerButtonText[language]}</p>
        </MenuItem>
      </DomainManagerLink>
      <RoleWrapper keyName="objectTypeManager">
        <Link
          onClick={closeSidebar}
          to="/objects"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem>
            <p>{translations.Menu.Tools.Objects[language]}</p>
          </MenuItem>
        </Link>
      </RoleWrapper>
      <Link
        onClick={closeSidebar}
        to="/changelog"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <MenuItem id="changelog">
          <p>{translations.Menu.Tools.Changelog[language]}</p>
        </MenuItem>
      </Link>
    </Stack>
  );
}
