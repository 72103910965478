import { useContext } from "react";
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Clear } from "@mui/icons-material";
import { context } from "./provider";
import Loading from "views/Loading";
import { toast } from "react-toastify";

type RecordOptions = Record<"template_id", string>;

export default function EditTemplate() {
  const { templates, loading, deleteTemplate, updateTemplate } =
    useContext(context);
  const params = useParams<RecordOptions>();
  // @ts-ignore
  const templateID = parseInt(params.template_id);
  const navigate = useNavigate();

  const template = templates.find((t) => t.id === templateID);

  function onClose() {
    navigate("..");
  }
  // @ts-ignore
  function onFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name") as string;
    if (!name) {
      toast.error("Please enter a name");
      return;
    }
    updateTemplate(templateID, name).then(() => {
      navigate("..");
    });
  }
  function onTemplateDelete() {
    const confirmation = window.confirm(
      "Are you sure you want to delete this template?"
    );
    if (!confirmation) {
      return;
    }
    deleteTemplate(templateID)
      .then(() => {
        navigate("..");
      })
      .catch((e) => {
        console.error(e);
        toast.error(
          "Failed to delete template. Check console for more details"
        );
      });
  }

  if (!template)
    return (
      <Dialog open={true} onClose={onClose}>
        <Stack p={2}>
          {loading ? (
            <Stack>
              <Loading relative size={60} color="black" />
              <Typography>Loading templates...</Typography>
            </Stack>
          ) : (
            <Typography>
              Unable to find template with id {templateID}
            </Typography>
          )}
        </Stack>
      </Dialog>
    );

  return (
    <Dialog open={true} onClose={onClose}>
      <Stack p={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Typography>
            Editing template <b>"{template.name}"</b>
          </Typography>
          <IconButton onClick={onClose}>
            <Clear />
          </IconButton>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <form onSubmit={onFormSubmit}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Typography>Name</Typography>
            <TextField
              name="name"
              defaultValue={template.name}
              // disable default dropdown
              select={false}
              autoComplete="off"
              fullWidth
              variant="standard"
              placeholder="Name of template"
            />
          </Stack>

          <Stack alignItems="center" justifyContent="center" my={2}>
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={onTemplateDelete}
            >
              Delete template
            </Button>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            mt={2}
            spacing={2}
          >
            <Button color="error" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" color="success" type="submit">
              Save
            </Button>
          </Stack>
        </form>
      </Stack>
    </Dialog>
  );
}
