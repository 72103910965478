import React, { createContext, useContext, useState } from "react";
var defaultSettings = {
  showCrosshairs: false,
  showHighlightBox: true,
  wasdMode: false,
};
export var SettingsContext = createContext(defaultSettings);

var pullSettingsFromLocalStorage = function pullSettingsFromLocalStorage() {
  if (!window || !window.localStorage) return {};
  var settings = {};

  for (var i = 0; i < window.localStorage.length; i++) {
    var key = window.localStorage.key(i);

    if (key.startsWith("settings_")) {
      try {
        settings[key.replace("settings_", "")] = JSON.parse(
          window.localStorage.getItem(key)
        );
      } catch (e) {}
    }
  }

  return settings;
};

export var useSettings = function useSettings() {
  return useContext(SettingsContext);
};
export var SettingsProvider = function SettingsProvider(_ref) {
  var children = _ref.children;

  var [state, changeState] = useState(function () {
    return pullSettingsFromLocalStorage();
  });

  var changeSetting = function changeSetting(setting, value) {
    changeState({
      ...state,
      setting: value,
    });
    window.localStorage.setItem(
      "settings_".concat(setting),
      JSON.stringify(value)
    );
  };

  return React.createElement(
    SettingsContext.Provider,
    {
      value: { ...state, changeSetting },
    },
    children
  );
};
export default SettingsProvider;
