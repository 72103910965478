import { Stack } from "@mui/material";
import { useSelector, useDispatch } from "hooks";
import Select from "react-select";
import { setSkyqraftHiddenSetting } from "state/actions";
import { skyqraftHiddenSetting } from "api/user/userSettings";

export default function SkyqraftHiddenSettings() {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.user.skyqraft_hidden);

  const options = [
    {
      value: skyqraftHiddenSetting.hideHidden,
      label: "Hide hidden",
    },
    {
      value: skyqraftHiddenSetting.showHidden,
      label: "Show hidden",
    },
    {
      value: skyqraftHiddenSetting.hiddenOnly,
      label: "Show only hidden",
    },
  ];

  return (
    <Stack direction="column" spacing={1} px={2} mt={1}>
      <Select
        options={options}
        value={options.find((option) => option.value === setting)}
        placeholder="Select arkionHidden setting"
        onChange={(option) => {
          // @ts-ignore
          dispatch(setSkyqraftHiddenSetting(option.value));
        }}
      />
    </Stack>
  );
}
