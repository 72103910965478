import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Accordion } from "components";
// @ts-ignore
import md5 from "md5-hash";
import translations from "translations";
import { useLanguage, useLastUploads, useSelector } from "hooks";
import Loading from "views/Loading";

export default function LastUploads() {
  const demoMode = useSelector((state) => state.ui.demoMode);
  const { language } = useLanguage();
  const { data, loading } = useLastUploads();
  return (
    <Accordion
      title={translations.Landing.LastUploads[language]}
      description={translations.Landing.LastUploads.Description[language]}
      value={data.length}
    >
      {loading ? (
        <Loading />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translations.words.ID[language]}</TableCell>
                <TableCell>{translations.words.Mission[language]}</TableCell>
                <TableCell>{translations.words.Folder[language]}</TableCell>
                <TableCell>{translations.words.Timestamp[language]}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((upload, i) => (
                <TableRow key={upload.id}>
                  <TableCell component="th">{upload.id}</TableCell>
                  <TableCell component="th">
                    {demoMode
                      ? md5(upload.mission).substring(0, 4)
                      : upload.mission}
                  </TableCell>
                  <TableCell component="th">{upload.folder}</TableCell>
                  <TableCell component="th">{upload.created}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Accordion>
  );
}
