import { useEffect, useState } from "react";
import { authorizedGet } from "utils/request";
import Template from "../Counters/Template";
import { round } from "utils/functions";
import translations from "translations";
import { useLanguage, useSelector } from "hooks";

export default function PowerlineLidarLength() {
  const { language } = useLanguage();
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [length, setLength] = useState<number>(0);
  const customerId = useSelector((state) => state.mission?.id ?? -1);
  useEffect(() => {
    setLoading(true);
    setFailed(false);
    authorizedGet<{ length: number }>("/statistics/powerline/lidar")
      .then((response) => setLength(response.length))
      .catch(() => setFailed(true))
      .finally(() => setLoading(false));
  }, [customerId]);

  return (
    <Template
      title={translations.Menu.Statistics.lidarLength[language]}
      value={`${round(length / 1000)} km`}
      loading={loading}
      success={!failed}
    />
  );
}
