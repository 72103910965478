import { Checkbox, FormControlLabel } from "@mui/material";
import { useNewDefectsFilter, useLanguage } from "hooks";
import translations from "translations";

export default function NewDefectsFilter() {
  const { newDefects, setNewDefects } = useNewDefectsFilter();
  const { language } = useLanguage();
  return (
    <FormControlLabel
      checked={newDefects}
      sx={{
        "&:hover": {
          backgroundColor: "#EDEDED",
        },
        p: 0,
        m: 0,
        py: 1,
      }}
      // @ts-ignore
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setNewDefects(!e.target.checked)
      }
      control={<Checkbox />}
      label={translations.Filter.NewDefects[language]}
    />
  );
}
