import { connect } from "react-redux";
import Component from "./SupervisorReviewCounter";
import { getSupervisorReviewStats } from "state/actions";
import { RootState } from "state/store";

interface IStateProps {
  nImages: number;
}

interface IDispatchProps {
  getSupervisorReviewStats: (callback?: Function, onError?: Function) => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    nImages: state.statistics.nSupervisorReviewImages,
  };
};

const mapDispatchToProps = {
  getSupervisorReviewStats,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
