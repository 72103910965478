import styled, { keyframes } from "styled-components";
import { IPoleComponentStatusItem } from "views/PoleViewer/api";
import { Tooltip } from "@mui/material";

type Props = {
  status: IPoleComponentStatusItem;
  item: {
    id: number;
    name: string;
    status: IPoleComponentStatusItem[];
  };
  selectedPlacementID: number;
  // @ts-ignore
  placementClick: (e, item, status) => void;
};

const RingContainer = styled.div`
  position: relative;
  display: inline-block; /* Ensure the container takes only the necessary space */
`;

const Circle = styled.div<{ bgColor: string }>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 600;
  color: #353535;
	// @ts-ignore
  background-color: ${(props) => props.bgColor};
`;

const pulsate = keyframes`
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  30% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
    opacity: 0.0;
  }
`;

const CircleRing = styled.div<{ borderColor: string }>`
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: absolute;
  top: -4px; /* Center the ring around the circle */
  left: -4px; /* Center the ring around the circle */
  animation: ${pulsate} 1.5s ease-out infinite;
  opacity: 0.0;
	// @ts-ignore
  border: 3px solid ${(props) => props.borderColor};
`;

export default function CircleDot({
  status,
  item,
  selectedPlacementID,
  placementClick,
}: Props) {
  return (
    <Tooltip title={status.name} placement="top">
      <RingContainer>
        {status.id === selectedPlacementID && (
          <CircleRing borderColor={status.value.color} />
        )}
        {/* @ts-ignore */}
        <Circle
          bgColor={status.value.color}
          // @ts-ignore
          onClick={(e) => placementClick(e, item, status)}
        >
          {status?.value?.name?.substring(0, 3)}
        </Circle>
      </RingContainer>
    </Tooltip>
  );
}
