import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AnnotationContext } from "../provider";
import type { IImageWithMeta } from "./types";
import { axiosInstance } from "utils/request";
import { sortImages } from "utils/utils";
import { useCurrentProject } from "hooks";
import { getThinAnnotations } from "api/image/annotation";

export function useSearchQueryItem(itemName: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  // @ts-ignore
  const item = Number.parseInt(searchParams.get(itemName));

  function setItem(value: number | null) {
    if (value === null) {
      searchParams.delete(itemName);
    } else {
      searchParams.set(itemName, value.toString());
    }
    setSearchParams(searchParams);
  }

  return {
    item,
    setItem,
  };
}

export function useBoxAnnotation(boxID: number) {
  const annotationState = useContext(AnnotationContext);

  const annotations = annotationState.data;
  const currentAnnotation = annotations.find(
    (annotation) => annotation.id === boxID
  );
  return {
    currentAnnotation,
    addItemToBox: annotationState.addItemToBox,
    deleteBoxItem: annotationState.deleteBoxItem,
  };
}

export function useItemPlacementAnnotation(placementID: number) {
  const annotationState = useContext(AnnotationContext);

  function updateImages() {
    if (placementID) {
      annotationState.getItemPlacementImages(placementID);
    }
  }

  useEffect(updateImages, [placementID]);

  return {
    updateImages,
    placementImages: annotationState.poleItemPlacementImages,
    deleteItemPlacementImage: annotationState.deleteItemPlacementImage,
    poleItemPlacementImages: annotationState.poleItemPlacementImages,
    addItemPlacementImage: annotationState.addItemPlacementImage,
  };
}

export function usePoleImages(poleId: number, projectId: number) {
  const [loading, setLoading] = useState<boolean>(true);
  const [images, setImages] = useState<IImageWithMeta[]>([]);

  async function collectImagesFromAPI(project: number, pole: number) {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get<{ images: IImageWithMeta[] }>(
        `/poles/${pole}/images`,
        {
          headers: {
            MissionID: project.toString(),
          },
        }
      );
      const images = sortImages(data.images);
      setImages(images);
    } catch (e) {
      console.error("No pole found");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    collectImagesFromAPI(projectId, poleId);
  }, [projectId, poleId]);

  return { images, loading };
}

type IThinAnnotation = Awaited<ReturnType<typeof getThinAnnotations>>[number];

export function useThinImageAnnotations(imageID: number) {
  const currentProject = useCurrentProject();

  const [annotations, setAnnotations] = useState<IThinAnnotation[]>([]);
  useEffect(() => {
    // @ts-ignore
    getThinAnnotations(currentProject.id, imageID).then(setAnnotations);
    // @ts-ignore
  }, [imageID, currentProject.id]);

  return { annotations };
}
