import { Component } from "react";
import {
  Button,
  IconButton,
  Stack,
  AppBar,
  Tab,
  Tabs,
  Box,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Add, Clear } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import isEqual from "lodash/isEqual";
import groupBy from "lodash/groupBy";
import { toast } from "react-toastify";
import translations from "translations";
import StatusBar from "components/StatusBar";
import Detections from "./Detections";
import Categories from "./Categories";
import { ObjectType, IssueCategory, ObjectCategory } from "interfaces";
import {
  addObjectType,
  updateObjectType,
  updateObjectCat,
  addObjectCat,
  updateStore,
} from "./api";
import RoleWrapper from "components/RoleHOC/wrapper";
import GetData from "./GetData";
import "./style.scss";

interface DataRequestField {
  name: string;
  type: string;
  slug: string;
  value?: any;
  isValid?: Function;
  options?: {
    label: string;
    // @ts-ignore
    value;
  }[];
}

interface Props {
  objectTypes: ObjectType[];
  issueCategories: IssueCategory[];
  detectedCategories: IssueCategory[];
  objectCategories: ObjectCategory[];
  language: string;
}
interface State {
  focus: number | null;
  newObjectTypes: ObjectType[];
  newIssueCategories: IssueCategory[];
  newDetectedCategories: IssueCategory[];
  newObjectCategories: ObjectCategory[];
  initialIssueCategories: IssueCategory[];
  initialDetectedCategories: IssueCategory[];
  initialObjectCategories: ObjectCategory[];
  initialObjectTypes: ObjectType[];
  getNewData: {
    title: string;
    onSubmit: Function;
    onCancel: Function;
    fields: DataRequestField[];
  };
  tab: number;
}

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#0078EB",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
  },
};

function NavigateBack() {
  const navigate = useNavigate();
  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="close"
      onClick={() => {
        navigate(-1);
      }}
    >
      <Clear />
    </IconButton>
  );
}

class ObjectTypeManager extends Component<Props, State> {
  // @ts-ignore
  constructor(props) {
    super(props);

    this.state = {
      focus: null,
      newObjectTypes: props.objectTypes,
      newIssueCategories: props.issueCategories,
      newDetectedCategories: props.detectedCategories,
      newObjectCategories: props.objectCategories,
      initialIssueCategories: props.issueCategories,
      initialDetectedCategories: props.detectedCategories,
      initialObjectCategories: props.objectCategories,
      initialObjectTypes: props.objectTypes,
      // @ts-ignore
      getNewData: null,
      tab: 0,
    };
  }
  // @ts-ignore
  static getDerivedStateFromProps(props, state) {
    if (state.newObjectTypes.length === 0) {
      return {
        newObjectTypes: props.objectTypes,
        newIssueCategories: props.issueCategories,
        newDetectedCategories: props.detectedCategories,
        newObjectCategories: props.objectCategories,
      };
    }
  }

  saveData = async () => {
    const toastRef = toast.info("Updating database", {
      autoClose: false,
    });

    if (!isEqual(this.props.objectTypes, this.state.newObjectTypes)) {
      // Update object types
      const new_types = [];
      const updated_types = [];

      // Find updated and/or created
      for (const newType of this.state.newObjectTypes) {
        const oldType = this.props.objectTypes.find(
          (ot) => ot.id === newType.id
        );
        if (!!oldType && !isEqual(oldType, newType)) {
          updated_types.push(newType);
        }
        if (!oldType) {
          new_types.push(newType);
        }
      }

      // Send changes to database
      for (let i = 0; i < updated_types.length; i++) {
        await updateObjectType(updated_types[i]);
      }
      for (let i = 0; i < new_types.length; i++) {
        await addObjectType(new_types[i]);
      }
    }

    // Do the same for categories
    if (!isEqual(this.props.issueCategories, this.state.newIssueCategories)) {
      const new_cats = [];
      const updated_cats = [];

      // Find updated and/or created
      for (const newCat of this.state.newIssueCategories) {
        const oldCat = this.props.issueCategories.find(
          (c) => c.id === newCat.id
        );
        if (!!oldCat && !isEqual(oldCat, newCat)) {
          updated_cats.push(newCat);
        }
        if (!oldCat) {
          new_cats.push(newCat);
        }
      }

      // Send changes to database
      for (const cat of updated_cats) {
        // @ts-ignore
        await updateObjectCat(cat);
      }
      for (const cat of new_cats) {
        // @ts-ignore
        await addObjectCat(cat);
      }
    }
    const { objectTypes, objectCategories } = await updateStore();
    this.setState(
      {
        newObjectTypes: objectTypes,
        newObjectCategories: objectCategories,
      },
      () => {
        toast.dismiss(toastRef);
        toast.success("Update successful");
      }
    );
  };
  // @ts-ignore
  updateData = (target, id, property, value) => {
    switch (target) {
      case "object_type":
        const updatedTypes = this.state.newObjectTypes.map((t) => {
          if (t.id === id) {
            let newType = {
              ...t,
            };
            // @ts-ignore
            newType[property] = value;
            return newType;
          } else {
            return t;
          }
        });
        this.setState({ newObjectTypes: updatedTypes });
        break;
      case "detected_category":
        const updatedDetectedCategories = this.state.newDetectedCategories.map(
          // @ts-ignore
          (t) => {
            if (t.id === id) {
              let newType = {
                ...t,
              };
              // @ts-ignore
              newType[property] = value;
              return newType;
            } else {
              return t;
            }
          }
        );
        this.setState({ newDetectedCategories: updatedDetectedCategories });
        break;
    }
  };

  editIssueCategory = (cat: IssueCategory) => {
    // @ts-ignore
    const onSubmit = (data) => {
      this.changeIssueCategory({ ...data, id: cat.id });
      // @ts-ignore
      this.setState({ getNewData: null });
    };
    const fields = [
      {
        name: "English name",
        type: "text",
        slug: "en_name",
        value: cat.en_name,
      },
      {
        name: "Swedish name",
        type: "text",
        slug: "se_name",
        value: cat.se_name,
      },
    ];
    this.collectData("Edit Defect Group", fields, onSubmit);
  };

  editDetectedCategory = (cat: IssueCategory) => {
    // @ts-ignore
    const onSubmit = (data) => {
      this.changeDetectedCategory({ ...data, id: cat.id });
      // @ts-ignore
      this.setState({ getNewData: null });
    };
    const fields = [
      {
        name: "English name",
        type: "text",
        slug: "en_name",
        value: cat.en_name,
      },
      {
        name: "Swedish name",
        type: "text",
        slug: "se_name",
        value: cat.se_name,
      },
    ];
    this.collectData("Edit detected Category", fields, onSubmit);
  };

  addIssueCategory = (cat: IssueCategory) => {
    this.setState(
      {
        newIssueCategories: [...this.state.newIssueCategories, cat],
      },
      () => this.saveData()
    );
  };

  addDetectedCategory = (cat: IssueCategory) => {
    this.setState(
      {
        newDetectedCategories: [...this.state.newDetectedCategories, cat],
      },
      () => this.saveData()
    );
  };

  addType = (type: ObjectType) => {
    this.setState(
      {
        newObjectTypes: [...this.state.newObjectTypes, type],
      },
      () => this.saveData()
    );
  };

  createIssueCategory = () => {
    const fields = [
      {
        name: "English name",
        type: "text",
        slug: "en_name",
      },
      {
        name: "Swedish name",
        type: "text",
        slug: "se_name",
      },
    ];
    // @ts-ignore
    const onSubmit = (data) => {
      let uniqueId =
        Math.max(...this.state.newIssueCategories.map((cat) => cat.id)) + 1;
      this.addIssueCategory({ id: uniqueId, ...data });
      // @ts-ignore
      this.setState({ getNewData: null });
    };
    this.collectData("Add Defect Group", fields, onSubmit);
  };

  createDetectedCategory = () => {
    const fields = [
      {
        name: "English name",
        type: "text",
        slug: "en_name",
      },
      {
        name: "Swedish name",
        type: "text",
        slug: "se_name",
      },
    ];
    // @ts-ignore
    const onSubmit = (data) => {
      let uniqueId =
        Math.max(...this.state.newDetectedCategories.map((cat) => cat.id)) + 1;
      this.addDetectedCategory({ id: uniqueId, ...data });
      // @ts-ignore
      this.setState({ getNewData: null });
    };
    this.collectData("Add Object Group", fields, onSubmit);
  };

  changeIssueCategory = (cat: IssueCategory) => {
    this.setState(
      {
        newIssueCategories: this.state.newIssueCategories.map((oldCat) =>
          oldCat.id === cat.id ? cat : oldCat
        ),
      },
      () => this.saveData()
    );
  };

  changeDetectedCategory = (cat: IssueCategory) => {
    this.setState(
      {
        newDetectedCategories: this.state.newDetectedCategories.map((oldCat) =>
          oldCat.id === cat.id ? cat : oldCat
        ),
      },
      () => this.saveData()
    );
  };

  changeType = (ot: ObjectType) => {
    let obtype = this.state.newObjectTypes.map((oldType) =>
      oldType.id === ot.id
        ? {
            ...ot,
          }
        : oldType
    );
    this.setState(
      {
        newObjectTypes: obtype,
      },
      () => this.saveData()
    );
  };

  collectData = (
    title: string,
    fields: DataRequestField[],
    onSubmit: Function,
    onCancel: Function = () => {
      // @ts-ignore
      this.setState({ getNewData: null });
    }
  ) => {
    const dataRequest = {
      title,
      onSubmit,
      onCancel,
      fields,
    };
    this.setState({ getNewData: dataRequest });
  };
  // @ts-ignore
  createType = (category_id) => {
    // @ts-ignore
    const onSubmit = (data) => {
      let uniqueId =
        Math.max(...this.state.newObjectTypes.map((ot) => ot.id)) + 1;
      this.addType({
        id: uniqueId,
        category: category_id,
        ...data,
      }); // @ts-ignore
      this.setState({ getNewData: null });
    };

    const categories = [
      ...this.props.detectedCategories.map((c) => ({
        label: c.en_name,
        value: c.id,
        issue: false,
      })),
      ...this.props.issueCategories.map((c) => ({
        label: c.en_name,
        value: c.id,
        issue: true,
      })),
    ];

    const fields = [
      {
        name: "English name",
        type: "text",
        slug: "en_name", // @ts-ignore
        isValid: (e) => !!e,
      },
      {
        name: "Swedish name",
        type: "text",
        slug: "se_name", // @ts-ignore
        isValid: (e) => !!e,
      },
      {
        name: "Color",
        type: "color",
        slug: "color", // @ts-ignore
        isValid: (e) => !!e,
      },
      {
        name: "Type",
        type: "select",
        options: [
          {
            label: "Issue",
            value: "true",
          },
          { label: "Object", value: "false" },
        ],
        slug: "issue", // @ts-ignore
        isValid: (e) => e !== undefined,
      },
      {
        name: "Category",
        type: "select",
        options: categories,
        slug: "category", // @ts-ignore
        isValid: (e) => e !== undefined,
      },
    ];
    this.collectData("New object type", fields, onSubmit);
  };
  // @ts-ignore
  updateType = (object_id) => {
    const ot = this.state.newObjectTypes.find((o) => o.id === object_id);
    // @ts-ignore
    const onSubmit = (data) => {
      this.changeType({
        ...data,
        id: object_id,
      }); // @ts-ignore
      this.setState({ getNewData: null });
    };

    const categories = (
      ot?.issue ? this.props.issueCategories : this.props.detectedCategories
    ).map((t) => ({
      label: t.en_name,
      value: t.id,
    }));

    const fields = [
      {
        name: "English name",
        type: "text",
        slug: "en_name", // @ts-ignore
        value: ot.en_name,
      },
      {
        name: "Swedish name",
        type: "text",
        slug: "se_name", // @ts-ignore
        value: ot.se_name,
      },
      {
        name: "Color",
        type: "color",
        slug: "color", // @ts-ignore
        value: ot.color,
      },
      {
        name: "Category",
        type: "select",
        options: [...categories],
        slug: "category", // @ts-ignore
        value: ot.category,
      },
    ];
    this.collectData("Edit Object Type", fields, onSubmit);
  };
  // @ts-ignore
  changeTab = (tabValue) => {
    this.setState({
      tab: tabValue,
    });
  };

  render() {
    const { language } = this.props;
    let newGroups = groupBy(
      this.state.newObjectTypes, // @ts-ignore
      (item) => item.categories
    ); // @ts-ignore
    let oldGroups = groupBy(this.props.objectTypes, (item) => item.categories);
    // @ts-ignore
    this.state.newIssueCategories.forEach((cat) => {
      if (!(cat.id in newGroups)) {
        newGroups[cat.id] = [];
      }
      if (!(cat.id in oldGroups)) {
        oldGroups[cat.id] = [];
      }
    });

    return (
      <RoleWrapper keyName="objectTypeManager">
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              direction: "row",
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              zIndex: 100,
            }}
          >
            <Box
              sx={{
                height: "100%",
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <StatusBar
                // @ts-ignore
                title={translations.Menu.Tools.ObjectManager[language]}
              />
              <AppBar
                component="div"
                position="static"
                elevation={0}
                sx={{ zIndex: 0 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Tabs value={this.state.tab} textColor="inherit">
                    <Tab
                      label="All Detections"
                      onClick={() => this.changeTab(0)}
                    />
                    <Tab
                      label="Defect Groups"
                      onClick={() => this.changeTab(1)}
                    />
                    <Tab
                      label="Object Groups"
                      onClick={() => this.changeTab(2)}
                    />
                  </Tabs>

                  <NavigateBack />
                </Stack>
              </AppBar>

              <Box
                sx={{
                  flex: 1,
                  py: 2,
                  px: 4,
                  bgcolor: "#eaeff1",
                  overflowY: "auto",
                }}
              >
                {this.state.tab === 0 && (
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => this.createType(newGroups.id)}
                    className="primaryButton"
                    startIcon={<Add />}
                  >
                    Add new type
                  </Button>
                )}
                <div className="objectContainer">
                  {this.state.tab === 0 && (
                    <Detections // @ts-ignore
                      key={newGroups.id}
                      objectTypes={this.state.newObjectTypes}
                      newObjectCat={this.state.newObjectCategories}
                      updateType={this.updateType}
                    />
                  )}

                  {this.state.tab === 1 && (
                    <>
                      <Categories // @ts-ignore
                        key={newGroups.id}
                        objectTypes={this.state.newIssueCategories}
                        editCategory={this.editIssueCategory}
                      />

                      <Button
                        variant="contained"
                        size="medium"
                        fullWidth
                        onClick={this.createIssueCategory}
                        className="primaryButton"
                        startIcon={<Add />}
                      >
                        Add new defect group
                      </Button>
                    </>
                  )}

                  {this.state.tab === 2 && (
                    <>
                      <Categories // @ts-ignore
                        key={newGroups.id}
                        objectTypes={this.state.newDetectedCategories}
                        editCategory={this.editDetectedCategory}
                      />

                      <Button
                        variant="contained"
                        size="medium"
                        fullWidth
                        onClick={this.createDetectedCategory}
                        className="primaryButton"
                        startIcon={<Add />}
                      >
                        Add new object group
                      </Button>
                    </>
                  )}
                  {!!this.state.getNewData && (
                    <GetData getNewData={this.state.getNewData} />
                  )}
                </div>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </RoleWrapper>
    );
  }
}
export default ObjectTypeManager;
