import styled from "@emotion/styled";

export const AnnotationName = styled.h4`
	font-weight: 800;
	font-size: 14px;
	display: flex;
	align-items: center;
	flex:1;
	width:195px;
	justify-content: flex-start;
	padding:0px;
	margin:0px;
`;
