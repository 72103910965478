import { Alert, Box, Divider, Stack } from "@mui/material";
import FullPage from "components/FullPage";
import { useMemo, useState } from "react";
import { Outlet, Link, useParams } from "react-router-dom";
import { SearchCard, DomainCard } from "../components";
import { useDomains } from "../hooks";
import translations from "../translations";
import { useLanguage } from "hooks";

export default function DomainManager() {
  const { language } = useLanguage();
  const [domainSearch, setDomainSearch] = useState("");
  const { domains, errorMessage, setErrorMessage, loading } = useDomains();

  // Extract the domain from react-router-dom
  const params = useParams<{ domain: string }>();
  const domainId = parseInt(params?.domain || "");

  // Filter the domains based on the search input
  const filteredDomains = useMemo(() => {
    return domains.filter((domain) => {
      const filterable = domain.name + domain.url;
      return filterable.toLowerCase().includes(domainSearch.toLowerCase());
    });
  }, [domains, domainSearch]);

  return (
    <FullPage title="Domain Manager" tabs={[]} tab={-1}>
      <Stack
        direction={{ xs: "row" }}
        overflow="auto"
        maxHeight="100%"
        data-testid="domain-manager-view"
      >
        <Stack
          flex={{
            xs: domainId ? 0 : 1,
            sm: 3,
            md: 3,
            lg: 2,
          }}
          mr={{
            xs: 0,
            sm: 1,
            md: 1,
          }}
          display={{ xs: domainId ? "none" : "block", sm: "block" }}
          pt={2}
          pl={2}
          pr={2}
          pb={4}
          spacing={1}
          overflow="auto"
          maxHeight="100%"
        >
          <SearchCard
            placeholder={translations.SearchDomains[language]}
            value={domainSearch}
            onChange={(e) => setDomainSearch(e.target.value)}
          />
          {errorMessage && (
            <Alert
              severity="error"
              onClose={() => setErrorMessage(null)}
              data-testid="domain-manager-error-alert"
            >
              {errorMessage}
            </Alert>
          )}
          {loading
            ? [1, 2].map((domain) => (
                <DomainCard key={domain} title="" subtitle="" loading={true} />
              ))
            : filteredDomains.map((domain) => (
                <Box key={domain.id}>
                  <Link
                    to={domainId === domain.id ? "." : domain.id.toString()}
                    replace={true}
                    style={{ textDecoration: "none", color: "inherit" }}
                    data-testid={`domain-manager-domain-card-${domain.id}`}
                  >
                    <DomainCard
                      key={domain.id}
                      title={domain.name}
                      subtitle={domain.url}
                      selected={domainId === domain.id}
                    />
                  </Link>
                </Box>
              ))}
        </Stack>
        <Box display={{ xs: domainId ? "block" : "none" }}>
          <Divider orientation="vertical" flexItem />
        </Box>
        <Stack
          flex={{
            xs: domainId ? 1 : 0,
            sm: 5,
            md: 7,
            lg: 7,
          }}
          pt={2}
          pb={4}
          px={2}
          spacing={1}
          display={{ xs: domainId ? "block" : "none", sm: "block" }}
          overflow="auto"
          maxHeight="100%"
        >
          <Outlet />
        </Stack>
      </Stack>
    </FullPage>
  );
}
