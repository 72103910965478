import { ImageType } from "interfaces";
import axios from "axios";
import { setVisibleImageTypes } from "state/actions";
import { BASE_URI } from "config";
import { RootState } from "state/store";

export function setImageTypes(types: ImageType[]) {
  return {
    type: "SET_IMAGE_TYPES",
    payload: types,
  };
}

export function getImageTypes(
  mission_id: number, // @ts-ignore
  callback: () => null = null, // @ts-ignore
  onError: () => null = null
) {
  // @ts-ignore
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const projects = state.user.missions;
    const project = projects.find((p) => p.id === mission_id);
    if (!project) {
      return;
    }
    try {
      const response = await axios.get<{ types: ImageType[] }>(
        `${BASE_URI}/image/types`,
        {
          withCredentials: true,
          headers: {
            MissionID: mission_id,
          },
        }
      );
      dispatch(setImageTypes(response.data.types));
      dispatch(
        setVisibleImageTypes([
          // @ts-ignore
          null,
          ...response.data.types
            .filter((t) => t.default_visible)
            .map((t) => t.id),
        ])
      );
      callback?.();
    } catch (err) {
      onError?.();
    }
  };
}
