import React from "react";
import store, { RootState } from "state/store";
import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import translations from "translations";
import { useParams } from "react-router-dom";
import { getLidarURL } from "authentication/apiDetails";
import { setLidarWindow } from "state/actions";
import { useLanguage } from "hooks";

interface IProps {
  // @ts-ignore
  data;
}

const ZoneItem: React.FC<IProps> = ({ data }) => {
  const { language } = useLanguage();
  const params = useParams();
  const hasLink = !!data.zoneID;
  let copyLink = hasLink
    ? `${window.location.origin}/${
        params.mission
      }?zone=${data.zoneId?.toString()}`
    : "no-link";
  return (
    <>
      <MenuItem
        onClick={() => {
          const state: RootState = store.getState();

          const lidarWindow = state.imageViewer.lidarWindow;
          const LIDAR_URI = getLidarURL();
          if (lidarWindow === null || lidarWindow?.closed) {
            // No lidar window exists
            const URL = `${data.zoneLink}&lat=${data.lat}&lng=${data.lng}${
              state.ui.demoMode ? "&demo=true" : ""
            }`;
            let lidarWindow = window.open(URL, "sq-lidar");
            store.dispatch(setLidarWindow(lidarWindow));
          } else {
            lidarWindow.postMessage(
              {
                type: "OPEN-LATLNG",
                mission: state.mission.id,
                project: data.name,
                lat: data.lat,
                lng: data.lng,
                demoMode: state.ui.demoMode,
              },
              LIDAR_URI
            );
            lidarWindow.focus();
          }
          /*if (state.ui.demoMode) {
            window.open(
              `${data.zoneLink}&lat=${data.lat}&lng=${data.lng}&demo=true`
            );
          } else {
            window.open(`${data.zoneLink}&lat=${data.lat}&lng=${data.lng}`);
          }*/
        }}
        onMouseEnter={() => {
          const state: RootState = store.getState();
          const map = state.map.gmap;

          map.data.overrideStyle(data.feature, { fillColor: "grey" });
        }}
        onMouseLeave={() => {
          const state: RootState = store.getState();
          const map = state.map.gmap;

          map.data.revertStyle(data.feature);
        }}
      >
        <ListItemIcon>
          <ViewInArIcon />
        </ListItemIcon>
        <Typography>
          {translations.Menu.Tools.Powerlines.Zones.Menu.Lidar[language]}
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={() => navigator.clipboard.writeText(copyLink)}
        onMouseEnter={() => {
          const state: RootState = store.getState();
          const map = state.map.gmap;

          map.data.overrideStyle(data.feature, { fillColor: "grey" });
        }}
        onMouseLeave={() => {
          const state: RootState = store.getState();
          const map = state.map.gmap;

          map.data.revertStyle(data.feature);
        }}
        disabled={!hasLink}
      >
        <ListItemIcon>
          <ContentCopyIcon />
        </ListItemIcon>
        <Typography>
          {translations.Menu.Tools.Powerlines.Zones.Menu.Link[language]}
        </Typography>
      </MenuItem>
    </>
  );
};

export default ZoneItem;
