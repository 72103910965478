import React from "react";
import { Switch } from "@mui/material";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { useLanguage } from "hooks";

interface IProps {
  showDatesBeforeProjectName: boolean;
  // @ts-ignore
  setShowDatesBeforeProjectName;
}

function DateBeforeProjectName({
  showDatesBeforeProjectName,
  setShowDatesBeforeProjectName,
}: IProps) {
  const { language } = useLanguage();
  return (
    <MenuItem
      id="clusterSwitch"
      onClick={() => setShowDatesBeforeProjectName(!showDatesBeforeProjectName)}
      tooltip={translations.Menu.Settings.ShowDatesBeforeProjectName[language]}
    >
      <p>{translations.Menu.Settings.ShowDatesBeforeProjectName[language]}</p>
      <Switch color="primary" checked={showDatesBeforeProjectName} />
    </MenuItem>
  );
}
export default DateBeforeProjectName;
