import Select from "react-select";
import { useSearchParams } from "react-router-dom";
import { getFilter, setFilter } from "utils/filter";
import {
  useDispatch,
  useClearanceAxis,
  useCurrentProject,
  useLanguage,
} from "hooks";
import { updateMarkers } from "state/actions";
import translations from "translations";

export default function ClearanceAxisFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { language } = useLanguage();
  const dispatch = useDispatch();
  const currentProject = useCurrentProject();
  const { clearanceAxes } = useClearanceAxis(currentProject?.id ?? -1);
  const selectedClearanceAxes = getFilter("clearanceAxes", searchParams);
  // @ts-ignore
  function setClearanceAxes(axesIDs) {
    setFilter("clearanceAxes", axesIDs, searchParams, setSearchParams);
    dispatch(updateMarkers());
  }

  const axes = clearanceAxes;

  const axesOptions = axes.map((axis) => {
    return {
      value: axis.id,
      label: axis.name.en,
    };
  });
  return (
    <Select
      isMulti
      placeholder={translations.Filter.CleranceAxis[language]}
      options={axesOptions}
      value={axes
        .filter((o) => selectedClearanceAxes.includes(o.id))
        .map((o) => {
          return {
            value: o.id,
            label: o.name.en,
          };
        })}
      onChange={(e) => {
        setClearanceAxes(
          e.map((o) => {
            return o.value;
          })
        );
      }}
    />
  );
}
