import { ReactElement, useState, useEffect } from "react";
import { Checkbox, Typography } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { useCurrentProject, useDispatch, useTranslation } from "hooks";
import { addToList, removeFromList } from "state/actions";

interface Props {
  // @ts-ignore
  lists;
  // @ts-ignore
  image;
}
// @ts-ignore
const ListContainer = ({ lists, image }: { lists; image: number }) => {
  const dispatch = useDispatch();
  const currentProject = useCurrentProject();
  const noListsTranslation = useTranslation("NoList");
  return (
    <div className="compact">
      {lists.length === 0 ? (
        <Typography sx={{ pl: 1, py: 1 }}>{noListsTranslation}</Typography>
      ) : (
        <table>
          <tbody>
            {lists.map(
              // @ts-ignore
              (list, key) => (
                <tr key={`${key}`}>
                  <td>
                    <Checkbox
                      checked={list.images.includes(image)}
                      disabled={list.locked}
                      onChange={() => {
                        if (!list.locked) {
                          if (list.images.includes(image)) {
                            dispatch(
                              // @ts-ignore
                              removeFromList(currentProject.id, list.id, image)
                            );
                          } else {
                            dispatch(
                              // @ts-ignore
                              addToList(currentProject.id, list.id, image)
                            );
                          }
                        }
                      }}
                    />
                    {list.locked && (
                      <Lock
                        style={{
                          transform: "translate(-198%, -14%)",
                          width: "19px",
                        }}
                      />
                    )}
                  </td>
                  <td>{list.title}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default function ImageViewerInterface({
  lists,
  image,
}: Props): ReactElement {
  const [showLists, setShowLists] = useState(false);

  useEffect(() => {
    setShowLists(showLists && !!lists.length);
  }, [lists, showLists]);

  return <ListContainer lists={lists} image={image} />;
}
