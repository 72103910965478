import { useEffect } from "react";
import { DialogContentText, Stack, Typography } from "@mui/material";
import SessionStoragePopupWindow from "components/SessionStoragePopupWindow";

type Props = {
  notify: boolean;
  redirect: boolean;
};

const DOMAIN_MAPPER = {
  "map.skyqraft.com": "app.arkion.co",
  "hawkeye.skyqraft.com": "hawkeye.arkion.co",
  "atom.skyqraft.com": "atom.arkion.co",
  "us.skyqraft.com": "us.arkion.co",
  "dev.skyqraft.com": "dev.arkion.co",
} as const;

export default function NewDomainManager({ notify, redirect }: Props) {
  // Redirect user to new domain
  useEffect(() => {
    if (redirect) {
      redirectUserToNewDomain();
    }
  }, [redirect]);

  function redirectUserToNewDomain() {
    // This function will redirect the user to the new domain
    const domain = window.location.hostname;
    if (!(domain in DOMAIN_MAPPER)) {
      return;
    }
    // @ts-ignore
    const newDomain = DOMAIN_MAPPER[domain];
    window.location.href = `${window.location.protocol}//${newDomain}${window.location.pathname}${window.location.search}`;
  }

  // Only show this if redirect is not enabled
  if (redirect) return null;
  // Return null if notification is off
  if (!notify) return null;

  // Don't show domain we don't want to map
  const oldDomain = window.location.hostname;
  if (!(oldDomain in DOMAIN_MAPPER)) {
    return null;
  }

  // Find the new domain
  // @ts-ignore
  const newDomain = DOMAIN_MAPPER[oldDomain];

  return (
    <SessionStoragePopupWindow
      storageKey="arkion-redirect"
      maxWidth="md"
      forceOpen // Force it top open every time a tab is opened
    >
      <Stack direction="column" spacing={2}>
        <img
          src="/logo_center_400px.png"
          style={{
            alignSelf: "center",
          }}
          alt="Arkion"
        />
        <Typography variant="h4" component="h2">
          New domain available!
        </Typography>
        <DialogContentText>
          We are excited to announce that you can now access your account
          through our new Arkion domains. While the old Skyqraft domains will
          remain functional until September 30th, after this date, you'll be
          automatically redirected to the new{" "}
          <a href={`http://${newDomain}`}>{newDomain}</a> domain.
          <br />
          <br />
          All your data is fully synchronized across both domains. However, we
          encourage you to start using the new Arkion domain at your earliest
          convenience.
          <br />
          <br />
          Please note, if you use a password manager or have saved your password
          in your browser, you will need to re-enter it on the new{" "}
          <a href={`http://${newDomain}`}>{newDomain}</a> domain.
        </DialogContentText>
      </Stack>
    </SessionStoragePopupWindow>
  );
}
