import { HideImage } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { EventHint, captureException } from "@sentry/react";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useCurrentProject } from "hooks";
import { axiosInstance } from "utils/request";
import { ReviewMode } from "views/image/AnnotationPreview";
import useSocket from "hooks/useSocket";
import PrimaryAction from "../PrimaryAction";

interface SocketEventData {
  action: "created" | "deleted";
  image_id: number;
  object_type_ids: number[];
  review_mode_id: number;
}
export interface Props {
  imageId: number;
  reviewModeId: ReviewMode;
  objectTypeIds?: number[];
  onIgnore?: (imageId: number) => void;
  onIgnoreReverted?: (imageId: number) => void;
}
export default function IgnoreImage(props: Props) {
  const {
    imageId,
    reviewModeId,
    objectTypeIds = [],
    onIgnore = () => {},
  } = props;

  const [ignoredImageIds, setIgnoredImageIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentProject = useCurrentProject();
  const { onEvent, removeListeners } = useSocket();

  const disabled = !objectTypeIds.length;
  // @ts-ignore
  const ignored = ignoredImageIds.includes(imageId);

  useEffect(() => {
    const socketEvent = "image_review.ignore_image";
    const socketKey = "ignore_image";
    // @ts-ignore
    onEvent(socketEvent, socketKey, (data: SocketEventData) => {
      // Check that the event is for the same review mode and object types
      if (
        data.review_mode_id !== reviewModeId ||
        !objectTypeIds.some((objectTypeId) =>
          data.object_type_ids.includes(objectTypeId)
        )
      ) {
        return;
      }
      if (data.action === "created") {
        // @ts-ignore
        if (!ignoredImageIds.includes(data.image_id)) {
          // @ts-ignore
          setIgnoredImageIds([...ignoredImageIds, data.image_id]);
        }
      }
      if (data.action === "deleted") {
        setIgnoredImageIds(
          ignoredImageIds.filter((imageId) => imageId !== data.image_id)
        );
      }
    });

    return () => {
      removeListeners(socketKey);
    };
  }, [ignoredImageIds, objectTypeIds]);

  async function ignoreImage() {
    try {
      await axiosInstance.post(
        `/image/${imageId}/review/ignore`,
        {
          reviewModeId,
          objectTypeIds,
        },
        {
          // @ts-ignore
          headers: { MissionID: currentProject.id },
        }
      );
      // @ts-ignore
      setIgnoredImageIds([...ignoredImageIds, imageId]);
      onIgnore(imageId);
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "IgnoreImage.ignoreImage.request",
        originalException: error,
      };
      captureException(error, exceptionHint);
      toast.error("Could not ignore image");
    }
  }

  async function revertIgnoreImage() {
    try {
      await axiosInstance.delete(`/image/${imageId}/review/ignore`, {
        // @ts-ignore
        headers: { MissionID: currentProject.id },
        data: {
          reviewModeId,
          objectTypeIds,
        },
      });
      setIgnoredImageIds(ignoredImageIds.filter((id) => id !== imageId));
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "IgnoreImage.revertIgnoreImage.request",
        originalException: error,
      };
      captureException(error, exceptionHint);
      toast.error("Could not revert ignore image");
    }
  }

  async function toggleIgnoreImage() {
    if (loading || disabled) {
      return;
    }
    setLoading(true);

    if (ignored) {
      await revertIgnoreImage();
    } else {
      await ignoreImage();
    }
    setLoading(false);
  }

  function getTitle() {
    if (disabled) {
      return "Filter on objects types to ignore image";
    }
    return ignored
      ? "Revert ignore image with current review mode and object types"
      : "Ignore image with current review mode and object types";
  }

  return (
    <RoleWrapper keyName="ignoreImage" fallback={null}>
      <PrimaryAction
        color="grey"
        hotkey="e"
        tooltipText={getTitle()}
        disabled={disabled}
        onClick={toggleIgnoreImage}
        icon={<HideImage sx={{ color: ignored ? "#E41616" : "#ffff" }} />}
        data-testid={`ignoreImage.${ignored ? "revertIgnore" : "addIgnore"}`}
      />
    </RoleWrapper>
  );
}
