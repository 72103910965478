import MenuItem from "components/MenuItem";
import translations from "translations";
import Select from "react-select";
import { useLanguage } from "hooks";

interface IProps {
  imageSortingMethod: "compass" | "timestamp";
  // @ts-ignore
  setImageSortingMethod;
}

function ThumbnailSorting({
  imageSortingMethod,
  setImageSortingMethod,
}: IProps) {
  const { language } = useLanguage();
  const options = [
    { value: "compass", label: "Compass" },
    { value: "timestamp", label: "Timestamp" },
  ];

  return (
    <MenuItem
      id="thumbnail-sorting"
      passive
      tooltip={translations.Menu.Settings.ThumbnailSorting[language]}
    >
      <p>{translations.Menu.Settings.ThumbnailSorting[language]}</p>
      <Select
        className="pr-10 pl-10 grow"
        name={"thumbnail-sorting"}
        options={options}
        value={options.find((o) => o.value === imageSortingMethod)}
        onChange={(e) => {
          // @ts-ignore
          setImageSortingMethod(e.value);
        }}
      />
    </MenuItem>
  );
}
export default ThumbnailSorting;
