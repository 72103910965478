import { Clear, Star, StarBorder } from "@mui/icons-material";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import { useFilterPlay, useLanguage } from "hooks";
import { useSavedFilters } from "hooks/filter/useSavedFilters";
import { useMemo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { createSavedFilter } from "api";
import translations from "translations";

export default function FilterPlay() {
  const { language } = useLanguage();
  const [searchParams] = useSearchParams();
  const {
    filterPopulated,
    pauseFilter,
    playFilter,
    clearFilter,
    filterActive,
  } = useFilterPlay();

  function onClick() {
    if (filterActive) {
      pauseFilter();
    } else {
      playFilter();
    }
  }
  const { savedFilters, setTabActive, update } = useSavedFilters();
  useEffect(() => {
    setTabActive(true);
  }, []);
  // @ts-ignore
  function compareFilters(filter, searchParams) {
    const matchesCurrentFilter = Object.keys(filter).every((key) => {
      if (key === "name" || key === "id") return true;
      return filter[key] === searchParams.get(key);
    });
    return matchesCurrentFilter;
  }

  const searchParamsString = searchParams.toString();

  const matchesASavedFilter = useMemo(() => {
    const matchesFilter = savedFilters.some((filter) =>
      compareFilters(filter, searchParams)
    );
    return matchesFilter;
  }, [
    savedFilters,
    searchParamsString,
    searchParams,
    savedFilters,
    filterPopulated,
    filterActive,
    compareFilters,
  ]);

  function saveCurrentFilter() {
    const filterName = window.prompt("Enter a name for the filter");
    if (!filterName) return;
    const search = new URLSearchParams(searchParams);
    // Convert searchParams and filerName to an object
    const filter = {
      name: filterName,
      ...Object.fromEntries(search),
    };
    // Save the filter
    createSavedFilter(filter).then(update);
  }

  return (
    <Stack direction="row">
      <Button
        onClick={onClick}
        variant="outlined"
        disabled={!filterPopulated}
        color={filterActive ? "error" : "success"}
        data-testid={`filters.${filterActive ? "disable" : "apply"}`}
      >
        {filterActive
          ? translations.Filter.DisableFilter[language]
          : translations.Filter.ApplyFilter[language]}
      </Button>
      <Tooltip
        title={translations.Filter.ClearFilter[language]}
        placement="top"
        arrow
      >
        <IconButton
          onClick={clearFilter}
          disabled={!filterPopulated}
          sx={{ ml: 1 }}
        >
          <Clear />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={translations.Filter.SaveCurrentFilter[language]}
        placement="top"
        arrow
      >
        <IconButton
          onClick={saveCurrentFilter}
          disabled={!filterPopulated || matchesASavedFilter}
        >
          {matchesASavedFilter ? (
            <Star htmlColor="#e5e100" />
          ) : (
            <StarBorder htmlColor="#e5e100" />
          )}
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
