import React, { useEffect, useState } from "react";
import TypeSelector from "./TypeSelector";
import { isDetection } from "react-image-annotate/Annotator/reducers/general-reducer";
import "./objects.scss";
import { getClientObjectTypeName } from "utils/utils";
import hasAccess from "utils/authTools";

interface IProps {
  // @ts-ignore
  region;
  onChangeRegion: (newRegion: any) => void;
  // @ts-ignore
  objectTypes;
  // @ts-ignore
  issueSeverities;
  // @ts-ignore
  issueCategories;
  // @ts-ignore
  setSelectedType;
  // @ts-ignore
  setSavedType;
  // @ts-ignore
  user;
  index: number;
  ml_class: boolean;
  // @ts-ignore
  savedRegions;
  // @ts-ignore
  annotatorObjectColor;
}

const SEARCH_TIMEOUT = 10000;
const REGEX_EXPRESSION = /^[a-z A-Z-_()]$/;

export default function SelectedItem({
  region,
  onChangeRegion,
  objectTypes,
  issueSeverities,
  issueCategories,
  setSelectedType,
  setSavedType,
  user,
  index,
  ml_class,
  savedRegions,
  annotatorObjectColor,
}: IProps) {
  const [typeVisible, settypeVisible] = useState();
  const [searchValue, _setSearchValue] = useState("");
  const [lastKeyPress, setLastKeyPress] = useState(new Date(0));
  const [searchOptions, setSearchOptions] = useState([]);
  const [typing, setTyping] = useState(false);
  const [searchIndex, setSearchIndex] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  // @ts-ignore
  let objectTypesNew = objectTypes.map((m) => getClientObjectTypeName(m.id));

  let userHasAccess = hasAccess("annotationToolEditObjects");
  // @ts-ignore
  function enterSearch(e) {
    if (!!!region.highlighted) {
      return null;
    }
    if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
      return null;
    }
    if (!userHasAccess && isDetection(region)) {
      return null;
    }
    if (region.types.length !== 1) {
      return null;
    }
    if (
      searchValue.length > 0 &&
      e.key === "Enter" &&
      searchOptions.length > 0
    ) {
      selectSearched(searchOptions[searchIndex]);
    } else if (searchValue.length > 0 && e.key === "ArrowDown") {
      e.stopPropagation();
      e.preventDefault();
      setSearchIndex(Math.min(searchIndex + 1, searchOptions.length - 1));
    } else if (searchValue.length > 0 && e.key === "ArrowUp") {
      e.stopPropagation();
      e.preventDefault();
      setSearchIndex(Math.max(searchIndex - 1, 0));
    } else if (e.key === "Backspace" && searchValue.length > 0) {
      setSearchIndex(0);
      setSearchValue(searchValue.substring(0, searchValue.length - 1));
      setLastKeyPress(new Date());
    } else if (REGEX_EXPRESSION.test(e.key)) {
      setSearchIndex(0);
      setSearchValue(searchValue + e.key);
      setLastKeyPress(new Date());
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(checkIfShouldClear, SEARCH_TIMEOUT);
    // @ts-ignore
    setTimeoutId(newTimeoutId);
  }

  // The function that clears the timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  function checkIfShouldClear() {
    const currentTime = new Date();
    //@ts-expect-error
    if (currentTime - lastKeyPress >= SEARCH_TIMEOUT) {
      setSearchValue("");
    }
  }
  // @ts-ignore
  function enterSearchBefore(event) {
    setTyping(event);
  }

  useEffect(() => {
    typing && enterSearch(typing);
  }, [typing]);

  useEffect(() => {
    if (!!region.highlighted) {
      document.addEventListener(
        "keydown",
        (event) => enterSearchBefore(event),
        true
      );
    } else if (!region.highlighted && region.highlighted) {
      document.addEventListener(
        "keydown",
        (event) => enterSearchBefore(event),
        true
      );
    }
    return () => {
      document.removeEventListener(
        "keydown",
        (event) => enterSearchBefore(event),
        true
      );
    };
  }, [region.highlighted]);
  // @ts-ignore
  function setSearchValue(newValue) {
    updateSearchSelection();
    _setSearchValue(newValue);
  }
  // @ts-ignore
  function selectSearched(obj) {
    if (!!!region.highlighted) {
      return null;
    }

    let searchDefect = !!region.is_defect[index] && !!!region.types[index];
    const searchSeverity =
      !searchDefect && !!region.is_defect[index] && !!!region.severities[index];
    if (!searchDefect && !searchSeverity) {
      searchDefect = true;
    }

    const searchDetection = !!!region.is_defect[index];

    if (searchDetection) {
      setSearchIndex(0);
      setSearchValue("");
      setSearchOptions([]);
      saveObject(
        {
          ...region,
          // @ts-ignore
          types: region.types.map((value, i) => (i === index ? obj.id : value)),
          // @ts-ignore
          severities: region.severities.map((value, i) =>
            i === index ? null : value
          ),
          // @ts-ignore
          categories: region.categories.map((value, i) =>
            i === index ? null : value
          ),
          editingLabels: false,
          isNew: false,
        },
        "detection"
      );
    } else if (searchDefect) {
      setSearchIndex(0);
      setSearchValue("");
      setSearchOptions([]);
      saveObject(
        {
          ...region,
          // @ts-ignore
          types: region.types.map((value, i) => (i === index ? obj.id : value)),
          // @ts-ignore
          severities: region.severities.map((value, i) =>
            i === index ? null : value
          ),
          // @ts-ignore
          categories: region.categories.map((value, i) =>
            i === index ? obj.categories : value
          ),
          isNew: false,
        },
        "defect"
      );
    } else if (searchSeverity) {
      setSearchIndex(0);
      setSearchValue("");
      setSearchOptions([]);
      saveObject(
        {
          ...region,
          // @ts-ignore
          severity: region.severities.map((value, i) =>
            i === index ? obj.id : value
          ),
          isNew: false,
        },
        "severity"
      );
    }
  }
  // @ts-ignore
  function saveObject(newRegion, variant) {
    if (variant === "detection") {
      setSavedType(newRegion.types);
      setSelectedType(newRegion.types);
    } else if (variant === "defect") {
      setSelectedType(newRegion.types);
    }
    onChangeRegion(newRegion);
  }

  function updateSearchSelection() {
    let searchDefect = !!region.is_defect[index] && !!!region.types[index];
    const searchSeverity =
      !searchDefect && !!region.is_defect[index] && !!!region.severities[index];
    if (!searchDefect && !searchSeverity) {
      searchDefect = true;
    }
    const searchDetection = !!!region.is_defect[index];
    let options;
    if (searchDetection) {
      options = objectTypesNew
        // @ts-ignore
        .filter((o) => !o.issue)
        // @ts-ignore
        .filter((o) =>
          o.name.toLowerCase().includes(searchValue.toLowerCase())
        );
    } else if (searchDefect) {
      options = objectTypesNew
        // @ts-ignore
        .filter((o) => o.issue)
        // @ts-ignore
        .filter((o) =>
          o.name.toLowerCase().includes(searchValue.toLowerCase())
        );
    } else if (searchSeverity) {
      // @ts-ignore
      options = issueSeverities.filter((o) =>
        o.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      options = [];
    }
    setSearchOptions(options);
  }

  return (
    <div className="objectDetails">
      {!!searchValue && <p className="searchValue">{searchValue}</p>}
      {!!searchValue &&
        (searchOptions.length > 0 ? (
          searchOptions.map((o, i) => (
            <p
              key={i}
              className={`searchOption ${
                searchIndex === i ? "highlighted" : ""
              }`}
              onClick={() => selectSearched(o)}
            >
              {/* @ts-ignore */}
              {o.name}
            </p>
          ))
        ) : (
          <p className="searchOption">No objects matches</p>
        ))}

      <TypeSelector
        region={region}
        objectTypes={objectTypesNew}
        issueSeverities={issueSeverities}
        issueCategories={issueCategories}
        // @ts-ignore
        typeVisible={typeVisible}
        onChangeRegion={onChangeRegion}
        saveObject={saveObject}
        user={user}
        ml_class={ml_class}
        index={index}
        savedRegions={savedRegions}
        annotatorObjectColor={annotatorObjectColor}
      />
    </div>
  );
}
