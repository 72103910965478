import { IconButton, Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useContext } from "react";
import { useNavigationBlocker } from "hooks";
import { AnnotationContext } from "../provider";
import { regionsAreEqual } from "./AnnotationsList/Utils";

const exitWithoutSavingMessage = "Do you want to exit without saving?";

export default function ExitButton() {
  const { annotations, originalAnnotations, exit } =
    useContext(AnnotationContext);

  const somethingChanged = !regionsAreEqual(annotations, originalAnnotations);

  const { assertExit } = useNavigationBlocker({
    enableBlocker: somethingChanged,
    confirmationMessage: exitWithoutSavingMessage,
  });

  return (
    <Tooltip title="Exit" placement="top" arrow disableInteractive>
      <IconButton
        onClick={() => {
          if (assertExit()) {
            exit();
          }
        }}
      >
        <Close />
      </IconButton>
    </Tooltip>
  );
}
