import { useParams, useSearchParams } from "react-router-dom";
import {
  useSearchQueryItem,
  usePoleImages,
  useItemPlacementAnnotation,
} from "./hooks";
import ImageDialog from "./components/ImageDialog";

export default function PoleItemPlacementConnector() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  // @ts-ignore
  const projectID = Number.parseInt(params.mission);
  const { item: poleID } = useSearchQueryItem("pole");
  const { item: placementID } = useSearchQueryItem("placementID");
  const { images } = usePoleImages(poleID, projectID);

  const { placementImages, deleteItemPlacementImage, addItemPlacementImage } =
    useItemPlacementAnnotation(placementID);

  function onClose() {
    if (searchParams.has("addPoleItem")) {
      searchParams.delete("addPoleItem");
    }
    if (searchParams.has("box")) {
      searchParams.delete("box");
    }
    if (searchParams.has("placementID")) {
      searchParams.delete("placementID");
    }
    if (searchParams.has("ConnectPlacementImages")) {
      searchParams.delete("ConnectPlacementImages");
    }
    setSearchParams(searchParams);
  }

  const filteredImageIds = placementImages.map((image) => image.id);

  async function connectImage(imageId: number) {
    await addItemPlacementImage(poleID, placementID, imageId);
  }

  async function deleteItem(itemID: number) {
    await deleteItemPlacementImage(poleID, placementID, itemID);
  }

  return (
    <ImageDialog
      title="Connect Images"
      onClose={onClose}
      images={images}
      filterImageIDs={filteredImageIds}
      addImage={connectImage}
      removeImage={deleteItem}
    />
  );
}
