import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Stack,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Paper,
  Input,
  Typography,
  Modal,
  ClickAwayListener,
} from "@mui/material";
import {
  Delete,
  Download,
  Edit,
  ExpandMore,
  FilterList,
  Share,
  Save,
  Clear,
} from "@mui/icons-material";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { context as pathContext } from "providers/NavigationProvider";
import { useInterval, useLanguage, useReport } from "hooks";
import translations from "translations";

import Loading from "views/Loading";

export default function PdfPopup() {
  const { language } = useLanguage();
  const params = useParams();
  const navigate = useNavigate();
  const { lastURL } = useContext(pathContext);
  const context = useOutletContext<{ updateTrigger?: Function }>();
  let updateTrigger = context?.updateTrigger;
  // @ts-ignore
  const report_id = parseInt(params.report_id);
  // @ts-ignore
  const mission = parseInt(params.mission);
  const [editing, setEditing] = useState<boolean>(false);
  const [highlightDownloadButton, setHighlightDownloadButton] =
    useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [otherLoading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);

  // Use custom hook to database
  const [report, updateReport, deleteReport, refresh, loading] = useReport(
    report_id,
    mission
  );
  // @ts-ignore
  let filters_used = [];
  if (report) {
    const uriQuery = new URLSearchParams(report.filter_used);
    if (uriQuery.has("menu")) {
      uriQuery.delete("menu");
    }
    filters_used = uriQuery
      .toString()
      .replaceAll("%2C", ",")
      .split("&")
      .map((s) => s.split("="));
  }

  useEffect(() => {
    if (!loading) {
      if (firstLoad && !!report) {
        // @ts-ignore
        if (!!report?.pending) {
          setHighlightDownloadButton(true);
        }
        setFirstLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, loading]);

  useInterval(() => {
    if (report?.pending) {
      // @ts-ignore
      if (!!updateTrigger) {
        refresh(false, updateTrigger);
      } else {
        refresh(false, () => {});
      }
    }
  }, 1000);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor:
      highlightDownloadButton && !report?.pending
        ? "#757778"
        : "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };

  return (
    <Modal
      open={true}
      onClose={() => navigate(`..${window.location.search}`)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {deleted ? (
          <Box sx={style}>
            <Typography>
              {translations.Inbox.Reports.Deleted[language]}
            </Typography>
          </Box>
        ) : loading || otherLoading ? (
          <Loading />
        ) : !report && !loading ? (
          <Paper sx={style}>
            <Typography>
              {translations.Inbox.Reports.FailedToLoad[language]}
            </Typography>
          </Paper>
        ) : (
          <Box sx={style}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ pb: 2 }}
            >
              <Stack direction="row" justifyContent="flex-start">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // @ts-ignore
                    const name = e.target.name.value;
                    setSaving(true);
                    updateReport({ name }).then(() => {
                      setSaving(false);
                      setEditing(false);
                      updateTrigger && updateTrigger();
                    });
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    {editing ? (
                      <Input
                        autoFocus
                        name="name"
                        disabled={saving}
                        defaultValue={report.name}
                      />
                    ) : (
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        {report.name}
                      </Typography>
                    )}

                    {editing ? (
                      <Stack spacing={2} direction="row">
                        <IconButton disabled={saving} type="submit">
                          <Save />
                        </IconButton>
                        <IconButton
                          disabled={saving}
                          onClick={() => setEditing(false)}
                        >
                          <Clear />
                        </IconButton>
                      </Stack>
                    ) : (
                      <Tooltip
                        title={
                          translations.Inbox.Reports.RenameReport[language]
                        }
                        arrow
                        disableInteractive
                        placement="right"
                      >
                        <Edit
                          onClick={() => {
                            setEditing(true);
                          }}
                          sx={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    )}
                  </Stack>
                </form>
              </Stack>
              <Tooltip
                title={translations.AnnotationTool.Close[language]}
                arrow
                placement="right"
                disableInteractive
              >
                <IconButton
                  onClick={() => {
                    if (lastURL) {
                      navigate(-1);
                    } else {
                      navigate("..");
                    }
                  }}
                >
                  <Clear />
                </IconButton>
              </Tooltip>
            </Stack>

            <Stack direction="row" spacing={4}>
              <Stack justifyContent="center" minWidth={315} spacing={2}>
                <Stack justifyContent="center" alignItems="center">
                  {/* @ts-ignore */}
                  {!!report?.image_url ? (
                    <img
                      src={report?.image_url ?? "/logo_300px_blue.png"}
                      width={report?.image_url ? 315 : "100%"}
                      height={report?.image_url ? 445.5 : "100%"}
                      alt="PDF Preview"
                      style={{
                        boxShadow: "0px 0px 10px rgb(0 0 0 / 25%)",
                        borderRadius: 8,
                        filter:
                          highlightDownloadButton && !report?.pending
                            ? "brightness(0.48)"
                            : "brightness(1)",
                      }}
                    />
                  ) : (
                    <Stack alignItems="center">
                      <Loading relative size={40} />
                      <Typography>
                        {translations.Inbox.Reports.Loading[language]}
                      </Typography>
                    </Stack>
                  )}
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Tooltip
                    title={translations.Inbox.Reports.ApplyOnMap[language]}
                    arrow
                    disableInteractive
                    placement="bottom"
                  >
                    <IconButton
                      onClick={() => {
                        navigate(`/${mission}?${report.filter_used}`);
                      }}
                    >
                      <FilterList />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={translations.Inbox.Reports.Share[language]}
                    arrow
                    disableInteractive
                    placement="bottom"
                  >
                    <IconButton disabled>
                      <Share />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={translations.Inbox.Reports.DeletePDF[language]}
                    arrow
                    disableInteractive
                    placement="bottom"
                  >
                    <IconButton
                      onClick={() => {
                        if (
                          window.confirm(
                            translations.Inbox.Reports.DeleteConfirm[language]
                          )
                        ) {
                          setLoading(true);
                          deleteReport().then(() => {
                            setLoading(false);
                            setDeleted(true);
                            updateTrigger && updateTrigger();
                          });
                        }
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
              <Stack minWidth={315}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>
                    {translations.Inbox.Reports.Created[language]}
                  </Typography>
                  <Typography color="text.secondary">
                    {report.timestamp}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>
                    {translations.Inbox.Reports.Creator[language]}
                  </Typography>
                  <Typography color="text.secondary">
                    {report.creator.name}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>
                    {translations.Inbox.Reports.PdfSize[language]}
                  </Typography>
                  <Typography color="text.secondary">{report.size}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>
                    {translations.Inbox.Reports.Images[language]}
                  </Typography>
                  <Typography color="text.secondary">
                    {report.n_images}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>
                    {translations.Inbox.Reports.Defects[language]}
                  </Typography>
                  <Typography color="text.secondary">
                    {report.n_defects}
                  </Typography>
                </Stack>
                <Divider sx={{ my: 2 }} />
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                      backgroundColor:
                        highlightDownloadButton && !report?.pending
                          ? "#757778"
                          : "background.paper",
                    }}
                  >
                    <Typography fontWeight="bold">
                      {translations.Inbox.Reports.FilterDetails[language]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack>
                      {/* @ts-ignore */}
                      {filters_used.map((filterString, i) => (
                        <Stack
                          key={i}
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Typography>{filterString[0]}</Typography>
                          <Typography
                            color="text.secondary"
                            sx={{
                              maxWidth: 250,
                              whiteSpace: "wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {filterString[1]}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                {!report?.pending && (
                  <div
                    style={{ display: "grid", placeItems: "center", flex: 1 }}
                  >
                    <ClickAwayListener
                      onClickAway={() => {
                        setHighlightDownloadButton(false);
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          padding: "2em",
                          borderRadius: 10,
                          backgroundColor: "white",
                        }}
                      >
                        <a
                          href={report.pdf_url}
                          target="_blank"
                          onClick={() => {
                            setHighlightDownloadButton(false);
                          }}
                          style={{ textDecoration: "none", color: "inherit" }}
                          rel="noreferrer"
                        >
                          <IconButton disabled={!!report.pending}>
                            <Download fontSize="large" />
                          </IconButton>
                        </a>

                        <Typography>
                          {translations.Inbox.Reports.DownloadPDF[language]}
                        </Typography>
                      </div>
                    </ClickAwayListener>
                  </div>
                )}
              </Stack>
            </Stack>
          </Box>
        )}
      </>
    </Modal>
  );
}
