import { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { AnnotationContext } from "../provider";

export default function VerifyAllButton() {
  const { setAnnotations, annotations } = useContext(AnnotationContext);

  const setWorkflow = () => {
    let newAnnotations = annotations.map((annotation) => {
      // @ts-ignore
      let workflow = annotation.workflow_status.map((w) => {
        if (w === 1) {
          w = 2;
        }
        return w;
      });
      return { ...annotation, workflow_status: workflow };
    });
    setAnnotations(newAnnotations);
  };

  const checkWorkflows = () => {
    let reviewRequested = false;
    annotations.map((annotation) => {
      // @ts-ignore
      annotation.workflow_status.map((w) => {
        if (w === 1) {
          reviewRequested = true;
        }
      });
    });
    return reviewRequested;
  };

  return (
    <Tooltip
      title={checkWorkflows() ? "Verify all" : "All verified"}
      arrow
      placement="top"
      disableInteractive
    >
      <IconButton
        onClick={() => setWorkflow()}
        style={{ width: "42px", height: "42px" }}
      >
        {checkWorkflows() ? <CheckBox /> : <CheckBox htmlColor="green" />}
      </IconButton>
    </Tooltip>
  );
}
