import { useState } from "react";
import { Stack, Typography, Switch, Checkbox } from "@mui/material";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { inspectionAssignment } from "api";

type Props = {
  annotators: {
    id: number;
    name: string;
    color: string;
  }[];
  area: google.maps.Polygon;
  setAnnotatorColor: (annotatorID: number, color: string) => void;
  overlays: google.maps.Polygon[];
  editable?: boolean;
  userID: number;
};

export default function AreaAssignment({
  annotators,
  area,
  setAnnotatorColor,
  overlays,
  editable,
  userID,
}: Props) {
  const [assignedAnnotator, setAssignedAnnotator] = useState<number | null>(
    area.get("annotator")
  );
  const [criticalAnalysis, setCriticalAnalysisState] = useState<boolean>(
    area.get("criticalAnalysis")
  );
  const [analysis, setAnalysisState] = useState<boolean>(area.get("analysis"));

  function setAnnotator(annotator: {
    id: number;
    name: string;
    color: string;
  }) {
    const oldAnnotator = assignedAnnotator;
    // Define default values
    // @ts-ignore
    let newAnnotator = null;
    let newOptions = {
      fillColor: "#000000",
      strokeColor: "#000000",
    };

    // Check if we should populate the data
    if (assignedAnnotator !== annotator.id) {
      newAnnotator = annotator.id;
      newOptions = {
        fillColor: annotator.color,
        strokeColor: annotator.color,
      };
    }

    // Update the switch
    setAssignedAnnotator(newAnnotator);

    // Notify the database
    const areaID = area.get("id");
    inspectionAssignment
      // @ts-ignore
      .update(areaID, { annotator: newAnnotator })
      .then(() => {
        // Double verify that the switch is correct
        // This is to avoid race conditions that might appear if you switch too frequently
        // @ts-ignore
        setAssignedAnnotator(newAnnotator);
        // Update the map
        area.setOptions(newOptions);
        // @ts-ignore
        area.set("annotator", newAnnotator);
      })
      .catch(() => {
        setAssignedAnnotator(oldAnnotator);
        toast.error("Failed to update map");
      });
  }

  function setColor(annotatorID: number, newColor: string) {
    overlays.forEach((overlay) => {
      const overlayAnnotator = overlay.get("annotator");
      if (overlayAnnotator === annotatorID) {
        overlay.setOptions({
          fillColor: newColor,
          strokeColor: newColor,
        });
      }
    });
    setAnnotatorColor(annotatorID, newColor);
  }
  const debouncedSetColor = debounce(
    (annotatorID: number, newColor: string) => setColor(annotatorID, newColor),
    150
  );

  function setCriticalAnalysis(value: boolean) {
    const oldAnalysisValue = area.get("criticalAnalysis");
    // Update the switch
    setCriticalAnalysisState(value);

    // Notify the database
    const areaID = area.get("id");
    inspectionAssignment
      .update(areaID, { criticalAnalysis: value })
      .then(() => {
        // Double verify that the switch is correct
        // This is to avoid race conditions that might appear if you switch too frequently
        setCriticalAnalysisState(value);
        // Update the map
        area.set("criticalAnalysis", value);
      })
      .catch(() => {
        setCriticalAnalysisState(oldAnalysisValue);
        toast.error("Failed to update map");
      });
  }

  function setAnalysis(value: boolean) {
    const oldAnalysisValue = area.get("analysis");
    // Update the switch
    setAnalysisState(value);

    // Notify the database
    const areaID = area.get("id");
    inspectionAssignment
      .update(areaID, { analysis: value })
      .then(() => {
        // Double verify that the switch is correct
        // This is to avoid race conditions that might appear if you switch too frequently
        setAnalysisState(value);
        // Update the map
        area.set("analysis", value);
      })
      .catch(() => {
        setAnalysisState(oldAnalysisValue);
        toast.error("Failed to update map");
      });
  }

  return (
    <Stack
      px={2}
      py={1}
      maxHeight={"50vh"}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Typography fontWeight="bold">
        {!!editable ? "Assign area to an annotator" : "Assigned to"}
      </Typography>
      <Stack
        direction="column"
        sx={{ pt: 1 }}
        spacing={1}
        overflow={"auto"}
        paddingRight={0.75}
      >
        {annotators
          .filter((annotator) => annotator.id === userID || editable)
          .map((annotator) => (
            <Stack
              key={annotator.id}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              {editable && (
                <Switch
                  disabled={!editable}
                  checked={assignedAnnotator === annotator.id}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAnnotator(annotator);
                  }}
                />
              )}
              <Typography>{annotator.name}</Typography>

              {editable && (
                <Stack justifyContent="center" alignItems="center">
                  <input
                    type="color"
                    defaultValue={annotator.color}
                    onChange={(e) =>
                      debouncedSetColor(annotator.id, e.target.value)
                    }
                    style={{
                      width: 30,
                      height: 30,
                      cursor: "pointer",
                      boxSizing: "inherit",
                      backgroundColor: "transparent",
                      color: "transparent",
                      borderWidth: 0,
                      borderStyle: "none",
                      overflow: "hidden",
                      borderColor: "transparent",
                      borderImage: "none",
                      padding: 0,
                    }}
                  />
                </Stack>
              )}
            </Stack>
          ))}
      </Stack>
      <Typography fontWeight="bold" sx={{ pt: 2 }}>
        {!!editable ? "Select type of inspection" : "Inspection assigned"}
      </Typography>
      <Stack direction="column" sx={{ pt: 0 }}>
        <Stack direction="row" alignItems="center">
          <Checkbox
            disabled={!editable}
            checked={criticalAnalysis}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setCriticalAnalysis(!criticalAnalysis);
            }}
          />
          <Typography>Critical Analysis</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Checkbox
            disabled={!editable}
            checked={analysis}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setAnalysis(!analysis);
            }}
          />
          <Typography>Analysis</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
