import { Button, FormGroup, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { authorizedPost } from "utils/request";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";

export default function NewRegion() {
  const navigate = useNavigate();
  const [regionName, setRegionName] = useState<string>("");
  // @ts-ignore
  const { group, updateGroup } = useOutletContext<{ group; updateGroup }>();

  async function onCreateGroup() {
    authorizedPost<{ id: number; name: string }>("/regions/new", {
      name: regionName,
      group: group.id,
    })
      .then((region) => {
        updateGroup();
        navigate(`/group/${group.id}/region/${region.id}`);
      })
      .catch(() => {
        toast.error("Failed to create project");
      });
  }
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" component="h2">
        Create a new region
      </Typography>
      <Typography color="text.secondary">
        A region is defined as a geographical location.
      </Typography>
      <Typography color="text.secondary">
        Add the following details to create the region.
      </Typography>
      <FormGroup sx={{ maxWidth: 600, my: 2 }}>
        <TextField
          disabled
          value={group.name}
          variant="standard"
          label="Group"
          sx={{ mb: 2 }}
        />
        <TextField
          onChange={(e) => setRegionName(e.target.value)}
          value={regionName}
          variant="standard"
          label="Name of region"
          required
        />
      </FormGroup>
      <Button
        variant="contained"
        color="success"
        onClick={onCreateGroup}
        disabled={regionName.length < 3}
      >
        Create region
      </Button>
    </Paper>
  );
}
