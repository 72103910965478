import { useLanguage } from "hooks";
import React from "react";
import translations from "translations";
import { getClientObjectTypeName } from "utils/utils";
import "./style.scss";

export var HighlightBox = function HighlightBox(_ref) {
  var mouseEvents = _ref.mouseEvents,
    zoomWithPrimary = _ref.zoomWithPrimary,
    createWithPrimary = _ref.createWithPrimary,
    objectTypes = _ref.objectTypes,
    onBeginMovePoint = _ref.onBeginMovePoint,
    setSelectedType = _ref.setSelectedType,
    onSelectRegion = _ref.onSelectRegion,
    r = _ref.region,
    pbox = _ref.pbox;
  const { language } = useLanguage();
  if (!pbox.w || pbox.w === Infinity) return null;
  if (!pbox.h || pbox.h === Infinity) return null;
  if (r.unfinished) return null;
  var styleCoords = {
    left: pbox.x - 5,
    top: pbox.y - 5,
    width: pbox.w + 10,
    height: pbox.h + 10,
  };

  var pathD = "M5,5 L"
    .concat(pbox.w + 5, ",5 L")
    .concat(pbox.w + 5, ",")
    .concat(pbox.h + 5, " L5,")
    .concat(pbox.h + 5, " Z");

  let types = objectTypes.filter((o) => r.types.includes(o.id));

  return React.createElement(
    "svg",
    Object.assign(
      {
        key: r.id,
        className: `highlightBox${r.highlighted ? " highlighted" : ""}`,
      },
      mouseEvents,
      !zoomWithPrimary
        ? {
            onMouseDown: function onMouseDown(e) {
              if (
                !r.locked &&
                "box" === "point" &&
                r.highlighted &&
                e.button === 0
              ) {
                return onBeginMovePoint(r);
              }

              if (e.button === 0 && !createWithPrimary) {
                setSelectedType(r?.types);
                onSelectRegion(r, { x: e.pageX, y: e.pageY });
              }

              mouseEvents.onMouseDown(e);
            },
          }
        : {},
      {
        style: {
          pointerEvents: r.highlighted
            ? "box" !== "point"
              ? "none"
              : undefined
            : zoomWithPrimary || (createWithPrimary && !r.highlighted)
              ? "none"
              : undefined,
          cursor: r.highlighted
            ? "grab"
            : !zoomWithPrimary
              ? "pointer"
              : createWithPrimary
                ? "crosshair"
                : undefined,
          position: "absolute",
          ...styleCoords,
        },
      }
    ),
    React.createElement(
      "foreignObject",
      {
        x: 11,
        y: types[0]?.name ? -25 * types.length : -25,
        height: 100,
        width: 500,
        className: "infoBoxWrapper",
      },
      <div className="infoBox">
        {types[0]?.name
          ? types.map((e) => {
              return (
                <div key={e.id}>{getClientObjectTypeName(e.id)?.name}</div>
              );
            })
          : translations.AnnotationTool.NewRegion[language]}
      </div>
    ),
    React.createElement("path", {
      d: pathD,
    })
  );
};
export default HighlightBox;
