import { useContext } from "react";
import { AnnotationContext } from "views/AnnotationTool/provider";
import { TextField, Box, Divider } from "@mui/material";
import Select, { components } from "react-select";
import { ISpecialAnnotation } from "views/AnnotationTool/provider/AnnotationProvider/context";
import { checkSteelworkConnection } from "views/AnnotationTool/api";

export type ISteelworkConnection = Awaited<
  ReturnType<typeof checkSteelworkConnection>
>;

interface IProps {
  annotation: ISpecialAnnotation;
  checkConnection: ISteelworkConnection;
}

export default function DropdownEdit({ annotation, checkConnection }: IProps) {
  const {
    updateAnnotation,
    steelworkDropdownValues,
    steelworkLegacyDirectionId,
  } = useContext(AnnotationContext);

  // Custom Control component to include data-testid
  // @ts-ignore
  const CustomControl = ({ dataTestId, props }) => (
    <components.Control
      {...props}
      innerProps={{
        ...props.innerProps,
        "data-testid": dataTestId,
      }}
    />
  );
  // @ts-ignore
  const updateSteelwork = (data) => {
    updateAnnotation(annotation.id, {
      ...annotation,
      steelwork: {
        ...annotation.steelwork,
        ...data,
      },
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <TextField
        id="itemId"
        data-testid="steelwork-item-id-input-field"
        label="Item id"
        size="small"
        sx={{ width: "300px", margin: "3px 9px 8px 20px" }}
        fullWidth
        value={annotation?.steelwork?.item_id}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          updateSteelwork({ item_id: event.target.value });
        }}
      />
      <Select
        components={{
          Control: (props) => (
            <CustomControl
              props={props}
              dataTestId="steelwork-direction-dropdown"
            />
          ),
        }}
        key={`direction-${annotation.steelwork?.direction?.id}`}
        isLoading={false}
        maxMenuHeight={160}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "300px",
            margin: "3px 9px 8px 20px",
          }),
        }}
        // @ts-ignore
        options={steelworkDropdownValues.directions.filter(
          (direction) => direction.id !== steelworkLegacyDirectionId
        )}
        // @ts-ignore
        value={steelworkDropdownValues.directions.find(
          (direction) => direction.id === annotation.steelwork?.direction?.id
        )}
        placeholder={"Direction"}
        getOptionValue={(option) => option.label}
        onChange={(selectedOption) => {
          updateSteelwork({
            direction: {
              // @ts-ignore
              id: selectedOption.id,
              // @ts-ignore
              name: selectedOption.label,
            },
          });
        }}
      />

      <Select
        components={{
          Control: (props) => (
            <CustomControl
              props={props}
              dataTestId="steelwork-second-direction-dropdown"
            />
          ),
        }}
        key={`second_direction-${annotation.steelwork?.second_direction?.id}`}
        isLoading={false}
        maxMenuHeight={160}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "300px",
            margin: "3px 9px 8px 20px",
          }),
        }}
        // @ts-ignore
        options={steelworkDropdownValues.second_directions.concat({
          // @ts-ignore
          id: null,
          label: "None",
        })}
        // @ts-ignore
        value={steelworkDropdownValues.second_directions.find(
          (direction) =>
            direction.id === annotation.steelwork?.second_direction?.id
        )}
        placeholder={"Second Direction"}
        getOptionValue={(option) => option.label}
        onChange={(selectedOption) => {
          updateSteelwork({
            second_direction: {
              // @ts-ignore
              id: selectedOption.id,
              // @ts-ignore
              name: selectedOption.label,
            },
          });
        }}
      />

      <Divider sx={{ width: "100%", my: 1, ml: 3 }}> Properties </Divider>

      <Select
        components={{
          Control: (props) => (
            <CustomControl props={props} dataTestId="steel-grading-dropdown" />
          ),
        }}
        key={`steel-grading-${annotation.steelwork?.steel_grading?.id}`}
        isDisabled={!!annotation.steelwork?.parent_link?.id}
        isLoading={false}
        maxMenuHeight={120}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "300px",
            margin: "3px 9px 8px 20px",
          }),
        }}
        // @ts-ignore
        options={steelworkDropdownValues.steel_gradings.concat({
          // @ts-ignore
          id: null,
          label: "None",
        })}
        // @ts-ignore
        value={steelworkDropdownValues.steel_gradings.find(
          (type) =>
            type.id ===
            (annotation.steelwork?.parent_link?.id // @ts-ignore
              ? checkConnection.steel_grading_id
              : annotation.steelwork?.steel_grading?.id)
        )}
        placeholder={"Steel Grading"}
        getOptionValue={(option) => option.label}
        onChange={(selectedOption) => {
          updateSteelwork({
            steel_grading: {
              // @ts-ignore
              id: selectedOption.id,
              // @ts-ignore
              name: selectedOption.label,
            },
          });
        }}
      />

      <Select
        components={{
          Control: (props) => (
            <CustomControl props={props} dataTestId="bolt-condition-dropdown" />
          ),
        }}
        key={`bolt_condition-${annotation.steelwork?.bolt_condition?.id}`}
        isDisabled={!!annotation.steelwork?.parent_link?.id}
        isLoading={false}
        maxMenuHeight={120}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "300px",
            margin: "3px 9px 8px 20px",
          }),
        }}
        // @ts-ignore
        options={steelworkDropdownValues.bolt_conditions.concat({
          // @ts-ignore
          id: null,
          label: "None",
        })}
        // @ts-ignore
        value={steelworkDropdownValues.bolt_conditions.find(
          (type) =>
            type.id ===
            (annotation.steelwork?.parent_link?.id
              ? // @ts-ignore
                checkConnection.bolt_condition_id
              : annotation.steelwork?.bolt_condition?.id)
        )}
        placeholder={"Bolt Condition"}
        getOptionValue={(option) => option.label}
        onChange={(selectedOption) => {
          updateSteelwork({
            bolt_condition: {
              // @ts-ignore
              id: selectedOption.id,
              // @ts-ignore
              name: selectedOption.label,
            },
          });
        }}
      />
    </Box>
  );
}
