import { useEffect, useState } from "react";
import { authorizedGet } from "utils/request";
import { IProject } from ".";

interface LinkableProjects {
  projects?: [IProject];
}

/**
 * A React hook that collects linkable projects
 *
 *  @example
 *  ```javascript
 *  const [project, updateProject, loading] = useLidarLinkableProjects(25);
 *  ```
 */
export function useLidarLinkableProjects(
  project_id: number
): [IProject[], Function, boolean] {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function updateProjects() {
    collectProjects(project_id);
  }

  function collectProjects(project_id: number) {
    const endpoint = "/lidar/linkable_projects";
    setLoading(true);
    authorizedGet<LinkableProjects>(endpoint, { MissionId: project_id })
      .then((response) => {
        setLoading(false);
        // @ts-ignore
        setProjects(response.projects);
      })
      .catch((err) => {
        setLoading(false);
        setProjects([]);
        console.error("Failed to collect linkable projects", err);
      });
  }

  useEffect(() => {
    collectProjects(project_id);
  }, []);

  return [projects, updateProjects, loading];
}
