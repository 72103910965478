import { EventHint, captureException } from "@sentry/react";
import { qualities } from "constants/image";
import { axiosInstance } from "utils/request";
import { getImageUrl } from "views/image/AnnotationPreview/Canvas/SpeedImage/utils";

// @ts-ignore
export async function preloadImage({ imageId, projectId }) {
  try {
    // Image meta will be cached by browser
    const { data } = await axiosInstance.get(`/image/${imageId}/meta`, {
      headers: { MissionID: projectId },
    });
    const { cdn } = data;
    const nextImageUrl = getImageUrl({
      quality: qualities.LOW,
      cdn,
      i: 0,
      j: 0,
    });

    const nextImg = new Image();
    nextImg.src = nextImageUrl; // Image will be cached by browser
  } catch (error) {
    console.error(error);
    const exceptionHint: EventHint = {
      event_id: "imageReview.preloadImage.request",
      originalException: error,
      data: {
        projectId,
        imageId,
      },
    };
    captureException(error, exceptionHint);
  }
}
