import { Checkbox, FormControlLabel } from "@mui/material";
import {
  useDefectNotProcessedFilter,
  useLanguage,
  useCurrentProject,
} from "hooks";
import translations from "translations";

export default function DefectNotReportedFilter() {
  const { defectNotProcessed, setDefectNotProcessed } =
    useDefectNotProcessedFilter();
  const { language } = useLanguage();

  const currentProject = useCurrentProject();

  if (!currentProject?.processable_defects) {
    return null;
  }

  return (
    <FormControlLabel
      checked={defectNotProcessed}
      sx={{
        "&:hover": {
          backgroundColor: "#EDEDED",
        },
        p: 0,
        m: 0,
        py: 1,
      }}
      // @ts-ignore
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setDefectNotProcessed(!e.target.checked)
      }
      control={<Checkbox />}
      label={translations.Filter.DefectNotProcessed[language]}
    />
  );
}
