import { ReactElement } from "react";

import "./progress.css";

interface Props {
  percentage: number;
  disabled?: boolean;
}

export default function ProgressBar({
  percentage,
  disabled,
}: Props): ReactElement {
  if (disabled) {
    return (
      <div className="progressBar">
        <div style={{ backgroundColor: "grey" }}>-</div>
      </div>
    );
  }

  return (
    <div className="progressBar">
      <div
        style={{
          width: `${100 * (percentage || 0)}%`,
        }}
      >
        <span style={{ marginLeft: 4 }}>
          {`${Math.round(100 * (percentage || 0))}%`}
        </span>
      </div>
    </div>
  );
}
