import { FilterKey } from "hooks/filter/search_parameters/constants";
import { useState, useEffect } from "react";
import { axiosInstance } from "utils/request";

export interface IReportPreview {
  total_images: number;
  total_defects: number;
  defects: IDefectReportPreview[];
}

export interface IDefectReportPreview {
  id: number;
  category: {
    id: number;
    name: string;
  };
  defect: {
    id: number;
    name: string;
  };
  severity: {
    id: number;
    name: string;
  };
  image: {
    name: string;
    id: number;
    url: string;
    image_url: string;
    box: {
      x: number;
      y: number;
      w: number;
      h: number;
    };
    color: string;
    lat: number;
    lng: number;
  };
  pole: {
    id: number;
  };
  properties: {
    lat: number;
    lng: number;
  };
}

/**
 * A React hook that collects data and signed urls for a report for all or one project
 *
 * @param {string} [searchQuery] - The filter search query
 * @param {number} [projectID] - The project ID.
 *
 * It is important that the project ID is the same as the project the report belongs to.
 * This is used to validate that you have access to the report. We can do this implicitly
 * but it's easier this way. (It's a corner we decided to cut)
 *
 * @example
 * // Collect report for everything on project 7.
 * useReportPreview("", 7)
 * // Collect report for severities 1 and 2 on project 3.
 * useReportPreview("?severity=1,2", 3)
 *
 */
export function useReportPreview(
  searchQuery: string,
  projectID: number,
  reportLanguage: string,
  clientNaming: boolean,
  hideFixedDefects: boolean
): [IReportPreview, boolean] {
  const [report, setReport] = useState<IReportPreview | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!projectID) {
      return;
    }
    const query = new URLSearchParams(searchQuery);
    query.set("language", reportLanguage);
    if (hideFixedDefects) {
      query.set("hideFixedDefects", "true");
    }

    if (clientNaming) {
      query.set("clientNaming", "true");
    } else {
      query.set("clientNaming", "false");
    }
    // If the user has selected a list and not severity
    // then we include all severity levels

    if (!query.has(FilterKey.SEVERITY) && query.has(FilterKey.LIST)) {
      query.set(FilterKey.SEVERITY, "1,2,3,4");
    }

    const endpoint = `/report/defect/generate/preview?${query.toString()}`;
    const headers = {
      MissionID: projectID,
    };
    setLoading(true);
    axiosInstance
      .get<IReportPreview>(endpoint, {
        headers,
        withCredentials: true,
      })
      .then((response) => {
        setReport(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setReport(null);
        console.error("Failed to collect reports", err);
      });
  }, [searchQuery, projectID, reportLanguage, hideFixedDefects, clientNaming]);
  // @ts-ignore
  return [report, loading];
}
