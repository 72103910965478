import React from "react";
import Select from "react-select";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { useLanguage } from "hooks";

interface IProps {
  mapType: string;
  gmap: google.maps.Map;
  demoMode: boolean;
  setMapType: (value: string) => void;
}

const MapTypeSelector: React.FC<IProps> = ({
  mapType,
  gmap,
  demoMode,
  setMapType,
}) => {
  const { language } = useLanguage();
  const options = [
    { value: "roadmap", label: "Roadmap" },
    { value: "satellite", label: "Satellite" },
    { value: "hybrid", label: "Hybrid" },
    { value: "terrain", label: "Terrain" },
  ];

  return (
    <MenuItem onClick={() => {}} passive id="mapTypeSelector">
      <p>{translations.Menu.Settings.Map[language]}</p>
      <Select
        className="pr-10 pl-10 grow"
        name={"Map Type"}
        options={
          demoMode ? options.filter((o) => o.value === "satellite") : options
        }
        value={options.find((o) => o.value === mapType)}
        onChange={(e) => {
          // @ts-ignore
          setMapType(e.value);
          // @ts-ignore
          gmap.setMapTypeId(e.value);
        }}
      />
    </MenuItem>
  );
};
export default MapTypeSelector;
