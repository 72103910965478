import { connect } from "react-redux";
import InfoWindow from "./InfoWindow";
import { RootState } from "state/store";
import { ImageMeta } from "interfaces";

interface IStateProps {
  gmap: google.maps.Map;
  imageMeta: ImageMeta;
}

interface IDispatchProps {}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    gmap: state.map.gmap,
    imageMeta: state.image.current,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(InfoWindow);
