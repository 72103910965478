import { useContext } from "react";
import { AnnotationContext } from "views/AnnotationTool/provider";
import {
  Box,
  CardContent,
  Typography,
  Button,
  CardActions,
  Card,
} from "@mui/material";
import { ISpecialAnnotation } from "views/AnnotationTool/provider/AnnotationProvider/context";
import { checkSteelworkConnection } from "views/AnnotationTool/api";

export type ISteelworkConnection = Awaited<
  ReturnType<typeof checkSteelworkConnection>
>;

interface IProps {
  annotation: ISpecialAnnotation;
  checkConnection: ISteelworkConnection;
}

export default function AnnotherMemberDetected({
  annotation,
  checkConnection,
}: IProps) {
  const { updateAnnotation } = useContext(AnnotationContext);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Card sx={{ mx: 3, mb: 3 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Link As Second Image?
          </Typography>
          <Typography variant="body2">
            Identical member detected:
            <br />
            {checkConnection?.item_id
              ? `Item ID: ${checkConnection.item_id}`
              : ""}
            <br />
            {checkConnection?.direction_id
              ? `Direction: ${checkConnection.direction_id}`
              : ""}
            <br />
            {checkConnection?.second_direction_id
              ? `Second Direction: ${checkConnection.second_direction_id}`
              : ""}
            <br />
            {checkConnection?.bolt_condition_id
              ? `Bolt Condition: ${checkConnection.bolt_condition_id}`
              : ""}
            <br />
            {checkConnection?.steel_grading_id
              ? `Steel Grading: ${checkConnection.steel_grading_id}`
              : ""}
          </Typography>
        </CardContent>

        <CardActions>
          <Button
            size="small"
            variant="outlined"
            onClick={() =>
              updateAnnotation(annotation.id, {
                ...annotation,
                steelwork: {
                  ...annotation.steelwork,
                  parent_link: {
                    // @ts-ignore
                    id: checkConnection.steelwork_id,
                    image_object_id: null,
                    image_id: null,
                  },
                },
              })
            }
          >
            Link as second image
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
