import styled from "@emotion/styled";

export const AnnotationToggleListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	z-index: 50;
	position: relative;
	cursor: default;
	border-radius: 5px;
	overflow: scroll;
	max-height: calc(100% - 300px);
	background: rgba(255, 255, 255, 0.73);
	background: rgba(255, 255, 255, 0);
	left: 10px;
	gap: 5px;
	overflow-x: hidden;
	align-items: flex-start;
`;
