import React, { useState } from "react";
import {
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import { LogData } from "./JobUploadView";
import { useParams } from "react-router-dom";
import { Clear, Check, AccessTime as Time } from "@mui/icons-material";
import { BounceLoader } from "react-spinners";
import StatusIndicator from "./StatusIndicator";

type Props = {
  loading?: boolean;
  data: LogData;
};

const LOGCOLOR = {
  debug: undefined,
  info: undefined,
  warning: "#F7B31C",
  error: "#f95050",
  critical: "#d93030",
};

// @ts-ignore
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1, px: 2 }}>{children}</Box>}
    </div>
  );
}

export default function StepView({ data }: Props) {
  const params = useParams();
  const job = params.job;
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <>
      <DialogTitle>
        {/* @ts-ignore */}
        <StatusIndicator status={data.json.status}>
          Upload #{job}
        </StatusIndicator>
        {/* @ts-ignore */}
        <Typography>Created by: {data.json.creator}</Typography>
        {/* @ts-ignore */}
        <Typography>Started at: {data.json.start}</Typography>
        {/* @ts-ignore */}
        <Typography>Watchers: {data.json.watchers.join(", ")}</Typography>
        {/* @ts-ignore */}
        <Typography>{data.json.n_images} images</Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
          }}
        >
          <Tabs
            orientation="vertical"
            value={currentStep}
            onChange={(e, newValue) => {
              setCurrentStep(newValue);
            }}
            aria-label="Vertical tabs example"
            indicatorColor="secondary"
          >
            {/* @ts-ignore */}
            {data.json.steps.map((step, i) => {
              // @ts-ignore
              let IconToUse;
              let iconProps: any = {};
              switch (step.status) {
                case "waiting":
                  IconToUse = Time;
                  break;
                case "running":
                  IconToUse = BounceLoader;
                  iconProps.size = 18;
                  iconProps.color = "#F7F700";
                  break;
                case "cancel":
                  IconToUse = Clear;
                  break;
                case "fail":
                  IconToUse = Clear;
                  break;
                case "success":
                  IconToUse = Check;
                  break;
              }

              return (
                <Box
                  key={i}
                  sx={{
                    backgroundColor: currentStep === i ? "white" : "black",
                  }}
                >
                  <Box
                    onClick={() => setCurrentStep(i)}
                    sx={{
                      backgroundColor: currentStep === i ? "black" : "white",
                      color: currentStep === i ? "white" : "black",
                      borderTopLeftRadius: currentStep === i ? 10 : 0,
                      borderBottomLeftRadius: currentStep === i ? 10 : 0,
                      borderBottomRightRadius:
                        currentStep === i + 1 ? 10 : undefined,
                      borderTopRightRadius:
                        currentStep === i - 1 ? 10 : undefined,
                    }}
                  >
                    <Tab
                      icon={<IconToUse {...iconProps} />}
                      iconPosition="start" // @ts-ignore
                      label={step.title}
                      disableRipple
                    />
                  </Box>
                </Box>
              );
            })}
          </Tabs>
          {/* @ts-ignore */}
          {data.json.steps.map((step, i) => (
            <TabPanel
              key={i}
              value={currentStep}
              index={i}
              style={{
                backgroundColor: "black",
                flex: 1,
                color: "white",
                borderTopLeftRadius: currentStep === 0 ? 0 : 10,
                borderBottomLeftRadius: // @ts-ignore
                  currentStep === data.json.steps.length - 1 ? 0 : 10,
                borderBottomRightRadius: 10,
                borderTopRightRadius: 10,
              }}
            >
              {step.logs.map((log, i) => (
                <Stack
                  key={i}
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  sx={{ background: LOGCOLOR[log.level] }}
                >
                  <Typography sx={{ p: 1 }}>{log.text}</Typography>
                  <Typography sx={{ p: 1 }}>
                    {new Date(parseInt(log.timestamp)).toLocaleDateString()}{" "}
                    {new Date(parseInt(log.timestamp)).toLocaleTimeString()}
                  </Typography>
                </Stack>
              ))}
            </TabPanel>
          ))}
        </Box>
      </DialogContent>
    </>
  );
}
