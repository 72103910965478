import { Close } from "@mui/icons-material";
import { AppBar, Box, IconButton, Stack, Tab, Tabs } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import StatusBar from "components/StatusBar";
import { Link, useNavigate } from "react-router-dom";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#0078EB",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
  },
};

interface Props {
  title: string;
  tab?: number;
  tabs?: {
    label: string;
    // @ts-ignore
    onClick?: (e) => void;
    link?: string;
  }[];
  // @ts-ignore
  children;
  // @ts-ignore
  sx?;
}

export default function FullPage({ title, tab, tabs, children, sx }: Props) {
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          zIndex: 100,
          ...sx,
        }}
      >
        <Box
          sx={{
            height: "100%",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StatusBar title={title} />
          {/* @ts-ignore */}
          {tabs.length > 0 && (
            <AppBar
              component="div"
              position="static"
              elevation={0}
              sx={{ zIndex: 0 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tabs value={tab} textColor="inherit">
                  {/* @ts-ignore */}
                  {tabs.map((tab, i) =>
                    tab.link ? (
                      <Link
                        key={`${i}`}
                        to={tab.link}
                        replace
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Tab label={tab.label} />
                      </Link>
                    ) : (
                      <Tab
                        key={`${i}`}
                        label={tab.label}
                        onClick={tab.onClick}
                      />
                    )
                  )}
                </Tabs>

                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <Close />
                </IconButton>
              </Stack>
            </AppBar>
          )}

          <Box
            sx={{
              flex: 1,
              bgcolor: "#eaeff1",
              overflowY: "auto",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
