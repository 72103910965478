import { ReactNode, createContext, useState, useEffect } from "react";
import {
  updatePoleComponent,
  getAvailablePoleComponents,
  IPoleComponent,
  deletePoleComponent,
  addPoleComponent,
  getPoleValues,
  IPoleSystem,
  updatePoleComponentPlacement,
  deletePoleComponentPlacement,
  addPoleComponentPlacement,
} from "views/PoleViewer/api";
import { Outlet } from "react-router-dom";
import { RootState } from "state/reducers";

interface IContextValue {
  components: IPoleComponent[];
  valueSystems: IPoleSystem[];
  loading: boolean;
  updateComponent: (
    componentID: number,
    name?: string,
    valueSystemID?: number
  ) => Promise<void>;
  deleteComponent: (componentID: number) => Promise<void>;
  createComponent: (name: string, gradingSystemID: number) => Promise<number>;
  updatePlacement: (
    componentID: number,
    placementID: number,
    name?: string,
    regexPattern?: string
  ) => Promise<void>;
  deletePlacement: (componentID: number, placementID: number) => Promise<void>;
  createPlacement: (
    componentID: number,
    name: string,
    regexPattern?: string
  ) => Promise<number>;
  group: RootState["group"]["groups"][number] | null;
  setGroup: (group: RootState["group"]["groups"][number] | null) => void;
  project: RootState["user"]["missions"][number] | null;
  setProject: (project: RootState["user"]["missions"][number] | null) => void;
}

// Set up a context that is importable
const context = createContext<IContextValue>({
  components: [],
  valueSystems: [],
  loading: false,
  updateComponent: async () => {},
  deleteComponent: async () => {},
  createComponent: async () => 0,
  updatePlacement: async () => {},
  deletePlacement: async () => {},
  createPlacement: async () => 0,
  group: null,
  setGroup: () => null,
  project: null,
  setProject: () => null,
});

interface IProps {
  children?: ReactNode;
}

function ComponentOptionsProvider({ children }: IProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [components, setComponents] = useState<IPoleComponent[]>([]);
  const [valueSystems, setValueSystems] = useState<IPoleSystem[]>([]);
  const [group, setGroup] = useState<
    RootState["group"]["groups"][number] | null
  >(null);
  const [project, setProject] = useState<
    RootState["user"]["missions"][number] | null
  >(null);
  // Define the shared state

  async function updateValueSystems(groupID: number | null) {
    // @ts-ignore
    const systems = await getPoleValues(groupID);
    setValueSystems(systems);
  }

  async function updateComponents(groupID: number | null, projectId: number) {
    setLoading(true);
    // @ts-ignore
    const components = await getAvailablePoleComponents(projectId, groupID);
    setComponents(components);
    setLoading(false);
  }

  async function updateComponent(
    componentID: number,
    name: string | null = null,
    valueSystemID: number | null = null
  ) {
    await updatePoleComponent(componentID, name, valueSystemID, group?.id);
    // @ts-ignore
    await updateComponents(group?.id, project?.id);
  }

  async function deleteComponent(componentID: number) {
    await deletePoleComponent(componentID);
    // @ts-ignore
    await updateComponents(group?.id, project?.id);
  }

  async function createComponent(name: string, gradingSystemID: number) {
    const componentID = await addPoleComponent(
      name,
      gradingSystemID,
      group?.id
    );
    // @ts-ignore
    await updateComponents(group?.id, project?.id);
    return componentID;
  }

  async function updatePlacement(
    componentID: number,
    placementID: number,
    name: string | null = null,
    regexPattern: string | null = null
  ) {
    await updatePoleComponentPlacement(
      componentID,
      placementID,
      project?.id,
      name,
      regexPattern,
      group?.id
    );
    // @ts-ignore
    await updateComponents(group?.id, project?.id);
  }

  async function deletePlacement(componentID: number, placementID: number) {
    await deletePoleComponentPlacement(componentID, placementID);
    // @ts-ignore
    await updateComponents(group?.id, project?.id);
  }

  async function createPlacement(
    componentID: number,
    name: string,
    regexPattern: string | null = null
  ) {
    const placementID = await addPoleComponentPlacement(
      componentID,
      name, // @ts-ignore
      project.id,
      regexPattern,
      group?.id
    );
    // @ts-ignore
    await updateComponents(group?.id, project?.id);
    return placementID;
  }

  useEffect(() => {
    // @ts-ignore
    updateComponents(group?.id, project?.id);
    // @ts-ignore
    updateValueSystems(group?.id);
  }, [group, project]);

  // Build the payload to all consumers
  const payload: IContextValue = {
    components,
    valueSystems,
    loading,
    updateComponent,
    deleteComponent,
    createComponent,
    updatePlacement,
    deletePlacement,
    createPlacement,
    group,
    setGroup,
    project,
    setProject,
  };

  // Render the context provider
  return (
    <context.Provider value={payload}>
      {children ? children : <Outlet />}
    </context.Provider>
  );
}

export default ComponentOptionsProvider;
export { context };
export type { IContextValue };
