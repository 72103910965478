import { useSelector } from "react-redux";
import { RootState } from "state/reducers";
// @ts-ignore
import md5 from "md5-hash";
import { useParams } from "react-router-dom";

type Mission = RootState["user"]["missions"][number];

export default function useCurrentProject(): Mission | null {
  const params = useParams();
  const projects = useSelector((state: RootState) => state.user.missions);
  const demoMode = useSelector((state: RootState) => state.ui.demoMode);
  const missionParam = params.mission;
  const currentProject = missionParam ? parseInt(missionParam) : null;

  if (!!projects && !!currentProject) {
    const project = projects.find((project) => project.id === currentProject);

    if (demoMode && project) {
      // If demo mode, anonymize all names without mutating the original redux project state
      return {
        ...project,
        ...(project?.name && { name: md5(project.name).substring(0, 4) }),
        ...(project?.area?.name && {
          area: {
            ...project.area,
            name: md5(project.area.name).substring(0, 4),
          },
        }),
        ...(project?.region?.name && {
          region: {
            ...project.region,
            name: md5(project.region.name).substring(0, 4),
          },
        }),
        ...(project?.group?.name && {
          group: {
            ...project.group,
            name: md5(project.group.name).substring(0, 4),
          },
        }),
      };
    }
    // @ts-ignore
    return project;
  } else {
    return null;
  }
}
