import React from "react";
import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import Header from "./Header";
import { RadialBar } from "@nivo/radial-bar";
import { IDefectReportPreview } from "hooks";
import { Pie } from "@nivo/pie";
import translations from "translations";

const colors = [
  "rgb(109, 181, 127)",
  "rgb(0, 116, 224)",
  "rgb(53, 66, 97)",
  "rgb(179, 173, 161)",
  "rgb(213, 32, 44)",
];

type Props = {
  totalImages: number;
  totalDefects: number;
  defects: IDefectReportPreview[];
  mission: {
    label: string;
    value: number;
    fixable: boolean;
  };
  language: string;
};

const colorOne = "#FF0000";
const colorTwo = "rgb(0, 116, 224)";
// @ts-ignore
const MetricOne = ({ center, bars }) => {
  const totalData = bars
    // @ts-ignore
    .map((bar) => bar.value)
    // @ts-ignore
    .reduce((partialSum, a) => partialSum + a, 0);
  return (
    <text
      x={center[0]}
      y={center[1] + 8}
      textAnchor="middle"
      style={{
        fontSize: 25,
        fill: colorOne,
      }}
    >
      {totalData}
    </text>
  );
};
// @ts-ignore
const MetricTwo = ({ center, bars }) => {
  const totalData = bars
    // @ts-ignore
    .map((bar) => bar.value)
    // @ts-ignore
    .reduce((partialSum, a) => partialSum + a, 0);
  return (
    <text
      x={center[0]}
      y={center[1] + 8}
      textAnchor="middle"
      style={{
        fontSize: 25,
        fill: colorTwo,
      }}
    >
      {totalData}
    </text>
  );
};

export default function SummaryPage({
  totalImages,
  totalDefects,
  defects,
  mission,
  language,
}: Props) {
  let images = new Set();
  let severities = {};
  defects?.forEach((defect) => {
    images.add(defect.image.id);
    // Add the severities
    if (!(defect.severity.id in severities)) {
      // @ts-ignore
      severities[defect.severity.id] = {
        name: defect.severity.name,
        categories: {},
      };
    }
    // Add categories in severities
    // @ts-ignore
    if (!(defect.category.name in severities[defect.severity.id].categories)) {
      // @ts-ignore
      severities[defect.severity.id].categories[defect.category.name] = {
        name: defect.category.name,
        count: 0,
      };
    }
    // @ts-ignore
    severities[defect.severity.id].categories[defect.category.name].count += 1;
  });
  return (
    <Container>
      <Paper sx={{ py: 4, px: 4 }}>
        <Header missionName={mission?.label} />
        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Typography fontSize={18}>
            {
              // @ts-ignore
              translations.Inbox.Reports.Preview.SummaryPage.StatisticsSummary[
                language
              ]
            }
          </Typography>
          <Stack direction="row" spacing={8}>
            <Stack alignItems="center">
              <RadialBar
                width={200}
                height={200}
                maxValue={totalDefects}
                startAngle={0}
                endAngle={-360}
                cornerRadius={0}
                innerRadius={0.5}
                colors={[colorOne]}
                isInteractive={false}
                data={[
                  {
                    id: "defects",
                    data: [
                      {
                        x: "defects",
                        y: defects?.length,
                      },
                    ],
                  },
                ]}
                layers={["tracks", "bars", MetricOne]}
              />
              <Typography>
                {
                  // @ts-ignore
                  translations.Inbox.Reports.Preview.SummaryPage.ofTotal[
                    language
                  ]
                }{" "}
                {totalDefects}{" "}
                {
                  // @ts-ignore
                  translations.Inbox.Reports.Preview.SummaryPage.defects[
                    language
                  ]
                }
              </Typography>
            </Stack>
            <Stack alignItems="center">
              <RadialBar
                width={200}
                height={200}
                maxValue={totalImages}
                startAngle={0}
                endAngle={-360}
                cornerRadius={0}
                innerRadius={0.5}
                colors={[colorTwo]}
                isInteractive={false}
                data={[
                  {
                    id: "images",
                    data: [
                      {
                        x: "images",
                        y: images.size,
                      },
                    ],
                  },
                ]}
                layers={["tracks", "bars", MetricTwo]}
              />
              <Typography>
                {
                  // @ts-ignore
                  translations.Inbox.Reports.Preview.SummaryPage.ofTotal[
                    language
                  ]
                }{" "}
                {totalImages}{" "}
                {
                  // @ts-ignore
                  translations.Inbox.Reports.Preview.SummaryPage
                    .imagesWithDefects[language]
                }
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ width: "100%" }}>
            {Object.keys(severities).map((key) => {
              // @ts-ignore
              const categories = severities[key].categories;
              const catKeys = Object.keys(categories);
              const data = catKeys
                .map((catKey) => {
                  return {
                    id: categories[catKey].name,
                    label: categories[catKey].name,
                    value: categories[catKey].count,
                  };
                })
                .sort((a, b) => b.value - a.value);
              let dataWithColor = [];

              for (let i = 0; i < data.length; i++) {
                if (i > 3 && data.length > 4) {
                  dataWithColor[3].value += data[i].value;
                } else if (i === 3 && data.length > 4) {
                  dataWithColor.push({
                    // @ts-ignore
                    id: translations.Inbox.Reports.Preview.SummaryPage.Other[
                      language
                    ],
                    label:
                      // @ts-ignore
                      translations.Inbox.Reports.Preview.SummaryPage.Other[
                        language
                      ],
                    value: data[i]?.value,
                    color: colors[i],
                  });
                } else if (i === 3 && data.length === 4) {
                  dataWithColor.push({
                    ...data[i],
                    color: colors[i],
                  });
                } else {
                  dataWithColor.push({
                    ...data[i],
                    color: colors[i],
                  });
                }
              }
              return (
                <Grid container sx={{ width: "100%", flex: 1 }}>
                  <Grid item xs={3} display="grid">
                    <Stack
                      alignItems="flex-end"
                      justifyContent="center"
                      flex={1}
                    >
                      {/* @ts-ignore */}
                      <Typography>{severities[key].name}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} display="grid">
                    <Stack
                      alignItems="flex-end"
                      justifyContent="center"
                      flex={1}
                    >
                      <Pie
                        colors={colors.filter(
                          (c, i) => i < dataWithColor.length
                        )}
                        borderWidth={5}
                        borderColor="#FFFFFF"
                        cornerRadius={0}
                        animate={false}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        data={dataWithColor}
                        height={150}
                        width={150}
                      />
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    display="grid"
                    sx={{ placeItems: "center start" }}
                  >
                    <Stack sx={{ ml: 2 }}>
                      {dataWithColor.map((d, i) => (
                        <Stack
                          key={i}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={3}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <div
                              style={{
                                width: 20,
                                height: 20,
                                borderRadius: 10,
                                background: d.color,
                              }}
                            />
                            <Typography>{d.label}</Typography>
                          </Stack>
                          <Typography>{d.value}</Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              );
            })}
          </Stack>
        </Stack>
      </Paper>
    </Container>
  );
}
