import React from "react";
import CMSRichTextContent from ".";

type Props = {
  content: any;
};

export default function CMSHeading({ content }: Props) {
  const attributes = content.attrs;
  let base = null;
  if (!!content.content) {
    base = content.content.map(
      // @ts-ignore
      (c, i) => <CMSRichTextContent key={i} content={c} />
    );
  }
  if (attributes.level === 1) {
    return <h1>{base}</h1>;
  }
  if (attributes.level === 2) {
    return <h2>{base}</h2>;
  }
  if (attributes.level === 3) {
    return <h3>{base}</h3>;
  }
  if (attributes.level === 4) {
    return <h4>{base}</h4>;
  }
  if (attributes.level === 5) {
    return <h5>{base}</h5>;
  }
  if (attributes.level === 6) {
    return <h6>{base}</h6>;
  }
  return <p>Something else</p>;
  return base;
}
