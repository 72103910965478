import styled from "@emotion/styled";

export const ReviewMenuButtons = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-left: 0px;
	margin-right: 3px;
	height: 100%;
`;
