import { useState } from "react";
import {
  Alert,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useCurrentProject, useLanguage } from "hooks";
import { useProjectImageLists } from "./hooks";
import ImageListSelector from "./ImageListSelector";
import Loading from "views/Loading";
import translations from "./translations";
import { toast } from "react-toastify";
import {
  startReportGeneration,
  getReportStatus,
  downloadReportPDF,
} from "./utils";

type ProjectImageListType = ReturnType<
  typeof useProjectImageLists
>["list"][number];

export default function ImageReport() {
  const { language } = useLanguage();
  const [selectedList, setSelectedList] = useState<ProjectImageListType | null>(
    null
  );
  const currentProject = useCurrentProject();
  // @ts-ignore
  const { list, loading } = useProjectImageLists(currentProject?.id);

  function onSelectList(list: ProjectImageListType | null) {
    setSelectedList(list);
  }

  async function generateReport() {
    // Make a manual toast loading
    const toastId = toast.info("Generating report", {
      autoClose: false,
      progress: 0,
    });

    // Trigger a start of the report generation
    const { id: reportId } = await startReportGeneration(
      // @ts-ignore
      currentProject?.id,
      // @ts-ignore
      selectedList.id,
      language
    );

    // Check the status of the report
    let progress = 0;
    let pending = true;

    // Check progress every second
    const interval = setInterval(async () => {
      // Get the current status
      // @ts-ignore
      const response = await getReportStatus(currentProject?.id, reportId);
      progress = response.progress;
      pending = response.pending;

      // Update the toast with the progress
      // and make sure it doesn't reach 100% if it's pending
      toast.update(toastId, {
        render: "Generating report...",
        progress:
          response.progress === 1.0 && pending ? 0.99 : response.progress,
      });

      // Clear the interval if the report is done
      if (!pending) {
        clearInterval(interval);
        toast.update(toastId, {
          render: "Report generated. Showing report...",
          type: "success",
          autoClose: 3000,
          progress: undefined,
        });
        // Download the report
        // @ts-ignore
        const report = await downloadReportPDF(currentProject?.id, reportId);

        // Download the blob
        const url = URL.createObjectURL(report);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `image_report_${reportId}.pdf`);
        document.body.appendChild(link);
        link.click();
      }
    }, 1000);
  }

  return (
    <Container sx={{ pt: 2 }}>
      <Stack spacing={1}>
        {!currentProject && (
          <Alert severity="warning">
            {translations.NoProjectSelected[language]}
          </Alert>
        )}
        {currentProject && !list.length && (
          <Alert severity="info">{translations.NoImageLists[language]}</Alert>
        )}
        <Typography variant="h4" component="h2">
          {translations.ImageReport[language]}
        </Typography>
        <Typography>{translations.ImageReportDescription[language]}</Typography>
        <Divider />
        {loading ? (
          <Loading relative size={50} color="black" />
        ) : (
          <ImageListSelector
            imageList={list}
            selectedList={selectedList}
            onSelect={onSelectList}
          />
        )}
        <Stack direction="row" justifyContent="flex-end">
          <Button
            data-testid="generateReport"
            variant="contained"
            color="primary"
            onClick={generateReport}
            disabled={!selectedList}
          >
            {translations.GenerateReport[language]}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
