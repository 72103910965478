import { useEffect, useState } from "react";
import {
  Stack,
  Button,
  TextField,
  Paper,
  Divider,
  Typography,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import Select from "react-select";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { getCloudFolders } from "state/actions";
import { useDispatch, useSelector } from "hooks";

export default function SelectFolder() {
  const dispatch = useDispatch();
  const cloudFolders = useSelector((state) => state.imageUpload.cloudFolders);
  const params = useParams();
  const navigate = useNavigate();
  const folder = params.folder;
  const [newFolder, setNewFolder] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const options = cloudFolders.map((c) => ({
    value: c,
    label: c,
  }));

  useEffect(() => {
    dispatch(getCloudFolders());
  }, [params.mission, getCloudFolders]);

  return (
    <>
      <Divider flexItem sx={{ pb: 2 }}>
        Select upload folder
      </Divider>
      <Stack spacing={2} direction={{ xs: "column", sm: "column", md: "row" }}>
        <Paper sx={{ flex: 1 }}>
          <DialogTitle>
            <Typography variant="inherit">Create a new folder</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
              When uploading to the Arkion software, we have a folder that you
              upload to. This folder contains a subset of all images for the
              mission. We recommend that you split your uploads into date
              folders.
            </Typography>
            <Typography>
              The folder you upload from your computer can contain subfolders.
              They should be the folders from your drones' memory card(s). Each
              subfolder is assigned its own flight id. This usage of subfolders
              is an optional feature.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ pb: 3, pr: 3 }}>
            <TextField
              required
              id="standard-required"
              value={newFolder}
              variant="standard"
              onChange={(e) => {
                if (e.target.value === "" && folder !== "") {
                  navigate(".");
                  setNewFolder(e.target.value);
                } else {
                  setNewFolder(e.target.value);
                }
              }}
              sx={{ flex: 1, mx: 2 }}
              type="search"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                toast.success(`Folder ${newFolder} was added.`);
                navigate(newFolder);
              }}
            >
              <Typography variant="inherit" sx={{ color: "white" }}>
                New folder
              </Typography>
            </Button>
          </DialogActions>
        </Paper>
        <Divider orientation="vertical" flexItem>
          or
        </Divider>
        <Paper sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <DialogTitle>
            <Typography variant="inherit">Select an existing folder</Typography>
          </DialogTitle>

          <DialogContent sx={{ flex: 1 }}>
            <Typography>
              You can of course upload to an already existing folder. Please
              choose one from the list below
            </Typography>
          </DialogContent>
          <DialogActions>
            <Stack sx={{ flex: 1, p: 2 }}>
              <Select
                options={options}
                value={{ value: folder, label: folder }}
                onChange={(e) => {
                  if (!!e) {
                    // @ts-ignore
                    navigate(e.value);
                  } else {
                    navigate(".");
                  }
                }}
                isClearable
              />
            </Stack>
          </DialogActions>
        </Paper>
      </Stack>
    </>
  );
}
