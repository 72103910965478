import React from "react";
import { Switch } from "@mui/material";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { useLanguage } from "hooks";

interface IProps {
  clusterVisible: boolean;
  // @ts-ignore
  setClusterVisible;
}

function ClusterSwitch({ clusterVisible, setClusterVisible }: IProps) {
  const { language } = useLanguage();
  return (
    <MenuItem
      id="clusterSwitch"
      onClick={() => setClusterVisible(!clusterVisible)}
      tooltip={translations.Menu.Settings.Cluster[language]}
    >
      <p>{translations.Menu.Settings.Cluster[language]}</p>
      <Switch color="primary" checked={clusterVisible} />
    </MenuItem>
  );
}
export default ClusterSwitch;
