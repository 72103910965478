import React from "react";
import CMSRichTextContent from ".";
type Props = {
  content: any;
};
export default function CMSBulletList({ content }: Props) {
  return (
    <ul>
      {content.map(
        // @ts-ignore
        (c, i) => (
          <CMSRichTextContent key={i} content={c} />
        )
      )}
    </ul>
  );
}
