import ImageLayer from "./ImageLayer";

type Props = {
  // @ts-ignore
  imagePosition;
  // @ts-ignore
  onLoad;
  imageSrc: string;
  // @ts-ignore
  mat;
  // @ts-ignore
  dimensions;
  cdn: string | null;
};

export default function SpeedImage({
  onLoad,
  imagePosition,
  mat,
  dimensions,
  imageSrc,
  cdn,
}: Props) {
  return (
    <div>
      <ImageLayer
        quality="low"
        imageSrc={imageSrc}
        imagePosition={imagePosition}
        dimensions={dimensions}
        mat={mat}
        onLoad={onLoad}
        cdn={cdn}
      />
      <ImageLayer
        quality="medium"
        imageSrc={imageSrc}
        imagePosition={imagePosition}
        dimensions={dimensions}
        mat={mat}
        cdn={cdn}
      />
      <ImageLayer
        quality="high"
        imageSrc={imageSrc}
        imagePosition={imagePosition}
        dimensions={dimensions}
        mat={mat}
        cdn={cdn}
      />
    </div>
  );
}
