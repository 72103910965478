export interface IState {
  totalGridLength: number;
  totalInspectedLength: number;
  totalReflightLength: number;
  totalImageLength: number;
  machineDetections: number;
  machineInventoryDetections: number;
  machineDefectDetections: number;
  humanDetections: number;
  humanInventoryDetections: number;
  humanDefectDetections: number;
  workflowStatus: number[];
  defectFixedObjects: number;
  issueCategoryCount: Array<any>;
  truePositive: Array<any>;
  falsePositive: Array<any>;
  falseNegative: Array<any>;
  totalPoles: number;
  nImages: number;
  nFlaggedImages: number;
  nMachineReviewImages: number;
  nSupervisorReviewImages: number;
}

export const initialState: IState = {
  totalGridLength: 0,
  totalInspectedLength: 0,
  totalReflightLength: 0,
  totalImageLength: 0,
  machineDetections: 0,
  machineInventoryDetections: 0,
  machineDefectDetections: 0,
  humanDetections: 0,
  humanInventoryDetections: 0,
  humanDefectDetections: 0,
  workflowStatus: [0, 0, 0, 0],
  defectFixedObjects: 0,
  issueCategoryCount: [],
  truePositive: [],
  falsePositive: [],
  falseNegative: [],
  totalPoles: 1,
  nImages: 0,
  nFlaggedImages: 0,
  nMachineReviewImages: 0,
  nSupervisorReviewImages: 0,
};

// @ts-ignore
const statisticsReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case "UPDATE_FLIGHT_STATISTICS":
      return {
        ...state,
        ...action.payload,
      };
    case "UPDATE_POWERLINE_STATISTICS":
      return {
        ...state,
        totalGridLength: action.payload.totalLength,
        totalInspectedLength: action.payload.inspectedLength,
        totalReflightLength: action.payload.reflightLength,
        totalImageLength: action.payload.imagesLength,
      };
    case "UPDATE_OBJECT_STATISTICS":
      return {
        ...state,
        machineDetections: action.payload.machineDetections,
        machineInventoryDetections: action.payload.machineInventoryDetections,
        machineDefectDetections: action.payload.machineDefectDetections,
        humanDetections: action.payload.humanDetections,
        humanInventoryDetections: action.payload.humanInventoryDetections,
        humanDefectDetections: action.payload.humanDefectDetections,
        defectFixedObjects: action.payload.defectFixedObjects,
      };
    case "UPDATE_STATISTICS_ACCURACY":
      return {
        ...state,
        truePositive: action.payload.truePositive,
        falsePositive: action.payload.falsePositive,
        falseNegative: action.payload.falseNegative,
      };
    case "UPDATE_POLES_STATISTICS":
      return {
        ...state,
        totalPoles: action.payload,
      };
    case "UPDATE_CATEGORY_STATISTICS":
      return {
        ...state,
        issueCategoryCount: action.payload,
      };
    case "UPDATE_WORKFLOW_STATISTICS":
      return {
        ...state,
        workflowStatus: action.payload,
      };
    case "SET_IMAGE_COUNT":
      return {
        ...state,
        nImages: action.payload,
      };
    case "SET_FLAGGED_IMAGE_COUNT":
      return {
        ...state,
        nFlaggedImages: action.payload,
      };
    case "SET_MACHINE_REVIEW_IMAGE_COUNT":
      return {
        ...state,
        nMachineReviewImages: action.payload,
      };
    case "SET_SUPERVISOR_REVIEW_IMAGE_COUNT":
      return {
        ...state,
        nSupervisorReviewImages: action.payload,
      };
    default:
      return state;
  }
};

export default statisticsReducer;
