import { useContext, useEffect } from "react";
import { Tab, Tabs, Button } from "@mui/material";
import { Bolt, Park, Public, Save, Warning } from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";
import { Stack } from "@mui/system";
import FilterTitle from "./FilterTitle";
import FilterPlay from "./FilterPlay";
import ArrowBackItem from "../ArrowBackItem";
import {
  SeverityFilters,
  ElectricFilters,
  VegetationFilters,
  SpatialFilters,
  SavedFilters,
} from "./tabs";
import { context as FilterContext } from "providers/FilterProvider";
import { useFilterPopulated } from "hooks/filter/useFilterPopulated";
import {
  useActorsCount,
  useCurrentProject,
  useDefectCount,
  useDetectionCount,
  useFlightCount,
  useLanguage,
  useSeverityCount,
  useSidebar,
  useWorkflowCount,
} from "hooks";
import translations from "translations";
import ActiveDot from "icons/ActiveDot";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index } = props;
  return value === index && <div style={{ overflow: "auto" }}>{children}</div>;
}

export default function Filters() {
  const currentProject = useCurrentProject();
  const { language } = useLanguage();
  const [searchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();
  const { filterTab, setFilterTab } = useContext(FilterContext);
  const severityCount = useSeverityCount();
  const defectCount = useDefectCount();
  const workflowCount = useWorkflowCount();
  const detectionCount = useDetectionCount();
  const flightCount = useFlightCount();
  const actorCount = useActorsCount();
  const {
    severityTabActive,
    electricalTabActive,
    vegetationTabActive,
    spatialTabActive,
    savedTabActive,
  } = useFilterPopulated();
  const { closeSidebar } = useSidebar();

  useEffect(() => {
    severityCount.setActive(filterTab === 0);
    workflowCount.setActive(filterTab === 0);
    defectCount.setActive(filterTab === 1 || filterTab === 2);
    detectionCount.setActive(filterTab === 1 || filterTab === 2);
    flightCount.setActive(filterTab === 3);
    actorCount.setActive(filterTab === 3);
  }, [
    filterTab,
    severityCount,
    workflowCount,
    defectCount,
    detectionCount,
    flightCount,
    actorCount,
  ]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setFilterTab(newValue);
  };
  return (
    <Stack
      direction="column"
      spacing={1}
      alignItems="center"
      position="relative"
      height="100%"
      width="100%"
    >
      <ArrowBackItem>
        {currentProject && (
          <Link
            onClick={closeSidebar}
            to={`${currentProject.id}/report/defect${
              searchParamsString.length > 0 ? `?${searchParamsString}` : ""
            }`}
          >
            <Button>{translations.Filter.CreateReport[language]}</Button>
          </Link>
        )}
      </ArrowBackItem>
      <FilterTitle />
      <FilterPlay />

      <Tabs
        value={filterTab}
        onChange={handleChange}
        scrollButtons
        allowScrollButtonsMobile
        sx={{ borderBottom: "1px solid #bbb" }}
      >
        <Tab
          icon={
            <Stack position="relative">
              <Warning />
              <ActiveDot active={severityTabActive} />
            </Stack>
          }
          label={translations.Filter.Severity[language]}
        />
        <Tab
          icon={
            <Stack position="relative" data-testid="filterMenu.electricTab">
              <Bolt />
              <ActiveDot active={electricalTabActive} />
            </Stack>
          }
          label={translations.Filter.Electric[language]}
        />
        <Tab
          icon={
            <Stack position="relative">
              <Park />
              <ActiveDot active={vegetationTabActive} />
            </Stack>
          }
          label={translations.Filter.Vegetation[language]}
        />
        <Tab
          icon={
            <Stack position="relative">
              <Public />
              <ActiveDot active={spatialTabActive} />
            </Stack>
          }
          label={translations.Filter.Spatial[language]}
        />
        <Tab
          icon={
            <Stack position="relative">
              <Save />
              <ActiveDot active={savedTabActive} />
            </Stack>
          }
          label={translations.Filter.Saved[language]}
        />
      </Tabs>
      <Stack
        sx={{
          width: "100%",
          position: "relative",
          minHeight: 300,
          flex: 1,
        }}
      >
        {/* @ts-ignore */}
        <CustomTabPanel value={filterTab} index={0}>
          <SeverityFilters />
        </CustomTabPanel>
        {/* @ts-ignore */}
        <CustomTabPanel value={filterTab} index={1}>
          <ElectricFilters />
        </CustomTabPanel>
        {/* @ts-ignore */}
        <CustomTabPanel value={filterTab} index={2}>
          <VegetationFilters />
        </CustomTabPanel>
        {/* @ts-ignore */}
        <CustomTabPanel value={filterTab} index={3}>
          <SpatialFilters />
        </CustomTabPanel>
        {/* @ts-ignore */}
        <CustomTabPanel value={filterTab} index={4}>
          <SavedFilters />
        </CustomTabPanel>
      </Stack>
    </Stack>
  );
}
