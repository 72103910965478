import { Button } from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import translations from "translations";
import { useLanguage } from "hooks";
import { toast } from "react-toastify";
import { getLambdaAxiosWithAuth } from "utils/request";

type Languages = ReturnType<typeof useLanguage>["language"];

export async function getUsageReport(
  reportType: "csv" | "excel",
  language: Languages
) {
  const generationPrompt =
    reportType === "csv"
      ? translations.Menu.Tools.UsageReport.GeneratingCSV[language]
      : translations.Menu.Tools.UsageReport.GeneratingExcel[language];
  const fileType = reportType === "csv" ? "csv" : "xlsx";

  const toastPointer = toast.info(generationPrompt, { autoClose: false });
  const axiosInstance = await getLambdaAxiosWithAuth();
  const endpoint = `/report/usage/${reportType}`;
  const response = await axiosInstance.get(endpoint, {
    responseType: "blob",
  });
  const report = response.data;
  toast.dismiss(toastPointer);
  toast.success(translations.Menu.Tools.UsageReport.Generated[language]);
  const url = window.URL.createObjectURL(
    new Blob([report], { type: "application/octet-stream" })
  );
  const link = document.createElement("a");
  link.href = url;
  const d = new Date();
  link.setAttribute(
    "download",
    `usage_report_${d.getFullYear()}-${
      d.getMonth() + 1
    }-${d.getDate()}_${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}.${fileType}`
  );
  document.body.appendChild(link);
  link.click();
}

export default function UsageReport() {
  const { language } = useLanguage();
  return (
    <RoleWrapper keyName="usageReport">
      <h2>{translations.Menu.Tools.UsageReport[language]}</h2>
      <p>{translations.Menu.Tools.UsageReport.Tooltip[language]}</p>
      <hr />
      <Button
        onClick={() => getUsageReport("excel", language)}
        variant="contained"
        className="primaryButton"
        style={{ marginLeft: 5, textTransform: "none" }}
      >
        Excel
      </Button>

      <Button
        onClick={() => {
          getUsageReport("csv", language);
        }}
        variant="contained"
        className="secondaryButton"
        style={{ marginLeft: 5, textTransform: "none" }}
      >
        CSV
      </Button>
    </RoleWrapper>
  );
}
