import { axiosInstance } from "utils/request";
import { DomainSchema, DetailedDomainSchema, GroupSchema } from "./schemas";

// Create a new domain
export async function addGroupToDomain(domainId: number, groupId: number) {
  const endpoint = `/domains/${domainId}/group`;
  await axiosInstance.post(endpoint, { group_id: groupId });
}

// Delete a group from a domain
export async function removeGroupFromDomain(domainId: number, groupId: number) {
  const endpoint = `/domains/${domainId}/group/${groupId}`;
  await axiosInstance.delete(endpoint);
}

// Get a list of all available domains
export async function getDomains() {
  const endpoint = "/domains";
  const response = await axiosInstance.get(endpoint);
  const data = response.data;

  // Validate, parse and return the data using the schema
  const parsedData = DomainSchema.array().parse(data);
  return parsedData;
}

// Get the detailed information about a domain
export async function getDomain(domainId: number) {
  const endpoint = `/domains/${domainId}`;
  const response = await axiosInstance.get(endpoint);
  const data = response.data;

  // Validate, parse and return the data using the schema
  const parsedData = DetailedDomainSchema.parse(data);
  return parsedData;
}

export async function getGroups() {
  const endpoint = "/domains/groups";
  const response = await axiosInstance.get(endpoint);
  const data = response.data;

  // Validate, parse and return the data using the schema
  const parsedData = GroupSchema.array().parse(data);
  return parsedData;
}
