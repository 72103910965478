import React, { ReactElement } from "react";
import translations from "translations";
import { ResponsiveBar } from "@nivo/bar";
import { useLanguage } from "hooks";

interface Props {
  truePositive: Array<any>;
  falsePositive: Array<any>;
  falseNegative: Array<any>;
  totalPoles: number;
}
// @ts-ignore
const customeTooltip = ({ id, data }) => (
  <div
    style={{
      padding: 5,
      borderRadius: 2,
      boxShadow: "1px 1px",
      background: "#ffff",
    }}
  >
    {id}:<strong>{data[id] + "%"}</strong>
  </div>
);
// @ts-ignore
const MyResponsiveBar = ({ data }) => (
  <ResponsiveBar
    tooltip={customeTooltip}
    layout="vertical"
    groupMode="grouped"
    data={data}
    keys={["Accuracy", "Precision", "Recall"]}
    indexBy="type"
    margin={{ top: 40, right: 60, bottom: 130, left: 60 }}
    padding={0.3}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "nivo" }}
    borderColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    axisBottom={{ tickRotation: 45 }}
    axisTop={null}
    axisRight={null}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    role="application"
    ariaLabel="Nivo bar chart demo"
    barAriaLabel={function (e) {
      return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
    }}
  />
);

export default function MachineDetectionCategoriesChart({
  truePositive,
  falsePositive,
  falseNegative,
  totalPoles,
}: Props): ReactElement {
  const { language } = useLanguage();

  // @ts-ignore
  let dataMachineAccuracy = [];
  // @ts-ignore
  let type_id = [];
  // @ts-ignore
  let TP = [];
  // @ts-ignore
  let FN = [];
  // @ts-ignore
  let FP = [];
  // @ts-ignore
  let TN = [];

  function MachineValues() {
    // @ts-ignore
    let Accuracy = [];
    // @ts-ignore
    let Precision = [];
    // @ts-ignore
    let Recall = [];
    // @ts-ignore
    truePositive.forEach((e) => {
      // @ts-ignore
      type_id.push({ name: e.name, sv_name: e.sv_name, type_id: e.type_id });
    });
    // @ts-ignore
    falseNegative.forEach((e) => {
      // @ts-ignore
      type_id.findIndex((item) => item.type_id === e.type_id) === -1 &&
        // @ts-ignore
        type_id.push({ name: e.name, sv_name: e.sv_name, type_id: e.type_id });
    });
    // @ts-ignore
    falsePositive.forEach((e) => {
      // @ts-ignore
      type_id.findIndex((item) => item.type_id === e.type_id) === -1 &&
        // @ts-ignore
        type_id.push({ name: e.name, sv_name: e.sv_name, type_id: e.type_id });
    });
    // @ts-ignore
    type_id.forEach((e, i) => {
      let TP_index = truePositive.findIndex(
        (item) => item.type_id === e.type_id
      );
      let FN_index = falseNegative.findIndex(
        (item) => item.type_id === e.type_id
      );
      let FP_index = falsePositive.findIndex(
        (item) => item.type_id === e.type_id
      );

      TP_index === -1
        ? TP.push({
            count: 0,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          })
        : TP.push({
            count: truePositive[TP_index].count,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          });
      FN_index === -1
        ? FN.push({
            count: 0,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          })
        : FN.push({
            count: falseNegative[FN_index].count,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          });
      FP_index === -1
        ? FP.push({
            count: 0,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          })
        : FP.push({
            count: falsePositive[FP_index].count,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          });

      TN.push({
        // @ts-ignore
        count: totalPoles - TP[i].count - FP[i].count - FN[i].count,
        name: e.name,
        sv_name: e.sv_name,
        type_id: e.type_id,
      });
      // @ts-ignore
      Accuracy.push((TP[i].count + TN[i].count) / totalPoles);
      // @ts-ignore
      TP[i].count + FP[i].count === 0
        ? Precision.push(0) // @ts-ignore
        : Precision.push(TP[i].count / (TP[i].count + FP[i].count));
      // @ts-ignore
      TP[i].count + FN[i].count === 0
        ? Recall.push(0) // @ts-ignore
        : Recall.push(TP[i].count / (TP[i].count + FN[i].count));
      // @ts-ignore
      if (!(TP[i].count === 0 && FN[i].count === 0)) {
        dataMachineAccuracy.push({
          // @ts-ignore
          type: language === "EN" ? e.name : e.sv_name,
          // @ts-ignore
          Accuracy: Math.round(Accuracy[i] * 100),
          // @ts-ignore
          Precision: Math.round(Precision[i] * 100),
          // @ts-ignore
          Recall: Math.round(Recall[i] * 100),
        });
      }
    });
  }

  MachineValues();

  return (
    <>
      {dataMachineAccuracy.length > 0 && (
        <>
          <div className="statisticitem">
            <h2>
              {
                translations.Menu.Statistics.MachineLearning
                  .MachineDetectionCategories[language]
              }
            </h2>
            <div style={{ height: "400px" }}>
              {/* @ts-ignore */}
              <MyResponsiveBar data={dataMachineAccuracy} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
