import { useSelector } from "hooks";
import { getThinAnnotations } from "api/image/annotation";

type IThinAnnotation = Awaited<ReturnType<typeof getThinAnnotations>>[number];

export default function ImageAnnotation({
  imageId,
  annotation,
}: { imageId: number; annotation: IThinAnnotation }) {
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const annotationType = annotation.types[0];
  const objectType = objectTypes.find(
    (objectType) => objectType.id === annotationType
  );
  const { x, y, w, h } = annotation;
  let left_percentage = x * 100;
  let top_percentage = y * 100;
  let width_percentage = w * 100;
  let height_percentage = h * 100;

  // Cap height at 100%

  // Cap top at 0%
  if (top_percentage < 0) {
    top_percentage = 0;
  }
  // Cap left at 0%
  if (left_percentage < 0) {
    left_percentage = 0;
  }
  if (height_percentage + top_percentage > 100) {
    const diff = height_percentage + top_percentage - 100;
    height_percentage -= diff;
  }
  // Cap width at 100%
  if (width_percentage + left_percentage > 100) {
    const diff = width_percentage + left_percentage - 100;
    width_percentage -= diff;
  }

  return (
    <div
      // @ts-ignore
      data-testid={`image-connector-image-${imageId}-annotation-${objectType.id}`}
      style={{
        position: "absolute",
        top: `${top_percentage}%`,
        left: `${left_percentage}%`,
        width: `${width_percentage}%`,
        height: `${height_percentage}%`,
        border: `2px solid ${objectType?.color}`,
        margin: "-2px",
      }}
    />
  );
}
