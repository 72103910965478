import { AccessLevel } from "interfaces";
import store from "state/store";

// @ts-ignore
export default function hasAccess(keyName) {
  const state = store.getState();

  let superuser = state.user.superuser;
  let skyqraft_employee = state.user.skyqraft_employee;
  let missions = state.user.missions;
  let accessRules = state.user.accessRules;
  let customerId = state.mission?.id ?? -1;
  let accessLevelOverride = state.user.accessLevelOverride;

  if (!!!accessRules?.rules) {
    return null;
  }

  // Extract all missions
  let myMissions = [...missions];

  // Use a list because one can have multiple ways to gain access to a mission
  let currentMissionList = [...myMissions];

  // Extract the centralised key storage
  // @ts-ignore
  let recievedKeys = accessRules.rules[keyName].permissions;
  // @ts-ignore
  let missionAgnostic = accessRules.rules[keyName].missionAgnostic;

  // Check if we are looking at all missions
  if (!missionAgnostic) {
    currentMissionList = myMissions.filter(
      (mission) => mission.id === customerId
    );
  }

  let hasAccess = false;
  if (currentMissionList.length === 0) {
    hasAccess = false;
  }

  if (recievedKeys.length > 0) {
    hasAccess = false;
    recievedKeys.forEach((key: AccessLevel) => {
      currentMissionList.forEach((mission) => {
        if (mission.access_level.includes(key)) {
          hasAccess = true;
        }
      });

      if (key === "superuser" && superuser) {
        hasAccess = true;
      }
      if (key === "skyqraft_employee" && skyqraft_employee) {
        hasAccess = true;
      }
    });
  }

  if (hasAccess && !!accessLevelOverride) {
    hasAccess = false;
    // @ts-ignore
    currentMissionList.forEach((mission) => {
      // @ts-ignore
      if (mission.access_level.includes(accessLevelOverride)) {
        hasAccess = true;
      }
    });
  }

  return hasAccess;
}
