import { connect } from "react-redux";
import AnnotationTool from "./AnnotationTool";
import { RootState } from "state/store";
import { useLocation, useNavigate } from "react-router-dom";
import {
  flagImageForReannotation,
  updateMarkers,
  setAnnotationToolHumanMachine,
  setCanSwitchImage,
  sendFeedback,
  setInBubble,
  setExitURL,
} from "state/actions";
import {
  ImageMeta,
  ObjectType,
  IssueCategory,
  IssueSeverity,
  User,
  Mission,
} from "interfaces";
import { useContext } from "react";
import { context } from "providers/NavigationProvider";

interface IStateProps {
  customerId: number;
  showBBOX: boolean;
  user: User;
  showHumanMachine: boolean;
  objectTypes: ObjectType[];
  issueCategories: IssueCategory[];
  detectedCategories: IssueCategory[];
  issueSeverities: IssueSeverity[];
  zoomSpeed: number;
  image: ImageMeta;
  missions: Mission[];
  annotatorObjectColor: boolean;
  stateLink: string;
}

interface IDispatchProps {
  setHumanMachine: Function;
  setFlagged: (image: number, value: boolean, callback: Function) => void;
  updateMarkerData: () => void;
  setCanSwitchImage: (value: boolean) => void;
  sendFeedback: (
    image_id: number,
    feedback_type: number,
    comment: string,
    callback?: Function,
    onError?: Function
  ) => void;
  setInBubble: (value: boolean) => void;
  setExitURL: (value: string) => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    objectTypes: state.objects.objectTypes.filter(
      (t) => !t.skyqraft_only || state.user.skyqraft_employee
    ),
    issueCategories: state.objects.issueCategories,
    detectedCategories: state.objects.detectedCategories,
    issueSeverities: state.objects.issueSeverities,
    user: state.user,
    customerId: state.mission.id,
    missions: state.user.missions,
    showBBOX: true,
    showHumanMachine: state.objects.showHumanMachine,
    zoomSpeed: state.imageViewer.zoomSpeed,
    image: state.image.current,
    annotatorObjectColor: state.user.annotator_color,
    stateLink: state.navigation.exitURL,
  };
};

const mapDispatchToProps = {
  setHumanMachine: setAnnotationToolHumanMachine,
  setFlagged: flagImageForReannotation,
  updateMarkerData: updateMarkers,
  setCanSwitchImage,
  sendFeedback,
  setInBubble,
  setExitURL,
};

function NavigateWrapper(props: IStateProps & IDispatchProps) {
  const { lastURL } = useContext(context);
  const navigate = useNavigate();
  const location = useLocation();
  const showAnnotator = () => {
    if (lastURL) {
      navigate(-1);
    } else {
      navigate(`..${location.search}`);
    }
  };
  return <AnnotationTool {...props} showAnnotator={showAnnotator} />;
}

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(NavigateWrapper);
