import { useFlags } from "launchdarkly-react-client-sdk";
import { DomainManager } from "./views";
import { useSelector } from "hooks";

export default function FlagProtectedDomainManager() {
  const isSuperuser = useSelector((state) => state.user.superuser);
  // Render conditionally on feature flags
  const { domainManager } = useFlags();

  // https://app.launchdarkly.com/projects/default/flags/domain-manager
  if (!domainManager) {
    return null;
  }
  if (!isSuperuser) {
    return <p>You are not allowed access this resource</p>;
  }

  return <DomainManager />;
}
export { featureLocation } from "./constants";
export { DomainPage } from "./views";
