import { ThumbUpAlt } from "@mui/icons-material";
import styled from "styled-components";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EventHint, captureException } from "@sentry/react";
import { useSearchParams } from "react-router-dom";
import { axiosInstance } from "utils/request";
import { useCurrentProject } from "hooks";
import { CanvasContext } from "views/AnnotationTool/provider";
import { useInputListener } from "views/image/AnnotationPreview/hooks/inputListener";
import { preloadImage } from "./utils";
import useImageNavigation from "../hooks/imageNavigation";
import ToolsContainer from "../ReviewTools/ToolsContainer";
import PrimaryAction from "../ReviewTools/PrimaryAction";
import IgnoreImage from "../ReviewTools/IgnoreImage/IgnoreImage";
import ToggleBBIcon from "../ReviewTools/SecondaryActions/ToggleBBIcon";
import CloseReviewToolIcon from "../ReviewTools/SecondaryActions/CloseReviewToolIcon";
import OpenInEditorIcon from "../ReviewTools/SecondaryActions/OpenInEditorIcon";
import { reviewModes } from "constants/imageReview";

const reviewMode = reviewModes.FALSE_POSITIVE_IMAGES;

const MlModelContainer = styled.div`
	position: relative;
	z-index: 50;
	margin-left: 14px;
	border-radius: 8px;
	width: 250px;
	background-color: #ffffffad;
`;

const mlModelQueryKey = "mlModel";

export default function FalsePositiveImageReview() {
  const currentProject = useCurrentProject();
  const params = useParams();
  // @ts-ignore
  const currentImage: number = parseInt(params.image);

  const [mlModelDetections, setMlModelDetections] = useState([]);
  const [mlModels, setMlModels] = useState<
    { id: number; name: string; types: number[] }[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [imageIds, setImageIds] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedModel = searchParams.get(mlModelQueryKey);

  const { canvasRef } = useContext(CanvasContext);

  useInputListener({
    canvasRef,
    reviewMode,
  });

  useEffect(() => {
    getMlModels();
  }, []);

  useEffect(() => {
    if (mlModels.length) {
      setMlModel(selectedModel || mlModels[0].id);
    }
  }, [mlModels]);

  useEffect(() => {
    if (selectedModel && mlModelDetections.length) {
      getFalsePositiveImagesToReview(selectedModel, mlModelDetections);
    }
  }, [selectedModel, mlModelDetections]);

  useEffect(() => {
    const nextImageId = imageIds[currentIndex + 1];

    if (!nextImageId) {
      return;
    }
    // @ts-ignore
    preloadImage({ imageId: nextImageId, projectId: currentProject.id });
  }, [currentImage]);

  // @ts-ignore
  function setMlModel(modelId) {
    const model = mlModels.find((e) => e.id === parseInt(modelId));

    if (model?.types) {
      // @ts-ignore
      setMlModelDetections(model?.types);
    }
    searchParams.set(mlModelQueryKey, modelId);
    setSearchParams(searchParams, { replace: true });
  }

  const {
    currentIndex,
    navigateToNextImage,
    navigateToPrevImage,
    goToImageByIndex,
  } = useImageNavigation({
    imageIds,
    currentImage,
  });

  function onImageReviewed() {
    if (currentIndex === imageIds.length - 1) {
      toast.info(
        "That was the final image in this review. Refresh browser to see if there are more images to review"
      );
    }
    navigateToNextImage();
  }

  async function approveFalsePositiveObjects({
    skip,
  }: {
    skip: boolean;
  }) {
    try {
      await axiosInstance.post(
        "/review/falsepositive",
        {
          skip,
          image: currentImage,
          // @ts-ignore
          mlModel: parseInt(selectedModel),
          train: true,
          empty_txt: true,
          supervisor_approved: true,
        },
        {
          // @ts-ignore
          headers: { MissionID: currentProject.id },
        }
      );
      onImageReviewed();
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "FalsePositiveReview.approveFalsePositiveObjects.request",
        originalException: error,
      };
      captureException(error, exceptionHint);
      toast.error("Could not approve false positive objects");
    }
  }

  async function getFalsePositiveImagesToReview(
    mlModel: string,
    mlmodeldetections: number[]
  ) {
    setLoading(true);
    setImageIds([]);
    try {
      const { data } = await axiosInstance.get(
        // @ts-ignore
        `/review/${reviewMode}/project/${currentProject.id}/images`,
        {
          params: {
            mlModel,
            detection: mlmodeldetections.toString(),
          },
        }
      );

      if (data.length) {
        setImageIds(data);
      } else {
        toast.error("No (more) images to review found");
      }
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "FalsePositiveReview.getFalsePositiveImagesToReview.request",
        originalException: error,
      };
      captureException(error, exceptionHint);
      toast.error("Failed to get images to review");
    }
    setLoading(false);
  }

  async function getMlModels() {
    try {
      const { data } = await axiosInstance.get("/review/falsepositive/models", {
        // @ts-ignore
        headers: { MissionID: currentProject.id },
      });

      if (data) {
        setMlModels(data.models);
      } else {
        toast.error("No false positive review objects found");
      }
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "FalsePositiveReview.getMlModels.request",
        originalException: error,
      };
      captureException(error, exceptionHint);
      toast.error("Could not get false positive ml models");
    }
  }

  const primaryActions = (
    <>
      <PrimaryAction
        color="green"
        hotkey="q"
        tooltipText="Approve objects"
        data-testid="falsePositiveReview.approveImage"
        onClick={() => approveFalsePositiveObjects({ skip: false })}
        icon={<ThumbUpAlt sx={{ color: "#ffff" }} />}
        disabled={loading}
      />
      <IgnoreImage
        imageId={currentImage}
        reviewModeId={reviewMode}
        objectTypeIds={mlModelDetections}
        onIgnore={navigateToNextImage}
      />
    </>
  );

  const secondaryActions = (
    <>
      <ToggleBBIcon />
      <OpenInEditorIcon />
      <CloseReviewToolIcon />
    </>
  );

  return (
    <>
      <MlModelContainer>
        <FormControl fullWidth>
          <Select
            id="select-ml-model"
            data-testid="falsePositiveReview.select.mlModel"
            value={selectedModel}
            onChange={(event) => setMlModel(event.target.value)}
          >
            {mlModels.map((e, i) => (
              <MenuItem
                key={`${i}`}
                value={e.id}
                data-testid={`falsePositiveReview.select.mlModel.${e.id}`}
              >
                {e.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MlModelContainer>
      <ToolsContainer
        loading={loading}
        currentIndex={currentIndex}
        navigateToNextImage={navigateToNextImage}
        navigateToPrevImage={navigateToPrevImage}
        goToImageByIndex={goToImageByIndex}
        imageIds={imageIds}
        primaryActions={primaryActions}
        secondaryActions={secondaryActions}
      />
    </>
  );
}
