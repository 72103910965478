import {
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingDots } from "components/LoadingDots";

export interface IOption {
  id: number;
  name: string;
  count: number;
  selectIds: number[];
}

interface IProps {
  option: IOption;
  onSelect: (option: IOption) => void;
  selected: boolean;
  loading?: boolean;
}

export default function OptionItem({
  option,
  onSelect,
  selected,
  loading,
}: IProps) {
  return (
    <FormControlLabel
      onChange={(e) => {
        onSelect(option);
      }}
      checked={selected}
      control={<Checkbox />}
      sx={{
        "&:hover": {
          backgroundColor: "#EDEDED",
        },
      }}
      label={
        <Stack direction="row" alignItems="center" spacing={1}>
          <Chip label={loading ? <LoadingDots /> : option.count} />
          <Typography>{option.name}</Typography>
        </Stack>
      }
    />
  );
}
