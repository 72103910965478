import ImageLayer from "./ImageLayer";

type Props = {
  // @ts-ignore
  imagePosition;
  // @ts-ignore
  onLoad;
  // @ts-ignore
  image;
  cdn: string | null;
  canvas: HTMLCanvasElement;
};

export default function SpeedImage({
  onLoad,
  imagePosition,
  cdn,
  canvas,
}: Props) {
  return (
    <div style={{ pointerEvents: "none" }} data-testid="annotation-tool-image">
      <ImageLayer
        quality="low"
        imagePosition={imagePosition}
        onLoad={onLoad}
        cdn={cdn}
        canvas={canvas}
      />
      <ImageLayer
        quality="medium"
        imagePosition={imagePosition}
        cdn={cdn}
        canvas={canvas}
      />
      <ImageLayer
        quality="high"
        imagePosition={imagePosition}
        cdn={cdn}
        canvas={canvas}
      />
    </div>
  );
}
