import { Search } from "@mui/icons-material";
import { Stack, TextField } from "@mui/material";

interface IProps {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SearchCard({ placeholder, value, onChange }: IProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Search />
      <TextField
        data-testid="search-card"
        variant="standard"
        placeholder={placeholder}
        fullWidth
        value={value}
        onChange={onChange}
      />
    </Stack>
  );
}
