import { createContext, useState } from "react";

export enum Sidebar {
  Projects = "projects",
  Filters = "filters",
  Tools = "tools",
  Statistics = "statistics",
  Settings = "settings",
  None = "",
}

export interface IMenuProviderContext {
  sidebar: Sidebar;
  setSidebar: (value: Sidebar) => void;
}

export const MenuContext = createContext<IMenuProviderContext>({
  sidebar: Sidebar.None,
  setSidebar: (value: Sidebar) => {},
});
// @ts-ignore
export function MenuProvider({ children }) {
  const [sidebar, setSidebar] = useState<Sidebar>(Sidebar.None);

  return (
    <MenuContext.Provider
      value={{
        sidebar,
        setSidebar,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}
