import axios from "axios";
import { toast } from "react-toastify";
import { RootState } from "state/store";
import { authorizedGet } from "utils/request";
// @ts-ignore
const parsePath = (path) => {
  const tempPath = path[0] === "/" ? path.substring(1, path.length) : path;
  if (tempPath.includes("/")) {
    return tempPath;
  } else {
    return `Uncategorized/${tempPath}`;
  }
};

export const setImageUploadStatus = (value: "idle" | "running" | "stopped") => {
  return {
    type: "SET_IMAGE_UPLOAD_STATUS",
    payload: value,
  };
};

export const uploadFile = (
  signedUrl: string, // @ts-ignore
  file: any, // @ts-ignore
  callback: (err) => void
) => {
  const image_data = new FormData();
  image_data.append("image", file, file.name);
  axios
    .put(signedUrl, file)
    .then(() => callback(null))
    .catch(() => callback(true));
};

export const getFile = (bucket: string, destination: string) => {
  return authorizedGet(
    `/image/upload/head_image?bucket=${bucket}&destination=${destination}`
  );
};

export const setJobs = (value: Array<any>) => {
  return {
    type: "SET_JOBS",
    payload: value,
  };
};

export const setSubFolders = (value: Array<any>) => {
  return {
    type: "SET_SUB_FOLDERS",
    payload: value,
  };
};

export function setUploadToastMessage(
  value: string | null,
  progress: undefined | number = undefined
) {
  // @ts-ignore
  return async function (dispatch, getState) {
    const state: RootState = getState();
    const toastHandle = state.imageUpload.toast;
    if (!!toastHandle) {
      if (!!value) {
        // Update the value if there is one
        toast.update(toastHandle, {
          render: value,
          progress,
        });
      } else {
        // Remove the message if there isn't a value
        toast.dismiss(toastHandle);
      }
    } else {
      if (!!value) {
        const newHandle = toast.info(value, { progress, autoClose: false });
        dispatch({
          type: "SET_TOAST_HANDLE",
          payload: newHandle,
        });
      } else {
        // No value and no toast container...
        // Do noting.
      }
    }
  };
}

export function setCloudFolders(folders: string[]) {
  return {
    type: "SET_CLOUD_FOLDERS",
    payload: folders,
  };
}

export function getCloudFolders() {
  // @ts-ignore
  return async function (dispatch) {
    // @ts-ignore
    authorizedGet<{ folders }>("/upload/folders").then((data) => {
      // @ts-ignore
      const sortedDataFolders = data.folders.sort((a, b) => {
        let a_date = new Date(a);
        let b_date = new Date(b);
        let same = a_date.getTime() === b_date.getTime();
        if (same) return 0;
        if (b_date > a_date) return 1;
        return -1;
      });
      dispatch(
        setCloudFolders(
          // @ts-ignore
          sortedDataFolders.filter((folder) => folder !== "_logs")
        )
      );
    });
  };
}

export function setAutomaticImport(value: boolean) {
  return {
    type: "SET_AUTOMATIC_IMPORT",
    payload: value,
  };
}

export function addCloudFolder(value: string) {
  // @ts-ignore
  return async function (dispatch, getState) {
    const state: RootState = getState();
    const folders = state.imageUpload.cloudFolders;
    dispatch(setCloudFolders([...folders, value]));
  };
}

export function getRawLocation() {
  // @ts-ignore
  return async function (dispatch) {
    // @ts-ignore
    authorizedGet<{ rawFolder; s3bucket }>("/mission/get_raw_folder").then(
      (data) => {
        dispatch({
          type: "SET_RAW_FOLDER",
          payload: data.rawFolder,
        });
        dispatch({
          type: "SET_S3_BUCKET",
          payload: data.s3bucket,
        });
      }
    );
  };
}
// @ts-ignore
export function setFiles(files) {
  return {
    type: "SET_FILES",
    payload: files,
  };
}
// @ts-ignore
function updateFile(fileToChange, settings) {
  // @ts-ignore
  return async function (dispatch, getState) {
    const state: RootState = getState();
    const files = state.imageUpload.files;
    const newFiles = files.map((file) =>
      file.data.path === fileToChange.data.path
        ? {
            ...fileToChange,
            ...settings,
          }
        : file
    );
    dispatch(setFiles(newFiles));
  };
}

export function setS3FolderName(value: string) {
  return {
    type: "SET_S3_FOLDER",
    payload: value,
  };
}

export function resetUpload() {
  // @ts-ignore
  return (dispatch) => {
    dispatch(setImageUploadStatus("idle"));
    dispatch(setFiles([]));
  };
}

export function stopUpload() {
  // @ts-ignore
  return (dispatch) => {
    dispatch(setImageUploadStatus("stopped"));
  };
}
// @ts-ignore
export function uploadFileContent(file) {
  // @ts-ignore
  return async (dispatch, getState) => {
    dispatch({
      type: "SET_CURRENT_FILE",
      payload: file.data.path,
    });
    // @ts-ignore
    const postProcess = (err) => {
      // Flag the file
      dispatch(updateFile(file, err ? { failed: true } : { uploaded: true }));
      if (err?.code) {
        dispatch(setImageUploadStatus("stopped"));
      } else {
      }
      // Go to the next image
      dispatch(uploadData());
    };

    const state: RootState = getState();
    const container = state.imageUpload.bucket;
    const s3Folder = state.imageUpload.s3Folder;
    getFile(container, `${s3Folder}/${parsePath(file.data.path)}`)
      .then(({ exists, signedUrl }) => {
        if (!exists) {
          uploadFile(signedUrl, file.data, postProcess);
        } else {
          postProcess(null);
        }
      })
      .catch(() => {
        postProcess({ code: "Fetching file failed" });
      });
  };
}

export function uploadData() {
  // @ts-ignore
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const uploadState = state.imageUpload.status;
    const files = state.imageUpload.files;
    const processedFiles = files.filter((f) => f.uploaded || f.failed);

    if (uploadState === "running") {
      const pendingFiles = files.filter(
        (file) => !file.uploaded && !file.failed
      );
      if (pendingFiles.length === 0) {
        dispatch(setUploadToastMessage("Upload done", 1));
        dispatch(stopUpload());
        toast.success("Upload done");
        dispatch(startBatchJob());
      } else {
        const nextFile = pendingFiles[0];
        // TODO
        dispatch(
          setUploadToastMessage(
            "Uploading files...",
            processedFiles.length / files.length
          )
        );
        dispatch(uploadFileContent(nextFile));
      }
    }
  };
}

export function startBatchJob(rerun = false, overwrite_db = false) {
  const RERUN_FALSE = "&rerun=false";
  const RERUN_TRUE = "&rerun=true";
  const OVERWRITE_DB_TRUE = "&overwrite_db=true";
  const OVERWRITE_DB_FALSE = "&overwrite_db=false";
  // @ts-ignore
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const folder = state.imageUpload.s3Folder;

    let requestURI = `/upload?folder=${folder}`;
    requestURI += rerun ? RERUN_TRUE : RERUN_FALSE;
    requestURI += overwrite_db ? OVERWRITE_DB_TRUE : OVERWRITE_DB_FALSE;
    // @ts-ignore
    await authorizedGet<{ jobId }>(requestURI);

    toast.success("AI Job started");
    dispatch(getCloudFolders());
  };
}

export function startUpload() {
  // @ts-ignore
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const container = state.imageUpload.bucket;
    const files = state.imageUpload.files;

    if (container && files.length > 0) {
      dispatch(setUploadToastMessage("Starting Upload"));
      dispatch(setImageUploadStatus("running"));
      dispatch(setFiles(files.map((file) => ({ ...file, failed: false }))));
      dispatch(uploadData());
    } else {
      toast.error("Container not set");
    }
  };
}
