import { useState } from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useClientAlteredTypes, useLanguage } from "hooks";
import { useParams } from "react-router-dom";
import translations from "translations";
import { orderBy } from "lodash";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Accordion } from "components";

export default function FlaggedImageList() {
  const { language } = useLanguage();
  const params = useParams();
  // @ts-ignore
  const missionID = parseInt(params.mission);
  const [imageFeedback, loading] = useClientAlteredTypes({
    mission: missionID,
  });

  const [showAll, setShowAll] = useState(false);

  const filteredImageFeedback = imageFeedback.filter(
    (f) => f.old_type !== f.new_type || f.old_severity !== f.new_severity
  );

  const sortedImageFeedbackAll = orderBy(
    filteredImageFeedback,
    ["customer_id"],
    ["desc"]
  );

  const sortedImageFeedback = orderBy(filteredImageFeedback, ["id"], ["desc"]);
  const sortedImageFeedbackRecent = sortedImageFeedback.slice(0, 10);
  // @ts-ignore
  const returnList = (status, i) => {
    return (
      <Grid
        key={i}
        container
        spacing={2}
        sx={{ py: 1 }}
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.open(`/${status.customer_id}/${status.image_id}`, "_blank");
        }}
      >
        {!missionID && (
          <Grid display="flex" alignItems="center" item xs={2}>
            <Box>
              <Typography>{status.customer_id}</Typography>
            </Box>
          </Grid>
        )}

        <Grid display="flex" alignItems="center" item xs={!missionID ? 3 : 3}>
          <Box>
            <Typography>{status.image_id}</Typography>
          </Box>
        </Grid>
        <Grid
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="end"
          item
          xs={3}
        >
          {status.old_severity !== status.new_severity && (
            <Typography>{status.old_severity}</Typography>
          )}
        </Grid>
        <Grid
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="end"
          item
          xs={4}
        >
          {status.old_severity !== status.new_severity && (
            <Typography>{status.new_severity}</Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Accordion
      title={translations.Landing.ClientAlteredTypes[language]}
      description={
        translations.Landing.ClientAlteredTypes.SeeMostRecentAlteredTypes[
          language
        ]
      }
      value={filteredImageFeedback.length}
    >
      <FormControlLabel
        control={
          <Switch
            checked={showAll}
            onChange={() => setShowAll(!showAll)}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={
          translations.Landing.ClientAlteredTypes.SeeAllClientAlteredTypes[
            language
          ]
        }
        sx={{ mb: "8px", ml: "10px" }}
      />

      <Grid container spacing={2}>
        {!missionID && (
          <Grid item xs={2} display="flex">
            <Typography fontWeight="bold">
              {translations.Landing.ImageFeedback.clientId[language]}
            </Typography>
          </Grid>
        )}
        <Grid item xs={!missionID ? 3 : 3} display="flex">
          <Typography fontWeight="bold">
            {translations.Landing.ImageFeedback.imageId[language]}
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="end">
          <Typography fontWeight="bold">
            {translations.Landing.ClientAlteredTypes.Old[language]}
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="end">
          <Typography fontWeight="bold">
            {translations.Landing.ClientAlteredTypes.New[language]}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }} />
      <Stack divider={<Divider />}>
        {!showAll
          ? sortedImageFeedbackRecent.map((status, i) => returnList(status, i))
          : sortedImageFeedbackAll.map((status, i) => returnList(status, i))}
      </Stack>
    </Accordion>
  );
}
