import { axiosInstance } from "utils/request";

interface ISavedFilter {
  id: number;
  name: string;
  severity?: string | null;
  workflow?: string | null;
  detection?: string | null;
  dateImageCapture?: string | null;
  dateImageUploaded?: string | null;
  dateDefectAdded?: string | null;
  dateDefectProcessed?: string | null;
  dateDefectFixed?: string | null;
  dateDefectReported?: string | null;
  area?: string | null;
  flight?: string | null;
  flagged?: string | null;
  showDsoTso?: string | null;
  feedBay?: string | null;
  showLidarMarkers?: string | null;
  defectNotReported?: string | null;
  defectNotProcessed?: string | null;
  new?: string | null;
  skyqraftHidden?: string | null;
  actors?: string | null;
  list?: string | null;
  clearanceRules?: string | null;
  clearanceAxes?: string | null;
}

export async function getSavedFilters() {
  const response = await axiosInstance.get<{ savedFilters: ISavedFilter[] }>(
    "/filter/saved"
  );
  if (response.status !== 200) {
    throw new Error("Error fetching saved filters");
  }
  return response.data.savedFilters;
}

export async function createSavedFilter(newFilter: Omit<ISavedFilter, "id">) {
  const response = await axiosInstance.post<ISavedFilter>("/filter/saved", {
    ...newFilter,
  });
  if (response.status !== 201) {
    throw new Error("Error creating saved filter");
  }
  return response.data;
}

export async function updateSavedFilter(
  id: number,
  updatedFilter: Partial<ISavedFilter>
) {
  const response = await axiosInstance.put<ISavedFilter>(
    `/filter/saved/${id}`,
    updatedFilter
  );
  if (response.status !== 204) {
    throw new Error("Error updating saved filter");
  }
  return response.data;
}

export async function deleteSavedFilter(id: number) {
  const response = await axiosInstance.delete(`/filter/saved/${id}`);
  if (response.status !== 204) {
    throw new Error("Error deleting saved filter");
  }
}
