import {
  Typography,
  Stack,
  Chip,
  Paper,
  Divider,
  Box,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, Outlet, useNavigate, Link } from "react-router-dom";
import FullPage from "components/FullPage";
import { useLanguage, useReportInbox, useCurrentProject } from "hooks";
import { Add, Clear } from "@mui/icons-material";
import translations from "translations";
import { getProjectName } from "utils/utils";
import useLocationHistory from "hooks/useLocationHistory";
import { FilterCountProvider } from "providers/FilterCountProvider";

export default function Inbox() {
  const navigate = useNavigate();
  const params = useParams();
  const { language } = useLanguage();
  // @ts-ignore
  const mission = parseInt(params.mission);
  const isNew = window.location.pathname.endsWith("/new");
  const [reports, updateTrigger, reportsLoading] = useReportInbox();
  const currentProject = useCurrentProject();
  const { previousRenderLocation } = useLocationHistory();
  const [locationBeforeInbox, setLocationBeforeInbox] = useState(null);

  useEffect(() => {
    // @ts-ignore
    setLocationBeforeInbox(previousRenderLocation);
  }, []);

  const missionCount = {};

  // Count the amount of reports for each existing mission
  for (const report of reports) {
    const projectName = getProjectName(report.project.id);
    if (report.project.id in missionCount) {
      // @ts-ignore
      missionCount[report.project.id].count += 1;
    } else {
      // @ts-ignore
      missionCount[report.project.id] = {
        count: 1,
        name: projectName?.fullname,
      };
    }
  }
  const missionIDs = Object.keys(missionCount);

  return (
    <FilterCountProvider>
      <FullPage
        title={translations.Inbox.Reports.Title[language]}
        tab={0}
        tabs={[]}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          style={{ position: "absolute", top: 64, right: 8 }}
          onClick={() => {
            if (locationBeforeInbox) {
              navigate(
                // @ts-ignore
                `${locationBeforeInbox.pathname}${locationBeforeInbox.search}`
              );
            } else {
              navigate(`/${currentProject?.id || ""}`);
            }
          }}
        >
          <Clear />
        </IconButton>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={4}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{
            height: "100%",
            overflowY: "auto",
            position: "relative",
            pr: 2,
          }}
        >
          <Stack
            direction="column"
            minWidth={300}
            maxWidth={300}
            spacing={1}
            sx={{ overflowY: "auto", height: "100%" }}
          >
            <Link
              to="/inbox/new"
              replace
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Box sx={{ p: 2 }}>
                <Stack
                  width="100%"
                  direction="row"
                  alignItems="center"
                  spacing={2}
                >
                  <Add />
                  <Typography>
                    {translations.Inbox.Reports.CreateNewReport[language]}
                  </Typography>
                </Stack>
              </Box>
            </Link>
            <Link
              to="/inbox"
              replace
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Paper
                sx={{
                  p: 2,
                  backgroundColor: !mission && !isNew ? "#D6E7FF" : undefined,
                }}
              >
                <Stack
                  width="100%"
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>
                    {translations.Inbox.Reports.AllProjects[language]}
                  </Typography>
                  <Chip label={reports.length} />
                </Stack>
              </Paper>
            </Link>
            {missionIDs.map((missionID) => (
              <Link
                key={missionID}
                to={`/${missionID}/inbox`}
                replace
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Paper
                  sx={{
                    p: 2,
                    backgroundColor:
                      parseInt(missionID) === mission ? "#D6E7FF" : undefined,
                  }}
                >
                  <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {/* @ts-ignore */}
                    <Typography>{missionCount[missionID].name}</Typography>
                    {/* @ts-ignore */}
                    <Chip label={missionCount[missionID].count} />
                  </Stack>
                </Paper>
              </Link>
            ))}
          </Stack>
          <Box overflow="auto" width="100%">
            <Outlet
              context={{
                reports: reports.filter(
                  (report) => report.project.id === mission || !mission
                ),
                updateTrigger,
                reportsLoading,
              }}
            />
          </Box>
        </Stack>
      </FullPage>
    </FilterCountProvider>
  );
}
