import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch, useCurrentProject } from "hooks";
import { reviewModes } from "constants/imageReview";
import { updateAnnotations } from "state/actions";
import Annotations from "../Annotations";
import NoReview from "./NoReview";

export default function NoReviewContainer() {
  const params = useParams();
  // @ts-ignore
  const currentImage: number = parseInt(params.image);

  const annotations = useSelector((state) => state.image.annotations); // TODO: Replace with own fetch
  const annotationsLoading = useSelector(
    (state) => state.objects.reviewLoading
  );

  const dispatch = useDispatch();
  const currentProject = useCurrentProject();
  const skyqraftHiddenSetting = useSelector(
    (state) => state.user.skyqraft_hidden
  );
  const showDsoTso = useSelector((state) => state.user.show_dso_tso);
  const location = useLocation();

  useEffect(() => {
    if (currentProject && currentImage) {
      dispatch(
        updateAnnotations(
          currentProject.id,
          currentImage,
          skyqraftHiddenSetting,
          showDsoTso
        )
      );
    }
  }, [
    location.pathname,
    currentProject?.id,
    updateAnnotations,
    skyqraftHiddenSetting,
    showDsoTso,
  ]);

  return (
    <>
      <NoReview currentImage={currentImage} />
      <Annotations
        loading={annotationsLoading}
        reviewMode={reviewModes.NONE}
        imageId={currentImage}
        annotations={annotations}
      />
    </>
  );
}
