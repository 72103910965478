import translations from "translations";
import { Dialog, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "hooks";

export default function Changelog() {
  const navitate = useNavigate();
  const { language } = useLanguage();
  return (
    <Dialog fullScreen open={true}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              navitate(-1);
            }}
          >
            <Close />
          </IconButton>

          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            data-testid="ChangeLogTitle"
          >
            {translations.Menu.Tools.Changelog[language]}
          </Typography>
        </Toolbar>
      </AppBar>

      <iframe
        title="changelog"
        height="100%"
        data-testid="ChangeLog"
        src="https://docs.google.com/document/d/e/2PACX-1vSvx_eJooddtuI4ti5cXVGIjhoyiKcNL-NajTtukd3G2dQBbFJsjZRs3NLvMf2dFcjxeYa0ZEoAldog/pub?embedded=true"
      />
    </Dialog>
  );
}
