import React from "react";

type Props = {
  content: any;
};

export default function CMSImage({ content }: Props) {
  const attributes = content.attrs;
  return (
    <img src={attributes.src} alt={attributes.alt} title={attributes.title} />
  );
}
