import { Subject } from "rxjs";

const boundsSubject = new Subject<google.maps.LatLngBoundsLiteral>();

export const bounds = {
  subscribe: (handler: (bounds: google.maps.LatLngBoundsLiteral) => void) =>
    boundsSubject.subscribe({
      next: (bounds) => handler(bounds),
    }),
  sendMessage: (bounds: google.maps.LatLngBoundsLiteral) =>
    boundsSubject.next(bounds),
};

let loadingMap = {};

const loadingSubject = new Subject<boolean>();

export const loading = {
  subscribe: (handler: (isLoading: boolean) => void) =>
    loadingSubject.subscribe({
      next: (isLoading) => handler(isLoading),
    }),
  sendMessage: (key: string, isLoading: boolean) => {
    if (isLoading) {
      // @ts-ignore
      loadingMap[key] = true;
    } else {
      // @ts-ignore
      delete loadingMap[key];
    }
    loadingSubject.next(Object.keys(loadingMap).length > 0);
  },
};
