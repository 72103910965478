import { authorizedGet } from "utils/request";

export function getPowerlineStats(
  // @ts-ignore
  callback: Function = null,
  // @ts-ignore
  onError: Function = null
) {
  // @ts-ignore
  return async function (dispatch) {
    try {
      const response = await authorizedGet<{
        // @ts-ignore
        totalLength;
        // @ts-ignore
        inspectedLength;
        // @ts-ignore
        reflightLength;
        // @ts-ignore
        imagesLength;
      }>("/statistics/powerlines", {}, {}, true);
      dispatch({
        type: "UPDATE_POWERLINE_STATISTICS",
        payload: {
          totalLength: response.totalLength,
          inspectedLength: response.inspectedLength,
          reflightLength: response.reflightLength,
          imagesLength: response.imagesLength,
        },
      });
      !!callback && callback();
    } catch (err) {
      !!onError && onError();
    }
  };
}
export function get_object_statitsics(
  // @ts-ignore
  callback: Function = null,
  // @ts-ignore
  onError: Function = null
) {
  // @ts-ignore
  return async function (dispatch) {
    try {
      const response = await authorizedGet<{
        // @ts-ignore
        machineDetections;
        // @ts-ignore
        machineInventoryDetections;
        // @ts-ignore
        machineDefectDetections;
        // @ts-ignore
        humanDetections;
        // @ts-ignore
        humanInventoryDetections;
        // @ts-ignore
        humanDefectDetections;
        // @ts-ignore
        defectFixedObjects;
      }>("/statistics/objects");
      dispatch({
        type: "UPDATE_OBJECT_STATISTICS",
        payload: {
          machineDetections: response.machineDetections,
          machineInventoryDetections: response.machineInventoryDetections,
          machineDefectDetections: response.machineDefectDetections,
          humanDetections: response.humanDetections,
          humanInventoryDetections: response.humanInventoryDetections,
          humanDefectDetections: response.humanDefectDetections,
          defectFixedObjects: response.defectFixedObjects,
        },
      });
      !!callback && callback();
    } catch (err) {
      !!onError && onError();
    }
  };
}
export function get_statistics_accuracy(
  // @ts-ignore
  callback: Function = null,
  // @ts-ignore
  onError: Function = null
) {
  // @ts-ignore
  return async function (dispatch) {
    try {
      const response = await authorizedGet<{
        // @ts-ignore
        truePositive;
        // @ts-ignore
        falsePositive;
        // @ts-ignore
        falseNegative;
      }>("/statistics/accuracy");
      dispatch({
        type: "UPDATE_STATISTICS_ACCURACY",
        payload: {
          truePositive: response.truePositive,
          falsePositive: response.falsePositive,
          falseNegative: response.falseNegative,
        },
      });
      !!callback && callback();
    } catch (err) {
      !!onError && onError();
    }
  };
}
export function get_poles_statistics(
  // @ts-ignore
  callback: Function = null,
  // @ts-ignore
  onError: Function = null
) {
  // @ts-ignore
  return async function (dispatch) {
    try {
      // @ts-ignore
      const response = await authorizedGet<{ totalPoles }>("/statistics/poles");
      dispatch({
        type: "UPDATE_POLES_STATISTICS",
        payload: response.totalPoles,
      });
      !!callback && callback();
    } catch (err) {
      !!onError && onError();
    }
  };
}
export function get_category_statistics(
  // @ts-ignore
  callback: Function = null,
  // @ts-ignore
  onError: Function = null
) {
  // @ts-ignore
  return async function (dispatch) {
    try {
      // @ts-ignore
      const response = await authorizedGet<{ issueCategoryCount }>(
        "/statistics/categories"
      );
      dispatch({
        type: "UPDATE_CATEGORY_STATISTICS",
        payload: response.issueCategoryCount,
      });
      !!callback && callback();
    } catch (err) {
      !!onError && onError();
    }
  };
}
export function get_workflow_statistics(
  // @ts-ignore
  callback: Function = null,
  // @ts-ignore
  onError: Function = null
) {
  // @ts-ignore
  return async function (dispatch) {
    try {
      // @ts-ignore
      const response = await authorizedGet<{ workflowStatus }>(
        "/statistics/workflow"
      );
      dispatch({
        type: "UPDATE_WORKFLOW_STATISTICS",
        payload: response.workflowStatus,
      });
      !!callback && callback();
    } catch (err) {
      !!onError && onError();
    }
  };
}

export function getImageStats(
  // @ts-ignore
  callback: Function = null,
  // @ts-ignore
  onError: Function = null
) {
  // @ts-ignore
  return async function (dispatch) {
    try {
      const response = await authorizedGet<{ n_images: number }>(
        "/statistics/images"
      );
      dispatch({
        type: "SET_IMAGE_COUNT",
        payload: response.n_images,
      });
      !!callback && callback();
    } catch (err) {
      !!onError && onError();
    }
  };
}
export function getFlaggedImageStats(
  // @ts-ignore
  callback: Function = null,
  // @ts-ignore
  onError: Function = null
) {
  // @ts-ignore
  return async function (dispatch) {
    try {
      const response = await authorizedGet<{ n_flagged_images: number }>(
        "/statistics/images/flagged"
      );
      dispatch({
        type: "SET_FLAGGED_IMAGE_COUNT",
        payload: response.n_flagged_images,
      });
      !!callback && callback();
    } catch (err) {
      !!onError && onError();
    }
  };
}
export function getMachineReviewStats(
  // @ts-ignore
  callback: Function = null,
  // @ts-ignore
  onError: Function = null
) {
  // @ts-ignore
  return async function (dispatch) {
    try {
      const response = await authorizedGet<{
        n_machine_reviewable_images: number;
      }>("/statistics/images/machine_review");
      dispatch({
        type: "SET_MACHINE_REVIEW_IMAGE_COUNT",
        payload: response.n_machine_reviewable_images,
      });
      !!callback && callback();
    } catch (err) {
      !!onError && onError();
    }
  };
}
export function getSupervisorReviewStats(
  // @ts-ignore
  callback: Function = null,
  // @ts-ignore
  onError: Function = null
) {
  // @ts-ignore
  return async function (dispatch) {
    try {
      const response = await authorizedGet<{
        n_supervisor_reviewable_images: number;
      }>("/statistics/images/supervisor_review");
      dispatch({
        type: "SET_SUPERVISOR_REVIEW_IMAGE_COUNT",
        payload: response.n_supervisor_reviewable_images,
      });
      !!callback && callback();
    } catch (err) {
      !!onError && onError();
    }
  };
}

export const updateGridStatistics = () => {
  // @ts-ignore
  return (dispatch) => {
    dispatch(getPowerlineStats());
    dispatch(get_object_statitsics());
    dispatch(get_statistics_accuracy());
    dispatch(get_poles_statistics());
    dispatch(get_category_statistics());
    dispatch(get_workflow_statistics());
  };
};
