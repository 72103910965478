import { createContext, useEffect, useState, useCallback, useRef } from "react";
import throttle from "lodash/throttle";
import {
  getWorkSessions,
  getTags,
  startNewSession,
  stopSession as stopSessionAPI,
  createTag as createTagAPI,
  updateWorkSession,
  deleteWorkSession,
  updateWorkSessionAutoStopTimeAPI,
} from "./api";
import { EventHint, captureException } from "@sentry/react";
import { useCurrentProject, useSelector } from "hooks";
import useSocket from "hooks/useSocket";

// Make worksessions the responsetype of getWorkSessions

type IWorkSession = Awaited<
  ReturnType<typeof getWorkSessions>
>["work_sessions"][number];

type ITag = Awaited<ReturnType<typeof getTags>>["tags"][number];

export interface IWorkSessionContext {
  currentSession: IWorkSession | null;
  selectedTags: number[];
  description: string;
  timeElapsed: number;
  workSessions: IWorkSession[];
  tags: ITag[];
  loading: boolean;
  update: () => Promise<void>;
  createTag: (name: string) => Promise<ITag>;
  deleteTag: (id: number) => Promise<void>;
  startSession: (
    description: string,
    tags: number[],
    start_time: string,
    end_time: string | null
  ) => Promise<number>;
  stopSession: (id: number) => Promise<void>;
  updateSession: (
    id: number,
    description?: string,
    tags?: number[]
  ) => Promise<void>;
  setCurrentSession: (session: IWorkSession | null) => void;
  setSelectedTags: (tags: number[]) => void;
  setDescription: (description: string) => void;
  setTimeElapsed: (time: number) => void;
  deleteSession: (id: number) => Promise<void>;
}

export const WorkSessionContext = createContext<IWorkSessionContext>({
  currentSession: null,
  selectedTags: [],
  description: "",
  timeElapsed: 0,
  workSessions: [],
  tags: [],
  loading: false,
  createTag: async () => ({}) as ITag,
  update: async () => {},
  deleteTag: async () => {},
  startSession: async () => 0,
  stopSession: async () => {},
  updateSession: async () => {},
  setCurrentSession: () => {},
  setSelectedTags: () => {},
  setDescription: () => {},
  setTimeElapsed: () => {},
  deleteSession: async () => {},
});
// @ts-ignore
export const WorkSessionProvider = ({ children }) => {
  const myUserId = useSelector((state) => state.user.id);
  const [currentSession, setCurrentSession] = useState<IWorkSession | null>(
    null
  );
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [description, setDescription] = useState<string>("");
  const [timeElapsed, setTimeElapsed] = useState<number>(0);
  const [workSessions, setWorkSessions] = useState<IWorkSession[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);
  const [loading, setLoading] = useState(true);

  const autoUpdateWorkSessionsTimeoutRef = useRef(null);
  const clickListenerRef = useRef(null);

  const project = useCurrentProject();
  const { onEvent, removeListeners } = useSocket();

  async function updateTags() {
    const response = await getTags();
    setTags(response.tags);
  }

  async function update() {
    await updateWorkSessions();
  }

  async function deleteSession(sessionID: number) {
    setLoading(true);
    await deleteWorkSession(sessionID);
    await updateWorkSessions();
    setLoading(false);
  }

  async function updateWorkSessions() {
    if (!project) return;
    const { work_sessions = [] } = await getWorkSessions(); // Get user work sessions

    const sessionsForProject = work_sessions.filter(
      (session) => session.project_id === project.id
    );
    const activeSessions = sessionsForProject.filter(
      (session) => session.end_time === null
    );

    if (activeSessions[0]) {
      setCurrentSession(activeSessions[0]);
    } else {
      setTimeElapsed(0);
      setDescription("");
      setCurrentSession(null);
      setSelectedTags([]);
    }

    setWorkSessions(work_sessions);
    return work_sessions;
  }

  async function updateSession(
    id: number,
    description: string | undefined = undefined,
    newTags: number[] | undefined = undefined
  ) {
    // Apply the update instantly and revert it if the request fails
    const sessionIndex = workSessions.findIndex((s) => s.id === id);
    const updatedWorkSessions = [...workSessions];
    if (description)
      updatedWorkSessions[sessionIndex].description = description;
    if (newTags)
      updatedWorkSessions[sessionIndex].tags = tags.filter((t) =>
        newTags.includes(t.id)
      );
    setWorkSessions(updatedWorkSessions);
    try {
      setLoading(true);
      await updateWorkSession(id, description, newTags);
      await updateWorkSessions();
      setLoading(false);
    } catch (e) {
      setWorkSessions(workSessions);
      setLoading(false);
      throw e;
    }
  }

  async function createTag(name: string) {
    setLoading(true);
    const response = await createTagAPI(name);
    await updateTags();
    setLoading(false);
    return response;
  }

  async function deleteTag(id: number) {
    setLoading(true);
    await deleteTag(id);
    await updateTags();
    setLoading(false);
  }

  async function startSession(
    description: string,
    tags: number[],
    start_time: string,
    end_time: string | null
  ) {
    setLoading(true);
    const session = await startNewSession(
      // @ts-ignore
      project.id,
      description,
      tags,
      start_time,
      end_time
    );
    const workSessions = await updateWorkSessions();
    // @ts-ignore
    const currentSession = workSessions.find((s) => s.id === session.id);
    // @ts-ignore
    setCurrentSession(currentSession);
    setLoading(false);
    return session.id;
  }

  async function stopSession(id: number) {
    setLoading(true);
    await stopSessionAPI(id);
    await updateWorkSessions();
    setLoading(false);
  }

  const throttledUpdateWorkSessionAutoStopTime = useCallback(
    throttle(
      async (params: { session_id: number; project_id: number }) => {
        try {
          await updateWorkSessionAutoStopTimeAPI(params);
        } catch (e) {
          const hint: EventHint = {
            event_id: "WorkSessionProvider.updateWorkSessionAutoStopTimeAPI",
            originalException: e,
            data: { params },
          };
          captureException(e, hint);
        }
      },
      1000 * 30,
      { leading: false, trailing: true }
    ),
    []
  );

  // Trigger when the component loads
  useEffect(() => {
    // @ts-ignore
    clickListenerRef.current = () => {
      // @ts-ignore
      if (!currentSession?.id || !project.id) return;

      throttledUpdateWorkSessionAutoStopTime({
        session_id: currentSession.id,
        // @ts-ignore
        project_id: project.id,
      });
    };
    // @ts-ignore
    window.addEventListener("click", clickListenerRef.current);

    return () => {
      // @ts-ignore
      window.removeEventListener("click", clickListenerRef.current);
      // @ts-ignore
      clearInterval(autoUpdateWorkSessionsTimeoutRef.current);
    };
  }, [currentSession?.id, project?.id]);

  // Trigger when the component loads
  useEffect(() => {
    const socketEvent = "work_session";
    const socketKey = "WorkSessionsProider";

    (async function init() {
      // Load the data initially
      setLoading(true);

      // Wait for all promises to resolve
      await Promise.all([updateTags(), updateWorkSessions()]);

      setLoading(false);

      onEvent(socketEvent, socketKey, updateWorkSessions);
    })();

    return () => {
      removeListeners(socketKey);
    };
  }, [project?.id]);

  return (
    <WorkSessionContext.Provider
      value={{
        currentSession,
        description,
        selectedTags,
        timeElapsed,
        setDescription,
        setSelectedTags,
        setTimeElapsed,
        workSessions,
        tags,
        loading,
        createTag,
        deleteTag,
        update,
        startSession,
        stopSession,
        updateSession,
        setCurrentSession,
        deleteSession,
      }}
    >
      {children}
    </WorkSessionContext.Provider>
  );
};
