import { useContext, useRef, useEffect } from "react";
import ItemColumn from "../ItemColumn";
import { context } from "./ComponentOptionsProvider";
import { useParams } from "react-router-dom";
import { Button, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { Help } from "@mui/icons-material";
import { toast } from "react-toastify";
import Select from "react-select";
import { useSelector } from "hooks";

type paramTypes = Record<"component_id" | "placement_id", string>;

export default function EditPlacement() {
  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const regexRef = useRef<HTMLInputElement>(null);
  const {
    components,
    updatePlacement,
    deletePlacement,
    group,
    project,
    setProject,
  } = useContext(context);
  const params = useParams<paramTypes>();
  // @ts-ignore
  const componentID = parseInt(params.component_id);
  const availableProjects = useSelector((state) =>
    state.user.missions.filter((m) => m?.group?.id === group?.id)
  );

  const component = components.find((c) => c.id === componentID);
  const placements = component?.placements || [];
  // @ts-ignore
  const placementID = parseInt(params.placement_id);
  const placement = placements.find((p) => p.id === placementID);

  useEffect(() => {
    if (placement && nameRef.current) {
      nameRef.current.value = placement.name;
    }
    if (placement && regexRef.current) {
      // @ts-ignore
      regexRef.current.value = placement.regex_pattern;
    }
  }, [placement]);

  const projectIsValid = availableProjects.some((p) => p.id === project?.id);

  useEffect(() => {
    if (!projectIsValid) {
      setProject(null);
    }
  }, [projectIsValid, setProject]);
  // @ts-ignore
  function onFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name") as string;
    const regexPattern = formData.get("regexPattern") as string;

    // Validate data
    if (!name || name === "") {
      toast.error("Name cannot be empty");
      return;
    }

    // Populate the payload
    let newName = null;
    let newRegexPattern = null;

    if (name !== placement?.name) {
      newName = name;
    }

    if (regexPattern !== placement?.regex_pattern) {
      newRegexPattern = regexPattern;
    }
    // @ts-ignore
    updatePlacement(componentID, placementID, newName, newRegexPattern);
  }

  function onDelete() {
    const confirmation = window.confirm(
      "Are you sure you want to delete this placement? All towers using this template and placement combo will be altered."
    );
    if (!confirmation) {
      return;
    }

    deletePlacement(componentID, placementID);
  }

  if (!placement) {
    return (
      <ItemColumn
        title="Placement not found"
        description="The placement you are trying to edit does not exist"
      />
    );
  }

  return (
    <ItemColumn
      title={placement?.name}
      description="Edit the properties of this placement below"
      onSave={() => {
        onFormSubmit({ target: formRef.current, preventDefault: () => {} });
      }}
    >
      <form onSubmit={onFormSubmit} ref={formRef}>
        <Stack spacing={3} mt={2}>
          <Stack>
            <Typography>Name</Typography>
            <TextField
              inputRef={nameRef}
              name="name"
              variant="standard"
              defaultValue={placement.name}
            />
          </Stack>
          <Select
            options={[
              {
                label: "Select a project",
                value: null,
              },
              ...availableProjects.map((p) => ({
                label: p.name,
                value: p.id,
              })),
            ]}
            value={
              projectIsValid
                ? { label: project?.name, value: project?.id }
                : { label: "Select a project", value: null }
            }
            onChange={(e) => {
              const selectedProject = availableProjects.find(
                // @ts-ignore
                (p) => p.id === e.value
              ); // @ts-ignore
              setProject(selectedProject);
            }}
          />
          <Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Image regex pattern</Typography>
              <a
                target="_blank"
                href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "grid",
                  placeItems: "center",
                }}
                rel="noreferrer"
              >
                <Tooltip
                  title="Link to the definition of regex"
                  placement="right"
                  arrow
                  disableInteractive
                >
                  <Help fontSize="small" />
                </Tooltip>
              </a>
            </Stack>
            <TextField
              disabled={!project}
              inputRef={regexRef}
              name="regexPattern"
              variant="standard"
              placeholder={
                project
                  ? "Enter a regex pattern"
                  : "Select a project to edit regex"
              }
              defaultValue={placement.regex_pattern}
            />
          </Stack>
        </Stack>
        <Stack mt={2} spacing={1} direction="row">
          <Button type="submit" variant="contained" color="success">
            Save
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </form>
    </ItemColumn>
  );
}
