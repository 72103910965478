import { bbox, length, lineString } from "@turf/turf";
import axios from "axios";
import { getLambdaURL } from "utils";
import { axiosInstance } from "utils/request";
import { toGmapBounds } from "utils/utils";

export async function getPowerlineUploadSignedURL(
  filename: string,
  // @ts-ignore
  projectID: number = null
) {
  let endpoint = `/powerline/upload/signed?filename=${filename}`;
  if (projectID) {
    endpoint = `${endpoint}&project=${projectID}`;
  }
  const response = await axiosInstance.get<string>(endpoint);
  return response.data;
}

export async function uploadFileToS3(
  files: FileList,
  projectID: number | null
) {
  const image_data = new FormData();
  const urlLocations = [];
  for (let i = 0; i < files.length; i++) {
    const file = files.item(i);
    // @ts-ignore
    const signedURL = await getPowerlineUploadSignedURL(file.name, projectID);
    urlLocations.push(signedURL);
    // @ts-ignore
    image_data.append(file.name, file);
    await axios.put(signedURL, file);
  }
  return urlLocations;
}

export async function loadPowerlineFile(
  gmap: google.maps.Map,
  urlLocations: string[],
  // @ts-ignore
  project
) {
  const lambdaURL = getLambdaURL();
  const accessToken = (await axiosInstance.get("/auth/token")).data;
  // @ts-ignore
  const response = await axiosInstance.post<{ powerlines; poles }>(
    `${lambdaURL}/powerline/convert`,
    {
      fileLinks: urlLocations,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        MissionID: project,
      },
    }
  );
  if (response.status < 400) {
    const dataset = response.data;
    const mapBounds = toGmapBounds(bbox(dataset));
    if (!gmap) return;
    // biome-ignore lint/complexity/noForEach: Google maps isnt iterative
    gmap.data.forEach((feature) => {
      gmap.data.remove(feature);
    });
    gmap.fitBounds(mapBounds);
    gmap.data.addGeoJson(dataset);

    // Hide all markers and poles
    gmap.data.setStyle((feature) => {
      // @ts-ignore
      if (feature.getGeometry().getType() === "Point") {
        return {
          visible: false,
        };
      } else {
        return { strokeColor: "red", strokeWeight: 1 };
      }
    });

    return true;
  } else {
    throw new Error(`Failed to load powerlines: ${response.statusText}`);
  }
}

export async function getFileLength(
  gmap: google.maps.Map,
  propertyTag: string
) {
  const files = {};

  if (!gmap) return;
  // biome-ignore lint/complexity/noForEach: google maps isnt iterative
  gmap.data.forEach((feature: google.maps.Data.Feature) => {
    const fileKey = feature.getProperty(propertyTag);
    if (!fileKey) {
      return;
    }
    // @ts-ignore
    const geometryType = feature.getGeometry().getType();

    if (geometryType === "Point") {
      const fileKey = feature.getProperty(propertyTag);
      if (fileKey in files) {
        // @ts-ignore
        files[fileKey].poles += 1;
      } else {
        // @ts-ignore
        files[fileKey] = {
          poles: 1,
          powerlines: 0,
        };
      }
    } else if (geometryType === "LineString") {
      const geometry = feature.getGeometry();
      // @ts-ignore
      const lineCoordinates = [];
      // @ts-ignore
      geometry.forEachLatLng((latlng) => {
        lineCoordinates.push([latlng.lng(), latlng.lat()]);
      });
      // @ts-ignore
      const line = lineString(lineCoordinates);
      const featureLength = length(line, { units: "kilometers" });
      if (fileKey in files) {
        // @ts-ignore
        files[fileKey].powerlines += featureLength;
      } else {
        // @ts-ignore
        files[fileKey] = {
          poles: 0,
          powerlines: featureLength,
        };
      }
    }
  });

  const returnList: {
    name: string;
    poles: number;
    distance: number;
  }[] = [];

  for (const key of Object.keys(files)) {
    returnList.push({
      name: key,
      // @ts-ignore
      poles: files[key].poles,
      // @ts-ignore
      distance: files[key].powerlines,
    });
  }
  return returnList;
}

export async function getFeatureKeys(gmap: google.maps.Map) {
  const poleKeys: Record<string, string[]> = {};
  const powerlineKeys: Record<string, string[]> = {};

  if (!gmap) return;
  // biome-ignore lint/complexity/noForEach: google maps isnt iterative
  gmap.data.forEach((feature: google.maps.Data.Feature) => {
    const geometry = feature.getGeometry();
    // @ts-ignore
    const geometryType = geometry.getType();
    const isPole = geometryType === "Point";
    const isPowerline = geometryType === "LineString";

    feature.forEachProperty((value, key) => {
      if (value) {
        if (isPole) {
          if (key in poleKeys && poleKeys[key].length < 3) {
            poleKeys[key] = [...poleKeys[key], value];
          } else {
            poleKeys[key] = [value];
          }
        }
        if (isPowerline) {
          if (key in powerlineKeys && powerlineKeys[key].length < 3) {
            powerlineKeys[key] = [...powerlineKeys[key], value];
          } else {
            powerlineKeys[key] = [value];
          }
        }
      }
    });
  });

  return { poleKeys, powerlineKeys };
}

export function highlightFile(
  gmap: google.maps.Map,
  filename: string,
  propertyTag: string
) {
  gmap.data.setStyle((feature) => {
    if (feature.getProperty(propertyTag) === filename) {
      // @ts-ignore
      if (feature.getGeometry().getType() === "Point") {
        return { zIndex: 2, visible: true };
      } else {
        return { strokeColor: "white", strokeWeight: 2, zIndex: 5 };
      }
    } else {
      // @ts-ignore
      if (feature.getGeometry().getType() === "Point") {
        return { zIndex: 2, visible: false };
      } else {
        return { strokeColor: "red", strokeWeight: 1, zIndex: 5 };
      }
    }
  });
}

export function removeFile(
  gmap: google.maps.Map,
  filename: string,
  propertyTag: string
) {
  if (!gmap) return;
  // biome-ignore lint/complexity/noForEach: Google maps is not iterative
  gmap.data.forEach((feature) => {
    if (feature.getProperty(propertyTag) === filename) {
      gmap.data.remove(feature);
    }
  });
}
