import SortIcon from "@mui/icons-material/Sort";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import { orderImagesByOptions } from "./constants";

interface IProps {
  setSelectedEntry: (
    string: (typeof orderImagesByOptions)[keyof typeof orderImagesByOptions]
  ) => void;
}

export default function OrderByDropDown({ setSelectedEntry }: IProps) {
  const [sortingDropDownAnchor, setSortingDropDownAnchor] = useState(null);
  // @ts-ignore
  const closeSortingDropDown = (e) => {
    setSortingDropDownAnchor(null);
    setSelectedEntry(e.target.id);
  };

  return (
    <>
      <Tooltip title="Change image sort" placement="bottom" arrow>
        <SortIcon
          sx={{ color: "#ffff" }}
          onClick={(e) => {
            // @ts-ignore
            setSortingDropDownAnchor(e.target);
          }}
          data-testid="machineReview.sortIcon"
        />
      </Tooltip>
      <Menu
        anchorEl={sortingDropDownAnchor}
        open={!!sortingDropDownAnchor}
        onClose={() => setSortingDropDownAnchor(null)}
      >
        <MenuItem
          id={orderImagesByOptions.CONFIDENCE_DESC}
          style={{ padding: "5px" }}
          onClick={closeSortingDropDown}
          data-testid="machineReview.sortDesc"
        >
          Confidence (Descending)
        </MenuItem>
        <MenuItem
          id={orderImagesByOptions.CONFIDENCE_ASC}
          style={{ padding: "5px" }}
          onClick={closeSortingDropDown}
          data-testid="machineReview.sortAsc"
        >
          Confidence (Ascending)
        </MenuItem>
        <MenuItem
          id="id"
          style={{ padding: "5px" }}
          onClick={closeSortingDropDown}
          data-testid="machineReview.sortId"
        >
          Image ID
        </MenuItem>
      </Menu>
    </>
  );
}
