import { useEffect } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { authorizedGet } from "utils/request";
import translations from "translations";
import { useLanguage } from "hooks";

export default function PoleCatcher() {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    // @ts-ignore
    const pole_id = parseInt(params.pole);
    // @ts-ignore
    const project = parseInt(params.mission);
    let isClientID = false;
    if (searchParams.has("is_client_id")) {
      isClientID = !!searchParams.get("is_client_id");
      searchParams.delete("is_client_id");
    }
    let newSearch = searchParams.toString();
    if (newSearch.length > 0) {
      newSearch = `?${newSearch}`;
    }

    if (isClientID) {
      authorizedGet<{ images: { id: number; name: string }[] }>(
        `/poles/client_id/${pole_id}/images`
      ).then((response) => {
        const images = response.images;
        if (images.length === 0) {
          toast.error(translations.PoleCatcher.NoImagesFound[language]);
          navigate(`/${project}${newSearch}`);
        } else {
          const targetImage = images[0];
          navigate(`/${project}/${targetImage.id}${newSearch}`);
        }
      });
    } else {
      authorizedGet<{ images: { id: number; name: string }[] }>(
        `/poles/${pole_id}/images`
      ).then((response) => {
        const images = response.images;
        if (images.length === 0) {
          toast.error(translations.PoleCatcher.NoImagesFound[language]);
          navigate(`/${project}${newSearch}`);
        } else {
          const targetImage = images[0];
          navigate(`/${project}/${targetImage.id}${newSearch}`);
        }
      });
    }
  }, [location]);
  return <></>;
}
