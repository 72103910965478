import { useContext } from "react";
import {
  MenuList,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Tooltip,
} from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import {
  DoneAll,
  ThumbDown,
  Done,
  MotionPhotosOffOutlined,
  RemoveDone,
  Link as LinkIcon,
} from "@mui/icons-material";
import { AnnotationContext } from "../../../provider";
import { useSelector } from "react-redux";
import { RootState } from "state/store";

type Props = {
  // @ts-ignore
  user;
  // @ts-ignore
  annotation;
  // @ts-ignore
  index;
  // @ts-ignore
  small;
};

export default function WorkflowMenu({
  user,
  annotation,
  index,
  small,
}: Props) {
  const { updateAnnotation } = useContext(AnnotationContext);

  const current_project = useSelector((state: RootState) => state.mission.id);
  // @ts-ignore
  function defectOnly(user) {
    return !(
      user.admin ||
      user.supervisor ||
      user.annotator ||
      user.skyqraft_employee
    );
  }
  // @ts-ignore
  const setWorkflowStatus = (number) => {
    updateAnnotation(annotation.id, {
      ...annotation,
      // @ts-ignore
      workflow_status: annotation.workflow_status.map((value, i) =>
        i === index ? number : value
      ),
    });
  };

  const toggleHidden = () => {
    updateAnnotation(annotation.id, {
      ...annotation,
      // @ts-ignore
      skyqraft_hidden: annotation.skyqraft_hidden.map((value, i) =>
        i === index ? !value : value
      ),
      // @ts-ignore
      workflow_status: annotation.workflow_status.map((value, i) =>
        i === index && value === 1 ? 2 : value
      ),
    });
  };

  if (defectOnly(user)) {
    return null;
  }

  let buttonSize = small ? "25px" : "30px";

  return (
    <>
      <MenuList
        style={{
          display: "flex",
          flexDirection: "row",
          marginRight: "5px",
          height: small ? "24px" : "",
          padding: small ? "0px" : "",
        }}
      >
        {annotation?.steelwork?.parent_link?.id && (
          <>
            <Tooltip
              title="Linked Image: This is the second angle for this member. Click the link to open a new tab with the main image."
              placement="top"
            >
              <LinkIcon
                sx={{ width: "40px", mt: "3px" }}
                onClick={() => {
                  window.open(
                    `${location.origin}/${current_project}/${annotation.steelwork.parent_link.image_id}/annotate${location.search}`,
                    "_blank"
                  );
                }}
              />
            </Tooltip>
          </>
        )}

        {annotation?.steelwork?.child_link?.id && (
          <>
            <Tooltip
              title="Linked Image: This member has a second angle. Click the link to open a new tab with the second image."
              placement="top"
            >
              <LinkIcon
                sx={{ width: "40px", mt: "3px" }}
                onClick={() => {
                  window.open(
                    `${location.origin}/${current_project}/${annotation.steelwork.child_link.image_id}/annotate${location.search}`,
                    "_blank"
                  );
                }}
              />
            </Tooltip>
          </>
        )}

        {annotation.is_defect[index] === true && (
          <MenuItem
            style={{ padding: small ? 0 : 5 }}
            onClick={() => toggleHidden()}
            disabled={defectOnly(user)}
          >
            <MotionPhotosOffOutlined
              sx={{ width: "20px" }}
              htmlColor={
                annotation.skyqraft_hidden[index] ? "orange" : undefined
              }
            />
          </MenuItem>
        )}

        <ToggleButtonGroup
          size="small"
          value={annotation.workflow_status[index]}
          exclusive
          sx={{ alignItems: "center" }}
        >
          <ToggleButton
            value={2}
            aria-label="module"
            sx={{ width: buttonSize, height: buttonSize }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (annotation.workflow_status[index] === 2) {
                setWorkflowStatus(1);
              } else {
                setWorkflowStatus(2);
              }
            }}
          >
            <Done
              // @ts-ignore
              htmlColor={annotation.workflow_status[index] === 2 && "green"}
            />
          </ToggleButton>
          <RoleWrapper
            keyName="supervisorApprove"
            fallback={
              <ToggleButton
                value={3}
                aria-label="quilt"
                disabled={true}
                sx={{ width: "30px", height: "30px" }}
              >
                <DoneAll
                  // @ts-ignore
                  htmlColor={annotation.workflow_status[index] === 3 && "green"}
                />
              </ToggleButton>
            }
          >
            <ToggleButton
              value={3}
              aria-label="quilt"
              sx={{ width: buttonSize, height: buttonSize }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (annotation.workflow_status[index] === 3) {
                  setWorkflowStatus(1);
                } else {
                  setWorkflowStatus(3);
                }
              }}
            >
              <DoneAll
                // @ts-ignore
                htmlColor={annotation.workflow_status[index] === 3 && "green"}
              />
            </ToggleButton>
          </RoleWrapper>
        </ToggleButtonGroup>

        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

        <ToggleButtonGroup
          size="small"
          value={annotation.workflow_status[index]}
          sx={{ alignItems: "center" }}
          exclusive
        >
          <ToggleButton
            value={4}
            aria-label="module"
            sx={{ width: buttonSize, height: buttonSize }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (annotation.workflow_status[index] === 4) {
                setWorkflowStatus(1);
              } else {
                setWorkflowStatus(4);
              }
            }}
          >
            <ThumbDown
              // @ts-ignore
              htmlColor={annotation.workflow_status[index] === 4 && "red"}
              sx={{ width: "17px" }}
            />
          </ToggleButton>

          <RoleWrapper
            keyName="supervisorApprove"
            fallback={
              <ToggleButton
                value={5}
                aria-label="quilt"
                disabled={true}
                sx={{ width: buttonSize, height: buttonSize }}
              >
                <RemoveDone
                  // @ts-ignore
                  htmlColor={annotation.workflow_status[index] === 5 && "red"}
                />
              </ToggleButton>
            }
          >
            <ToggleButton
              value={5}
              aria-label="quilt"
              sx={{ width: buttonSize, height: buttonSize }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (annotation.workflow_status[index] === 5) {
                  setWorkflowStatus(1);
                } else {
                  setWorkflowStatus(5);
                }
              }}
            >
              <RemoveDone
                // @ts-ignore
                htmlColor={annotation.workflow_status[index] === 5 && "red"}
              />
            </ToggleButton>
          </RoleWrapper>
        </ToggleButtonGroup>
      </MenuList>
    </>
  );
}
