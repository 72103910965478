import React, { Suspense } from "react";
import { connect } from "react-redux";
import RingLoader from "react-spinners/RingLoader";
import { RootState } from "state/store";
import SuspenseWrapper from "components/SuspenseWrapper";
import { getEmailList } from "state/actions";
import { findCurrentMission } from "utils/utils";
import { Mission } from "interfaces";

import { useLocation, useParams } from "react-router-dom";

const Component = React.lazy(() => import("./MissionLanding"));

interface IStateProps {
  missions: Mission[];
  demoMode: boolean;
}

interface IDispatchProps {
  getEmailList: (projectID: number) => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    missions: state.user.missions,
    demoMode: state.ui.demoMode,
  };
};

const mapDispatchToProps = {
  getEmailList,
};

function LazyLoadComponent(props: IStateProps & IDispatchProps) {
  const params = useParams();
  const location = useLocation();
  // @ts-ignore
  const mission = parseInt(params.mission);

  let targetMission = null;
  if (location.pathname.includes("order")) {
    targetMission = "order";
  } else {
    targetMission = findCurrentMission(mission, props.missions);
  }

  return (
    <Suspense
      fallback={
        <SuspenseWrapper>
          <RingLoader color="white" />
        </SuspenseWrapper>
      }
    >
      {/* @ts-ignore */}
      <Component mission={targetMission} {...props} />
    </Suspense>
  );
}

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(LazyLoadComponent);
