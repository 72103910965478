import { CheckBox, IndeterminateCheckBox } from "@mui/icons-material";
import { Chip, Divider, Stack } from "@mui/material";
import { getFeedIdentifier } from "utils/map";

type Props = {
  feedStation: {
    feedStation: string;
    feedBays: {
      name: string;
      color: string;
    }[];
  };
  highlightedFeedBays: string[];
  highlightFeedBays: (feedBays: string[]) => void;
};

export default function FeedStationItem({
  feedStation,
  highlightFeedBays,
  highlightedFeedBays,
}: Props) {
  const feedStationIdentifiers = feedStation.feedBays.map((f) =>
    getFeedIdentifier(feedStation.feedStation, f.name)
  );

  const partiallyHighlighted = highlightedFeedBays.some((f) =>
    feedStationIdentifiers.includes(f)
  );
  const fullyHighlighted = feedStationIdentifiers.every((f) =>
    // @ts-ignore
    highlightedFeedBays.includes(f)
  );
  // @ts-ignore
  function onStationClick(e) {
    if (partiallyHighlighted) {
      highlightFeedBays(
        highlightedFeedBays.filter((f) => !feedStationIdentifiers.includes(f))
      );
    } else {
      // @ts-ignore
      highlightFeedBays([...highlightedFeedBays, ...feedStationIdentifiers]);
    }
  }
  // @ts-ignore
  function onBayClick(e, feedBayIdentifier) {
    e.stopPropagation();
    if (highlightedFeedBays.includes(feedBayIdentifier)) {
      highlightFeedBays(
        highlightedFeedBays.filter((f) => f !== feedBayIdentifier)
      );
    } else {
      highlightFeedBays([...highlightedFeedBays, feedBayIdentifier]);
    }
  }
  return (
    <Stack>
      <Divider
        key={feedStation.feedStation}
        onClick={onStationClick}
        sx={{
          py: 0.5,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(221, 237, 255, 0.481)",
          },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center">
          {partiallyHighlighted ? (
            fullyHighlighted ? (
              <CheckBox fontSize="small" sx={{ mr: 0.5 }} />
            ) : (
              <IndeterminateCheckBox fontSize="small" sx={{ mr: 0.5 }} />
            )
          ) : null}
          {feedStation.feedStation === "null"
            ? "No feed station"
            : feedStation.feedStation}
        </Stack>
      </Divider>
      <Stack direction="row" flexWrap="wrap">
        {feedStation.feedBays.map((feedBay) => {
          const feedBayIdentifier = getFeedIdentifier(
            feedStation.feedStation,
            feedBay.name
          );
          return (
            <Chip
              key={feedBay.name}
              onClick={(e) => onBayClick(e, feedBayIdentifier)}
              sx={{
                py: 0.5,
                cursor: "pointer",
                // @ts-ignore
                backgroundColor: highlightedFeedBays.includes(feedBayIdentifier)
                  ? "rgba(0,0,0,0.1)"
                  : "transparent",
                "&:hover": {
                  backgroundColor: "rgba(164, 206, 255, 0.481)",
                },
              }}
              label={feedBayIdentifier}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
