import React, { ReactElement, useEffect, useState } from "react";
import { Collapse } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { authorizedGet } from "utils/request";
import { Typography, Tabs, Tab, Box } from "@mui/material";

interface Props {
  // @ts-ignore
  region;
}

interface ObjectHistory {
  created?: boolean;
  origin: string;
  timestamp: string;
  human: boolean;
  changes?: {
    x?: {
      old: number;
      new: number;
    };
    y?: {
      old: number;
      new: number;
    };
    width?: {
      old: number;
      new: number;
    };
    height?: {
      old: number;
      new: number;
    };
    comment?: {
      old: string;
      new: string;
    };
  };
}
interface TypeHistory {
  created?: boolean;
  origin: string;
  timestamp: string;
  human: boolean;
  changes?: {
    type?: {
      old: number;
      new: number;
    };
    workflow_status?: {
      old: number;
      new: number;
    };
    severity?: {
      old: number | null;
      new: number | null;
    };
    last_updated_by?: {
      old: string;
      new: string;
    };
    skyqraft_hidden?: {
      old: boolean;
      new: boolean;
    };
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function History({ region }: Props): ReactElement {
  const [objectHistory, setObjectHistory] = useState<ObjectHistory[]>([]);
  const [typeHistory, setTypeHistory] = useState<TypeHistory[]>([]);

  useEffect(() => {
    if (!region.id.includes("n")) {
      authorizedGet<{ object: ObjectHistory[]; types: TypeHistory[] }>(
        `/object/${region.id}/history`
      )
        .then((response) => {
          setObjectHistory(response.object);
          setTypeHistory(response.types);
        })
        .catch(() => {
          console.error("Fetching history failed");
          setObjectHistory([]);
          setTypeHistory([]);
        });
    }
  }, [region.showHistory, region.id]);
  const [selectedTab, setTab] = useState<number>(0);
  return (
    <Collapse in={!!region.showHistory} sx={{ width: "100%" }}>
      <div className="historyContainer">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={(e, b) => {
              setTab(b);
            }}
            aria-label="basic tabs example"
          >
            <Tab label="Object" />
            <Tab label="Types" />
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} index={0}>
          {[...objectHistory].reverse().map((o, index) => (
            <div className="historyItem" key={index}>
              <div className="historyIcon">
                {o.human ? (
                  <AccountCircle viewBox="0 0 25 25" />
                ) : (
                  <div className="avatar">ML</div>
                )}
              </div>

              <div className="historyText">
                <p>
                  <i>{o.origin}</i>
                </p>
                <p>
                  <i>{o.timestamp}</i>
                </p>
                {!!o.created && <p>Created</p>}
                {(!!o?.changes?.x ||
                  !!o?.changes?.y ||
                  !!o?.changes?.width ||
                  !!o?.changes?.height) && <p>Moved</p>}
                {o?.changes?.comment?.old !== o?.changes?.comment?.new && // @ts-ignore
                  !!o?.changes?.comment.new && (
                    <p>Comment: {o.changes.comment.new}</p>
                  )}
              </div>
            </div>
          ))}
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          {[...typeHistory].reverse().map((t, index) => (
            <div className="historyItem" key={index}>
              <div className="historyIcon">
                {t.human ? (
                  <AccountCircle viewBox="0 0 25 25" />
                ) : (
                  <div className="avatar">ML</div>
                )}
              </div>

              <div className="historyText">
                <p>
                  <i>{t.origin}</i>
                </p>
                <p>
                  <i>{t.timestamp}</i>
                </p>
                {!!t.created && <p>Created</p>}
                {t?.changes?.type?.old !== t?.changes?.type?.new &&
                  t?.changes?.type?.new && <p>Type changed</p>}
                {t?.changes?.workflow_status?.old !==
                  t?.changes?.workflow_status?.new &&
                  t?.changes?.workflow_status?.new && (
                    <p>Workflow status changed</p>
                  )}
                {t?.changes?.severity?.old !== t?.changes?.severity?.new &&
                  t?.changes?.severity?.new && <p>Severity changed</p>}
                {t?.changes?.skyqraft_hidden?.old !==
                  t?.changes?.skyqraft_hidden?.new &&
                  t?.changes?.skyqraft_hidden?.new && (
                    <p>Hidden status changed</p>
                  )}
              </div>
            </div>
          ))}
        </TabPanel>
      </div>
    </Collapse>
  );
}
