import { IHeatmap } from "api/image/marker";
import { Pole } from "interfaces/pole";

export interface IState {
  type: string;
  mapTool: any;
  bounds: any;
  gmap: any;
  drawingManager: any;
  inspectorMode: boolean;
  previewMode: boolean;
  poles: any[];
  menu: {
    show: boolean;
    x: number;
    y: number;
    lat: number;
    lng: number;
    hasImages: boolean;
    items: any[];
  };
  menuMarker: any;
  markers: any[];
  listViewMarkers: any[];
  clearanceMarkers: any[];
  lidarMarkers: any[];
  topologyMode: boolean;
  heatmap: IHeatmap;
  hasPowerlines: boolean;
  powerlinesLoaded: boolean;
  markersHighlight: any;
  selection: any;
  powerlineName: string;
  orderMode: boolean;
  visibleImageTypes: any[];
  overlays: google.maps.Polygon[];
  markerController: any;
  powerlineController: any;
  filterArea: any;
  listViewExpandPoleId: any[];
  listViewExpandImageId: any[];
  listViewPage: number;
  listViewPagePoleImageToggle: boolean;
  openedMarkers: number[];
  polesLoading: boolean;
  previousDefectPoles: Pole[];
  previousDefectProject: number | null;
  previousDefectPolesPage: number | null;
  previousDefectPolesCount: { pages: number | null; poles: number | null };
}

export const initialState = {
  type: "hybrid",
  mapTool: null,
  bounds: null,
  gmap: null,
  drawingManager: null,
  inspectorMode: false,
  previewMode: false,
  poles: [],
  menu: {
    show: false,
    x: 0,
    y: 0,
    lat: 0,
    lng: 0,
    hasImages: false,
    items: [],
  },
  menuMarker: null,
  markers: [],
  listViewMarkers: [],
  clearanceMarkers: [],
  lidarMarkers: [],
  topologyMode: false,
  heatmap: {
    positions: [],
  },
  hasPowerlines: false,
  powerlinesLoaded: false,
  markersHighlight: null,
  selection: null,
  powerlineName: "",
  orderMode: false,
  visibleImageTypes: [null, 1, 2, 3, 4],
  overlays: [],
  markerController: null,
  powerlineController: null,
  filterArea: null,
  listViewExpandPoleId: [],
  listViewExpandImageId: [],
  listViewPage: 1,
  listViewPagePoleImageToggle: true,
  openedMarkers: [],
  polesLoading: false,
  previousDefectPoles: [],
  previousDefectProject: null,
  previousDefectPolesPage: null,
  previousDefectPolesCount: { pages: null, poles: null },
};

// @ts-ignore
const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOPOLOGY_MODE":
      return {
        ...state,
        topologyMode: action.payload,
      };
    case "SET_OPENED_MARKERS":
      return {
        ...state,
        openedMarkers: action.payload,
      };
    case "SET_MENU":
      return {
        ...state,
        menu: action.payload,
      };
    case "SET_FILTER_AREA":
      return {
        ...state,
        filterArea: action.payload,
      };
    case "SET_CLEARANCE_MARKERS":
      return {
        ...state,
        clearanceMarkers: action.payload,
      };
    case "SET_LIDAR_MARKERS":
      return {
        ...state,
        lidarMarkers: action.payload,
      };
    case "SET_MARKER_CONTROLLER":
      return {
        ...state,
        markerController: action.payload,
      };
    case "SET_POWERLINE_CONTROLLER":
      return {
        ...state,
        powerlineController: action.payload,
      };
    case "SET_OVERLAYS":
      return {
        ...state,
        overlays: action.payload,
      };
    case "SET_DRAWING_MANAGER":
      return {
        ...state,
        drawingManager: action.payload,
      };
    case "SET_HAS_POWERLINES":
      return {
        ...state,
        hasPowerlines: action.payload,
      };
    case "SET_VISIBLE_IMAGE_TYPES":
      return {
        ...state,
        visibleImageTypes: action.payload,
      };
    case "SET_POWERLINES_LOADED":
      return {
        ...state,
        powerlinesLoaded: action.payload,
      };
    case "SET_ORDER_MODE":
      return {
        ...state,
        orderMode: action.payload,
      };
    case "SET_SELECTION":
      return {
        ...state,
        selection: action.payload,
      };
    case "SET_MENU_MARKER": {
      return {
        ...state,
        menuMarker: action.payload,
      };
    }
    case "HIDE_MENU":
      return {
        ...state,
        menu: initialState.menu,
      };

    case "SET_MAP_BOUNDS":
      return {
        ...state,
        bounds: action.payload,
      };
    case "SET_MAP_TYPE":
      return {
        ...state,
        type: action.payload,
      };
    case "SET_INSPECTOR_MODE":
      return {
        ...state,
        inspectorMode: action.payload,
      };
    case "SET_PREVIEW_MODE":
      return {
        ...state,
        previewMode: action.payload,
      };
    case "SET_MAP":
      return {
        ...state,
        gmap: action.payload,
      };
    case "SET_MAP_TOOL":
      return {
        ...state,
        mapTool: action.payload,
      };

    case "SET_POLES":
      if (action.payload !== undefined) {
        return {
          ...state,
          poles: action.payload,
        };
      } else {
        return state;
      }
    case "SET_POLES_LOADING":
      return {
        ...state,
        polesLoading: action.payload,
      };
    case "SET_PREVIOUS_DEFECT_POLES":
      return {
        ...state,
        previousDefectPoles: action.payload,
      };
    case "SET_PREVIOUS_DEFECT_POLES_PAGE":
      return {
        ...state,
        previousDefectPolesPage: action.payload,
      };
    case "SET_PREVIOUS_DEFECT_POLES_COUNT":
      return {
        ...state,
        previousDefectPolesCount: action.payload,
      };
    case "SET_PREVIOUS_DEFECT_PROJECT":
      return {
        ...state,
        previousDefectProject: action.payload,
      };
    case "REQUEST_MARKERS": {
      return {
        ...state,
      };
    }
    case "RECIEVE_MARKERS": {
      return {
        ...state,
        markers: action.payload,
        heatmap: {
          positions: [],
        },
      };
    }
    case "RECIEVE_LIST_VIEW_MARKERS": {
      return {
        ...state,
        listViewMarkers: action.payload,
      };
    }
    case "SET_HEATMAP": {
      return {
        ...state,
        heatmap: {
          positions: action.payload,
        },
        markers: [],
      };
    }

    case "SET_MARKERS_HIGHLIGHT": {
      return {
        ...state,
        markersHighlight: action.payload,
      };
    }

    case "SET_LIST_VIEW_EXPAND_POLE_ID": {
      return {
        ...state,
        listViewExpandPoleId: action.payload,
      };
    }

    case "SET_LIST_VIEW_EXPAND_IMAGE_ID": {
      return {
        ...state,
        listViewExpandImageId: action.payload,
      };
    }

    case "SET_LIST_VIEW_PAGE": {
      return {
        ...state,
        listViewPage: action.payload,
      };
    }

    case "SET_LIST_VIEW_POLE_IMAGE_TOGGLE": {
      return {
        ...state,
        listViewPagePoleImageToggle: action.payload,
      };
    }

    default:
      return state;
  }
};

export default mapReducer;
