import axios, { AxiosResponse } from "axios";
import { useState, useEffect } from "react";
import { BASE_URI } from "config";

export interface IReport {
  id: number;
  pending: boolean;
  name: string;
  filename: string;
  creator: {
    id: number;
    name: string;
  };
  user: {
    id: number;
    name: string;
  };
  accepted: boolean;
  starred: boolean;
  filter_used: string;
  project: {
    id: number;
    name: string;
  };
  type: {
    id: number;
    name: string;
  };
  pdf_url: string;
  image_url: string;
  timestamp: string;
  size: string;
  n_defects: number;
  n_images: number;
}

interface IUpdateProps {
  accepted?: boolean;
  size?: string;
  starred?: boolean;
  name?: string;
}

/**
 * A React hook that collects data and signed urls for a report for all or one project
 *
 * @param {number} [reportID] - The reportID to collect.
 * @param {number} [projectID] - The projectID to collect.
 *
 * It is important that the project ID is the same as the project the report belongs to.
 * This is used to validate that you have access to the report. We can do this implicitly
 * but it's easier this way. (It's a corner we decided to cut)
 *
 * @example
 * // Collect report 17 on project 7.
 * useReportInbox(17, 7)
 * // Collect report 13 on project 3.
 * useReportInbox(13, 3)
 *
 */
export function useReport(
  reportID: number,
  projectID: number
): [
  IReport,
  (data: IUpdateProps) => Promise<String>,
  () => Promise<String>,
  Function,
  boolean,
] {
  const [report, setReport] = useState<IReport | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  async function updateReport(data: IUpdateProps) {
    const endpoint = `${BASE_URI}/report/defect/${reportID}`;
    const headers = {
      MissionID: projectID,
    };
    const response = await axios.put(endpoint, data, {
      headers,
      withCredentials: true,
    });
    if (response.status === 200) {
      const newReport = {
        ...report,
        ...data,
      };
      // @ts-ignore
      setReport(newReport);
    }

    return "Done";
  }

  async function deleteReport() {
    const endpoint = `${BASE_URI}/report/defect/${reportID}`;
    const headers = {
      MissionID: projectID,
    };
    const response = await axios.delete(endpoint, {
      headers,
      withCredentials: true,
    });
    return response.statusText;
  }

  function getReport(updateLoading = true, callback = null) {
    const endpoint = `${BASE_URI}/report/defect/${reportID}`;
    const headers = {};
    if (projectID) {
      // @ts-ignore
      headers["MissionID"] = projectID;
    }
    updateLoading && setLoading(true);
    axios
      .get<IReport>(endpoint, { headers, withCredentials: true })
      .then((response) => {
        setReport(response.data);
        updateLoading && setLoading(false);
        if (!response.data.pending) {
          // @ts-ignore
          callback && callback();
        }
      })
      .catch((err) => {
        setReport(null);
        updateLoading && setLoading(false);
      });
  }

  useEffect(getReport, [reportID, projectID]);
  // @ts-ignore
  return [report, updateReport, deleteReport, getReport, loading];
}
