import { useEffect, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "hooks";
import PageTitle from "./PageTitle";
import UploadButton from "./UploadButton";
import MetaSelector from "./MetaSelector";
import MapCount from "./MapCount";
import { getFileLength, getFeatureKeys } from "./utils";
import { Loading, MapView } from "views";
import SaveButton from "./SaveButton";
import { IKeyPayload } from "components/KMLUploader/MetaSelector";
import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";

export type FileLength = Awaited<ReturnType<typeof getFileLength>>;
export type PowerlineKeys = Awaited<
  ReturnType<typeof getFeatureKeys> // @ts-ignore
>["powerlineKeys"];
// @ts-ignore
export type PoleKeys = Awaited<ReturnType<typeof getFeatureKeys>>["poleKeys"];

interface IProps {
  disabled?: boolean;
  projectID?: number;
  onFileLoad?: (fileSize: FileLength) => void;
  onSave?: (kmlData: string, kmlKeys: IKeyPayload) => Promise<void>;
  keys: IKeyPayload;
  setKeys: (keys: IKeyPayload) => void;
}

export default function KMLUploader({
  projectID,
  disabled,
  onSave,
  keys,
  setKeys,
  onFileLoad,
}: IProps) {
  const [fileLength, setFileLength] = useState<FileLength | null>(null);
  const [powerlineKeys, setPowerlineKeys] = useState<PowerlineKeys>({});
  const [loaded, setLoaded] = useState(false);
  const [poleKeys, setPoleKeys] = useState<PoleKeys>({});
  const [propertyTag, setPropertyTag] = useState<string>("folder");
  const gmap = useSelector((state) => state.map.gmap);
  const [saving, setSaving] = useState(false);
  const [failed, setFailed] = useState(false);
  const [done, setDone] = useState(false);

  async function updateFileSize(propertyKey: string) {
    const filelengths = await getFileLength(gmap, propertyKey);
    setFileLength(filelengths);
    onFileLoad?.(filelengths);
  }

  useEffect(() => {
    if (loaded) {
      updateFileSize(propertyTag);
    }
  }, [propertyTag, loaded]);

  async function updateFileKeys() {
    const keys = await getFeatureKeys(gmap);
    // @ts-ignore
    setPowerlineKeys(keys.powerlineKeys);
    // @ts-ignore
    setPoleKeys(keys.poleKeys);
  }

  function onUpload() {
    setLoaded(true);
    updateFileKeys();
    updateFileSize(propertyTag);
  }
  return (
    <Stack id="kml-uploader" direction="row">
      {saving ? (
        <Stack flex={1} alignItems="center" justifyContent="center" spacing={1}>
          <Loading relative size={40} color="black" />
          <Typography variant="h4" color="success">
            Saving geospatial data
          </Typography>
          <Typography variant="subtitle1">
            This might take a while if the uploaded file is large
          </Typography>
        </Stack>
      ) : failed ? (
        <Stack flex={1} alignItems="center" justifyContent="center" spacing={1}>
          <HighlightOff sx={{ fontSize: "4rem" }} color="error" />
          <Typography variant="h4" color="error">
            Saving failed
          </Typography>
          <Typography variant="subtitle1" color="error">
            Please try again or contact support
          </Typography>
        </Stack>
      ) : done ? (
        <Stack flex={1} alignItems="center" justifyContent="center" spacing={1}>
          <CheckCircleOutline sx={{ fontSize: "4rem" }} color="success" />
          <Typography variant="h4" color="success">
            Geospatial data saved
          </Typography>
        </Stack>
      ) : (
        <Stack flex={1} divider={<Divider />} spacing={2}>
          <PageTitle />
          <UploadButton
            // @ts-ignore
            projectID={projectID}
            onLoad={onUpload}
            disabled={disabled || saving || done}
          />

          {Object.keys(powerlineKeys).length + Object.keys(poleKeys).length >
            0 && (
            <MetaSelector
              powerlineKeys={powerlineKeys}
              poleKeys={poleKeys}
              keys={keys}
              setKeys={setKeys}
              disabled={disabled || saving || done}
            />
          )}
          {fileLength && (
            <MapCount
              updateFileSize={updateFileSize}
              propertyTag={propertyTag}
              setPropertyTag={setPropertyTag}
              fileCounts={fileLength}
              disabled={disabled || saving || done}
              poleKeys={Object.keys(poleKeys)}
              powerlineKeys={Object.keys(powerlineKeys)}
            />
          )}

          {onSave && (
            <SaveButton
              onSave={(kmlData) => {
                setSaving(true);
                onSave(kmlData, keys)
                  .then(() => {
                    setFailed(false);
                  })
                  .catch(() => {
                    setFailed(true);
                  })
                  .finally(() => {
                    setSaving(false);
                    setDone(true);
                  });
              }}
              disabled={disabled || saving || done}
            />
          )}
        </Stack>
      )}

      <Stack flex={1}>
        <Box
          flex={{ xs: undefined, sm: undefined, md: undefined, lg: 1, xl: 1 }}
          height={400}
          minWidth={200}
          minHeight={200}
          maxHeight={600}
        >
          {/* @ts-ignore */}
          <MapView hideElements />
        </Box>
      </Stack>
    </Stack>
  );
}
