import { Stack, Typography } from "@mui/material";
import KeySelector from "./KeySelector";

type Props = {
  poleKeys: Record<string, string[]>;
  powerlineKeys: Record<string, string[]>;
  keys: IKeyPayload;
  setKeys: (keys: IKeyPayload) => void;
  disabled?: boolean;
};

export interface IKeyPayload {
  poleClientID: string | null;
  poleNote: string | null;
  powerlineClientID: string | null;
  powerlineFeedBay: string | null;
  powerlineFeedStation: string | null;
  powerlineTopologyColor: string | null;
}

export default function MetaSelector({
  poleKeys,
  powerlineKeys,
  keys,
  setKeys,
  disabled,
}: Props) {
  return (
    <Stack spacing={2} flex={1}>
      <Typography variant="h6" component="h2">
        Select a key for each property of the uploaded file
      </Typography>
      <KeySelector
        disabled={disabled}
        title="Structure Client ID key"
        options={poleKeys}
        onKeySelect={(value) =>
          setKeys({
            ...keys,
            poleClientID: value,
          })
        }
        // @ts-ignore
        value={keys.poleClientID}
      />
      <KeySelector
        disabled={disabled}
        title="Structure Note key"
        options={poleKeys}
        onKeySelect={(value) =>
          setKeys({
            ...keys,
            poleNote: value,
          })
        }
        // @ts-ignore
        value={keys.poleNote}
      />
      <KeySelector
        disabled={disabled}
        title="Powerline Client ID key"
        options={powerlineKeys}
        onKeySelect={(value) =>
          setKeys({
            ...keys,
            powerlineClientID: value,
          })
        }
        // @ts-ignore
        value={keys.powerlineClientID}
      />
      <KeySelector
        disabled={disabled}
        title="Powerline Feed bay key"
        options={powerlineKeys}
        onKeySelect={(value) =>
          setKeys({
            ...keys,
            powerlineFeedBay: value,
          })
        }
        // @ts-ignore
        value={keys.powerlineFeedBay}
      />

      <KeySelector
        disabled={disabled}
        title="Powerline Feed Station key"
        options={powerlineKeys}
        onKeySelect={(value) =>
          setKeys({
            ...keys,
            powerlineFeedStation: value,
          })
        }
        // @ts-ignore
        value={keys.powerlineFeedStation}
      />

      <KeySelector
        disabled={disabled}
        title="Powerline Topology Color key"
        options={powerlineKeys}
        onKeySelect={(value) =>
          setKeys({
            ...keys,
            powerlineTopologyColor: value,
          })
        }
        // @ts-ignore
        value={keys.powerlineTopologyColor}
      />
    </Stack>
  );
}
