import { connect } from "react-redux";
import Component from "./DropZone";
import { RootState } from "state/store";
import {
  getCloudFolders,
  getRawLocation,
  setFiles,
  startUpload,
  resetUpload,
  setS3FolderName,
  stopUpload,
  addCloudFolder,
  setAutomaticImport,
} from "state/actions";

interface IStateProps {
  cloudFolders: string[];
  files: any[];
  status: "idle" | "running" | "stopped";
  // @ts-ignore
  currentFile;
  s3FolderName: string;
  automaticImport: boolean;
}

interface IDispatchProps {
  getCloudFolders: () => void;
  getRawLocation: () => void;
  // @ts-ignore
  setFiles: (files) => void;
  startUpload: () => void;
  setS3FolderName: (value: string) => void;
  resetUpload: () => void;
  stopUpload: () => void;
  addCloudFolder: (value: string) => void;
  setAutomaticImport: (value: boolean) => void;
}

interface IOwnProps {
  disabled?: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    cloudFolders: state.imageUpload.cloudFolders,
    container: state.imageUpload.bucket,
    files: state.imageUpload.files,
    status: state.imageUpload.status,
    currentFile: state.imageUpload.currentFile,
    s3FolderName: state.imageUpload.s3Folder,
    automaticImport: state.imageUpload.startImportAutomatically,
  };
};

const mapDispatchToProps = {
  getCloudFolders,
  getRawLocation,
  setFiles,
  stopUpload,
  startUpload,
  resetUpload,
  setS3FolderName,
  addCloudFolder,
  setAutomaticImport,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
