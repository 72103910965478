import { connect } from "react-redux";
import ListView from "./ListView";
import { RootState } from "state/store";
import {
  updateListViewMarkers,
  setListViewExpandPoleId,
  setListViewExpandImageId,
  setListViewPage,
} from "state/actions";
import { ListViewMarker } from "interfaces";

interface IStateProps {
  markers: ListViewMarker[];
  // @ts-ignore
  expandPoleId;
  // @ts-ignore
  expandImageId;
  // @ts-ignore
  page;
  poleImageToggle: boolean;
  imageId: number | null;
  gmap: google.maps.Map;
  mapLoading: boolean;
}

interface IDispatchProps {
  updateListViewMarkers: Function;
  setListViewExpandPoleId: Function;
  setListViewExpandImageId: Function;
  setListViewPage: Function;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    markers: state.map.listViewMarkers,
    expandPoleId: state.map.listViewExpandPoleId,
    expandImageId: state.map.listViewExpandImageId,
    page: state.map.listViewPage,
    poleImageToggle: state.map.listViewPagePoleImageToggle,
    imageId: state.image?.current?.id,
    gmap: state.map.gmap,
    mapLoading: !!state.map.markerController,
  };
};

const mapDispatchToProps = {
  updateListViewMarkers,
  setListViewExpandPoleId,
  setListViewExpandImageId,
  setListViewPage,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(ListView);
