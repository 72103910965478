// @ts-ignore
export const setToastMessage = (value) => {
  return {
    type: "SET_TOAST_MESSAGE",
    payload: value,
  };
};

export const clearToastMessage = () => {
  return {
    type: "CLEAR_TOAST_MESSAGE",
  };
};
