import { useContext, useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";
import { SummaryContext } from "views/PoleViewer/provider";
import { IPoleComponentStatusItem } from "views/PoleViewer/api";
import { ArrowDropDown, Image } from "@mui/icons-material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getPoleItemPlacementImages } from "views/PoleViewer/api";

type Props = {
  placement: IPoleComponentStatusItem;
  componentID: number;
  valueSystem: number;
  setThumbnailRegexPattern: (pattern: string | null) => void;
  thumbnailRegexPattern: string | null;
  setThumbnailPoleItemPlacement: (id: number | null) => void;
  thumbnailPoleItemPlacement: number | null;
  placementID: number | null; // @ts-ignore
  onClick: (e) => void;
};

function StatusItem({
  statusLevel,
  label,
  color,
  textColor,
}: {
  statusLevel: number;
  label: string;
  color: string;
  textColor?: string;
}) {
  const colorMap = {
    1: "green",
    2: "orange",
    3: "red",
  };
  return (
    <Stack
      direction="row"
      justifyContent={"center"}
      alignItems="center"
      spacing={1}
    >
      <div
        style={{
          background: color,
          borderRadius: "100%",
          width: 14,
          height: 14,
        }}
      />
      <Typography sx={{ color: textColor }}>{label}</Typography>
    </Stack>
  );
}

export default function PolePlacementItem({
  placement,
  componentID,
  valueSystem,
  setThumbnailRegexPattern,
  thumbnailRegexPattern,
  setThumbnailPoleItemPlacement,
  thumbnailPoleItemPlacement,
  placementID,
  onClick,
}: Props) {
  const summaryContext = useContext(SummaryContext);
  const [params, setSearchParams] = useSearchParams();
  const pathParams = useParams();
  const navigate = useNavigate();
  // @ts-ignore
  const mission = parseInt(pathParams["mission"]);
  // @ts-ignore
  const imageID = parseInt(pathParams["image"]);
  // @ts-ignore
  const poleID = parseInt(params.get("pole"));
  const imageObjectIDs = params.get("boxIds")?.split(",") || [];

  const lowestSeverityColor = "#11DF60";

  useEffect(() => {
    return () => {
      setThumbnailRegexPattern(null);
      setThumbnailPoleItemPlacement(null);
    };
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const meunOpen = Boolean(menuAnchorEl); // @ts-ignore
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }; // @ts-ignore
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const system = summaryContext.poleValueSystems.find(
    (s) => s.id === valueSystem
  );

  const selected = placementID === placement.id;
  const active =
    !!placement.regex_pattern &&
    placement.regex_pattern === thumbnailRegexPattern;

  const selectBoxBeforeGrading = (color: string) => {
    if (color === lowestSeverityColor) {
      return { needsSelection: false, imageObjectID: null };
    }
    if (imageObjectIDs.length === 1) {
      return {
        needsSelection: false,
        imageObjectID: parseInt(imageObjectIDs[0]),
      };
    }
    return { needsSelection: true, imageObjectID: null };
  };

  useEffect(() => {
    if (placementID === placement.id) {
      getPoleItemPlacementImages(poleID, placement.id).then((response) => {
        if (response.length > 0) {
          setSearchParams(params);
          setThumbnailPoleItemPlacement(placement.id);
        } else {
          setThumbnailPoleItemPlacement(null);
        }
      });
      setThumbnailRegexPattern(placement.regex_pattern);
      setThumbnailPoleItemPlacement(placement.id);
    }
  }, [placementID]);

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          background: selected ? "#1876D1" : "transparent",
        }}
      >
        <div
          style={{ cursor: "pointer" }}
          data-testid={`pole-placement-item-${placement.name}`}
          onContextMenu={handleMenuClick}
          onClick={onClick}
        >
          <Stack
            px={2}
            py={1}
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography color={selected ? "#FFF" : "#404040"}>
              {placement.name}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip
                label={
                  <StatusItem
                    statusLevel={placement.value.id}
                    label={placement.value.name}
                    color={placement.value.color}
                    textColor={selected ? "#FFF" : "#404040"}
                  />
                }
                onClick={handleClick}
                icon={
                  <ArrowDropDown
                    sx={{
                      color: selected ? "#fff!important" : "#404040!important",
                    }}
                  />
                }
              />
              <IconButton
                onClick={() => {
                  if (placement.regex_pattern === thumbnailRegexPattern) {
                    setThumbnailRegexPattern(null);
                  } else {
                    setThumbnailRegexPattern(placement.regex_pattern);
                  }
                }}
              >
                <Image
                  htmlColor={active ? (selected ? "#FFF" : "#404040") : "#AAA"}
                />
              </IconButton>
            </Stack>
          </Stack>
        </div>
      </Paper>
      <Menu
        id="placement-menu"
        anchorEl={menuAnchorEl}
        open={meunOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            const newName = window.prompt(
              "Change placement name",
              placement.name
            );
            if (newName) {
              summaryContext.updateComponentPlacement(
                componentID,
                placement.id,
                newName,
                placement.regex_pattern
              );
            }
            handleMenuClose();
          }}
        >
          Change placement name
        </MenuItem>
        <MenuItem
          onClick={() => {
            const newRegex = window.prompt(
              "Change placement regex", // @ts-ignore
              placement.regex_pattern
            );
            if (newRegex) {
              summaryContext.updateComponentPlacement(
                componentID,
                placement.id,
                placement.name,
                newRegex
              );
            }
            handleMenuClose();
          }}
        >
          Change placement regex
        </MenuItem>
        <MenuItem
          onClick={() => {
            params.set("placementID", placement.id.toString());
            params.set("ConnectPlacementImages", "true");
            setSearchParams(params);
          }}
        >
          Connect images
        </MenuItem>
        <MenuItem
          onClick={() => {
            summaryContext.deletePoleStatus(poleID, componentID, placement.id);
            handleMenuClose();
          }}
        >
          Delete placement
        </MenuItem>
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {system?.values.map((value) => (
          <Tooltip
            title={
              selectBoxBeforeGrading(value.color).needsSelection
                ? "Please select a bounding box in the image viewer"
                : ""
            }
            arrow
            placement="left"
          >
            <span>
              <MenuItem
                disabled={selectBoxBeforeGrading(value.color).needsSelection}
                value={value.id}
                onClick={() => {
                  summaryContext.updatePoleStatusValue(
                    poleID,
                    componentID,
                    placement.id,
                    value.id,
                    imageID,
                    selectBoxBeforeGrading(value.color).imageObjectID
                  );
                  setAnchorEl(null);
                }}
              >
                <StatusItem
                  statusLevel={value.id}
                  label={value.name}
                  color={value.color}
                />
              </MenuItem>
            </span>
          </Tooltip>
        ))}
        <MenuItem
          onClick={() => {
            summaryContext.deletePoleStatus(poleID, componentID, placement.id);
            setAnchorEl(null);
          }}
        >
          <Typography>Remove</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
