import { getCDNURL } from "authentication/apiDetails";
import { qualities } from "constants/image";

export function getImageUrl(args: {
  quality: (typeof qualities)[keyof typeof qualities];
  cdn: string;
  i: number;
  j: number;
}) {
  const { quality, cdn, i, j } = args;

  const base = `${getCDNURL()}/image`;
  const cdn_components = cdn.split(".");
  const extension = cdn_components.pop();
  const path = cdn_components.join(".");
  return encodeURI(`${base}/${path}_${quality}_${i}_${j}.${extension}`);
}
