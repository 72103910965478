import { Clear } from "@mui/icons-material";
import {
  Typography,
  Stack,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";

type Props = {
  title: string;
  options: Record<string, string[]>;
  onKeySelect: (value: string) => void;
  value: string;
  disabled?: boolean;
};

export default function KeySelector({
  title,
  options,
  value,
  onKeySelect,
  disabled,
}: Props) {
  return (
    <Stack>
      <Typography>{title}</Typography>
      <Stack direction="row" alignItems="center" spacing={2} mr={2}>
        <IconButton
          disabled={!value}
          onClick={() => {
            // @ts-ignore
            onKeySelect(null);
          }}
        >
          <Clear htmlColor={value ? "red" : "grey"} />
        </IconButton>
        <Select
          disabled={disabled}
          sx={{ minWidth: 200 }}
          fullWidth
          variant="standard"
          onChange={(e: SelectChangeEvent<string>) => {
            onKeySelect(e.target.value);
          }}
          value={value}
        >
          {Object.keys(options).map((key) => (
            <MenuItem value={key}>{key}</MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        ml={1}
      >
        {value in options && (
          <Typography>Example: {options[value].join(", ")}</Typography>
        )}
      </Stack>
    </Stack>
  );
}
