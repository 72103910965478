import { Checkbox, FormControlLabel } from "@mui/material";
import { useFlaggedFilter, useTranslation } from "hooks";

export default function FlaggedImageFilter() {
  const Flagged = useTranslation("Flagged");
  const { flagged, setFlagged } = useFlaggedFilter();

  return (
    <FormControlLabel
      checked={flagged}
      sx={{
        "&:hover": {
          backgroundColor: "#EDEDED",
        },
        p: 0,
        m: 0,
        py: 1,
      }}
      // @ts-ignore
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setFlagged(!e.target.checked)
      }
      control={<Checkbox />}
      label={Flagged}
    />
  );
}
