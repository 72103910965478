import React, { ReactElement, useEffect } from "react";
import { Button, Divider, InputLabel } from "@mui/material";
import Select from "react-select";
import translations from "translations";
import { useLanguage } from "hooks";

interface Props {
  uploadStatus: string;
  uploadUsers: Array<any>;
  defaultUser: number;
  objectFile: any;
  // @ts-ignore
  setObjectFile: (value) => void;
  // @ts-ignore
  setDefaultUser: (value) => void;
  getUploadUsers: () => void;
  uploadObjectFile: () => void;
}

export default function ObjectUpload({
  uploadStatus,
  uploadUsers,
  defaultUser,
  objectFile,
  setObjectFile,
  setDefaultUser,
  getUploadUsers,
  uploadObjectFile,
}: Props): ReactElement {
  const { language } = useLanguage();
  // @ts-ignore
  const validateSize = (file) => {
    let size = 30000;
    let err = "";
    if (file.size > size) {
      err = file.type + "is too large, please pick a smaller file\n";
      alert(err);
    }
    return true;
  };
  // @ts-ignore
  const onFileChange = (e) => {
    let file = e.target.files[0];
    validateSize(e) && setObjectFile(file);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getUploadUsers, []);

  return (
    <div className="objectUpload">
      <h2>{translations.Menu.Tools.ImageObjects.Upload.Title[language]}</h2>
      <p>{translations.Menu.Tools.ImageObjects.Upload.Description[language]}</p>
      <form>
        <div style={{ margin: 10, marginTop: 20, marginBottom: 20 }}>
          <InputLabel id="label">
            {translations.Menu.Tools.ImageObjects.Upload.SelectUser[language]}
          </InputLabel>
          <Select
            placeholder={
              translations.Menu.Tools.ImageObjects.Upload.SelectUser[language]
            }
            className="uploadUserSelector"
            options={uploadUsers}
            onChange={(meta) => setDefaultUser(meta.value)}
            value={uploadUsers.find((user) => user.value === defaultUser)}
          />
        </div>
        <br />
        <hr />
        <div className="buttonContainer">
          <Button
            variant="contained"
            className="primaryButton"
            component="label"
            style={{ marginLeft: 5, textTransform: "none" }}
          >
            <input
              type="file"
              accept="application/json"
              hidden
              onChange={onFileChange}
            />
            {translations.Menu.Tools.ImageObjects.Upload.ChooseFile[language]}
          </Button>
          <Button
            id="real_upload"
            onClick={() => uploadObjectFile()}
            variant="contained"
            className="secondaryButton"
            disabled={!objectFile}
            style={{ marginLeft: 5, textTransform: "none" }}
          >
            {translations.Menu.Tools.ImageObjects.Upload.Upload[language]}
          </Button>
        </div>

        {uploadStatus.length > 0 && (
          <>
            <Divider style={{ margin: 10 }} />
            <span style={{ margin: 10 }}>{uploadStatus}</span>
          </>
        )}
      </form>
    </div>
  );
}
