import { connect } from "react-redux";
import { RootState } from "state/store";
import {
  setLidarWindow,
  updateMarkers,
  setClickedLidarMarker,
} from "state/actions";
import Component from "./ClearanceMarker";

interface IStateProps {
  severities: any[];
  clickedLidarMarker: number | string | null;
}

interface IDispatchProps {
  setLidarWindow: (window: any) => void;
  updateMarkers: () => void;
  setClickedLidarMarker: (lidarMarkerId: number | string) => void;
}

interface IOwnProps {
  severity: number;
  scene: string;
  boxID: string;
  project: number;
  lat: number;
  lng: number;
}

const mapStateToProps = (state: RootState) => {
  return {
    severities: state.objects.issueSeverities,
    clickedLidarMarker: state.lidar.clickedLidarMarker,
  };
};

const mapDispatchToProps = {
  setLidarWindow,
  updateMarkers,
  setClickedLidarMarker,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps, // @ts-ignore
  mapDispatchToProps
)(Component);
