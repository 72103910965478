import { Chip, Stack, Typography } from "@mui/material";
import Select from "react-select";
import { useActorsCount, useActorsFilter, useLanguage } from "hooks";
import { Add, ChangeHistory } from "@mui/icons-material";
import translations from "translations";

export default function ActorsFilter() {
  const { actorsFilter, setActorsFilter } = useActorsFilter();
  const { language } = useLanguage();
  const { actors, loading } = useActorsCount();

  const selectOptions = actors.map((actor) => ({
    label: (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={0}>
          <Chip
            icon={<Add />}
            label={actor.count.created}
            sx={{ m: 0, p: 0 }}
          />
          <Chip
            icon={<ChangeHistory />}
            label={actor.count.updated}
            sx={{ m: 0, p: 0 }}
          />
        </Stack>
        <Typography>{actor.name}</Typography>
      </Stack>
    ),
    value: actor.id,
  }));
  // @ts-ignore
  function handleSelectChange(selected) {
    // @ts-ignore
    setActorsFilter(selected.map((actor) => actor.value));
  }

  const selectValue = selectOptions.filter((option) => {
    return actorsFilter.includes(option.value);
  });

  return (
    <Select
      options={selectOptions}
      isMulti
      value={selectValue}
      loadingMessage={() => translations.Filter.Loading[language]}
      isLoading={loading}
      onChange={handleSelectChange}
      menuPosition="fixed"
      placeholder={translations.Filter.ActorsFilter[language]}
    />
  );
}
