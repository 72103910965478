import { useState } from "react";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "views";
import { BASE_URI } from "config";

// @ts-ignore
export default function MFA({ setLoggedIn }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mfaCode, setMfaCode] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageLevel, setMessageLevel] = useState<
    "info" | "warning" | "error"
  >("info");

  const session = searchParams.get("session");
  const phone_number = searchParams.get("phone");
  const username = searchParams.get("username");
  // @ts-ignore
  function onMfaSubmit(e) {
    e.preventDefault();
    setSending(true);
    axios
      .post(
        `${BASE_URI}/auth/mfa`,
        {
          challenge: phone_number ? "SMS_MFA" : "SOFTWARE_TOKEN_MFA",
          mfa_code: mfaCode,
          session,
          username,
        },
        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        if (data.redirect) {
          setMessage(data.redirect.message);
          setMessageLevel("info");
          setTimeout(() => {
            window.location.href = data.redirect.url;
          }, 500);
        } else {
          setLoggedIn(true);
        }
      })
      .catch((err) => {
        console.log("Failed", err);
        setSending(false);
        setMessage(err.response.data);
        setMessageLevel("error");
      });
  }

  return (
    <Stack justifyContent="center" alignItems="center" flex={1}>
      <Paper sx={{ p: 2 }}>
        {!!message && (
          <Alert
            severity={messageLevel}
            sx={{ mb: 1 }}
            onClose={() => setMessage("")}
          >
            {message}
          </Alert>
        )}

        <Typography component="h1" variant="h5">
          Verify login with MFA
        </Typography>

        <Box
          component="form"
          onSubmit={onMfaSubmit}
          noValidate
          autoComplete="off"
          sx={{ mt: 1, width: "100%" }}
        >
          <Typography>Enter your code</Typography>
          {phone_number && (
            <Typography color="text.secondary">
              The code was sent to {phone_number}
            </Typography>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="MFA Code"
            name="password"
            type="password"
            autoComplete="password"
            value={mfaCode}
            error={mfaCode.length !== 6 && mfaCode.length > 0}
            onChange={(e) => setMfaCode(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            endIcon={sending && <Loading relative size={20} />}
            disabled={mfaCode.length !== 6}
            sx={{ mb: 2 }}
          >
            Submit code
          </Button>
        </Box>
        <Link to="/" style={{ color: "#0078EB" }}>
          Go back to login
        </Link>
      </Paper>
    </Stack>
  );
}
