import { authorizedPost, authorizedGet } from "utils/request";
import { RootState } from "state/store";
import { toast } from "react-toastify";
import translations from "translations";

export const setUploadStatus = (value: string) => {
  return {
    type: "SET_UPLOAD_STATUS",
    payload: value,
  };
};

export const setObjectFile = (value: any) => {
  return {
    type: "SET_OBJECT_FILE",
    payload: value,
  };
};

export const setUploadUsers = (value: Array<any>) => {
  return {
    type: "SET_UPLOAD_USERS",
    payload: value,
  };
};

export const setDefaultUser = (value: number) => {
  return {
    type: "SET_DEFAULT_USER",
    payload: value,
  };
};

export const getUploadUsers = () => {
  // @ts-ignore
  return function (dispatch, getState) {
    const state: RootState = getState();
    let language = state.user.language;
    // @ts-ignore
    authorizedGet<{ users }>("/user/useforimport").then((data) => {
      const users = [
        {
          id: null,
          info: translations.Menu.Tools.ImageObjects.Upload.SelectUser.FromFile[
            language
          ],
        },
        ...data.users,
      ];
      dispatch(
        setUploadUsers(
          users.map((user) => ({ value: user.id, label: user.info }))
        )
      );
    });
  };
};

interface UploadObjectFileResponse {
  additions: number;
  inserts: number;
  duplicates: number;
  missed: number;
  history: number;
}

export const uploadObjectFile = () => {
  // @ts-ignore
  return function (dispatch, getState) {
    const state: RootState = getState();
    let data = new FormData();
    data.append("file", state.imageObjectUpload.objectFile);
    const header = { "content-type": "multipart/form-data" };
    toast.info("Uploading JSON File");
    let dry = false;
    authorizedPost<UploadObjectFileResponse>(
      `/object/upload?dry=${dry}&defaultUser=${state.imageObjectUpload.defaultUser}`,
      data,
      header
    )
      .then((data) => {
        let additions = data.additions;
        let inserts = data.inserts;
        let duplicates = data.duplicates;
        let missed = data.missed;
        let history = data.history;
        dispatch(
          setUploadStatus(`Total additions : ${additions},
                        Inserts : ${inserts},
                        Duplicates : ${duplicates},
                        Missing Images : ${missed},
                        Deletions : ${history}`)
        );
        toast.success("Upload succeded");
      })
      .catch(() => toast.error("Upload failed"));
  };
};
