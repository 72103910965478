import { connect } from "react-redux";
import Component from "./FeedbackButton";
import { RootState } from "state/store";
import { getImageFeedback, sendFeedback, resolveFeedback } from "state/actions";
import { ImageFeedbackItem } from "interfaces";

interface IDispatchProps {
  getImageFeedback: (
    image_id: number,
    callback?: Function,
    onError?: Function
  ) => void;
  sendFeedback: (
    image_id: number,
    feedback_type: number,
    comment: string,
    callback?: Function,
    onError?: Function
  ) => void;
  resolveFeedback: (
    image_id: number,
    feedback_id: number,
    callback?: Function,
    onError?: Function
  ) => void;
}

interface IStateProps {
  imageFeedback: ImageFeedbackItem[];
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    imageFeedback: state.image.feedback,
  };
};

const mapDispatchToProps = {
  getImageFeedback,
  sendFeedback,
  resolveFeedback,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps, // @ts-ignore
  mapDispatchToProps
)(Component);
