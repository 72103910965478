import { connect } from "react-redux";
import InventoryReport from "./InventoryReport";
import { RootState } from "state/store";

interface IStateProps {
  demoMode: boolean;
  // @ts-ignore
  detectedCategories;
}

const mapStateToProps = (state: RootState) => {
  return {
    demoMode: state.ui.demoMode,
    detectedCategories: state.objects.detectedCategories,
  };
};

export default connect<IStateProps, {}, {}, RootState>(
  mapStateToProps,
  {}
)(InventoryReport);
