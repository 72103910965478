import translations from "translations";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useLanguage, useDispatch, useCurrentProject } from "hooks";
import { blurAllImages } from "state/actions";
import { useState } from "react";
import Loading from "./Loading";
import { toast } from "react-toastify";

function BlurAllImages() {
  const [loading, setLoading] = useState(false);
  const currentProject = useCurrentProject();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useLanguage();

  function blur(projectID: number) {
    setLoading(true);
    dispatch(
      blurAllImages(projectID, {
        callback: () => navigate(`..${location.search}`),
        onError: (error) =>
          toast.error(
            error?.response?.data?.message || "Failed to blur images"
          ),
        finally: () => setLoading(false),
      })
    );
  }

  function goBack() {
    navigate(`..${location.search}`);
  }

  return (
    <Dialog open={true} onClose={goBack}>
      <DialogTitle data-testid="BlurDialogTitle">
        {translations.Menu.Tools.BlurImages.Tooltip[language]}
      </DialogTitle>
      <DialogActions>
        <Button
          disabled={loading}
          data-testid="BlurDialogItemNo"
          onClick={goBack}
        >
          {translations.Menu.Tools.BlurImages.No[language]}
        </Button>
        <Button
          endIcon={loading && <Loading relative size={20} color="black" />}
          disabled={!currentProject?.id || loading}
          data-testid="BlurDialogItemYes"
          // @ts-ignore
          onClick={() => blur(currentProject.id)}
        >
          {translations.Menu.Tools.BlurImages.Yes[language]}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BlurAllImages;
