import {
  authorizedPut,
  authorizedDelete,
  authorizedGet,
  authorizedPost,
} from "utils/request";

interface IUpdateInspectionAssignmentPayload {
  annotator?: number;
  coordinates?: {
    lat: number;
    lng: number;
  }[];
  analysis?: boolean;
  criticalAnalysis?: boolean;
}

interface IAssignmentStatus {
  id: number;
  mission: {
    id: number;
    name: string;
    active: boolean;
  };
  user: {
    id: number;
    name: string;
  };
  progress: {
    analysis: {
      assigned: number;
      completed: number;
    };
    criticalAnalysis: {
      assigned: number;
      completed: number;
    };
  };
}

export async function updateInspectionAssignment(
  assignmentID: number,
  payload: IUpdateInspectionAssignmentPayload
) {
  const endpoint = `/assignments/${assignmentID}`;
  const response = await authorizedPut<string>(endpoint, payload);
  return response;
}

export async function deleteInspectionAssignment(assignmentID: number) {
  const endpoint = `/assignments/${assignmentID}`;
  await authorizedDelete(endpoint);
}

interface IGetAssignmentStatusProps {
  supervisor: boolean;
  projectID?: number;
  grouping?: "assignment" | "user" | "mission";
}
export async function getAssignmentStatus({
  supervisor,
  // @ts-ignore
  projectID = null,
  grouping = "mission",
}: IGetAssignmentStatusProps) {
  let endpoint = `/assignments/status?grouping=${grouping}`;
  if (!!supervisor) {
    endpoint = `/assignments/supervisor/status?grouping=${grouping}`;
  }
  if (!!projectID) {
    endpoint += `&mission=${projectID}`;
  }

  const response = await authorizedGet<{ assignments: IAssignmentStatus[] }>(
    endpoint
  );
  return response.assignments;
}

export async function deleteAssignmentStatus(assignmentID: number) {
  const endpoint = `/assignments/${assignmentID}`;
  await authorizedDelete(endpoint);
}

export async function createAssignmentStatus({
  coordinates,
}: {
  coordinates: {
    lat: number;
    lng: number;
  }[];
}) {
  const endpoint = `/assignments`;
  const assignmentID = await authorizedPost<string>(endpoint, { coordinates });
  return assignmentID;
}

interface IAssignment {
  id: number;
  annotator: number;
  coordinates: {
    lat: number;
    lng: number;
  }[];
  color: string;
  analysis: boolean;
  criticalAnalysis: boolean;
}

export async function getAssignmentAreas({
  supervisor,
}: {
  supervisor: boolean;
}) {
  let endpoint = "/assignments/areas";
  if (!!supervisor) {
    endpoint = "/assignments/supervisor/areas";
  }

  const response = await authorizedGet<{ areas: IAssignment[] }>(endpoint);
  return response.areas;
}

export const assignmentStatus = {
  get: getAssignmentStatus,
  delete: deleteAssignmentStatus,
  create: createAssignmentStatus,
};

// Create an easier way to call the API (CRUD)
export const inspectionAssignment = {
  get: getAssignmentAreas,
  update: updateInspectionAssignment,
  delete: deleteInspectionAssignment,
};
