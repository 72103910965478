export interface IState {
  flightsData: Array<any>;
  totalCount: number;
  humanOrNotLabel: string;
  customerName: string;
}

export const initialState: IState = {
  flightsData: [],
  totalCount: 0,
  humanOrNotLabel: "",
  customerName: "",
};

// @ts-ignore
const imageObjectDownloadReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case "SET_FLIGHTS_DATA":
      return {
        ...state,
        flightsData: action.payload,
      };

    case "SET_TOTAL_COUNT":
      return {
        ...state,
        totalCount: action.payload,
      };

    case "SET_HUMAN_OR_NOT_LABEL":
      return {
        ...state,
        humanOrNotLabel: action.payload,
      };

    case "SET_CUSTOMER_NAME":
      return {
        ...state,
        customerName: action.payload,
      };

    default:
      return state;
  }
};

export default imageObjectDownloadReducer;
