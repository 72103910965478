import { EventHint, captureException } from "@sentry/react";
import { useEffect } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  // If anything breaks in an error boundary,
  // we want to know about it.
  useEffect(() => {
    // Log to the browser
    console.error(error);

    // Create event ID
    const path = window.location.pathname;
    const event_id = `error-boundary-${path}`;
    const context: EventHint = {
      event_id,
      data: {
        location: window.location.href,
        userAgent: navigator.userAgent,
      },
    };
    // Log the error to Sentry
    captureException(error, context);
  }, [error]);

  if (isRouteErrorResponse(error)) {
    return (
      <>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </>
    );
  } else if (error instanceof Error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </div>
    );
  } else {
    return <h1>Unknown Error {JSON.stringify(error)}</h1>;
  }
}
