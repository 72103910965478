import { useEffect, useState } from "react";
import { authorizedGet } from "utils/request";
interface ProjectScenes {
  project_id: number;
  scenes: string[];
}

interface LinkedProjects {
  links: ProjectScenes[];
}

/**
 * A React hook that collects linkable projects
 *
 *  @example
 *  ```javascript
 *  const [projects, getLinkedLidarProjects] = useLinkedLidarProjects(25);
 *  ```
 */
export function useLinkedLidarProjects(
  project_id: number
): [ProjectScenes[], Function] {
  const [projects, setProjects] = useState<ProjectScenes[]>([]);

  function getLinkedLidarProjects() {
    collectProjects(project_id);
  }

  function collectProjects(project_id: number) {
    const endpoint = "/lidar/linked_projects";
    authorizedGet<LinkedProjects>(endpoint, { MissionId: project_id })
      .then((response) => {
        setProjects(response.links);
      })
      .catch((err) => {
        setProjects([]);
        console.error("Failed to collect linked projects", err);
      });
  }

  useEffect(() => {
    collectProjects(project_id);
  }, [project_id]);

  return [projects, getLinkedLidarProjects];
}
