import { ReactNode } from "react";
import { Stack } from "@mui/material";
import { Clear, Check, AccessTime as Time } from "@mui/icons-material";
import { BounceLoader } from "react-spinners";

type Props = {
  status: "waiting" | "running" | "cancel" | "fail" | "success";
  children?: ReactNode;
  color?: string;
};

export const COLORS = {
  waiting: "#C2C0C2",
  running: "#C2C0C2",
  cancel: "#f95050",
  fail: "#f95050",
  success: "#3fb950",
};

export const ICON = {
  waiting: <Time fontSize="inherit" />,
  running: <BounceLoader size={18} color="#F7F700" />,
  cancel: <Clear fontSize="inherit" />,
  fail: <Clear fontSize="inherit" />,
  success: <Check fontSize="inherit" />,
};

export const FONTSIZE = {
  waiting: 16,
  running: 16,
  cancel: 16,
  fail: 16,
  success: 16,
};

export const SIZE = 18;

export default function StatusIndicator({ children, status, color }: Props) {
  return (
    <Stack direction="row" alignItems="center">
      <Stack
        sx={{
          backgroundColor: COLORS[status],
          width: SIZE,
          height: SIZE,
          borderRadius: "100%",
          fontSize: FONTSIZE[status],
          color: !!color ? color : "white",
          mr: 1,
          position: "relative",
        }}
        alignItems="center"
        justifyContent="center"
      >
        {ICON[status]}
      </Stack>
      {children}
    </Stack>
  );
}
