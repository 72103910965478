import { BBox } from "@turf/turf";
import { Mission, ObjectType } from "interfaces";
// @ts-ignore
import md5 from "md5-hash";
import { getClientIssueSeverities, setPowerlineBlock } from "state/actions";
import store, { RootState } from "state/store";
import translations from "translations";

export const toGmapBounds = (box: BBox): google.maps.LatLngBoundsLiteral => ({
  west: box[0],
  south: box[1],
  east: box[2],
  north: box[3],
});

// @ts-ignore
export function HighlightMarkers(image_id, cluster_id, pole_id = null) {
  const state: RootState = store.getState();
  let clusterMethod = state.imageViewer.clusterMethod;
  // Find markers of same cluster id and add class "highlighted"
  if (clusterMethod === "pole" && pole_id) {
    document.querySelectorAll(`.marker.pole-${pole_id}`).forEach((node) => {
      node.classList.add("highlighted");
    });
  } else if (clusterMethod === "cluster" && cluster_id) {
    document
      .querySelectorAll(`.marker.cluster-${cluster_id}`)
      .forEach((node) => {
        node.classList.add("highlighted");
      });
  }
  image_id &&
    document.querySelectorAll(`.marker.image-${image_id}`).forEach((node) => {
      node.classList.add("highlighted");
    });
}
// @ts-ignore
export function unHighlightMarkers(image_id, cluster_id, pole_id = null) {
  // Find markers of same cluster id and add class "highlighted"
  if (pole_id) {
    document
      .querySelectorAll(`.marker.pole-${pole_id}.highlighted`)
      .forEach((node) => {
        node.classList.remove("highlighted");
      });
  } else if (cluster_id) {
    document
      .querySelectorAll(`.marker.cluster-${cluster_id}.highlighted`)
      .forEach((node) => {
        node.classList.remove("highlighted");
      });
  }
  image_id &&
    document
      .querySelectorAll(`.marker.image-${image_id}.highlighted`)
      .forEach((node) => {
        node.classList.remove("highlighted");
      });
}
// @ts-ignore
export function highlightObjects(object_id) {
  document.querySelectorAll(`.object.object-${object_id}`).forEach((node) => {
    node.classList.add("highlighted");
  });
}
// @ts-ignore
export function unHighlightObjects(object_id) {
  document.querySelectorAll(`.object.object-${object_id}`).forEach((node) => {
    node.classList.remove("highlighted");
  });
}

export function blockPowerlines() {
  store.dispatch(setPowerlineBlock(true));
}

export function unBlockPowerlines() {
  store.dispatch(setPowerlineBlock(false));
}

export function findCurrentMissions(customerId: number, missions: Mission[]) {
  let currentMissions = [
    ...missions.filter((mission) => mission.id === customerId),
  ];

  if (currentMissions.length === 0) {
    return null;
  }

  return currentMissions;
}

export function findCurrentMission(customerId: number, missions: Mission[]) {
  let m = findCurrentMissions(customerId, missions);

  if (!!!m) {
    return null;
  }

  return m[0];
}
// @ts-ignore
export function validateEmail(email) {
  // Regex for email validation according to RFC 5322
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}
// @ts-ignore
export function getClientSeverityName(issueSeverityId) {
  const state = store.getState();
  let language = state.user.language;
  let issueSeverities = state.objects.issueSeverities;
  let clientIssueSeverities = state.objects.clientIssueSeverities;
  let clientNaming = state.user.client_naming;
  let returnValue;
  let name;
  let externalId;
  let id;

  if (clientNaming) {
    let filteredType = clientIssueSeverities.filter(
      // @ts-ignore
      (e) => e.skyqraft_issue_severity_id === issueSeverityId
    )[0];

    name = filteredType?.client_issue_severity_name;
    externalId = filteredType?.external_type_id;
    id = filteredType?.client_issue_severity_id;

    if (!name) {
      // @ts-ignore
      filteredType = issueSeverities.filter((e) => e.id === issueSeverityId)[0];

      name = !filteredType
        ? ""
        : filteredType[
            `${(language === "NO" ? "EN" : language).toLowerCase()}_name`
          ];
    }

    if (!externalId) {
      externalId = "";
    } else {
      externalId = externalId + " - ";
    }

    if (!id) {
      id = issueSeverityId;
    }

    returnValue = {
      name: externalId + name,
      client_severity_type_id: id,
      id: issueSeverityId,
    };
  } else {
    if (issueSeverities.length !== 0) {
      let filteredType = issueSeverities.filter(
        // @ts-ignore
        (e) => e.id === issueSeverityId
      )[0];

      let name = !filteredType
        ? ""
        : filteredType[
            `${(language === "NO" ? "EN" : language).toLowerCase()}_name`
          ];

      returnValue = {
        name: name,
        client_severity_type_id: issueSeverityId,
        id: issueSeverityId,
      };
    }
  }

  return returnValue;
}

interface IClientObjectTypeName {
  name: string;
  client_object_type_id: number;
  id: number;
  category_id: number;
  category_name: string;
  category_sorting: number;
  issue: boolean;
  skyqraft_only: boolean;
  color: string;
  class_color: string;
}
// @ts-ignore
export function getClientObjectTypeName(objectTypeId): IClientObjectTypeName {
  const state = store.getState();

  const language = state.user.language;
  const objectTypes: ObjectType[] = state.objects.objectTypes;
  const clientObjects = state.objects.clientObjects;
  const clientNaming = state.user.client_naming;
  const colorMode = state.user.annotator_color;

  let returnValue;
  let name;
  let id;
  let category_id;
  let category_name;
  let client_object_type_id;

  const isIssue = objectTypes.filter((e) => e.id === objectTypeId)[0]?.issue;
  const categorySortingOrder = objectTypes.filter(
    (e) => e.id === objectTypeId
  )[0]?.category_sorting_order;

  const skyqraftOnly = objectTypes.filter((e) => e.id === objectTypeId)[0]
    ?.skyqraft_only;

  let color = objectTypes.filter((e) => e.id === objectTypeId)[0]?.color;
  const class_color = objectTypes.filter((e) => e.id === objectTypeId)[0]
    ?.class_color;

  if (clientNaming) {
    let filteredType = clientObjects.filter(
      // @ts-ignore
      (e) => e.skyqraft_object_type_id === objectTypeId
    )[0];

    name = filteredType?.client_name;
    id = filteredType?.client_object_type_id;

    if (name) {
      category_id = filteredType.category_id;
      category_name =
        filteredType[
          `category_${(language === "NO" ? "EN" : language).toLowerCase()}_name`
        ];
      client_object_type_id = -id;
      color = filteredType.color ? filteredType.color : color;
    }

    filteredType = objectTypes.filter((e) => e.id === objectTypeId)[0];

    if (!name && !!filteredType) {
      name = !filteredType
        ? ""
        : filteredType[
            `${(language === "NO" ? "EN" : language).toLowerCase()}_name`
          ];
      id = objectTypeId;
      category_id = filteredType?.category;
      category_name =
        filteredType[
          `category_${(language === "NO" ? "EN" : language).toLowerCase()}_name`
        ];
      client_object_type_id = id;
    }

    return {
      name: name,
      client_object_type_id: client_object_type_id,
      id: objectTypeId,
      category_id: category_id,
      category_name: category_name,
      category_sorting: categorySortingOrder,
      issue: isIssue,
      skyqraft_only: skyqraftOnly,
      color: colorMode ? class_color : color,
      class_color: class_color,
    };
  } else if (objectTypes.length !== 0) {
    const filteredType = objectTypes.filter((e) => e.id === objectTypeId)[0];

    if (filteredType) {
      name = !filteredType
        ? "" // @ts-ignore
        : filteredType[
            `${(language === "NO" ? "EN" : language).toLowerCase()}_name`
          ];

      category_id = filteredType.category;
      category_name = // @ts-ignore
        filteredType[
          `category_${(language === "NO" ? "EN" : language).toLowerCase()}_name`
        ];
    }

    return {
      name: name,
      client_object_type_id: objectTypeId,
      id: objectTypeId,
      // @ts-ignore
      category_id: category_id,
      category_name: category_name,
      category_sorting: categorySortingOrder,
      issue: isIssue,
      skyqraft_only: skyqraftOnly,
      color: colorMode ? class_color : color,
      class_color: class_color,
    };
  }
}
// @ts-ignore
export function getProjectName(projectId) {
  const state = store.getState();

  const mission = state.user.missions;
  const language = state.user.language;
  // @ts-ignore
  const demoMode = state.ui.demoMode;
  // @ts-ignore
  const showDatesBeforeProjectName = state.ui.showDatesBeforeProjectName;
  let name = mission.filter((e) => e.id === projectId)[0]?.name;
  name = name ? name : "";
  const year = mission.filter((e) => e.id === projectId)[0]?.year;

  if (demoMode) {
    name = `${translations.words.Mission[language]} ${md5(name).substring(
      0,
      4
    )}`;
  }

  let fullName = `${name}: ${year}`;

  if (!showDatesBeforeProjectName) {
    fullName = name;
  }

  return { fullname: fullName, name: name, year: year };
}

// @ts-ignore
export function compareYearAndName(a, b) {
  if (a.year < b.year) return 1;
  if (a.year > b.year) return -1;
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
}

export function dateTimeToString(
  date: Date,
  year = true,
  month = true,
  day = true,
  hour = true,
  minute = true,
  second = true
) {
  const dateParts = [];
  // @ts-ignore
  const timeParts = [];
  if (year) {
    dateParts.push(date.getFullYear());
  }
  if (month) {
    dateParts.push(date.getMonth() + 1);
  }
  if (day) {
    dateParts.push(date.getDate());
  }
  if (hour) {
    dateParts.push(date.getHours());
  }
  if (minute) {
    dateParts.push(date.getMinutes());
  }
  if (second) {
    dateParts.push(date.getSeconds());
  }
  const dateString = dateParts.join("-");
  // @ts-ignore
  const timeString = timeParts.join(":");

  // Only return the data the user wants
  if (dateString === "" && timeString === "") {
    return "unknown";
  }
  if (dateString === "") {
    return timeString;
  }

  if (timeString === "") {
    return dateString;
  }
  return `${dateString}_${timeString}`;
}
// @ts-ignore
export function sortImages(images, ROTATION_OFFSET = 180) {
  const state = store.getState();
  const sortingMethod = state.imageViewer.imageSortingMethod;

  if (images.length === 0) return images;

  if (sortingMethod === "compass") {
    const sortedImages = images.sort(
      // @ts-ignore
      (a, b) =>
        (a?.compass_dir - ROTATION_OFFSET < 0
          ? a?.compass_dir - ROTATION_OFFSET + 360
          : a?.compass_dir - ROTATION_OFFSET) -
        (b.compass_dir - ROTATION_OFFSET < 0
          ? b?.compass_dir - ROTATION_OFFSET + 360
          : b?.compass_dir - ROTATION_OFFSET)
    );

    return sortedImages;
  } else if (sortingMethod === "timestamp") {
    // Define the regular expression pattern to match the timestamp at the beginning of the name
    const pattern = /^(\d{2}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2})/;
    // @ts-ignore
    const sortedThumbnails = images.sort((a, b) => {
      const matchA = a?.name?.match(pattern);
      const matchB = b?.name?.match(pattern);

      if (matchA && matchB) {
        const timestampA = matchA[1];
        const timestampB = matchB[1];

        // Parse timestamps manually
        const dateA = parseTimestamp(timestampA);
        const dateB = parseTimestamp(timestampB);

        // Compare timestamps
        return dateA.getTime() - dateB.getTime();
      } else {
        return 0;
      }
    });

    return sortedThumbnails;
  } else {
    return images;
  }
}

// Function to parse timestamp manually
// @ts-ignore
function parseTimestamp(timestamp) {
  const [year, month, day, hour, minute, second] = timestamp.split(/[-_]/);
  return new Date(`20${year}-${month}-${day} ${hour}:${minute}:${second}`);
}
