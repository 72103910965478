import { useState, useEffect } from "react";
import { authorizedGet } from "utils/request";

export interface IUser {
  id: number;
  name: string;
  email: string;
  lastLogin: string;
  superuser: boolean;
  mfa_enabled: boolean;
  mfa_type: string;
  skyqraftEmployee: boolean;
  email_verified: boolean;
  phone_number: string;
  phone_number_verified: boolean;
  created_date: string;
  enabled: boolean;
  status: string;
  annotator: boolean;
  skyqraftNote?: string;
  groups: {
    id: number;
    name: string;
    accessLevel: number;
  }[];
  projects: {
    id: number;
    name: string;
    accessLevel: number;
  }[];
}

/**
 * A React hook that collects a specific user
 *
 *  @example
 *  ```javascript
 *  const [user, updateUser, loading] = useUser(25);
 *  ```
 */
export function useUser(userID: number): [IUser, () => void, boolean] {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  function updateUser() {
    collectUser(userID);
  }

  function collectUser(userID: number) {
    const endpoint = `/admin/user/${userID}`;
    setLoading(true);
    authorizedGet<IUser>(endpoint)
      .then((response) => {
        setLoading(false);
        setUser(response);
      })
      .catch((err) => {
        setLoading(false);
        setUser(null);
        console.error("Failed to collect responsible annotator stats", err);
      });
  }

  useEffect(() => {
    collectUser(userID);
  }, [userID]);

  // @ts-ignore
  return [user, updateUser, loading];
}
