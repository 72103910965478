import { PreviousDefectImages } from "interfaces";

// @ts-ignore
export const setRefreshAnnotations = (value) => {
  return {
    type: "SET_REFRESH_ANNOTATIONS",
    payload: value,
  };
};

export const resetRefreshAnnotations = () => {
  return {
    type: "RESET_REFRESH_ANNOTATIONS",
  };
};
// @ts-ignore
export const setBBox = (value) => {
  return {
    type: "SHOW_IMAGE_VIEWER_BBOX",
    payload: value,
  };
};

export const resetMatrix = () => {
  return {
    type: "RESET_MATRIX",
  };
};
// @ts-ignore
export const setMatrix = (value) => {
  return {
    type: "SET_MATRIX",
    payload: value,
  };
};
// @ts-ignore
export const loadingImage = (value) => {
  return {
    type: "LOADING_IMAGE",
    payload: value,
  };
};
// @ts-ignore
export const setThermalData = (value) => {
  return {
    type: "SET_THERMAL_DATA",
    payload: value,
  };
};

export const resetThermalData = () => {
  return {
    type: "RESET_THERMAL_DATA",
  };
};

export const setClusterHidden = (value: boolean) => {
  return {
    type: "SET_CLUSTER_HIDDEN",
    payload: value,
  };
};

export const setZoomSpeed = (value: number) => {
  return {
    type: "SET_ZOOM_SPEED",
    payload: value,
  };
};

export const setPreviousDefectImages = (value: PreviousDefectImages[]) => {
  return {
    type: "SET_PREVIOUS_DEFECT_IMAGES",
    payload: value,
  };
};

export const setSelectedPreviousDefectImage = (
  value: PreviousDefectImages | null
) => {
  return {
    type: "SET_SELECTED_PREVIOUS_DEFECT_IMAGE",
    payload: value,
  };
};

export const setClusterMethod = (value: string) => {
  return {
    type: "SET_CLUSTER_METHOD",
    payload: value,
  };
};

export const setImageSortingMethod = (value: "compass" | "timestamp") => {
  return {
    type: "SET_IMAGE_SORTING_METHOD",
    payload: value,
  };
};

export const setClusterDistance = (value: number) => {
  return {
    type: "SET_CLUSTER_DISTANCE",
    payload: value,
  };
};

export function setCanSwitchImage(value: boolean) {
  return {
    type: "SET_CAN_SWITCH_IMAGE",
    payload: value,
  };
}
// @ts-ignore
export function setLidarWindow(value) {
  return {
    type: "SET_LIDAR_WINDOW",
    payload: value,
  };
}

export function setExpandAnnotationsList(value: boolean) {
  return {
    type: "SET_EXPAND_ANNOTATIONS_LIST",
    payload: value,
  };
}
