import React from "react";
import CMSRichTextContent from ".";

type Props = {
  content: any;
};

export default function CMSQuote({ content }: Props) {
  return (
    <blockquote>
      {content.map(
        // @ts-ignore
        (c, i) => (
          <CMSRichTextContent key={i} content={c} />
        )
      )}
    </blockquote>
  );
}
