import React from "react";
import CMSRichTextContent from ".";

type Props = {
  content: any;
};

export default function CMSCodeBlock({ content }: Props) {
  let children = null;
  if (!!content.content) {
    children = content.content.map(
      // @ts-ignore
      (c, i) => <CMSRichTextContent key={i} content={c} />
    );
  }
  return (
    <div style={{ display: "flex" }}>
      <code
        style={{
          whiteSpace: "pre-wrap",
          padding: "1rem 1rem",
          background: "#F7F8F9",
          border: "1px solid #DFE3E7",
          borderRadius: 3,
        }}
      >
        {children}
      </code>
    </div>
  );
}
