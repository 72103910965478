import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getDefectNotProcessedFilter(
  searchParams: URLSearchParams
): FilterType["DEFECT_NOT_PROCESSED"] {
  const defectNotProcessedParams =
    searchParams.get(FilterKey.DEFECT_NOT_PROCESSED) ?? "";
  return defectNotProcessedParams === "true";
}

export function useDefectNotProcessedFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const defectNotProcessed = getDefectNotProcessedFilter(searchParams);

  const setDefectNotProcessed = (value: boolean) => {
    if (value) {
      if (searchParams.has(FilterKey.DEFECT_NOT_PROCESSED))
        searchParams.delete(FilterKey.DEFECT_NOT_PROCESSED);
    } else {
      searchParams.set(FilterKey.DEFECT_NOT_PROCESSED, "true");
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { defectNotProcessed, setDefectNotProcessed };
}
