import { useState } from "react";
import {
  Typography,
  Stack,
  Paper,
  Divider,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import { useParams, Outlet, Link, useNavigate } from "react-router-dom";
import FullPage from "components/FullPage";
import { Add, Clear, Search } from "@mui/icons-material";
import { useAdminGroups } from "hooks/projects/useAdminGroups";
import RoleWrapper from "components/RoleHOC/wrapper";
import Loading from "views/Loading";

export default function UserAdministrator() {
  const params = useParams();
  const navigate = useNavigate();
  // @ts-ignore
  const currentGroup = parseInt(params.group);
  const [searchValue, setSearchValue] = useState<string>("");
  const { groups, update, loading } = useAdminGroups();

  function isIn(source: string, test: string): boolean {
    const string = source.replace(/[^a-zA-Z0-9@. ]/g, "");
    return string.toLowerCase().includes(test.toLowerCase());
  }

  function textMatches(textA: string, textB: string) {
    return isIn(
      textA.toLowerCase().replace(/[^\w!?]/g, ""),
      textB.toLowerCase().replace(/[^\w!?]/g, "")
    );
  }

  function filterFunction(group: (typeof groups)[number]): boolean {
    // Match on ID
    let textMatch = isIn(JSON.stringify(group.id), searchValue);
    // Match on group name
    textMatch = textMatch || textMatches(group.name, searchValue);
    // Match on project name
    textMatch =
      textMatch ||
      !group.projects.every(
        (projectName) => !textMatches(projectName, searchValue)
      );
    // Match on region name
    textMatch =
      textMatch ||
      !group.regions.every(
        (projectName) => !textMatches(projectName, searchValue)
      );
    return textMatch;
  }

  const filteredGroups = groups
    .filter(filterFunction)
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  const group = groups.find((g) => g.id === currentGroup);

  return (
    <FullPage title={"Group Administration"} tab={0} tabs={[]}>
      <RoleWrapper keyName="projectAdmin">
        <IconButton
          style={{ position: "absolute", top: 52, right: 8 }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <Clear />
        </IconButton>

        <Stack
          spacing={1}
          direction={{ xs: "column", md: "row" }}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{
            height: "100%",
            position: "relative",
          }}
          paddingLeft={{ xs: 1, md: 0 }}
          paddingRight={{ xs: 1, md: 2 }}
        >
          <Stack direction="column" minWidth={300} maxWidth={300}>
            <Stack sx={{ pb: 2, pr: 2 }}>
              <Link
                data-testid="createNewGroup"
                to="/group/new"
                replace
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box sx={{ py: 2 }}>
                  <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Add />
                    <Typography>Create new group</Typography>
                  </Stack>
                </Box>
              </Link>

              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ pr: 2 }}
              >
                <Search sx={{ my: 0.5 }} />
                <TextField
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  placeholder="Search for a group"
                  variant="standard"
                  sx={{ flex: 1 }}
                />
              </Stack>
            </Stack>
            <Stack
              sx={{ overflowY: "auto" }}
              paddingRight={{ xs: 0, md: 2 }}
              height={{ xs: 300, md: "100%" }}
              spacing={1}
            >
              {loading ? (
                <Loading relative size={20} />
              ) : (
                <>
                  {filteredGroups.length === 0 && (
                    <Typography>No groups found</Typography>
                  )}
                  {!searchValue && (
                    <Link
                      to={"0"}
                      replace
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Paper
                        sx={{
                          p: 2,
                          backgroundColor:
                            0 === currentGroup ? "#D6E7FF" : "#F1D57B",
                        }}
                      >
                        <Stack
                          width="100%"
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography>No group</Typography>
                        </Stack>
                      </Paper>
                    </Link>
                  )}
                  {filteredGroups.map((g) => (
                    <Link
                      key={g.id}
                      to={`${g.id}`}
                      replace
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Paper
                        sx={{
                          p: 2,
                          backgroundColor:
                            g.id === currentGroup ? "#D6E7FF" : undefined,
                        }}
                      >
                        <Stack
                          width="100%"
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography>{g.name}</Typography>
                        </Stack>
                      </Paper>
                    </Link>
                  ))}
                </>
              )}
            </Stack>
          </Stack>
          <Box width="100%">
            <Outlet context={{ updateGroups: update, group }} />
          </Box>
        </Stack>
      </RoleWrapper>
    </FullPage>
  );
}
