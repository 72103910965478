import { useState, useEffect, ReactNode, createContext } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

export interface IContextValue {
  lastURL: string | null;
}

const context = createContext<IContextValue>({
  lastURL: null,
});

interface IProviderProps {
  children: ReactNode;
}

function NavigationProvider({ children }: IProviderProps) {
  // Set up some states to keep track of
  const [history, setHistory] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<string | null>(null);

  // Get the information about the current location
  const location = useLocation();
  const navigationType: "REPLACE" | "POP" | "PUSH" = useNavigationType();
  const url = location.pathname + location.search;

  useEffect(() => {
    let newHistory = [...history];

    if (navigationType === "PUSH") {
      // @ts-ignore
      newHistory.push(currentPage);
      setHistory(newHistory);
    } else if (navigationType === "POP") {
      newHistory.pop();
      setHistory(newHistory);
    } else if (navigationType === "REPLACE") {
      // Just replace the current page in state
    }
    setCurrentPage(url);
  }, [url]);

  // Create the payload to let
  // consumers know what the last URL was
  const payload: IContextValue = {
    lastURL: history[history.length - 1] || null,
  };

  return <context.Provider value={payload}>{children}</context.Provider>;
}

export default NavigationProvider;

export { context };
