export const workflow_status = {
  REVIEW_REQUESTED: 1,
  TRUE_POSITIVE: 2,
  SUPERVISOR_APPROVED: 3,
  FALSE_POSITIVE: 4,
  SUPERVISOR_FALSE_POSITIVE: 5,
} as const;

export const severities = {
  CRITICAL: 1,
  ISSUE: 2,
  SHOULD_BE_FIXED: 3,
  GOOD_TO_KNOW: 4,

  MACHINE_DETECTED: 11,
  SKYQRAFT_HIDDEN: 12,
  ML_REVIEW_PENDING: 13,
  ML_CONFIRMED_FALSE: 14,
};
