import { useEffect } from "react";
import { useLDClient, useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { useCurrentProject } from "hooks";
import { RootState } from "state/store";

export default function LaunchDarklyIdentifier() {
  const user = useSelector((state: RootState) => state.user);
  const currentProject = useCurrentProject();
  const ldClient = useLDClient();
  const flags = useFlags();

  const { id, email, language, realRoles } = user;

  useEffect(() => {
    if (ldClient && id && realRoles) {
      // @ts-ignore
      ldClient.identify({
        key: id.toString(),
        email,
        custom: {
          ...realRoles,
          language,
          group: currentProject?.group?.id,
          project: currentProject?.id,
          domain: window.location.hostname,
        },
      });
    }
  }, [ldClient, id, email, currentProject?.group?.id, language, realRoles]);

  useEffect(() => {
    if (flags) {
      window.enabledFlags = Object.keys(flags).filter((key) => flags[key]);
    }
  }, [flags]);

  return null;
}
