export interface IState {
  inBubble: boolean;
  exitURL: string;
  initialURL: string;
}

export const initialState: IState = {
  inBubble: false,
  exitURL: "/",
  initialURL: "",
};

// @ts-ignore
const navigationReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case "SET_IN_BUBBLE":
      return {
        ...state,
        inBubble: action.payload,
      };

    case "SET_EXIT_URL":
      return {
        ...state,
        exitURL: action.payload,
      };

    case "SET_INITIAL_URL":
      return {
        ...state,
        initialURL: action.payload,
      };

    default:
      return state;
  }
};

export default navigationReducer;
