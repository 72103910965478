import React, { useEffect, useState } from "react";
import MItem from "components/MenuItem";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { ChatBubble } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { ImageFeedbackItem } from "interfaces";
import translations from "translations";
import { useLanguage } from "hooks";

type Props = {
  getImageFeedback: (
    image_id: number,
    callback?: Function,
    onError?: Function
  ) => void;
  sendFeedback: (
    image_id: number,
    feedback_type: number,
    comment: string,
    callback?: Function,
    onError?: Function
  ) => void;
  resolveFeedback: (
    image_id: number,
    feedback_id: number,
    callback?: Function,
    onError?: Function
  ) => void;
  imageFeedback: ImageFeedbackItem[];
};

export default function FeedbackButton({
  getImageFeedback,
  sendFeedback,
  resolveFeedback,
  imageFeedback,
}: Props) {
  const { language } = useLanguage();
  const params = useParams();
  // @ts-ignore
  const image = parseInt(params.image);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFeedback, setFeedbacks] = useState<number[]>([]);

  useEffect(() => {
    getImageFeedback(image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let incorrectDetections = imageFeedback.filter(
    (feedback) => feedback.feedback.feedback_type === 1
  );
  let missingBlur = imageFeedback.filter(
    (feedback) => feedback.feedback.feedback_type === 2
  );
  let poorQuality = imageFeedback.filter(
    (feedback) => feedback.feedback.feedback_type === 3
  );
  let otherFeedback = imageFeedback.filter(
    (feedback) => feedback.feedback.feedback_type === 4
  );
  let allFeedbacks = [
    ...incorrectDetections,
    ...missingBlur,
    ...poorQuality,
    ...otherFeedback,
  ];
  let showFeedbackItems = allFeedbacks.filter((i) =>
    showFeedback.includes(i.id)
  );
  return (
    <>
      <MItem
        tooltip={translations.ImageViewer.SendFeedback[language]}
        onClick={handleClick}
        id="image-send-feedback"
      >
        <ChatBubble />
      </MItem>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mb: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              bottom: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Typography
          variant="body2"
          sx={{ px: 2, color: "text.secondary", textAlign: "center" }}
        >
          {translations.ImageViewer.SendFeedback.ReportImage[language]}
        </Typography>

        <MenuItem
          disabled={incorrectDetections.filter((i) => !!!i.resolved).length > 0}
          onClick={(e) => {
            e.stopPropagation();
            const comment = window.prompt(
              translations.ImageViewer.SendFeedback.AddComment[language]
            );
            let toastHandle = toast.info(
              translations.ImageViewer.SendFeedback.Registering[language],
              {
                autoClose: false,
              }
            );
            // @ts-ignore
            sendFeedback(image, 1, comment, () => {
              toast.update(toastHandle, {
                type: "success",
                render:
                  translations.ImageViewer.SendFeedback.Registered[language],
                autoClose: 5000,
              });
            });
          }}
        >
          <Stack direction="row" alignItems="center">
            <Typography>
              {
                translations.ImageViewer.SendFeedback.IncorrectDetection[
                  language
                ]
              }
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          disabled={missingBlur.filter((i) => !!!i.resolved).length > 0}
          onClick={(e) => {
            e.stopPropagation();
            const comment = window.prompt(
              translations.ImageViewer.SendFeedback.AddComment[language]
            );
            let toastHandle = toast.info(
              translations.ImageViewer.SendFeedback.Registering[language],
              {
                autoClose: false,
              }
            );
            // @ts-ignore
            sendFeedback(image, 2, comment, () => {
              toast.update(toastHandle, {
                type: "success",
                render:
                  translations.ImageViewer.SendFeedback.Registered[language],
                autoClose: 5000,
              });
            });
          }}
        >
          <Stack direction="row" alignItems="center">
            <Typography>
              {translations.ImageViewer.SendFeedback.MissingBlur[language]}
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          disabled={poorQuality.filter((i) => !!!i.resolved).length > 0}
          onClick={(e) => {
            e.stopPropagation();
            const comment = window.prompt(
              translations.ImageViewer.SendFeedback.AddComment[language]
            );
            let toastHandle = toast.info(
              translations.ImageViewer.SendFeedback.Registering[language],
              {
                autoClose: false,
              }
            );
            // @ts-ignore
            sendFeedback(image, 3, comment, () => {
              toast.update(toastHandle, {
                type: "success",
                render:
                  translations.ImageViewer.SendFeedback.Registered[language],
                autoClose: 5000,
              });
            });
          }}
        >
          <Stack direction="row" alignItems="center">
            <Typography>
              {translations.ImageViewer.SendFeedback.PoorImageQuality[language]}
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          disabled={otherFeedback.filter((i) => !!!i.resolved).length > 0}
          onClick={(e) => {
            e.stopPropagation();
            const comment = window.prompt(
              translations.ImageViewer.SendFeedback.AddCommentRequired[language]
            );

            if (!!comment) {
              let toastHandle = toast.info(
                translations.ImageViewer.SendFeedback.Registering[language],
                {
                  autoClose: false,
                }
              );
              sendFeedback(image, 4, comment, () => {
                toast.update(toastHandle, {
                  type: "success",
                  render:
                    translations.ImageViewer.SendFeedback.Registered[language],
                  autoClose: 5000,
                });
              });
            } else {
              toast.error(
                translations.ImageViewer.SendFeedback.CommentRequired[language]
              );
            }
          }}
        >
          <Stack direction="row" alignItems="center">
            <Typography>
              {translations.ImageViewer.SendFeedback.Other[language]}
            </Typography>
          </Stack>
        </MenuItem>

        {(incorrectDetections.length > 0 ||
          missingBlur.length > 0 ||
          poorQuality.length > 0 ||
          otherFeedback.length > 0) && (
          <div>
            <Divider />
            <Typography
              variant="body2"
              sx={{ px: 2, color: "text.secondary", textAlign: "center" }}
            >
              {translations.ImageViewer.SendFeedback.AlreadyReported[language]}
            </Typography>
          </div>
        )}
        {incorrectDetections.length > 0 && (
          <MenuItem
            onClick={() => setFeedbacks(incorrectDetections.map((i) => i.id))}
          >
            <Stack direction="row" alignItems="center">
              <Typography>
                {
                  translations.ImageViewer.SendFeedback.IncorrectDetection[
                    language
                  ]
                }
              </Typography>
            </Stack>
          </MenuItem>
        )}
        {missingBlur.length > 0 && (
          <MenuItem onClick={() => setFeedbacks(missingBlur.map((i) => i.id))}>
            <Stack direction="row" alignItems="center">
              <Typography>
                {translations.ImageViewer.SendFeedback.MissingBlur[language]}
              </Typography>
            </Stack>
          </MenuItem>
        )}
        {poorQuality.length > 0 && (
          <MenuItem onClick={() => setFeedbacks(poorQuality.map((i) => i.id))}>
            <Stack direction="row" alignItems="center">
              <Typography>
                {
                  translations.ImageViewer.SendFeedback.CommentRequired[
                    language
                  ]
                }
              </Typography>
            </Stack>
          </MenuItem>
        )}
        {otherFeedback.length > 0 && (
          <MenuItem
            onClick={() => setFeedbacks(otherFeedback.map((i) => i.id))}
          >
            <Stack direction="row" alignItems="center">
              <Typography>
                {translations.ImageViewer.SendFeedback.Other[language]}
              </Typography>
            </Stack>
          </MenuItem>
        )}
      </Menu>
      <Dialog open={showFeedback.length > 0} onClose={() => setFeedbacks([])}>
        <DialogTitle id="alert-dialog-title">
          {showFeedbackItems[0]?.feedback.feedback_name}
        </DialogTitle>
        <DialogContent>
          <Stack divider={<Divider />}>
            {showFeedbackItems.map((feedback, i) => (
              <DialogContentText key={i}>
                <Typography>{feedback.created_by.name}</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {feedback.created}
                </Typography>
                {feedback.comment && (
                  <Typography>{`${translations.words.Comment[language]}: ${feedback.comment}`}</Typography>
                )}
                {feedback.resolved && (
                  <>
                    <Typography>
                      {translations.ImageViewer.SendFeedback.Resolved[language]}
                      : {feedback.resolved}
                    </Typography>
                    <Typography>
                      {
                        translations.ImageViewer.SendFeedback.ResolvedBy[
                          language
                        ]
                      }
                      :
                      {
                        // @ts-ignore
                        feedback.resolved_by.name
                      }
                    </Typography>
                  </>
                )}

                <Button
                  onClick={() => resolveFeedback(image, feedback.id)}
                  disabled={!!feedback.resolved}
                >
                  {!feedback.resolved
                    ? translations.ImageViewer.SendFeedback.Resolve[language]
                    : translations.ImageViewer.SendFeedback.Resolved[language]}
                </Button>
              </DialogContentText>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeedbacks([])}>
            {translations.AnnotationTool.Close[language]}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
