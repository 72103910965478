import React, { useEffect, useState } from "react";
import Template from "../Template";
import translations from "translations";
import { useParams } from "react-router-dom";
import { useLanguage } from "hooks";

type Props = {
  nImages: number;
  getImageStats: (callback?: Function, onError?: Function) => void;
};

export default function ImageCounter({ nImages, getImageStats }: Props) {
  const { language } = useLanguage();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    getImageStats(
      () => {
        setLoading(false);
        setSuccess(true);
      },
      () => {
        setLoading(false);
        setSuccess(false);
      }
    );
  }, [getImageStats, params.mission]);

  return (
    <Template
      title={translations.Menu.Statistics.nImages[language]}
      value={`${nImages}`}
      loading={loading}
      success={success}
    />
  );
}
