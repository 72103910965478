import { Switch } from "@mui/material";
import MenuItem from "components/MenuItem";
import { useDispatch, useLanguage, useSelector } from "hooks";
import { setAutoSpeedZoomInReviewModes } from "state/actions";
import { RootState } from "state/reducers";
import translations from "translations";

function AutoSpeedZoomInReviewModes() {
  const dispatch = useDispatch();
  const { language } = useLanguage();
  const autoSpeedZoomInReviewModes = useSelector(
    (state: RootState) => state.user.auto_speed_zoom_in_review_modes
  );
  return (
    <MenuItem
      id="autoSpeedZoomSwitch"
      onClick={() =>
        dispatch(setAutoSpeedZoomInReviewModes(!autoSpeedZoomInReviewModes))
      }
    >
      <p>{translations.Menu.Settings.AutoSpeedZoomInReviewModes[language]}</p>
      <Switch color="primary" checked={autoSpeedZoomInReviewModes} />
    </MenuItem>
  );
}
export default AutoSpeedZoomInReviewModes;
