import { Checkbox, FormControlLabel } from "@mui/material";
import { useLidarMarkerFilter, useLanguage } from "hooks";
import translations from "translations";

export default function LidarMarkerFilter() {
  const { lidarMarkerFilter, setLidarMarkerValue } = useLidarMarkerFilter();
  const { language } = useLanguage();

  return (
    <FormControlLabel
      checked={lidarMarkerFilter}
      sx={{
        "&:hover": {
          backgroundColor: "#EDEDED",
        },
        p: 0,
        m: 0,
        py: 1,
      }}
      // @ts-ignore
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setLidarMarkerValue(!lidarMarkerFilter)
      }
      control={<Checkbox />}
      label={translations.Filter.LidarMarkers[language]}
    />
  );
}
