import { axiosInstance } from "utils/request";

interface ITag {
  id: number;
  name: string;
}

interface IUser {
  email: string;
  id: number;
  name: string;
}

interface IWorkSession {
  description: string;
  end_time: string | null;
  id: number;
  project_id: number;
  start_time: string;
  critical_inspected_length: number;
  inspected_length: number;
  annotator_inspected_length: number;
  confirmed_inspected_length: number;
  tags: ITag[];
  user: IUser;
}

interface IGetWorkSessionsResponse {
  work_sessions: IWorkSession[];
  total: number;
}
export async function getWorkSessions(
  projectID: null | number = null
): Promise<IGetWorkSessionsResponse> {
  let endpoint = "";
  if (projectID) {
    endpoint = `/project/${projectID}/work_session`;
  } else {
    endpoint = "/project/work_session";
  }

  const response = await axiosInstance.get<IGetWorkSessionsResponse>(endpoint);
  if (response.status !== 200) {
    throw new Error("Failed to fetch work sessions");
  }

  return response.data;
}

interface IStartWorkSessionResponse {
  id: number;
}

export async function startNewSession(
  project_id: number,
  // @ts-ignore
  description: string = undefined,
  // @ts-ignore
  tags: number[] = undefined,
  // @ts-ignore
  start_time: string = undefined,
  // @ts-ignore
  end_time: string | null = undefined
): Promise<IStartWorkSessionResponse> {
  const payload: Record<string, string | number | null | number[]> = {};
  if (description !== undefined) {
    payload.description = description;
  }
  if (tags !== undefined) {
    payload.tags = tags;
  }
  if (start_time !== undefined) {
    payload.start_time = start_time;
  }
  if (end_time !== undefined) {
    payload.end_time = end_time;
  }

  const response = await axiosInstance.post<IStartWorkSessionResponse>(
    `/project/${project_id}/work_session/start`,
    payload
  );
  if (response.status !== 201) {
    throw new Error("Failed to start new session");
  }
  return response.data;
}

interface IStopWorkSessionResponse {
  id: number;
}
export async function stopSession(
  session_id: number
): Promise<IStopWorkSessionResponse> {
  const response = await axiosInstance.post<IStopWorkSessionResponse>(
    `/project/work_session/${session_id}/stop`
  );
  if (response.status !== 200) {
    throw new Error("Failed to stop session");
  }
  return response.data;
}

interface DeleteWorkSessionResponse {
  id: number;
}
export async function deleteWorkSession(
  session_id: number
): Promise<DeleteWorkSessionResponse> {
  const response = await axiosInstance.delete<DeleteWorkSessionResponse>(
    `/project/work_session/${session_id}`
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete session");
  }
  return response.data;
}

interface IUpdateWorkSessionResponse {
  id: number;
}
export async function updateWorkSession(
  session_id: number,
  // @ts-ignore
  description: string = undefined,
  // @ts-ignore
  tags: number[] = undefined
): Promise<IUpdateWorkSessionResponse> {
  const payload: Record<string, string | number | null | number[]> = {};

  if (description !== undefined) {
    payload.description = description;
  }
  if (tags !== undefined) {
    payload.tags = tags;
  }

  const response = await axiosInstance.put<IUpdateWorkSessionResponse>(
    `/project/work_session/${session_id}`,
    payload
  );
  if (response.status !== 200) {
    throw new Error("Failed to update session");
  }
  return response.data;
}

export async function updateWorkSessionAutoStopTimeAPI({
  session_id,
  project_id,
}: {
  session_id: number;
  project_id: number;
}): Promise<void> {
  const response = await axiosInstance.post<void>(
    `/project/${project_id}/work_session/${session_id}/auto_stop_time`
  );
  if (response.status !== 204) {
    throw new Error("Failed to update work session auto stop time");
  }
}

interface IGetTagsResponse {
  tags: ITag[];
}
export async function getTags(): Promise<IGetTagsResponse> {
  const response = await axiosInstance.get<IGetTagsResponse>(
    "/project/work_session/tags"
  );
  if (response.status !== 200) {
    throw new Error("Failed to fetch tags");
  }
  return response.data;
}

interface ICreateTagResponse {
  id: number;
  name: string;
}

export async function createTag(name: string): Promise<ICreateTagResponse> {
  const response = await axiosInstance.post<ICreateTagResponse>(
    "/project/work_session/tags",
    {
      name,
    }
  );
  if (response.status !== 201) {
    throw new Error("Failed to create tag");
  }
  return response.data;
}

interface IDeleteTagResponse {
  id: number;
}
export async function deleteTag(id: number): Promise<IDeleteTagResponse> {
  const response = await axiosInstance.delete<IDeleteTagResponse>(
    `/project/work_session/tags/${id}`
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete tag");
  }
  return response.data;
}

interface IUpdateTagResponse {
  id: number;
  name: string;
}
export async function updateTag(
  id: number,
  name: string
): Promise<IUpdateTagResponse> {
  const response = await axiosInstance.put<IUpdateTagResponse>(
    `/project/work_session/tags/${id}`,
    {
      name,
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to update tag");
  }
  return response.data;
}
