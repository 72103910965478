import React, { useState, useEffect } from "react";
import {
  StoryblokComponent,
  StoryblokBridgeConfigV2,
  ISbStoriesParams,
  ISbStoryData,
  useStoryblokApi,
  registerStoryblokBridge,
} from "@storyblok/react";
import { Box, Paper, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import Loading from "views/Loading";
import "./style.scss";

interface IProps {
  language: string;
}

let languageTranslator = {
  EN: "default",
  SE: "sv",
};

export const useStoryblok = (
  slug: string,
  apiOptions: ISbStoriesParams = {},
  bridgeOptions: StoryblokBridgeConfigV2 = {}
) => {
  let storyblokApiInstance = useStoryblokApi();

  let [story, setStory] = useState<ISbStoryData>({} as ISbStoryData);

  const isBridgeEnable =
    typeof window !== "undefined" &&
    typeof window.storyblokRegisterEvent !== "undefined";

  useEffect(() => {
    async function initStory() {
      const { data } = await storyblokApiInstance.get(
        `cdn/stories/${slug}`,
        apiOptions
      );

      setStory(data.story);

      if (isBridgeEnable && data.story.id) {
        registerStoryblokBridge(
          data.story.id,
          (story) => setStory(story),
          bridgeOptions
        );
      }
    }

    initStory();
  }, [slug, JSON.stringify(apiOptions)]);

  return story;
};

function FAQ({ language }: IProps) {
  const location = useLocation();

  // The slug of the story
  let slug = location.pathname;
  if (slug.startsWith("/sv")) {
    slug = slug.replace("/sv", "");
  }

  const story = useStoryblok(
    slug, // @ts-ignore
    { version: "draft", language: languageTranslator[language] } // Or published,
  );
  const navigation = useStoryblok(
    "navigation/faq-menu", // @ts-ignore
    { version: "draft", language: languageTranslator[language] } // Or published,
  );

  if (!story || !story.content || !navigation || !navigation.content) {
    return <Loading />;
  }

  // If story is there, use StoryblokComponent to render it (dynamically)
  return (
    <Stack
      direction={{ xs: "column-reverse", md: "row" }}
      id="faq"
      flex={1}
      justifyContent="center"
    >
      <Box flex={{ xs: 0, md: 1, lg: 2 }}></Box>
      <Paper
        sx={{
          flex: 100,
          display: "flex",
          p: 2,
          m: 1,
          "& img": { maxWidth: "100%" },
          maxWidth: "800px",
          height: "calc(100vh - 100px)",
        }}
      >
        <Box
          py={{ xs: 1, md: 1 }}
          px={{ xs: 1, md: 3 }}
          sx={{ overflowY: "auto" }}
        >
          <StoryblokComponent blok={story.content} />
        </Box>
      </Paper>
      <Box
        flex={{ xs: 0, md: 1, lg: 2 }}
        display={"flex"}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        <Stack
          width={{ xs: "100%", md: 300 }}
          flex={{
            xs: undefined,
            sm: 1,
            md: 1,
            xl: 1,
          }}
          maxWidth="fit-content"
          minWidth="fit-content"
          mt={{ xs: 7, md: 2 }}
        >
          <Box>
            <StoryblokComponent blok={navigation.content} />
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}
export default FAQ;
