export default {
  Cancel: {
    EN: "Cancel",
    SE: "Avbryt",
    NO: "Avbryt",
  },
  GoBack: {
    EN: "Go back",
    SE: "Gå tillbaka",
    NO: "Gå tilbake",
  },
  NoDomainSelected: {
    EN: "No domain selected",
    SE: "Ingen domän vald",
    NO: "Ingen domene valgt",
  },
  NoGroupsFound: {
    EN: "No groups found",
    SE: "Inga grupper hittades",
    NO: "Ingen grupper funnet",
  },
  Refresh: {
    EN: "Refresh",
    SE: "Uppdatera",
    NO: "Oppdater",
  },
  Save: {
    EN: "Save",
    SE: "Spara",
    NO: "Lagre",
  },
  SearchDomains: {
    EN: "Search domains",
    SE: "Sök domäner",
    NO: "Søk domener",
  },
  SelectADomainFromTheList: {
    EN: "Select a domain from the list",
    SE: "Välj en domän från listan",
    NO: "Velg en domene fra listen",
  },
  SidebarLinkText: {
    EN: "Domain Manager",
    SE: "Domänhanterare",
    NO: "Domenebehandler",
  },
};
