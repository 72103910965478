import { ReactElement, useEffect } from "react";
import FilterInterface from "./FilterInterface";
import ImageViewerInterface from "./ImageViewerInterface";
import { useSearchParams } from "react-router-dom";
import { isFilterActive, getFilter, setFilter } from "utils/filter";
import "./style.scss";

interface Props {
  compact?: boolean;
  image: number;
  lists: {
    id: number;
    title: string;
    images: number[];
  }[];
  updateMarkers: () => void;
  userId: number;
}

export default function ImageLists({
  compact,
  image,
  lists,
  updateMarkers,
  userId,
}: Props): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterActive = isFilterActive(searchParams);
  const filterList = getFilter("list", searchParams);
  // @ts-ignore
  function setFilterList(value) {
    setFilter("list", value, searchParams, setSearchParams);
  }

  useEffect(() => {
    // Update markers when an image is added or deleted
    if (filterActive && compact) {
      updateMarkers();
    }
  }, [filterActive, lists, updateMarkers, compact]);

  if (compact) {
    return <ImageViewerInterface lists={lists} image={image} />;
  } else {
    return (
      <FilterInterface
        lists={lists}
        filterList={filterList}
        setFilterList={setFilterList}
        updateMarkers={updateMarkers}
        userId={userId}
      />
    );
  }
}
