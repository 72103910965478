import { Clear, Fullscreen } from "@mui/icons-material";
import Select from "react-select";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";

import { getLambdaAxiosWithAuth } from "utils/request";
import { useParams } from "react-router-dom";
import { loadPowerlines } from "state/actions";
import { Loading } from "views";
import { useFeedBayFilter } from "hooks/filter/search_parameters/useFeedBayFilter";
import { useSelector, useDispatch, useLanguage } from "hooks";
import translations from "translations";
import FeedStationItem from "./FeedStationItem";
import { getFeedIdentifier } from "utils/map";
import { toast } from "react-toastify";

interface IBayFeed {
  feedBay: string;
  feedStation: string;
  color: string;
}

export default function FeedBay() {
  const map = useSelector((state) => state.map.gmap);
  const dispatch = useDispatch();
  const params = useParams();
  const { language } = useLanguage();
  // @ts-ignore
  const projectID = parseInt(params.mission);
  const [showModal, setShowModal] = React.useState(false);
  const [feedBays, setFeedBays] = React.useState<Record<string, IBayFeed>>({});
  const [loading, setLoading] = React.useState(false);

  const { feedBayFilter, setFeedBayFilter } = useFeedBayFilter();

  useEffect(() => {
    updateFeedBays(map);
  }, [map]);

  async function generateColors() {
    setLoading(true);
    // Use the lambda instance since it will take some time
    const lambdaInstance = await getLambdaAxiosWithAuth();

    // Generate a promise to handle the server response
    const requestPromise = lambdaInstance
      .post(`/project/${projectID}/powerline/topology/colors/generate`)
      .then(() => {
        dispatch(loadPowerlines(map, false, projectID));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });

    // Render the status of the promise
    toast.promise(requestPromise, {
      pending: "Generating colors. This can take a few minutes.",
      success: "Colors generated successfully.",
      error: "Failed to generate colors",
    });
  }
  // @ts-ignore
  function updateFeedBays(map) {
    const foundFeedBays = {};
    if (map) {
      // biome-ignore lint/complexity/noForEach: Doesn't work on google maps
      map.data.forEach(
        // @ts-ignore
        (feature) => {
          const feedBay = feature.getProperty("feedBay");
          const feedStation = feature.getProperty("feedStation");
          const topologyColor = feature.getProperty("topologyColor");
          const key = getFeedIdentifier(feedStation, feedBay);
          if (feedBay) {
            // @ts-ignore
            foundFeedBays[key] = {
              feedBay,
              feedStation,
              color: topologyColor,
            };
          }
        }
      );
    }
    setFeedBays(foundFeedBays);
  }

  const feedStations = useMemo(() => {
    type FeedBay = {
      name: string;
      color: string;
    };
    const feedStationMap: Record<string, FeedBay[]> = {};

    for (const feedBay of Object.values(feedBays)) {
      const { feedStation } = feedBay;

      if (!feedStationMap[feedStation]) {
        feedStationMap[feedStation] = [];
      }

      feedStationMap[feedStation].push({
        name: feedBay.feedBay,
        color: feedBay.color,
      });
    }

    const results = Object.entries(feedStationMap).map(
      ([feedStation, feedBays]) => ({
        feedStation,
        feedBays,
      })
    );
    return results;
  }, [feedBays]);

  const selectOptions = Object.keys(feedBays).map((feedBay) => {
    const feedIdentifier = getFeedIdentifier(
      feedBays[feedBay].feedStation,
      feedBays[feedBay].feedBay
    );
    return {
      label: feedIdentifier,
      value: feedIdentifier,
    };
  });

  return (
    <div>
      <Paper
        sx={{
          px: 1,
          py: 1,
          minHeight: 250,
          maxHeight: 550,
          minWidth: 300,
          overflowY: "auto",
          transition: "all 0.3s ease",
        }}
      >
        <Stack position="relative">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ mx: 1 }}>
              {translations.Filter.FeedBayFilter[language]}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={0.5}
            >
              {feedBayFilter.length > 0 && (
                <Clear
                  sx={{
                    padding: 0.5,
                    borderRadius: "50%",
                    "&:hover": {
                      background: "rgba(0,0,0,0.1)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setFeedBayFilter([]);
                  }}
                  fontSize="small"
                />
              )}
              <Fullscreen
                sx={{
                  padding: 0.5,
                  borderRadius: "50%",
                  "&:hover": {
                    background: "rgba(0,0,0,0.1)",
                    cursor: "pointer",
                  },
                }}
                onClick={() => setShowModal(true)}
                fontSize="small"
              />
            </Stack>
          </Stack>
          <Divider sx={{ my: 1 }} />

          {feedStations.length === 0 ? (
            <Typography color="text.secondary" mx={1}>
              {translations.Filter.NoFeedBaysFound[language]}
            </Typography>
          ) : (
            <Stack
              sx={{
                height: "200px",
                transition: "height 0.3s ease",
                opacity: 1,
                overflow: "auto",
                px: 0,
              }}
              spacing={0.5}
            >
              {feedStations.map((feedStation) => (
                <FeedStationItem
                  key={feedStation.feedStation}
                  feedStation={feedStation}
                  highlightedFeedBays={feedBayFilter}
                  highlightFeedBays={setFeedBayFilter}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </Paper>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle>{translations.Filter.FeedBaySearch[language]}</DialogTitle>
        <DialogContent>
          <DialogContentText mb={1}>
            {translations.Filter.FeedBaySearch.Section1[language]}
          </DialogContentText>
          <DialogContentText mb={2}>
            {translations.Filter.FeedBaySearch.Section2[language]}
          </DialogContentText>
          <Select
            styles={{
              // @ts-ignore
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            menuPortalTarget={document.body}
            // @ts-ignore
            options={selectOptions.sort((a, b) => (a.label < b.label ? -1 : 1))}
            // @ts-ignore
            value={selectOptions.filter((f) => feedBayFilter.includes(f.value))}
            onChange={(selected) => {
              if (!selected) {
                setFeedBayFilter([]);
                return;
              }
              // @ts-ignore
              setFeedBayFilter(selected.map((s) => s.value));
            }}
            isMulti
          />
          <Box>
            <Button
              onClick={generateColors}
              endIcon={
                loading ? <Loading size={20} relative color="black" /> : null
              }
            >
              {translations.Filter.GenerateColors[language]}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowModal(false);
              setFeedBayFilter(feedBayFilter);
            }}
          >
            {translations.Filter.Show[language]}
          </Button>
          <Button
            onClick={() => {
              setFeedBayFilter([]);
              setShowModal(false);
            }}
          >
            {translations.Filter.Cancel[language]}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
