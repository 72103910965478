import { Dialog, Typography } from "@mui/material";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { AnnotationContext } from "../provider";
import ImageConnectorDialog from "../ImageConnectorDialog";

function NotFound() {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Dialog
      open={true}
      sx={{ zIndex: 20000, p: 2 }}
      onClose={() => {
        searchParams.delete("box");
        setSearchParams(searchParams);
      }}
    >
      <Typography>Not found</Typography>
    </Dialog>
  );
}

export default function PoleBox() {
  const [searchParams, setSearchParams] = useSearchParams();
  // @ts-ignore
  const boxID = Number.parseInt(searchParams.get("box"));

  const annotationState = useContext(AnnotationContext);

  const annotations = annotationState.data;
  const currentAnnotation = annotations.find(
    (annotation) => annotation.id === boxID
  );

  if (!currentAnnotation) {
    return <NotFound />;
  }

  return <ImageConnectorDialog />;
}
