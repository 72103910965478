import { RootState } from "state/store";
import { clearDrawingManager, loadDrawingManager } from "./map";

export const toggleAdminUI = () => {
  return {
    type: "TOGGLE_ADMIN_UI",
  };
};

export const toggleFilterWindow = () => {
  return {
    type: "TOGGLE_FILTER_WINDOW",
  };
};

export const toggleUploadUI = () => {
  return {
    type: "TOGGLE_UPLOAD_UI",
  };
};

export function setSupervisorMode(value: boolean) {
  // @ts-ignore
  return (dispatch, getState) => {
    const state: RootState = getState();
    const mission = state.mission;
    if (!value) {
      dispatch(clearDrawingManager());
    } else {
      if (mission.id) dispatch(loadDrawingManager());
    }
    dispatch({
      type: "SET_SUPERVISOR_MODE",
      payload: value,
    });
  };
}

export function setAssignmentVisibility(value: boolean) {
  // @ts-ignore
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const overlays: google.maps.Polygon[] = state.map.overlays;

    // biome-ignore lint/complexity/noForEach: Google maps require forEach
    overlays.forEach((overlay) => {
      overlay.setVisible(value);
    });
    dispatch({
      type: "SET_ASSIGNMENT_VISIBILITY",
      payload: value,
    });
  };
}

export const toggleDemoMode = (value: boolean) => {
  // @ts-ignore
  return (dispatch, getState) => {
    const state: RootState = getState();
    const map: google.maps.Map = state.map.gmap;
    if (map) {
      const mapType = map.getMapTypeId();
      if (value && mapType !== "sattelite") {
        map.setMapTypeId("satellite");
      }
    }

    dispatch({
      type: "SET_MAP_TYPE",
      payload: "satellite",
    });
    dispatch({
      type: "TOGGLE_DEMO_MODE",
      payload: value,
    });
  };
};

export const setListView = (value: boolean) => {
  return {
    type: "SET_LIST_VIEW",
    payload: value,
  };
};

export const setSidebarVisibility = (value: boolean) => {
  return {
    type: "SET_SIDEBAR_VISIBILITY",
    payload: value,
  };
};

export const setShowExtraInfo = (value: boolean) => {
  return {
    type: "SET_SHOW_EXTRA_INFO",
    payload: value,
  };
};

export const setHoverText = (value: string | null) => {
  return {
    type: "SET_HOVER_TEXT",
    payload: value,
  };
};

export const setClusterVisible = (value: boolean) => {
  return {
    type: "SET_CLUSTER_VISIBLE",
    payload: value,
  };
};

export const setShowDatesBeforeProjectName = (value: boolean) => {
  return {
    type: "SET_SHOW_DATES_BEFORE_PROJECT_NAME",
    payload: value,
  };
};

export const setProximityEnabled = (value: boolean) => {
  return {
    type: "SET_PROXIMITY",
    payload: value,
  };
};

export const setMarkerVisibility = (value: boolean) => {
  return {
    type: "SET_MARKERS_VISIBILITY",
    payload: value,
  };
};

export const setPowerlineVisibility = (value: boolean) => {
  return {
    type: "SET_POWERLINES_VISIBILITY",
    payload: value,
  };
};

export const setZoneVisibility = (value: boolean) => {
  return {
    type: "SET_ZONES_VISIBILITY",
    payload: value,
  };
};

export function setPoleVisibility(value: boolean) {
  return {
    type: "SET_POLE_VISIBILITY",
    payload: value,
  };
}

export function showUserManager(value: boolean) {
  return {
    type: "SHOW_USER_MANAGER",
    payload: value,
  };
}

export function showIssueReport(value: boolean) {
  return {
    type: "SHOW_ISSUE_REPORT",
    payload: value,
  };
}

export function showMissionManager(value: boolean) {
  return {
    type: "SHOW_MISSION_MANAGER",
    payload: value,
  };
}

export function showInventoryReport(value: boolean) {
  return {
    type: "SHOW_INVENTORY_REPORT",
    payload: value,
  };
}

export function showAssetReport(value: boolean) {
  return {
    type: "SHOW_ASSET_REPORT",
    payload: value,
  };
}

export function showStreetView(value: boolean) {
  return {
    type: "SET_STREETVIEW",
    payload: value,
  };
}

export function closeStreetView() {
  // @ts-ignore
  return (dispatch, getState) => {
    const state: RootState = getState();
    const map = state.map.gmap;
    const streetView = map.getStreetView();
    streetView.setVisible(false);
    dispatch(showStreetView(false));
  };
}

export function setClearanceVisibility(visibility: boolean) {
  return {
    type: "SET_CLEARANCE_VISIBILITY",
    payload: visibility,
  };
}

export function setLidarMarkerVisibility(visibility: boolean) {
  return {
    type: "SET_LIDAR_MARKER_VISIBILITY",
    payload: visibility,
  };
}

export function setHeatmapEnabled(enabled: boolean) {
  return {
    type: "SET_HEATMAP_ENABLED",
    payload: enabled,
  };
}

export function setBlockerModalMessage(title: string, message: string) {
  return {
    type: "SET_BLOCKER_MODAL_MESSAGE",
    payload: { title, message },
  };
}

export function clearBlockerModal() {
  return {
    type: "SET_BLOCKER_MODAL_MESSAGE",
    payload: null,
  };
}

export function setImageListVisibility(value: boolean) {
  return {
    type: "SET_IMAGE_LIST_VISIBILITY",
    payload: value,
  };
}
