import { useContext } from "react";
import { LocationHistoryContext } from "providers/LocationHistoryProvider";

export default function useLocationHistory() {
  const locationHistoryContext = useContext(LocationHistoryContext);

  if (!locationHistoryContext) {
    throw new Error(
      "useLocationHistory has to be used within <LocationHistoryProvider>"
    );
  }

  return locationHistoryContext;
}
