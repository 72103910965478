import { ReactElement } from "react";

import translations from "translations";

import { ResponsiveBar } from "@nivo/bar";
import { Box } from "@mui/material";
import { useLanguage } from "hooks";

interface Props {
  machineDetections: number;
  humanDetections: number;
  machineInventoryDetections: number;
  machineDefectDetections: number;
  humanInventoryDetections: number;
  humanDefectDetections: number; // @ts-ignore
  sx?; // @ts-ignore
  hideTitle?;
}
// @ts-ignore
const customeTooltip = ({ id, data }) => (
  <div
    style={{
      padding: 5,
      borderRadius: 2,
      boxShadow: "1px 1px",
      background: "#ffff",
    }}
  >
    {id}:<strong>{data[`${id}Total`]}</strong>
  </div>
);
// @ts-ignore
const MyResponsiveBar = ({ data, language }) => (
  <ResponsiveBar
    data={data}
    colors={({ id, data }) => String(data[`${id}Color`])}
    keys={[
      // @ts-ignore
      `${translations.Menu.Statistics.MachineLearning.Human[language]}`,
      // @ts-ignore
      `${translations.Menu.Statistics.MachineLearning.Machine[language]}`,
    ]}
    indexBy="type"
    valueFormat={(value) => {
      return `${value}%`;
    }}
    margin={{ top: 60, right: 70, bottom: 50, left: 80 }}
    padding={0.2}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    borderColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "%",
      legendPosition: "middle",
      legendOffset: -40,
    }}
    tooltip={customeTooltip}
  />
);

export default function DetectionCategories({
  machineDetections,
  humanDetections,
  machineInventoryDetections,
  machineDefectDetections,
  humanInventoryDetections,
  humanDefectDetections,
  sx,
  hideTitle,
}: Props): ReactElement {
  const { language } = useLanguage();
  let dataDetection = [
    {
      id: `${translations.Menu.Statistics.MachineLearning.Machine[language]}`,
      label: "Machine",
      value: machineDetections,
      color: "hsl(210,100%,46%)",
    },
    {
      id: `${translations.Menu.Statistics.MachineLearning.Human[language]}`,
      label: "Human",
      value: humanDetections,
      color: "hsl(177, 70%, 50%)",
    },
  ];

  let dataDetectionCategories = [
    {
      type: translations.Menu.Statistics.MachineLearning.Inventory[language],
      [`${translations.Menu.Statistics.MachineLearning.Machine[language]}`]:
        Math.round(
          (machineInventoryDetections /
            (machineInventoryDetections + humanInventoryDetections)) *
            100
        ),
      [`${translations.Menu.Statistics.MachineLearning.Machine[language]}Color`]:
        "hsl(210,100%,46%)",
      [`${translations.Menu.Statistics.MachineLearning.Human[language]}`]:
        Math.round(
          (humanInventoryDetections /
            (machineInventoryDetections + humanInventoryDetections)) *
            100
        ),
      [`${translations.Menu.Statistics.MachineLearning.Human[language]}Color`]:
        "hsl(177, 70%, 50%)",
      total: machineInventoryDetections + humanInventoryDetections,
      [`${translations.Menu.Statistics.MachineLearning.Machine[language]}Total`]:
        machineInventoryDetections,
      [`${translations.Menu.Statistics.MachineLearning.Human[language]}Total`]:
        humanInventoryDetections,
    },
    {
      type: translations.Menu.Statistics.MachineLearning.Defect[language],
      [`${translations.Menu.Statistics.MachineLearning.Machine[language]}`]:
        Math.round(
          (machineDefectDetections /
            (machineDefectDetections + humanDefectDetections === 0
              ? 1
              : machineDefectDetections + humanDefectDetections)) *
            100
        ),
      [`${translations.Menu.Statistics.MachineLearning.Machine[language]}Color`]:
        "hsl(210,100%,46%)",
      [`${translations.Menu.Statistics.MachineLearning.Human[language]}`]:
        Math.round(
          (humanDefectDetections /
            (machineDefectDetections + humanDefectDetections === 0
              ? 1
              : machineDefectDetections + humanDefectDetections)) *
            100
        ),
      [`${translations.Menu.Statistics.MachineLearning.Human[language]}Color`]:
        "hsl(177, 70%, 50%)",
      [`${translations.Menu.Statistics.MachineLearning.Machine[language]}Total`]:
        machineDefectDetections,
      [`${translations.Menu.Statistics.MachineLearning.Human[language]}Total`]:
        humanDefectDetections,
    },
  ];

  return (
    <>
      {dataDetection[0].value > 0 && dataDetection[1].value > 0 && (
        <Box
          sx={{
            width: 500,
            height: 550,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 1,
            background: "#fff",
            borderColor: "rgba(0, 0, 0, 0.24)",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 20px 0 rgba(0, 0, 0, 0.12)",
            padding: 2.5,
            ...sx,
          }}
        >
          {!!!hideTitle && (
            <h2>
              {
                translations.Menu.Statistics.MachineLearning
                  .DetectionCategories[language]
              }
            </h2>
          )}

          <div style={{ height: sx?.height ?? 350 }}>
            <MyResponsiveBar
              data={dataDetectionCategories}
              language={language}
            />
          </div>
        </Box>
      )}
    </>
  );
}
