import { useRef, useMemo } from "react";
import { useEventCallback } from "utils";

type Props = {
  // @ts-ignore
  imagePosition;
  // @ts-ignore
  imageSrc;
  zIndex: number;
  // @ts-ignore
  onLoad?: (payload) => void;
};

export default function Image({
  imagePosition,
  imageSrc,
  onLoad,
  zIndex,
}: Props) {
  const imageRef = useRef();
  // @ts-ignore
  const onImageLoaded = useEventCallback((event) => {
    const imageElm = event.currentTarget;
    if (onLoad)
      onLoad({
        naturalWidth: imageElm.naturalWidth,
        naturalHeight: imageElm.naturalHeight,
        imageElm: imageElm,
      });
  });

  const stylePosition = useMemo(() => {
    const width = imagePosition.bottomRight.x - imagePosition.topLeft.x;
    const height = imagePosition.bottomRight.y - imagePosition.topLeft.y;
    return {
      imageRendering: "pixelated",
      left: imagePosition.topLeft.x || 0,
      top: imagePosition.topLeft.y || 0,
      width: Number.isNaN(width) ? 0 : width,
      height: Number.isNaN(height) ? 0 : height,
    };
  }, [
    imagePosition.topLeft.x,
    imagePosition.topLeft.y,
    imagePosition.bottomRight.x,
    imagePosition.bottomRight.y,
  ]);

  return (
    <img
      src={imageSrc}
      alt="source"
      // @ts-ignore
      ref={imageRef}
      // @ts-ignore
      style={{
        ...stylePosition,
        zIndex: zIndex,
        position: "absolute",
      }}
      onLoad={onImageLoaded}
      onError={(e) => {
        // @ts-ignore
        e.target.onerror = null;
        // @ts-ignore
        e.target.src = "/missing.png";
      }}
    />
  );
}
