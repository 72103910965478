import { connect } from "react-redux";
import { RootState } from "state/store";
import {
  setLidarWindow,
  updateMarkers,
  setClickedLidarMarker,
} from "state/actions";
import Component from "./LidarMarker";

interface IStateProps {
  severities: any[];
  clickedLidarMarker: number | string | null;
}

interface IDispatchProps {
  setLidarWindow: (window: any) => void;
  updateMarkers: Function;
  setClickedLidarMarker: (lidarMarkerId: number) => void;
}

interface IOwnProps {
  severity: number;
  scene: string;
  project: number;
  lat: number;
  lng: number;
}

const mapStateToProps = (state: RootState) => {
  return {
    severities: state.objects.issueSeverities,
    clickedLidarMarker: state.lidar.clickedLidarMarker,
  };
};

const mapDispatchToProps = {
  setLidarWindow,
  updateMarkers,
  setClickedLidarMarker,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(Component);
