import { Select, MenuItem, SelectChangeEvent } from "@mui/material";

import translations from "translations";
import { useLanguage } from "hooks";

interface IProps {
  clientNaming: boolean;
  changeClientNaming: (value: boolean) => void;
}

function ClusterSwitch({ clientNaming, changeClientNaming }: IProps) {
  const onChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value === "true"; // Convert string back to boolean
    changeClientNaming(value);
  };

  const { language } = useLanguage();

  const options = [
    {
      value: false,
      label: "Arkion Naming",
      dataTestId: "settings.clientNaming.option.arkionNaming",
    },
    {
      value: true,
      label: "Client Naming",
      dataTestId: "settings.clientNaming.option.clientNaming",
    },
  ];

  return (
    <MenuItem id="proximitySwitch">
      <p>{translations.Menu.Settings.ClientNaming[language]}</p>

      <Select
        sx={{ ml: 2 }}
        fullWidth={true}
        id="select-client-naming"
        data-testid="settings.clientNaming"
        value={clientNaming.toString()} // Convert boolean to string
        onChange={onChange}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i.toString()}
            value={option.value.toString()} // Convert boolean to string
            data-testid={option.dataTestId}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </MenuItem>
  );
}

export default ClusterSwitch;
