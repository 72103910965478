import React from "react";
import CMSRichTextContent from ".";

type Props = {
  content: any;
};

export default function CMSParagraph({ content }: Props) {
  if (!!!content) {
    return <></>;
  }
  return (
    <p>
      {content.map(
        // @ts-ignore
        (c, i) => (
          <CMSRichTextContent key={i} content={c} />
        )
      )}
    </p>
  );
}
