import { createContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import type { Location as RouterLocation } from "react-router-dom";

interface IContext {
  previousRenderLocation: RouterLocation;
}
export const LocationHistoryContext = createContext<IContext>({
  // @ts-ignore
  previousRenderLocation: null,
});

// @ts-ignore
export function LocationHistoryProvider({ children }) {
  const previousRenderLocationRef = useRef<RouterLocation | null>(null);
  const location = useLocation();

  useEffect(() => {
    previousRenderLocationRef.current = { ...location };
  }, [location]);

  return (
    <LocationHistoryContext.Provider
      value={{
        // @ts-ignore
        previousRenderLocation: previousRenderLocationRef.current,
      }}
    >
      {children}
    </LocationHistoryContext.Provider>
  );
}
