import { connect } from "react-redux";
import AllDetections from "./AllDetections";
import { RootState } from "state/store";

interface IStateProps {
  machineDetections: number;
  humanDetections: number;
}

interface IDispatchProps {}

interface IOwnProps {
  // @ts-ignore
  sx?;
  // @ts-ignore
  hideTitle?;
}

const mapStateToProps = (state: RootState) => {
  return {
    machineDetections: state.statistics.machineDetections,
    humanDetections: state.statistics.humanDetections,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AllDetections);
