import { captureException } from "@sentry/react";
import {
  authorizedDelete,
  authorizedGet,
  authorizedPost,
  authorizedPut,
} from "utils/request";

interface IPoleResponse {
  id: number;
  clientID: string;
  boxes: IAnnotation[];
  summary: ISummaryItem[];
  poleType?: IPoleType;
  comment: string | null;
}

interface IPoleType {
  id: number;
  name: string;
  svg: string;
}

export interface ISummaryItem {
  id: number;
  count: number;
  objectType: number;
}

export interface IAnnotation {
  id: number;
  h: number;
  w: number;
  x: number;
  y: number;
  items: IItem[];
}

export interface IItem {
  id: number;
  image: {
    id: number;
    src: string;
  };
}

export interface IPoleComponent {
  id: number;
  name: string;
  placements: IPoleComponentItem[];
  value_system_id: number;
  group_id?: number | null;
}

export interface IPoleComponentItem {
  id: number;
  name: string;
  regex_pattern: string | null;
}

export interface IPoleTemplate {
  id: number;
  name: string;
  group_id: number | null;
  items: IPoleTemplateItem[];
}

export interface IPoleTemplateItem {
  id: number;
  component: {
    id: number;
    name: string;
  };
  placement: {
    id: number;
    name: string;
  };
}

export async function getPole(poleID: number) {
  const response = await authorizedGet<IPoleResponse>(`/poles/${poleID}`);
  return response;
}

export async function updatePole(
  poleID: number,
  comment: string | null = null
) {
  await authorizedPut(`/poles/${poleID}`, {
    comment,
  });
}

export async function addBoxToPole(poleID: number, box: IAnnotation) {
  await authorizedPost(`/poles/${poleID}/box`, box);
}

export async function deleteBoxFromPole(poleID: number, boxID: number) {
  await authorizedDelete(`/poles/${poleID}/box/${boxID}`);
}

export async function updateBox(poleID: number, box: IAnnotation) {
  await authorizedPut(`/poles/${poleID}/box/${box.id}`, box);
}

export async function addItemToBox(
  poleID: number,
  boxID: number,
  imageID: number
) {
  await authorizedPost(`/poles/${poleID}/box/${boxID}/item`, {
    imageID,
  });
}

export async function deleteItemFromBox(
  poleID: number,
  boxID: number,
  itemID: number
) {
  await authorizedDelete(`/poles/${poleID}/box/${boxID}/item/${itemID}`);
}

export async function deleteSummaryItem(poleID: number, itemID: number) {
  await authorizedDelete(`/poles/${poleID}/summary/${itemID}`);
}

export async function setSummaryItem(
  poleID: number,
  objectType: number,
  count: number
) {
  await authorizedPut(`/poles/${poleID}/summary`, {
    objectType,
    count,
  });
}

export async function getAvailablePoleComponents(
  projectId?: number,
  group_id?: number
): Promise<IPoleComponent[]> {
  let endpoint = "/poles/component";
  const params = new URLSearchParams();
  if (projectId) {
    params.append("project_id", projectId.toString());
  }
  if (group_id) {
    params.append("group_id", group_id.toString());
  }
  if (params.toString()) {
    endpoint += `?${params.toString()}`;
  }
  try {
    const response = await authorizedGet<{ components: IPoleComponent[] }>(
      endpoint
    );
    return response.components;
  } catch (e) {
    captureException(e, {
      extra: {
        group_id,
        endpoint,
      },
    });
    return [];
  }
}

export async function getPoleTemplates(
  group_id?: number
): Promise<IPoleTemplate[]> {
  let endpoint = "/poles/component/template";
  if (group_id) {
    endpoint += `?group_id=${group_id}`;
  }
  const response = await authorizedGet<{ templates: IPoleTemplate[] }>(
    endpoint
  );
  return response.templates;
}

export async function addPoleTemplate(
  name: string,
  group_id?: number
): Promise<number> {
  const response = await authorizedPost<{ id: number }>(
    "/poles/component/template",
    {
      name,
      group_id,
    }
  );
  return response.id;
}

export async function updatePoleTemplate(
  templateID: number,
  name: string | null = null,
  group_id: number | null = null
) {
  await authorizedPut(`/poles/component/template/${templateID}`, {
    name,
    group_id,
  });
}

export async function deletePoleTemplate(templateID: number) {
  await authorizedDelete(`/poles/component/template/${templateID}`);
}

export async function addPoleTemplateItem(
  templateID: number,
  componentID: number,
  placementID: number
) {
  await authorizedPost(`/poles/component/template/${templateID}/item`, {
    componentID,
    placementID,
  });
}

export async function updatePoleTemplateItem(
  templateID: number,
  itemID: number,
  componentID: number | null = null,
  placementID: number | null = null
) {
  await authorizedPut(
    `/poles/component/template/${templateID}/item/${itemID}`,
    {
      componentID,
      placementID,
    }
  );
}

export async function deletePoleTemplateItem(
  templateID: number,
  itemID: number
) {
  await authorizedDelete(
    `/poles/component/template/${templateID}/item/${itemID}`
  );
}

export async function addPoleComponent(
  name: string,
  gradingSystem: number,
  group_id?: number
): Promise<number> {
  const response = await authorizedPost<{ id: number }>("/poles/component", {
    name,
    poleValueSystemID: gradingSystem,
    group_id,
  });
  return response.id;
}

export async function deletePoleComponent(componentID: number) {
  await authorizedDelete(`/poles/component/${componentID}`);
}

export async function updatePoleComponent(
  componentID: number,
  name: string | null = null,
  valueSystemID: number | null = null,
  group_id: number | null = null
) {
  await authorizedPut(`/poles/component/${componentID}`, {
    name,
    poleValueSystemID: valueSystemID,
    group_id,
  });
}

export async function addPoleComponentPlacement(
  componentID: number,
  name: string,
  projectId: number,
  imageRegexPattern: string | null = null,
  group_id: number | null = null
): Promise<number> {
  const response = await authorizedPost<{ id: number }>(
    `/poles/component/${componentID}/placement`,
    {
      name,
      imageRegexPattern,
      group_id,
      project_id: projectId,
    }
  );
  return response.id;
}

export async function updatePoleComponentPlacement(
  componentID: number,
  placementID: number,
  projectId: number | null = null,
  name: string | null = null,
  regex_pattern: string | null = null,
  group_id: number | null = null
) {
  if (regex_pattern && projectId === null) {
    throw new Error(
      "Project ID is required when updating a placement with a regex pattern"
    );
  }
  await authorizedPut(
    `/poles/component/${componentID}/placement/${placementID}`,
    {
      name,
      regex_pattern,
      group_id,
      project_id: projectId,
    }
  );
}

export async function deletePoleComponentPlacement(
  componentID: number,
  placementID: number
) {
  await authorizedDelete(
    `/poles/component/${componentID}/placement/${placementID}`
  );
}

export interface IPoleStatus {
  id: number;
  name: string;
  placements: IPoleComponentStatusItem[];
  value_system_id: number;
}

export interface IPoleComponentStatusItem {
  id: number;
  name: string;
  regex_pattern: string | null;
  value: {
    id: number;
    name: string;
    color: string;
    weight: number;
  };
  image_id: number | null;
  image_object_id: number | null;
}

export async function getPoleStatus(
  poleID: number,
  projectId: number
): Promise<IPoleStatus[]> {
  const response = await authorizedGet<{ components: IPoleStatus[] }>(
    `/poles/component/status/${poleID}?project_id=${projectId}`
  );
  return response.components;
}

export async function addPoleStatus(
  poleID: number,
  poleComponentID: number,
  poleComponentPlacementID: number,
  valueID: number
) {
  await authorizedPost(`/poles/component/status/${poleID}`, {
    poleComponentID,
    poleComponentPlacementID,
    valueID,
  });
}

export async function deletePoleStatus(
  poleID: number,
  componentID: number,
  placementID: number
) {
  await authorizedDelete(
    `/poles/component/status/${poleID}/${componentID}/${placementID}`
  );
}

export async function updatePoleStatusValue(
  poleID: number,
  componentID: number,
  placementID: number,
  valueID: number,
  imageID: number | null = null,
  imageObjectID: number | null = null
) {
  await authorizedPut(
    `/poles/component/status/${poleID}/${componentID}/${placementID}`,
    {
      valueID,
      imageID,
      imageObjectID,
    }
  );
}

export interface IPoleSystem {
  id: number;
  name: string;
  values: IPoleValue[];
}

export interface IPoleValue {
  id: number;
  name: string;
  color: string;
}

export async function getPoleValues(group_id?: number): Promise<IPoleSystem[]> {
  let endpoint = "/poles/component/status/value";
  if (group_id) {
    endpoint += `?group_id=${group_id}`;
  }
  const response = await authorizedGet<{ values: IPoleSystem[] }>(endpoint);
  return response.values;
}

export interface IPoleItemPlacementImage {
  id: number;
  src: string;
}

export async function getPoleItemPlacementImages(
  poleID: number,
  placementID: number
): Promise<IPoleItemPlacementImage[]> {
  const response = await authorizedGet<{ images: IPoleItemPlacementImage[] }>(
    `/poles/${poleID}/images/placement/${placementID}`
  );
  return response.images;
}

export async function addPoleItemPlacementImage(
  poleID: number,
  placementID: number,
  imageID: number
) {
  const response = await authorizedPost(
    `/poles/${poleID}/images/placement/${placementID}`,
    {
      image_id: imageID,
    }
  );
  return response;
}

export async function deletePoleItemPlacementImage(
  poleID: number,
  placementID: number,
  imageID: number
) {
  const response = await authorizedDelete(
    `/poles/${poleID}/images/placement/${placementID}/image/${imageID}`
  );
  return response;
}
