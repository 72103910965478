import { connect } from "react-redux";
import { Menu } from "./Menu";
import { RootState } from "state/store";
import { onMapClick } from "state/actions";

interface IDispatchProps {
  onMapClick: () => void;
}

interface IStateProps {
  x: number;
  y: number;
  // @ts-ignore
  items;
}

interface IOwnProps {}

const mapStateToProps = (state: any) => ({
  x: state.map.menu.x,
  y: state.map.menu.y,
  items: state.map.menu.items,
});

const mapDispatchToProps = {
  onMapClick,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
