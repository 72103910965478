import { DateSelector } from "components/DateSelector";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import {
  useDateImageCaptureFilter,
  useDateImageUploadedFilter,
  useDateDefectAddedFilter,
  useDateDefectProcessedFilter,
  useDateDefectFixedFilter,
  useDateDefectReportedFilter,
  useLanguage,
} from "hooks";
import translations from "translations";

export default function DateSelectorFilter() {
  const { language } = useLanguage();
  const { dateImageCapture, setDateImageCapture } = useDateImageCaptureFilter();
  const { dateImageUploaded, setDateImageUploaded } =
    useDateImageUploadedFilter();
  const { dateDefectAdded, setDateDefectAdded } = useDateDefectAddedFilter();
  const { dateDefectProcessed, setDateDefectProcessed } =
    useDateDefectProcessedFilter();
  const { dateDefectFixed, setDateDefectFixed } = useDateDefectFixedFilter();
  const { dateDefectReported, setDateDefectReported } =
    useDateDefectReportedFilter();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{translations.Filter.DateFilter[language]}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          <Typography>
            {translations.Filter.DateImageCaptured[language]}
          </Typography>
          <DateSelector
            // @ts-ignore
            value={dateImageCapture}
            onChange={setDateImageCapture}
            isRange
          />
          <Typography>
            {translations.Filter.DateImageUploaded[language]}
          </Typography>
          <DateSelector
            // @ts-ignore
            value={dateImageUploaded}
            onChange={setDateImageUploaded}
            isRange
          />
          <Typography>
            {translations.Filter.DateDefectAdded[language]}
          </Typography>
          <DateSelector
            // @ts-ignore
            value={dateDefectAdded}
            onChange={setDateDefectAdded}
            isRange
          />
          <Typography>
            {translations.Filter.DateDefectProcessed[language]}
          </Typography>
          <DateSelector
            // @ts-ignore
            value={dateDefectProcessed}
            isRange
            onChange={setDateDefectProcessed}
          />
          <Typography>
            {translations.Filter.DateDefectFixed[language]}
          </Typography>
          <DateSelector
            // @ts-ignore
            value={dateDefectFixed}
            onChange={setDateDefectFixed}
            isRange
          />
          <Typography>
            {translations.Filter.DateDefectReported[language]}
          </Typography>
          <DateSelector
            // @ts-ignore
            value={dateDefectReported}
            onChange={setDateDefectReported}
            isRange
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
