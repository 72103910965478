import { captureException } from "@sentry/react";
import {
  IssueCategory,
  IssueSeverity,
  ObjectType,
  WorkflowStatus,
} from "interfaces";
import { toast } from "react-toastify";
import {
  setBlockerModalMessage,
  updateAnnotations,
  updateMarkers,
} from "state/actions";
import {
  IClientObject,
  IObjectMeta,
  IReviewObjectList,
} from "state/reducers/objectreducer";
import { RootState } from "state/store";
import { authorizedGet, authorizedPut, axiosInstance } from "utils/request";

export const setObjectMeta = (value: IObjectMeta) => {
  return {
    type: "SET_OBJECT_META",
    payload: value,
  };
};

export const setReviewLoading = (value: boolean) => {
  return {
    type: "SET_REVIEW_LOADING",
    payload: value,
  };
};
// @ts-ignore
export function updateAnnotationsData(newAnnotation, image) {
  // @ts-ignore
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const mission = state.mission.id;
    const skyqraftHiddenSetting = state.user.skyqraft_hidden;
    const showDsoTso = state.user.show_dso_tso;
    const response = await authorizedPut(`/object/${newAnnotation.id}`, {
      ...newAnnotation,
      customerId: mission,
      imageId: image,
    });
    if (response) {
      dispatch(
        updateAnnotations(mission, image, skyqraftHiddenSetting, showDsoTso)
      );
    }
  };
}

interface AnnotationsTypeUpdate {
  type?: number;
  severity?: number;
  workflow_status?: number;
  skyqraft_hidden?: boolean;
  comment?: string;
}

export function updateAnnotationsTypeData(
  image: number,
  type_id: number,
  type: AnnotationsTypeUpdate,
  onSuccess?: () => void
) {
  // @ts-ignore
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const mission = state.mission.id;
    const skyqraftHiddenSetting = state.user.skyqraft_hidden;
    const showDsoTso = state.user.show_dso_tso;
    try {
      const response = await axiosInstance.put(
        `/object/type/${mission}/${type_id}`,
        {
          ...type,
        }
      );
      if (response) {
        // If onSuccess is set (currently in all review modes) we will handle the state update in the client instead of re-fetching the annotation list. This whole action or onSuccess should be refactored away in the future.
        if (onSuccess) {
          onSuccess();
        } else {
          dispatch(
            updateAnnotations(mission, image, skyqraftHiddenSetting, showDsoTso)
          );
        }
      }
    } catch (error) {
      captureException(error, {
        event_id: "actions.objects.updateAnnotationsTypeData.request",
        originalException: error,
      });
    }
  };
}

export const setAnnotationToolHumanMachine = (value: boolean) => {
  return {
    type: "SET_ANNOTATION_TOOL_HUMAN_MACHINE",
    payload: value,
  };
};

interface AllTypesResponse {
  objectTypes: ObjectType[];
  issueCategories: IssueCategory[];
  issueSeverities: IssueSeverity[];
  workflowStatus: WorkflowStatus[];
  vegetationCategories: IssueCategory[];
}

export function getAllTypes() {
  // @ts-ignore
  return async (dispatch) => {
    try {
      const response =
        await axiosInstance.get<AllTypesResponse>("/object/types");
      dispatch({
        type: "SET_ALL_TYPES",
        payload: response.data,
      });
    } catch (error) {
      captureException(error, {
        event_id: "actions.objects.getAllTypes.request",
        originalException: error,
      });
      dispatch(
        setBlockerModalMessage(
          "Network Error",
          "Failed to get object types for the Arkion application. Without the object types, the application will not be able to function. Please reload the application."
        )
      );
    }
  };
}

export function getObjectTypes() {
  // @ts-ignore
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const isLoggedIn = state.user.loggedIn;
    if (!isLoggedIn) return;
    const response = await authorizedGet<{ types: ObjectType[] }>(
      "/object/types"
    );
    if (response) {
      dispatch({
        type: "SET_OBJECT_TYPES",
        payload: response.types,
      });
    }
  };
}

export function getIssueCategories() {
  // @ts-ignore
  return async (dispatch) => {
    const response = await authorizedGet<{ categories: IssueCategory[] }>(
      "/object/issue_category"
    );
    dispatch({
      type: "SET_ISSUE_CATEGORIES",
      payload: response.categories,
    });
  };
}

export function getDetectedCategories() {
  // @ts-ignore
  return async (dispatch) => {
    const response = await authorizedGet<{ categories: IssueCategory[] }>(
      "/object/detected_category"
    );
    dispatch({
      type: "SET_DETECTED_CATEGORIES",
      payload: response.categories,
    });
  };
}

export function getIssueSeverities() {
  // @ts-ignore
  return async (dispatch) => {
    const response = await authorizedGet<{ severities: IssueSeverity[] }>(
      "/object/types/issue_severity"
    );
    dispatch({
      type: "SET_ISSUE_SEVERITIES",
      payload: response.severities,
    });
  };
}
// @ts-ignore
export function getClientIssueSeverities(missionGroup) {
  // @ts-ignore
  return async (dispatch) => {
    const response = await authorizedGet<{ severities: IssueSeverity[] }>(
      `/object/types/client_issue_severity?group=${missionGroup}`
    );
    if (!!response && !!response.severities) {
      dispatch({
        type: "SET_CLIENT_ISSUE_SEVERITIES",
        payload: response.severities,
      });
    }
  };
}
// @ts-ignore
export function getClientObjects(missionGroup) {
  // @ts-ignore
  return async (dispatch) => {
    const response = await authorizedGet<{ objects: IClientObject[] }>(
      `/object/types/client_objects?group=${missionGroup}`
    );
    if (!!response && !!response.objects) {
      dispatch({
        type: "SET_CLIENT_OBJECTS",
        payload: response.objects,
      });
    }
  };
}

export function getWorkflowStatus() {
  // @ts-ignore
  return async (dispatch) => {
    const response = await authorizedGet<{ workflows: WorkflowStatus[] }>(
      "/object/workflow_status"
    );
    dispatch({
      type: "SET_WORKFLOW_STATUS",
      payload: response.workflows,
    });
  };
}

export function getMLReviewObjects(order_by: string | null = null) {
  // @ts-ignore
  return async (dispatch, getState) => {
    const baseQuery = new URLSearchParams(window.location.search);
    const params: Record<string, string> = {};
    const state: RootState = getState();
    if (order_by) {
      params.order_by = order_by;
    }
    if (baseQuery.has("detection")) {
      // @ts-ignore
      params.detection = baseQuery.get("detection");
    }
    if (baseQuery.has("flagged")) {
      // @ts-ignore
      params.flagged = baseQuery.get("flagged");
    }
    if (baseQuery.has("list")) {
      // @ts-ignore
      params.list = baseQuery.get("list");
    }
    if (baseQuery.has("actors")) {
      // @ts-ignore
      params.actors = baseQuery.get("actors");
    }
    if (baseQuery.has("severity")) {
      // @ts-ignore
      params.severity = baseQuery.get("severity");
    }
    if (baseQuery.has("feedBay")) {
      // @ts-ignore
      params.feed_bay = baseQuery.get("feedBay");
    }
    if (baseQuery.has("area")) {
      // @ts-ignore
      params.area = baseQuery.get("area");
    }

    const projectID = state.mission?.id ?? -1;

    try {
      const { data } = await axiosInstance.get<IReviewObjectList>(
        "/review/machine_learning",
        {
          headers: { MissionID: projectID.toString() },
          params,
        }
      );
      dispatch({
        type: "SET_ML_REVIEW_OBJECTS",
        payload: data,
      });
    } catch (error) {
      // @ts-ignore
      if (error.response?.status === 404) {
        toast.error("No (more) matches found");
      } else {
        toast.error("Failed to get ML review objects.");
        captureException(error, {
          event_id: "actions.objects.getMLReviewObjects.request",
          originalException: error,
        });
      }

      dispatch({
        type: "SET_ML_REVIEW_OBJECTS",
        payload: {
          imageIds: [],
        },
      });
    }
  };
}

export type MachineReviewActions =
  | "request"
  | "approve"
  | "approve-hide"
  | "super-deny"
  | "deny"
  | "supervisor-approve";

export function setMLReview(
  image: number,
  regionID: number,
  regionType: number,
  action: MachineReviewActions,
  severity_id = null,
  fetchNewData = true
) {
  // @ts-ignore
  return async (dispatch, getState) => {
    dispatch(setReviewLoading(true));
    const APPROVED_ACTIONS = [
      "request",
      "approve",
      "approve-hide",
      "super-deny",
      "deny",
      "supervisor-approve",
    ];

    // Assert the action is approved
    if (!APPROVED_ACTIONS.includes(action)) {
      console.error("Status review must be one of following", APPROVED_ACTIONS);
      return;
    }
    // Send the status update
    const response = await authorizedPut(
      `/object/${regionID}/review/${action}`,
      {
        type: regionType,
        severity: severity_id,
      }
    );
    if (response && fetchNewData) {
      dispatch(updateMarkers());
      const state: RootState = getState();
      const projectID = state.mission?.id ?? -1;
      const { skyqraft_hidden, show_dso_tso } = state.user;
      dispatch(
        updateAnnotations(projectID, image, skyqraft_hidden, show_dso_tso)
      );
    }
    dispatch(setReviewLoading(false));
  };
}

export function approveSupervisorObjects(
  image: number,
  project: number, // @ts-ignore
  callback: () => void = null
) {
  // @ts-ignore
  return async (dispatch, getState) => {
    dispatch(setReviewLoading(true));
    const state: RootState = getState();
    const type_ids = state.image.filteredAnnotations.flatMap(
      (annotation) => annotation.type_id
    );
    const response = await axiosInstance.post(
      "/review/supervisor",
      {
        type_ids: type_ids,
        image: image,
        supervisor_approved: true,
      },
      {
        headers: {
          MissionID: project,
        },
      }
    );

    if (response) {
      callback?.();
    }
    dispatch(setReviewLoading(false));
  };
}

export function getSupervisorReviewObjects() {
  // @ts-ignore
  return async (dispatch, getState) => {
    const baseQuery = new URLSearchParams(window.location.search);
    const params: Record<string, string> = {};
    const state: RootState = getState();
    if (baseQuery.has("detection")) {
      // @ts-ignore
      params.detection = baseQuery.get("detection");
    }
    if (baseQuery.has("flagged")) {
      // @ts-ignore
      params.flagged = baseQuery.get("flagged");
    }
    if (baseQuery.has("list")) {
      // @ts-ignore
      params.list = baseQuery.get("list");
    }
    if (baseQuery.has("actors")) {
      // @ts-ignore
      params.actors = baseQuery.get("actors");
    }
    if (baseQuery.has("severity")) {
      // @ts-ignore
      params.severity = baseQuery.get("severity");
    }
    if (baseQuery.has("feedBay")) {
      // @ts-ignore
      params.feed_bay = baseQuery.get("feedBay");
    }
    if (baseQuery.has("workflow")) {
      // @ts-ignore
      params.workflow_status = baseQuery.get("workflow");
    }

    const projectID = state.mission?.id ?? -1;

    try {
      const { data } = await axiosInstance.get<IReviewObjectList>(
        "/review/supervisor",
        {
          headers: { MissionID: projectID.toString() },
          params,
        }
      );
      dispatch({
        type: "SET_SUPERVISOR_REVIEW_OBJECTS",
        payload: data,
      });
    } catch (error) {
      // @ts-ignore
      if (error.response?.status === 404) {
        toast.error("No (more) matches found");
      } else {
        toast.error("Failed to get Supervisor review objects.");
        captureException(error, {
          event_id: "actions.objects.getSupervisorReviewObjects.request",
          originalException: error,
        });
      }

      dispatch({
        type: "SET_SUPERVISOR_REVIEW_OBJECTS",
        payload: {
          imageIds: [],
        },
      });
    }
  };
}
