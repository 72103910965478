import { Link as LinkIcon } from "@mui/icons-material";
import { Divider, Stack, Typography, Button } from "@mui/material";
import { ISteelwork } from "api/image/annotation";
import { useCurrentProject } from "hooks";
import { useState } from "react";
import { v4 as uuid4 } from "uuid";
import { EventHint, captureException } from "@sentry/react";
import { toast } from "react-toastify";

type Props = {
  steelwork: ISteelwork;
};

export default function SteelworkInfo({ steelwork }: Props) {
  const [tabs, setTabs] = useState<{ [key: string]: Window | null }>({});
  const currentProject = useCurrentProject();

  const {
    item_id,
    direction,
    second_direction,
    steel_grading,
    bolt_condition,
    child_link,
    parent_link,
  } = steelwork;

  const openLinkInNewTab = (url: string) => {
    let otherTabName = null;

    if (!window.name.includes("tab-id")) {
      // If we do our first click in the initial tab
      const uuid = uuid4();
      window.name = `tab-id-${uuid}-start`;
      otherTabName = `tab-id-${uuid}-target`;
    } else if (window.name.includes("-start")) {
      // If we do our second click in the initial tab
      otherTabName = window.name.replace("-start", "-target");
    } else if (window.name.includes("-target")) {
      // If we click in our target tab
      otherTabName = window.name.replace("-target", "-start");
    } else {
      // If we have an invalid window name
      const error = new Error("Invalid window name");
      const exceptionHint: EventHint = {
        event_id: "steelworkInfo.openLinkInNewTab",
        originalException: error,
        data: {
          windowName: window.name,
          otherTabName: otherTabName,
        },
      };
      captureException(error, exceptionHint);
      toast.error(
        "An error occurred while opening the link in a new tab. Please try again."
      );
      return;
    }

    window.open(url, otherTabName);
  };

  return (
    <Stack direction="column" spacing={1} alignItems="start">
      {item_id && (
        <Typography fontSize="small">{`Item ID: ${item_id}`}</Typography>
      )}
      {direction?.name && (
        <Typography fontSize="small">
          {`Direction: ${direction.name}`}
        </Typography>
      )}
      {second_direction?.name && (
        <Typography fontSize="small">
          {`Second Direction: ${second_direction.name}`}
        </Typography>
      )}
      {steel_grading?.name && (
        <Typography fontSize="small">
          {`Steel Grading: ${steel_grading.name}`}
        </Typography>
      )}
      {bolt_condition?.name && (
        <Typography fontSize="small">
          {`Bolt Condition: ${bolt_condition.name}`}
        </Typography>
      )}
      <Divider />
      {parent_link?.image_id && (
        <Button
          size="small"
          variant="outlined"
          sx={{ backgroundColor: "white", justifyContent: "start" }}
          fullWidth
          startIcon={<LinkIcon fontSize="small" />}
          onClick={() => {
            const url = new URL(
              // @ts-ignore
              `${location.origin}/${currentProject.id}/${parent_link.image_id}${location.search}`
            );
            url.searchParams.set(
              "boxIds",
              parent_link.image_object_id.toString()
            );
            const parentLinkUrl = url.toString();
            openLinkInNewTab(parentLinkUrl);
          }}
        >
          <Typography variant="body1" fontSize="small">
            This is the second angle. Click to go to primary angle
          </Typography>
        </Button>
      )}
      {child_link?.image_id && (
        <Button
          size="small"
          variant="outlined"
          sx={{ backgroundColor: "white", justifyContent: "start" }}
          fullWidth
          startIcon={<LinkIcon fontSize="small" />}
          onClick={() => {
            const url = new URL(
              // @ts-ignore
              `${location.origin}/${currentProject.id}/${child_link.image_id}${location.search}`
            );
            url.searchParams.set(
              "boxIds",
              child_link.image_object_id.toString()
            );
            const childLinkUrl = url.toString();
            openLinkInNewTab(childLinkUrl);
          }}
        >
          <Typography variant="body1" fontSize="small">
            This is the primary angle. Click to go to second angle
          </Typography>
        </Button>
      )}
    </Stack>
  );
}
