import React, { useState, ReactElement, useEffect } from "react";
import { CircularProgress, Paper, Tabs, Tab } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LogList from "./LogList";
import { authorizedGet } from "utils/request";
import translations from "translations";
import { useParams, Outlet } from "react-router-dom";
import "./styles.css";
import { useLanguage } from "hooks";

interface Props {
  jobs: Array<any>;
  subFolders: Array<any>;
  // @ts-ignore
  setJobs: (value) => void;
  // @ts-ignore
  setSubFolders: (value) => void;
  disabled?: boolean;
}

export default function FolderView({
  jobs,
  subFolders,
  setJobs,
  setSubFolders,
  disabled,
}: Props): ReactElement {
  const { language } = useLanguage();
  const params = useParams();
  const mission = params.mission;
  const folder = params.folder;
  const [loading, setLoadingStatus] = useState(true);
  // @ts-ignore
  const getJobs = async (folder) => {
    // @ts-ignore
    authorizedGet<{ jobs; folders }>(`/upload/jobs?folder=${folder}`).then(
      (data) => {
        setJobs(data.jobs);
        setSubFolders(data.folders);
        setLoadingStatus(false);
      }
    );
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getJobs(folder);

    const interval = setInterval(() => getJobs(folder), 5000);

    return function cleanup() {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mission, folder]);

  return (
    <Paper sx={{ p: 2 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginBottom: 20,
          alignItems: "center",
          overflowY: "scroll",
        }}
      >
        <Typography sx={{ color: !!disabled ? "#A0A0A0" : undefined }}>
          {translations.Menu.Tools.ImageUpload.Import.FoldersFound[language]}:
        </Typography>
        {subFolders
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((folder) => {
            return (
              <div
                key={folder.name}
                style={{
                  padding: 10,
                  margin: 10,
                  border: "1px solid black",
                  borderRadius: 5,
                  width: "fit-content",
                  flexWrap: "wrap",
                  textAlign: "center",
                }}
              >
                {folder.name.split("/")[2]}
                <br />
                {translations.Menu.Tools.ImageUpload.Import.Images[language]}:{" "}
                <b>{folder.count}</b>
              </div>
            );
          })}
      </div>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
        }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="All" disabled={!!disabled} />
          <Tab label="Running" disabled={!!disabled} />
          <Tab label="Finished" disabled={!!disabled} />
          <Tab label="Failed" disabled={!!disabled} />
          <Tab label="Aborted" disabled={!!disabled} />
        </Tabs>
        {!!!disabled && (
          <>
            <TabPanel value={value} index={0}>
              {loading ? (
                <>
                  {" "}
                  <br />
                  <CircularProgress />
                </>
              ) : (
                <LogList jobs={jobs} />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {loading ? (
                <>
                  {" "}
                  <br />
                  <CircularProgress />
                </>
              ) : (
                <LogList jobs={jobs.filter((j) => j.running)} />
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {loading ? (
                <>
                  {" "}
                  <br />
                  <CircularProgress />
                </>
              ) : (
                <LogList
                  jobs={jobs.filter(
                    (j) => j.successful && !j.aborted && !j.running
                  )}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={3}>
              {loading ? (
                <>
                  {" "}
                  <br />
                  <CircularProgress />
                </>
              ) : (
                <LogList
                  jobs={jobs.filter(
                    (j) => !j.successful && !j.aborted && !j.running
                  )}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={4}>
              {loading ? (
                <>
                  {" "}
                  <br />
                  <CircularProgress />
                </>
              ) : (
                <LogList jobs={jobs.filter((j) => !j.running && j.aborted)} />
              )}
            </TabPanel>
          </>
        )}
      </Box>

      <Outlet />
    </Paper>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flex: 1 }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
