import { Checkbox, FormControlLabel, Grid } from "@mui/material";

type Props = {
  label: string;
  disabled: boolean;
  checked: boolean;
  onChange: (newValue: boolean) => void;
  willDelete: boolean;
  willAdd: boolean;
  dataTestId: string;
};

export default function GroupAccessCheckbox({
  label,
  disabled,
  checked,
  onChange,
  willDelete,
  willAdd,
  dataTestId,
}: Props) {
  return (
    <Grid item xs={12} sm={6} md={4} xl={3} data-testid={dataTestId}>
      <FormControlLabel
        control={<Checkbox data-testid={`${dataTestId}-checkbox`} />}
        label={label}
        disabled={disabled}
        checked={checked}
        onChange={() => {
          onChange(!checked);
        }}
        sx={{
          textDecoration: willDelete ? "line-through" : "none",
        }}
        componentsProps={{
          typography: {
            sx: {
              fontWeight: willAdd ? "bold" : "normal",
            },
          },
        }}
      />
    </Grid>
  );
}
