import React, { useState } from "react";
import { Outlet, useOutletContext, useParams, Link } from "react-router-dom";
import { Divider, Paper, Stack, TextField, Typography } from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useGroup } from "hooks";
import Loading from "views/Loading";
import { Add, Search } from "@mui/icons-material";
import groupBy from "lodash/groupBy";

export default function ProjectGroupPage() {
  const params = useParams();
  const context = useOutletContext<{ updateProjects: Function }>();
  // @ts-ignore
  const currentProjectID = parseInt(params.project);
  // @ts-ignore
  const currentGroupID = parseInt(params.group);
  const [group, updateGroup, loading] = useGroup(currentGroupID ?? null);
  const [searchValue, setSearchValue] = useState<string>("");

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" height="50%">
        <Loading relative size={40} />
        <Typography>Loading </Typography>
      </Stack>
    );
  }

  function isIn(source: string, test: string): boolean {
    const string = source.replace(/[^a-zA-Z0-9@. ]/g, "");
    return string.toLowerCase().includes(test.toLowerCase());
  }

  function textMatches(textA: string, textB: string) {
    return isIn(
      textA.toLowerCase().replace(/[^\w!?]/g, ""),
      textB.toLowerCase().replace(/[^\w!?]/g, "")
    );
  }

  function filterEntity(entity: {
    id: number;
    name: string;
    region?: { name: string };
  }) {
    const textMatch =
      textMatches(JSON.stringify(entity.id), searchValue) ||
      (!!entity.region &&
        textMatches(JSON.stringify(entity.region?.name), searchValue)) ||
      textMatches(entity.name, searchValue);
    return textMatch;
  }
  // @ts-ignore
  let projects = [];
  if (!!group) {
    projects = group.projects;
  }
  // @ts-ignore
  const filteredProjects = projects.filter(filterEntity);
  const regions = groupBy(filteredProjects, (p) => p?.region?.name);

  return (
    <RoleWrapper keyName="projectAdmin">
      {!!!group ? (
        <Typography>Group not found</Typography>
      ) : (
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 0, md: 2 }}
          marginTop={5}
          sx={{ height: "100%" }}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack spacing={0}>
            <Link
              to={`/order`}
              replace
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                spacing={2}
                py={2}
              >
                <Add />
                <Typography>Create Project</Typography>
              </Stack>
            </Link>
            {!!currentGroupID && (
              <Link
                to={`/group/${currentGroupID}/region/new`}
                replace
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Stack
                  width="100%"
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  px={2}
                  pb={2}
                >
                  <Add />
                  <Typography>Create Region</Typography>
                </Stack>
              </Link>
            )}
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ px: 2 }}
            >
              <Search sx={{ my: 0 }} />
              <TextField
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                placeholder="Search for a project"
                variant="standard"
                sx={{ flex: 1 }}
              />
            </Stack>
            <Stack
              maxWidth={"300px"}
              sx={{ height: "100%", overflowY: "auto" }}
            >
              {group.regions
                .sort((a, b) => (a.name < b.name ? -1 : 1))
                .map(
                  (region, i) =>
                    (!!regions[region.name]?.length || !!!searchValue) && (
                      <React.Fragment key={i}>
                        <Link
                          to={`region/${region.id}`}
                          replace
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Divider
                            sx={{
                              my: 2,
                              "&::before": {
                                top: 0,
                              },
                              "&::after": {
                                top: 0,
                              },
                            }}
                          >
                            {region.name}
                          </Divider>
                        </Link>
                        <Stack spacing={1}>
                          {regions[region.name]?.length > 0 ? (
                            regions[region.name]
                              ?.sort((a, b) => (a.name < b.name ? -1 : 1))
                              ?.map((project) => (
                                <Link
                                  key={project.id}
                                  to={`project/${project.id}`}
                                  replace
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <Paper
                                    sx={{
                                      p: 2,
                                      bgcolor:
                                        project.id === currentProjectID
                                          ? "rgb(214,231,255)"
                                          : "white",
                                    }}
                                  >
                                    <Typography>{project.name}</Typography>
                                    <Typography>{`ID : ${project.id}`}</Typography>
                                  </Paper>
                                </Link>
                              ))
                          ) : (
                            <Stack pl={2}>
                              <Typography>No projects</Typography>
                            </Stack>
                          )}
                        </Stack>
                      </React.Fragment>
                    )
                )}
              {!!regions["undefined"]?.length && (
                <>
                  <Divider
                    sx={{
                      my: 2,
                      "&::before": {
                        top: 0,
                      },
                      "&::after": {
                        top: 0,
                      },
                    }}
                  >
                    No region
                  </Divider>
                  <Stack spacing={1}>
                    {regions["undefined"]
                      ?.sort((a, b) => (a.name < b.name ? -1 : 1))
                      ?.map((project) => (
                        <Link
                          key={project.id}
                          to={`project/${project.id}`}
                          replace
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Paper
                            sx={{
                              p: 2,
                              bgcolor: "white",
                              mt: 2,
                            }}
                          >
                            <Typography>{project.name}</Typography>
                            <Typography>{`ID : ${project.id}`}</Typography>
                          </Paper>
                        </Link>
                      ))}
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
          <Stack flex={1} maxWidth={"1600px"}>
            <Divider
              sx={{
                my: 2,
                "&::before": {
                  top: 0,
                },
                "&::after": {
                  top: 0,
                },
              }}
            >
              Project
            </Divider>
            <Outlet
              context={{
                ...context,
                updateGroup,
                regions: group.regions,
                projects: group.projects,
              }}
            />
          </Stack>
        </Stack>
      )}
    </RoleWrapper>
  );
}
