import { EventHint, captureException, setExtra, setUser } from "@sentry/react";
import { assignmentStatus } from "api";
import { IUserSettings, getUserSettings } from "api/user/userSettings";
import { AccessLevel, Mission, OtherUser } from "interfaces";
import { toast } from "react-toastify";
import { IGroup, getGroup, getGroups, getMissionUsers } from "state/actions";
import {
  authorizedGet,
  authorizedPost,
  authorizedPut,
  axiosInstance,
} from "utils/request";
import { validateEmail } from "utils/utils";
// @ts-ignore
export const setAnnotator = (value, test = false) => {
  if (test) {
    return {
      type: "SET_ANNOTATOR_TEST",
      payload: value,
    };
  } else {
    return {
      type: "SET_ANNOTATOR",
      payload: value,
    };
  }
};
// @ts-ignore
export const setLanguage = (value) => {
  setExtra("language", value);
  return {
    type: "SET_LANGUAGE",
    payload: value,
  };
};
// @ts-ignore
export const changeLanguage = (language) => {
  // @ts-ignore
  return (dispatch) => {
    const body = {
      updateSettings: true,
      language,
    };
    authorizedPost("/user/settings", body).then(() => {
      setExtra("language", language);
      return dispatch({
        type: "SET_LANGUAGE",
        payload: language,
      });
    });
  };
};
// @ts-ignore
export const changeClientNaming = (client_naming) => {
  // @ts-ignore
  return (dispatch) => {
    const body = {
      updateSettings: true,
      client_naming,
    };
    authorizedPost("/user/settings/client_naming", body).then(() => {
      return dispatch({
        type: "SET_CLIENT_NAMING",
        payload: client_naming,
      });
    });
  };
};
// @ts-ignore
export const changeAnnotatorColor = (annotator_color) => {
  // @ts-ignore
  return async (dispatch) => {
    const body = {
      updateSettings: true,
      annotator_color,
    };
    const response = await authorizedPost("/user/settings/color", body);

    if (response) {
      return dispatch({
        type: "SET_ANNOTATOR_COLOR",
        payload: annotator_color,
      });
    }
  };
};
// @ts-ignore
export const setAdmin = (value, test = false) => {
  if (test) {
    return {
      type: "SET_ADMIN_TEST",
      payload: value,
    };
  } else {
    return {
      type: "SET_ADMIN",
      payload: value,
    };
  }
};
// @ts-ignore
export const setReadOnly = (value, test = false) => {
  if (test) {
    return {
      type: "SET_READ_ONLY_TEST",
      payload: value,
    };
  } else {
    return {
      type: "SET_READ_ONLY",
      payload: value,
    };
  }
};
// @ts-ignore
export const setAccessToken = (token) => {
  return {
    type: "SET_ACCESS_TOKEN",
    payload: token,
  };
};

export const setName = (name: string) => {
  return {
    type: "SET_NAME",
    payload: name,
  };
};

export const setPhoneNumber = (phoneNumber: string) => {
  return {
    type: "SET_PHONE_NUMBER",
    payload: phoneNumber,
  };
};

export const resetToRealPermissions = () => {
  return {
    type: "RESET_TO_REAL_PERMISSIONS",
  };
};
export const overrideAccessLevel = (accessLevel: AccessLevel) => {
  return {
    type: "OVERRIDE_ACCESS_LEVEL",
    payload: accessLevel,
  };
};
// @ts-ignore
export function loadSettings(callback: (settings) => void) {
  // @ts-ignore
  return async (dispatch) => {
    let settings: null | Awaited<ReturnType<typeof getUserSettings>> = null;
    try {
      settings = await getUserSettings();
    } catch (error) {
      const exceptionHint: EventHint = {
        event_id: "actions.user.loadSettings.request",
        originalException: error,
      };
      captureException(error, exceptionHint);
    }

    if (!settings) {
      return;
    }

    // Inform sentry
    setUser({
      email: settings.email,
      id: settings.email,
      username: settings.email,
    });
    setExtra("arkion_employee", settings.skyqraft_employee);
    setExtra("superuser", settings.superuser);
    setExtra("language", settings.language);

    // Populate redux state
    dispatch({
      type: "SET_SETTINGS",
      payload: settings,
    });
    callback(settings);
    dispatch(getGroups());
  };
}

export function updateUserMissionPermissions(
  mission: number,
  email: string,
  permissionLevel: string,
  callback: null | (() => void) = null,
  onError: null | ((message: string) => void) = null
) {
  // @ts-ignore
  return async (dispatch) => {
    try {
      const response = await authorizedPut<{
        missions: Mission[];
        groups: IGroup[];
      }>(`/user/${email}/access/mission`, {
        mission: mission,
        level: permissionLevel,
      });
      dispatch(getMissionUsers(mission));

      if (response) {
        callback?.();
      } else {
        onError?.("Failed to update mission");
      }
    } catch (err) {
      onError?.("Failed to update mission");
    }
  };
}

export function updateUserGroupPermissions(
  group_id: number,
  email: string,
  permissionLevel: AccessLevel,
  callback: null | (() => void) = null,
  onError: null | ((message: string) => void) = null
) {
  // @ts-ignore
  return async (dispatch) => {
    try {
      const response = await authorizedPut<{
        missions: Mission[];
        groups: IGroup[];
      }>(`/user/${email}/access/group`, {
        group: group_id,
        level: permissionLevel,
      });

      dispatch(getGroup(group_id));
      if (response) {
        callback?.();
      } else {
        onError?.("Failed to update group");
      }
    } catch (err) {
      onError?.("Failed to update group");
    }
  };
}

export function addUser(
  name: string,
  email: string,
  callback: null | (() => void) = null,
  onError: null | ((message: string) => void) = null
) {
  // @ts-ignore
  return async (dispatch) => {
    try {
      // Skip if email isn't valid
      if (!validateEmail(email.toLowerCase())) {
        onError?.("Email is not valid");
        console.error("Email is not valid");
        return;
      }

      await authorizedPost("/user/admin/adduser", {
        email: email.toLowerCase(),
        name,
      });
      dispatch(getUsers());
      callback?.();
    } catch (err) {
      onError?.("Something went wrong");
    }
  };
}

export function getUsers(
  callback: null | (() => void) = null,
  onError: null | (() => void) = null
) {
  // @ts-ignore
  return async (dispatch) => {
    try {
      const response = await authorizedGet<{ users: OtherUser[] }>(
        "/user/users"
      );
      dispatch({
        type: "SET_USERS",
        payload: response.users,
      });
      !!callback && callback();
    } catch (err) {
      !!onError && onError();
    }
  };
}

export function getAssignmentStatus(
  callback: null | (() => void) = null,
  onError: null | (() => void) = null
) {
  // @ts-ignore
  return async (dispatch) => {
    try {
      const assignments = await assignmentStatus.get({ supervisor: false });
      dispatch(setAssignmentsStatus(assignments));
      callback?.();
    } catch (err) {
      onError?.();
    }
  };
}

type IAssignmentStatus = Awaited<
  ReturnType<typeof assignmentStatus.get>
>[number];

export function setAssignmentsStatus(status: IAssignmentStatus[]) {
  return {
    type: "SET_ASSIGNMENTS_STATUS",
    payload: status,
  };
}
// @ts-ignore
export function resetUser(callback: () => void = null) {
  // @ts-ignore
  return (dispatch) => {
    dispatch({
      type: "RESET_USER",
    });
    callback?.();
  };
}

export function setSkyqraftHiddenSetting(
  setting: IUserSettings["skyqraft_hidden"]
) {
  // @ts-ignore
  return async (dispatch) => {
    let updated = false;
    try {
      await axiosInstance.put("/user/settings/skyqraft_hidden", {
        skyqraft_hidden: setting,
      });
      updated = true;
    } catch (error) {
      const exceptionHint: EventHint = {
        event_id: "actions.user.setSkyqraftHiddenSetting.request",
        originalException: error,
      };
      captureException(error, exceptionHint);
    }
    if (!updated) {
      toast.error("Failed to update arkion hidden setting");
      return;
    }
    dispatch({
      type: "SET_SKYQRAFT_HIDDEN",
      payload: setting,
    });
  };
}

export function setAutoSpeedZoomInReviewModes(setting: boolean) {
  // @ts-ignore
  return async (dispatch) => {
    await axiosInstance
      .put("/user/settings/auto_speed_zoom_in_review_modes", {
        auto_speed_zoom_in_review_modes: setting,
      })
      .then(() => {
        dispatch({
          type: "SET_AUTO_SPEED_ZOOM_IN_REVIEW_MODES",
          payload: setting,
        });
      })
      .catch((error) => {
        const exceptionHint: EventHint = {
          event_id: "actions.user.setAutoSpeedZoomInReviewModes.request",
          originalException: error,
        };
        captureException(error, exceptionHint);
      });
  };
}

export function setShowDsoTso(setting: boolean) {
  // @ts-ignore
  return async (dispatch) => {
    await axiosInstance
      .put("/user/settings/show_dso_tso", {
        show_dso_tso: setting,
      })
      .then(() => {
        dispatch({
          type: "SET_SHOW_DSO_TSO",
          payload: setting,
        });
      })
      .catch((error) => {
        const exceptionHint: EventHint = {
          event_id: "actions.user.setShowDsoTso.request",
          originalException: error,
        };
        captureException(error, exceptionHint);
      });
  };
}
