import { axiosInstance } from "utils/request";

interface ISeverityCount {
  id: number;
  count: number;
}

export async function getSeverityCount(
  projectID: number,
  flightIDs: number[] = [],
  workflow_statuses: number[] = [],
  detections: number[] = [],
  area: string = ""
) {
  const headers: Record<string, string> = {
    MissionID: `${projectID}`,
  };

  // Populate the search parameters in a clean way
  const params = new URLSearchParams();
  if (flightIDs.length > 0) {
    params.append("flight", flightIDs.join(","));
  }

  if (workflow_statuses.length > 0) {
    params.append("workflow", workflow_statuses.join(","));
  }

  if (detections.length > 0) {
    params.append("detection", detections.join(","));
  }

  if (area.length > 0) {
    params.append("area", area);
  }

  // Make the request
  const response = await axiosInstance.get<{ count: ISeverityCount[] }>(
    "/object/filter/count/defect_severity",
    {
      headers,
      params,
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch data");
  }
  const count = response.data.count;
  return count;
}
