import { ImageMeta, PreviousDefectImages } from "interfaces";
import { connect } from "react-redux";
import {
  addOpenedMarker,
  clearImageMeta,
  resetThermalData,
  setInitialURL,
  setSelectedPreviousDefectImage,
} from "state/actions";
import { RootState } from "state/store";
import Component from "./ImageViewer";
interface IDispatchProps {
  clearImageMeta: () => void;
  resetThermalData: () => void;
  addOpenedMarker: (marker_id: number) => void;
  setInitialURL: (value: string) => void;
  setSelectedPreviousDefectImage: (image: PreviousDefectImages | null) => void;
}

interface IStateProps {
  gmap: google.maps.Map;
  imageMeta: ImageMeta;
  initialURL: string;
  selectedPreviousDefectImage: PreviousDefectImages | null;
}

interface IOwnProps {}

const mapStateToProps = (state: any, ownProps: IOwnProps) => {
  return {
    gmap: state.map.gmap,
    imageMeta: state.image.current,
    initialURL: state.navigation.initialURL,
    selectedPreviousDefectImage: state.imageViewer.selectedPreviousDefectImage,
  };
};

const mapDispatchToProps = {
  clearImageMeta,
  resetThermalData,
  addOpenedMarker,
  setInitialURL,
  setSelectedPreviousDefectImage,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
