import { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Paper,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import { QuestionMark, ExpandMore } from "@mui/icons-material";
import SelectFolder from "./SelectFolder";
import { useSelector, useNavigationBlocker, useLanguage } from "hooks";
import translations from "./translations";

export default function Image() {
  const uploadingStatus = useSelector((state) => state.imageUpload.status);
  const { language } = useLanguage();

  useNavigationBlocker({
    enableBlocker: uploadingStatus === "running",
    confirmationMessage: translations.UploadInProgressWarning[language],
  });

  const [showInfo, setShowInfo] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Paper sx={{ mb: 2 }}>
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography variant="inherit">Upload images</Typography>
            <IconButton sx={{ ml: 2 }} onClick={() => setShowInfo(!showInfo)}>
              <QuestionMark />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography>
            You can have two approaches to uploading images to your mission.
            These are:
          </Typography>
          <ol>
            <li>
              Upload according to the instructions. The instructions can be
              found by clicking the question mark above.
            </li>
            <li>Just drop your images in a folder.</li>
          </ol>
          <Typography>
            Both methods will work, the closer you follow the instructions, the
            better control you will have over your data. Don't worry. Everything
            will still work just fine as long as each image contains GPS data.
          </Typography>
        </DialogContent>
      </Paper>
      <SelectFolder />
      <Outlet />
      <Dialog
        maxWidth="lg"
        fullWidth
        open={showInfo}
        keepMounted
        onClose={() => setShowInfo(!showInfo)}
        PaperProps={{ sx: { backgroundColor: "#E7E7E7" } }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Upload Instructions</DialogTitle>
        <DialogContent>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Folders</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom>
                When you want to upload images, you must first select the folder
                to which you will upload. This is typically the date the images
                were taken.
              </Typography>
              <Typography>
                You can select any name of the folder. The name of the folder
                has no significance, and are mainly there to help you organize
                your work.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Selecting images</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom>
                When selecting images to upload, you can either drag and drop or
                open the file explorer and select the images manually.
              </Typography>
              <Typography gutterBottom>
                Selecting images manually will result in the upload of
                uncategorized images. Uploading this way has virtually no impact
                on the end results. If you want to categorize the images when
                uploading, you will have to drag and drop folders to the web
                application.
              </Typography>
              <Typography>
                The drag and drop functionality is very useful when you want to
                upload large batches of images and have them be categorized in
                the data.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Metadata</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The metadata of each image is the second most valuable component
                when uploading. The metadata we are looking at taken from the
                drone and camera. The analyzed metadata includes but are not
                limited to:
              </Typography>

              <ul>
                <li>Latitude</li>
                <li>Longitude</li>
                <li>Altitude</li>
                <li>Pitch</li>
                <li>Yaw</li>
              </ul>
              <Typography>
                This data is used to make the user experience as seamless as
                possible.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Subfolders</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Each subfolder while uploading will be assigned its own flight
                id. You will later be able to filter for images that belongs to
                a certain flight.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Jobs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom>
                When an upload is complete, a notification will be sent to our
                systems that the upload is completed. This will trigger our AI
                to analyze the uploaded data.
              </Typography>
              <Typography>
                We will be able to monitor this analyzation process by
                navigating to the job running below.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Thermal</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom>
                If you are taking images with a thermal camera to analyze the
                electrical equipment, then you do not need to do any
                pre-processing of these images. The image itself will be
                viewable just like any other image.
              </Typography>
              <Typography gutterBottom>
                If the thermal image was captured using a DJI drone, then we
                will be able to extract the thermal temperatures from the image
                and give you a full insight into the temperature scaled on the
                image.
              </Typography>
              <Typography>
                If the thermal images are not from a DJI drone, please let us
                know and we will try to add support for the images you are
                trying to upload.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInfo(!showInfo)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
