import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useCurrentProject } from "hooks";

interface IProps {
  imageIds: number[];
  currentImage: number;
}
interface IUseImageNavigation {
  currentIndex: number;
  navigateToNextImage: () => void;
  navigateToPrevImage: () => void;
  goToImage: (image: number) => void;
  goToImageByIndex: (index: number) => void;
}
export default function useImageNavigation({
  imageIds,
  currentImage,
}: IProps): IUseImageNavigation {
  const navigate = useNavigate();
  const project = useCurrentProject();

  const goToImage = (image: number) => {
    navigate(
      // @ts-ignore
      `/${project.id}/${image}/${window.location.pathname.split("/").at(-1)}${
        window.location.search
      }`
    );
  };
  const goToImageByIndex = (index: number) => {
    const imageIdAtIndex = imageIds[index];

    if (imageIdAtIndex) {
      goToImage(imageIdAtIndex);
    }
  };

  const currentIndex = imageIds.findIndex((image) => image === currentImage);

  const navigateToNextImage = () => {
    if (!imageIds[currentIndex + 1]) {
      goToImage(imageIds[0]);
    } else {
      goToImage(imageIds[currentIndex + 1]);
    }
  };
  const navigateToPrevImage = () => {
    if (!imageIds[currentIndex - 1]) {
      goToImage(imageIds[imageIds.length - 1]);
    } else {
      goToImage(imageIds[currentIndex - 1]);
    }
  };

  useEffect(() => {
    if (currentIndex === -1 && imageIds.length) {
      goToImage(imageIds[0]);
    }
  }, [currentIndex, imageIds]);

  return {
    currentIndex,
    navigateToNextImage,
    navigateToPrevImage,
    goToImage,
    goToImageByIndex,
  };
}
