import { authorizedGet } from "utils/request";
import { IClearanceSystem } from "interfaces";
import { RootState } from "state/reducers";

export function getClearanceSystems(projectID: number) {
  // @ts-ignore
  return async (dispatch, getState) => {
    dispatch(setClearanceSystemsLoading(true));
    const state: RootState = getState();
    const projects = state.user.missions;
    const project = projects.find((p) => p.id === projectID);
    if (!project) {
      return;
    }
    const response = await authorizedGet<{ systems: IClearanceSystem[] }>(
      "/lidar/clearance/system",
      { MissionID: projectID }
    );
    if (response) {
      dispatch(setClearanceSystems(response.systems));
      dispatch(setClearanceSystemsLoading(false));
    } else {
      dispatch(setClearanceSystems([]));
      dispatch(setClearanceSystemsLoading(false));
    }
  };
}

function setClearanceSystems(systems: IClearanceSystem[]) {
  return {
    type: "SET_CLEARANCE_SYSTEMS",
    payload: systems,
  };
}

function setClearanceSystemsLoading(value: boolean) {
  return {
    type: "SET_CLEARANCE_SYSTEMS_LOADING",
    payload: value,
  };
}

export function setLidarMarkersActive(value: boolean) {
  return {
    type: "SET_LIDAR_MARKERS_ACTIVE",
    payload: value,
  };
}

export function setClickedLidarMarker(value: number) {
  return {
    type: "SET_CLICKED_LIDAR_MARKER",
    payload: value,
  };
}
