import React, { ReactElement, useEffect } from "react";
import Dropzone from "react-dropzone";
import {
  Button,
  FormControlLabel,
  Switch,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import ProgressBar from "../ProgressBar/ProgressBar";
import translations from "translations";
import { useParams } from "react-router-dom";
import "./styles.css";
import { useLanguage } from "hooks";

const allowedTypes = ["image/jpeg"];

interface Props {
  status: "idle" | "running" | "stopped";
  cloudFolders: string[];
  getCloudFolders: () => void;
  getRawLocation: () => void;
  files: any[];
  // @ts-ignore
  setFiles: (files) => void;
  startUpload: () => void;
  // @ts-ignore
  currentFile;
  s3FolderName: string;
  setS3FolderName: (value: string) => void;
  resetUpload: () => void;
  stopUpload: () => void;
  addCloudFolder: (value: string) => void;
  automaticImport: boolean;
  setAutomaticImport: (value: boolean) => void;
  disabled?: boolean;
}
// @ts-ignore
const parsePath = (path) => {
  const tempPath = path[0] === "/" ? path.substring(1, path.length) : path;
  if (tempPath.includes("/")) {
    return tempPath;
  } else {
    return `Uncategorized/${tempPath}`;
  }
};

export default function UploadImages({
  getCloudFolders,
  getRawLocation,
  cloudFolders,
  files,
  setFiles,
  startUpload,
  status,
  currentFile,
  setS3FolderName,
  resetUpload,
  stopUpload,
  automaticImport,
  setAutomaticImport,
  disabled,
}: Props): ReactElement {
  const { language } = useLanguage();
  const params = useParams();
  const s3FolderName = params.folder || "";
  // @ts-ignore
  const customerId = parseInt(params.mission);

  useEffect(() => {
    setS3FolderName(s3FolderName);
  }, [s3FolderName, setS3FolderName]);

  // Load required data
  useEffect(() => {
    getCloudFolders();
    getRawLocation();
  }, [customerId, getCloudFolders, getRawLocation]);

  // This function is ran whenever files are added
  // @ts-ignore
  const onDrop = async (droppedFiles) => {
    // @ts-ignore
    let dropFiles = [];
    // @ts-ignore
    let foundNonAllowedFiles = [];

    const sampleFile = droppedFiles[0];

    if (sampleFile && !sampleFile.path.includes("/")) {
      toast.warning(
        "Please drag and drop folders instead of selecting individual images."
      );
    }
    // @ts-ignore
    droppedFiles.forEach((file) => {
      if (
        allowedTypes.includes(file.type) &&
        !files.map((f) => f.data.path).includes(file.path)
      ) {
        let filePath = parsePath(file.path);
        let folderIndex = filePath.indexOf("/");
        let folderName;
        if (folderIndex === -1) {
          folderName = "Uncategorized";
        } else {
          folderName = filePath.substring(0, folderIndex);
        }
        // @ts-ignore
        if (!folders.includes(folderName)) {
          folders.push(folderName);
        }
        dropFiles.push({
          src: URL.createObjectURL(file),
          data: file,
          uploaded: false,
          failed: false,
          folder: folderName,
        });
      } else if (
        !allowedTypes.includes(file.type) && // @ts-ignore
        !foundNonAllowedFiles.includes(file.type)
      ) {
        // Make sure we inform only once per disallowed file
        file.type &&
          toast.warning(`Files of type ${file.type} are not allowed.`);
        foundNonAllowedFiles.push(file.type);
      }
    });
    // @ts-ignore
    setFiles([...files, ...dropFiles]);
  };
  // @ts-ignore
  let folders = [];
  const uploadedFiles = files.filter((f) => f.uploaded);
  const failedFiles = files.filter((f) => f.failed);
  const nProcessedFiles = uploadedFiles.length + failedFiles.length;
  const finished = uploadedFiles.length === files.length && nProcessedFiles > 0;
  const idle = status === "idle";
  const running = status === "running";

  // @ts-ignore
  files.forEach((file) => {
    // @ts-ignore
    if (!folders.includes(file.folder)) {
      // @ts-ignore
      folders.push(file.folder);
    }
  });

  return (
    <Paper sx={{ p: 2 }}>
      <div className="topBar">
        {idle && (
          <Dropzone onDrop={onDrop} disabled={!!disabled}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps({ className: "dropZone" })}
                style={{
                  backgroundColor: !!disabled ? "#E0E0E0" : undefined,
                  borderColor: !!disabled ? "#A0A0A0" : undefined,
                  cursor: !!disabled ? "inherit" : undefined,
                }}
              >
                {isDragActive ? (
                  <p style={{ color: !!disabled ? "#A0A0A0" : undefined }}>
                    {
                      translations.Menu.Tools.ImageUpload.Upload.DropHere[
                        language
                      ]
                    }
                  </p>
                ) : (
                  <p style={{ color: !!disabled ? "#A0A0A0" : undefined }}>
                    {
                      translations.Menu.Tools.ImageUpload.Upload.DragAndDrop[
                        language
                      ]
                    }
                  </p>
                )}
                <Stack spacing={1}>
                  <Button variant="contained" color="primary">
                    <label htmlFor="files" className="btn">
                      <Typography variant="inherit" sx={{ color: "white" }}>
                        Select Image
                      </Typography>
                    </label>
                    <input
                      id="files"
                      type="file"
                      accept="image/jpeg"
                      multiple
                      disabled={!!disabled}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        let files = [];
                        // @ts-ignore
                        for (let i = 0; i < e.target.files.length; i++) {
                          // @ts-ignore
                          let file = e.target.files[i];
                          // @ts-ignore
                          file.path = file.name;
                          files.push(file);
                        }
                        onDrop(files);
                      }}
                    />
                  </Button>

                  <Button variant="contained" color="primary">
                    <label htmlFor="folders" className="btn">
                      <Typography variant="inherit" sx={{ color: "white" }}>
                        Select folder
                      </Typography>
                    </label>
                    <input
                      id="folders"
                      type="file"
                      multiple // @ts-ignore
                      webkitDirectory=""
                      directory=""
                      disabled={!!disabled}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        let files = [];
                        // @ts-ignore
                        for (let i = 0; i < e.target.files.length; i++) {
                          // @ts-ignore
                          const file = e.target.files[i];
                          // @ts-ignore
                          file.path = file.webkitRelativePath;
                          files.push(file);
                        }
                        onDrop(files);
                      }}
                    />
                  </Button>
                </Stack>
              </div>
            )}
          </Dropzone>
        )}
      </div>

      {files.length > 0 && files.length < 100 && folders.length > 0 && (
        <div className="folderList">
          {/* @ts-ignore */}
          {folders.map((folder, id) => (
            <div key={id} className="folderView">
              <h2>{folder}</h2>
              <div className="fileList">
                {files
                  .filter((f) => f.folder === folder)
                  .map((file, id) => (
                    <div
                      key={id}
                      className={`${file.uploaded && "uploaded"} ${
                        file.failed && "failed"
                      } fileItem ${
                        currentFile === file.data.path && "current"
                      }`}
                      title={parsePath(file.data.path)}
                    >
                      <p className="fileTitle">{file.data.name}</p>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {files.length > 100 && (
        <div className="folderList">
          <div className="folderView">
            <h2>
              Due to the amount of files, upload will proceed without rendering.
            </h2>
          </div>
        </div>
      )}

      <div className="footer">
        {running && files.length > 0 && (
          <ProgressBar percentage={uploadedFiles.length / files.length} />
        )}
        {finished && (
          <div className="finished">
            {
              translations.Menu.Tools.ImageUpload.Upload.UploadFinished[
                language
              ]
            }
          </div>
        )}
        <FormControlLabel
          control={
            <Switch
              disabled={!!disabled}
              checked={automaticImport}
              onChange={(e) => {
                setAutomaticImport(e.target.checked);
              }}
              name="startAutomatically"
              color="primary"
            />
          }
          label={
            translations.Menu.Tools.ImageUpload.Upload.StartAutomatically[
              language
            ]
          }
        />
        <div className="buttonPanel">
          <Button
            variant="contained"
            color="secondary"
            className="primaryButton"
            onClick={() => {
              resetUpload();

              let filesInput = document.getElementById("files");
              if (!!filesInput) {
                // @ts-ignore
                filesInput.value = null;
              }
              let foldersInput = document.getElementById("folders");
              if (!!foldersInput) {
                // @ts-ignore
                foldersInput.value = null;
              }
            }}
            disabled={running || files.length === 0}
          >
            {translations.Menu.Tools.ImageUpload.Upload.NewUpload[language]}
          </Button>
          <Button
            variant="contained"
            onClick={() => stopUpload()}
            className="errorButton"
            disabled={!running}
          >
            {translations.Menu.Tools.ImageUpload.Upload.StopUpload[language]}
          </Button>
          <Button
            variant="contained"
            className="secondaryButton"
            onClick={startUpload}
            disabled={!idle || files.length === 0}
          >
            {`${translations.Menu.Tools.ImageUpload.Upload[language]} ${files.length} ${translations.Menu.Tools.ImageUpload.Upload.Images[language]}`}
          </Button>
        </div>
      </div>
    </Paper>
  );
}
