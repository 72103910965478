import { IPoleTemplate } from "views/PoleViewer/api";

export interface IComponent {
  id: number;
  name: string;
  nPlacements: number;
}

export interface IPlacement {
  id: number;
  name: string;
}

export function extractComponents(template: IPoleTemplate) {
  if (!template) {
    return [];
  }

  const components = {};
  for (const item of template.items) {
    if (!(item.component.id in components)) {
      // @ts-ignore
      components[item.component.id] = {
        id: item.component.id,
        name: item.component.name,
        nPlacements: 0,
      };
    }
    // @ts-ignore
    components[item.component.id].nPlacements += 1;
  }

  const returnComponents = [];
  for (const key in components) {
    // @ts-ignore
    returnComponents.push(components[key]);
  }

  return returnComponents;
}

export function extractPlacements(
  template: IPoleTemplate,
  componentID: number
) {
  if (!template) {
    return [];
  }
  const addedPlacements = new Set();

  const placements: IPlacement[] = [];
  for (const item of template.items) {
    if (item.component.id !== componentID) {
      continue;
    }
    if (addedPlacements.has(item.placement.id)) {
      continue;
    }
    placements.push(item.placement);
    addedPlacements.add(item.placement.id);
  }
  return placements;
}
