import { connect } from "react-redux";
import Component from "./AreaAssignment";
import { RootState } from "state/store";
import { setAnnotatorColor } from "state/actions/map/drawingManager";

interface IStateProps {
  annotators: {
    id: number;
    name: string;
    color: string;
  }[];
  overlays: google.maps.Polygon[];
  userID: number;
}

interface IDispatchProps {
  setAnnotatorColor: Function;
}

interface IOwnProps {
  area: google.maps.Polygon;
  editable?: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    annotators: state.mission.annotators,
    overlays: state.map.overlays,
    userID: state.user.id,
  };
};

const mapDispatchToProps = {
  setAnnotatorColor,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps // @ts-ignore
)(Component);
