import { Paper, Stack, Typography, Checkbox } from "@mui/material";
import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { authorizedPut } from "utils/request";

type Props = {};

export default function RegionPage({}: Props) {
  const params = useParams();
  // @ts-ignore
  const currentRegionID = parseInt(params.region);
  const { regions, projects, updateGroup } = useOutletContext<{
    // @ts-ignore
    regions;
    // @ts-ignore
    projects;
    // @ts-ignore
    updateGroup;
  }>();
  // @ts-ignore
  const currentRegion = regions.find((r) => r.id === currentRegionID);
  const projectsInRegion = projects.filter(
    // @ts-ignore
    (p) => p.region?.id === currentRegionID
  );
  const projectsOutsideOfRegion = projects.filter(
    // @ts-ignore
    (p) => p.region?.id !== currentRegionID
  );

  function removeProjectFromRegion(missionID: number) {
    authorizedPut(`/mission/${missionID}/attributes`, { region: null })
      .then(() => {
        updateGroup();
      })
      .catch(() => {
        toast.error("Failed to update project");
      });
  }

  function addProjectToRegion(missionID: number) {
    authorizedPut(`/mission/${missionID}/attributes`, {
      region: currentRegionID,
    })
      .then(() => {
        updateGroup();
      })
      .catch(() => {
        toast.error("Failed to update project");
      });
  }

  return !currentRegion ? (
    <Typography>Failed to find region</Typography>
  ) : (
    <Paper sx={{ p: 2, background: "#F7F7F7" }}>
      <Typography variant="h6" component="h2">
        Region: {currentRegion.name}
      </Typography>
      <Typography>Projects in region</Typography>
      <Stack spacing={0.5} mt={1}>
        {projectsInRegion
          // @ts-ignore
          .sort((a, b) => (a.name < b.name ? -1 : 1))
          // @ts-ignore
          .map((project) => (
            <Paper key={project.id} elevation={4} sx={{ pr: 2, maxWidth: 500 }}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Checkbox
                    checked={project.region?.id === currentRegionID}
                    onChange={() => {
                      removeProjectFromRegion(project.id);
                    }}
                  />
                  <Typography>{project.name}</Typography>
                </Stack>
                <Typography color="text.secondary">
                  {project.region?.name}
                </Typography>
              </Stack>
            </Paper>
          ))}
      </Stack>
      <Typography sx={{ mt: 2 }}>Projects not in region</Typography>
      <Stack spacing={0.5} mt={1}>
        {projectsOutsideOfRegion
          // @ts-ignore
          .sort((a, b) => (a.name < b.name ? -1 : 1))
          // @ts-ignore
          .map((project) => (
            <Paper key={project.id} elevation={4} sx={{ pr: 2, maxWidth: 500 }}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Checkbox
                    checked={project.region?.id === currentRegionID}
                    onChange={() => {
                      addProjectToRegion(project.id);
                    }}
                  />
                  <Typography>{project.name}</Typography>
                </Stack>
                <Typography color="text.secondary">
                  {project.region?.name}
                </Typography>
              </Stack>
            </Paper>
          ))}
      </Stack>
    </Paper>
  );
}
