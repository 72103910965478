import { featureCollection, bbox, polygon } from "@turf/turf";
import { toGmapBounds } from "utils/utils";
import { loading } from "state/view/events";
import {
  authorizedGet,
  authorizedGetBlob,
  authorizedPut,
  authorizedPost,
  getLambdaAxiosWithAuth,
  axiosInstance,
} from "utils/request";
import { toast } from "react-toastify";
import {
  getGroup,
  getGroups,
  updateMarkers,
  clearDrawingManager,
  IGroup,
} from "state/actions";
import { rebuildPath } from "utils/path";
import { Mission, MissionAttributes } from "interfaces";
import { RootState } from "state/store";
import { dateTimeToString } from "utils/utils";

const fetchPowerLines = async (projectID: number) => {
  try {
    // @ts-ignore
    const response = await authorizedGet<{ lines; features }>(
      `/powerline/project/${projectID}`,
      {},
      {},
      true
    );
    if (window.location.pathname === "/coverage") {
      return response.lines;
    }
    if (response.features.length === 0) {
      console.log("No powerlines found. Using image bounds");
    }
    return response;
  } catch (error) {
    console.error("failed fetching powerlines: ", error);
    return featureCollection([]);
  } finally {
    loading.sendMessage("powerlines", false);
  }
};

export function setMission(mission: number | string) {
  // Reroute old URI path to new URI path
  const uriQuery = new URLSearchParams(window.location.search);
  if (window.location.pathname) {
    let pathname: string;
    // @ts-ignore
    if (mission > 0) {
      pathname = `/${mission.toString()}`;
    } else {
      pathname = "";
    }

    const queryObject = {};
    uriQuery.forEach((value, key) => {
      // @ts-ignore
      queryObject[key] = value;
    });
    const newPath = rebuildPath(pathname, queryObject);
    window.history.pushState({ urlPath: newPath }, "", newPath);
    return window.location.replace(newPath);
  }
}

export function changeMission(
  missionID: number | string,
  updateBounds = true,
  setPath = true
) {
  // @ts-ignore
  if (missionID !== "coverage" && (!missionID || missionID <= 0)) {
    return () => {};
  }
  // @ts-ignore
  return (dispatch, getState) => {
    // Load state
    const state = getState();
    const map = state.map.gmap;

    if (state.map.drawingManager) {
      dispatch(clearDrawingManager());
    }

    if (setPath) {
      let newPath = `${missionID}`;

      if (missionID !== "coverage" && missionID in state.user.filters) {
        // Collect new configuration
        newPath += state.user.filters[missionID];
      }

      window.location.replace(newPath);
    }

    if (!map) {
      return;
    }
    // biome-ignore lint/complexity/noForEach: For each does not work in google maps api
    map.data.forEach(
      // @ts-ignore
      (feature) => map.data.remove(feature)
    );

    // Collect new powerlines
    fetchPowerLines(+missionID).then((lines) => {
      // Delete all features on the map

      if (window.location.pathname === "/coverage") {
        const hulls = [];
        for (const lineCollection of lines) {
          if (lineCollection.geom) {
            const feature = polygon(
              JSON.parse(lineCollection.geom).coordinates
            );
            // @ts-ignore
            feature.properties.missionid = lineCollection.id;
            // @ts-ignore
            feature.properties.coverage = true;
            hulls.push(feature);
          }
        }

        const collection = featureCollection(hulls);
        map.data.addGeoJson(collection);
        return;
      }

      for (const feature of lines.features) {
        feature.properties.powerline = true;
      }

      // Zoom to images if we have no powerlines
      let mapBounds: google.maps.LatLngBoundsLiteral;
      if (lines.features.length > 0) {
        mapBounds = toGmapBounds(bbox(lines));
      } else if (lines.backup.south !== null) {
        mapBounds = lines.backup;
      } else {
        mapBounds = { west: 10, east: 20, north: 70, south: 50 };
      }

      if (updateBounds) {
        map.fitBounds(mapBounds);
      }

      // Add bounding box if no powerlines exists
      if (lines.features.length === 0 && lines.backup.south !== null) {
        lines.features.push({
          geometry: {
            coordinates: [
              [mapBounds.west - 0.001, mapBounds.north + 0.001],
              [mapBounds.east + 0.001, mapBounds.north + 0.001],
              [mapBounds.east + 0.001, mapBounds.south - 0.001],
              [mapBounds.west - 0.001, mapBounds.south - 0.001],
              [mapBounds.west - 0.001, mapBounds.north + 0.001],
            ],
            type: "LineString",
          },
          properties: {
            isBoundingBox: true,
          },
          type: "Feature",
        });
      }

      map.data.addGeoJson(lines);

      dispatch(updateMarkers());
    });
  };
}

export function setMissionAttribute(
  missionID: number,
  attributes: MissionAttributes,
  callback = null,
  onError = null
) {
  // @ts-ignore
  return async (dispatch) => {
    try {
      const approvedKeys = [
        "active",
        "deleted",
        "is_demo",
        "name",
        "group_id",
        "fixable_defects",
        "mission_area",
        "timestamp",
        "region",
      ];

      const cleanAttributes: MissionAttributes = {};

      // Extract all attributes that have been approved
      for (const attributeKey in attributes) {
        if (approvedKeys.includes(attributeKey)) {
          // @ts-ignore
          cleanAttributes[attributeKey] = attributes[attributeKey];
        }
      }

      if (cleanAttributes.region === null) {
        cleanAttributes.region = null;
      }

      await authorizedPut<{
        missions: Mission[];
        groups: IGroup[];
      }>(`/mission/${missionID}/attributes`, cleanAttributes);
      dispatch(getMissions(callback));
      if ("group_id" in cleanAttributes) {
        // @ts-ignore
        dispatch(getGroup(cleanAttributes.group_id));
      }
    } catch (err) {
      // @ts-ignore
      onError?.(err);
      console.error(err);
    }
  };
}

export function getMissions(callback = null) {
  // @ts-ignore
  return async (dispatch) => {
    const missionResponse = await authorizedGet<{ missions: Mission[] }>(
      "/mission"
    );
    if (!!missionResponse && !!missionResponse.missions) {
      dispatch({
        type: "SET_MISSIONS",
        payload: missionResponse.missions,
      });
    }
    // @ts-ignore
    callback?.();
  };
}

export function setMissionName(mission: Mission, name: string) {
  // @ts-ignore
  return async (dispatch) => {
    await authorizedPost(`/mission/${mission.id}/name`, { name });
    dispatch(getMissions());
  };
}
// @ts-ignore
export function createGroup(name: string, callback: () => void = null) {
  // @ts-ignore
  return async (dispatch) => {
    await authorizedPost<{
      missions: Mission[];
      groups: IGroup[];
    }>("/user/admin/addgroup", {
      name,
    });
    callback?.();
  };
}

export function setGroupAttribute(
  groupID: number,
  attributes: { name: string }
) {
  // @ts-ignore
  return async (dispatch) => {
    const approvedKeys = ["name"];

    const cleanAttributes = {};

    // Extract all attributes that have been approved
    for (const attributeKey in attributes) {
      if (approvedKeys.includes(attributeKey)) {
        // @ts-ignore
        cleanAttributes[attributeKey] = attributes[attributeKey];
      }
    }

    await authorizedPut<{
      groups: IGroup[];
    }>(`/group/${groupID}/attributes`, cleanAttributes);
    dispatch(getMissions());
    dispatch(getGroup(groupID));
    dispatch(getGroups());
  };
}
export function getFeedBayReport(
  project: RootState["user"]["missions"][number],
  type: "excel" | "csv",
  demoMode: boolean
) {
  return async () => {
    const toast_handle = toast.info("Getting structure id report...", {
      autoClose: false,
    });
    try {
      const lambdaInstance = await getLambdaAxiosWithAuth();

      const response = await lambdaInstance.get(`/report/pole_id/${type}`, {
        params: {
          demoMode,
        },
        headers: {
          MissionID: project.id,
        },
        responseType: "blob",
      });

      let url: string;
      let extension: string;
      if (type === "excel") {
        url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        extension = "xlsx";
      } else if (type === "csv") {
        url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        extension = "csv";
      }

      toast.dismiss(toast_handle);
      toast.success(`${type.toUpperCase()} file downloaded`);

      const link = document.createElement("a");
      // @ts-ignore
      link.href = url;
      const d = new Date();
      const dateString = dateTimeToString(d);
      link.setAttribute(
        "download", // @ts-ignore
        `pole_id_report_project_${project.name}_${project.year}_${dateString}.${extension}`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
      toast.update(toast_handle, {
        render: "Failed to get structure id report",
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    }
  };
}

export function getPoleSummaryReport(
  project: RootState["user"]["missions"][number],
  type: "excel" | "csv"
) {
  return async () => {
    const toast_handle = toast.info("Getting structure summary report...", {
      autoClose: false,
    });
    const response = await authorizedGetBlob(
      `/report/pole_summary/${type}`,
      {},
      { responseType: "blob" }
    );

    let url: string;
    let extension: string;
    if (type === "excel") {
      url = window.URL.createObjectURL(
        new Blob([response], { type: "application/octet-stream" })
      );
      extension = "xlsx";
    } else if (type === "csv") {
      url = window.URL.createObjectURL(
        new Blob([response], { type: "application/octet-stream" })
      );
      extension = "csv";
    }

    toast.dismiss(toast_handle);
    toast.success(`${type.toUpperCase()} file downloaded`);

    const link = document.createElement("a");
    // @ts-ignore
    link.href = url;
    const d = new Date();
    const dateString = dateTimeToString(d);
    link.setAttribute(
      "download", // @ts-ignore
      `pole_summary_report_project_${project.name}_${project.year}_${dateString}.${extension}`
    );
    document.body.appendChild(link);
    link.click();
  };
}

export function getSteelGradingReport(
  project: RootState["user"]["missions"][number],
  type: "excel" | "csv"
) {
  return async () => {
    const toast_handle = toast.info("Getting steel grading report...", {
      autoClose: false,
    });
    try {
      const response = await axiosInstance.get(
        `/project/${project.id}/report/steel_grading/${type}`,
        {
          responseType: "blob",
        }
      );

      let url: string;
      let extension: string;
      if (type === "excel") {
        url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        extension = "xlsx";
      } else if (type === "csv") {
        url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );
        extension = "csv";
      }

      toast.dismiss(toast_handle);
      toast.success(`${type.toUpperCase()} file downloaded`);

      const link = document.createElement("a");
      // @ts-ignore
      link.href = url;
      const d = new Date();
      const dateString = dateTimeToString(d);
      link.setAttribute(
        "download", // @ts-ignore
        `steel_grading_report_${project.name}_${project.year}_${dateString}.${extension}`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
      toast.update(toast_handle, {
        render: "Failed to get steel grading report",
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    }
  };
}

export function getAssetReport(
  project: RootState["user"]["missions"][number],
  type: string
) {
  return async () => {
    const toast_handle = toast.info("Getting asset report...", {
      autoClose: false,
    });
    const response = await authorizedGetBlob(
      `/report/asset/${type}`,
      {},
      { responseType: "blob" }
    );

    let url: string;
    let extension: string;
    if (type === "excel") {
      url = window.URL.createObjectURL(
        new Blob([response], { type: "application/octet-stream" })
      );
      extension = "xlsx";
    } else if (type === "csv") {
      url = window.URL.createObjectURL(
        new Blob([response], { type: "application/octet-stream" })
      );
      extension = "csv";
    }

    toast.dismiss(toast_handle);
    toast.success(`${type.toUpperCase()} file downloaded`);
    const link = document.createElement("a");
    // @ts-ignore
    link.href = url;
    const d = new Date();
    const dateString = dateTimeToString(d);
    link.setAttribute(
      "download", // @ts-ignore
      `asset_report_project_${project.name}_${project.year}_${dateString}.${extension}`
    );
    document.body.appendChild(link);
    link.click();
  };
}

export function setMissionID(projectID: number) {
  return {
    type: "SET_MISSION_ID",
    payload: projectID,
  };
}

export function downloadClusterData(mission: number | string) {
  toast.info("Generating json");
  authorizedGet("/image/clusters")
    .then((data) => {
      const a = document.createElement("a");
      a.href = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(data)
      )}`;
      a.download = `cluster_data_mission_${mission}.json`;
      a.click();
      toast.success("Download successful");
    })
    .catch(() => {
      toast.error("Generation failed");
    });
}

export function getEmailList(mission: number) {
  // @ts-ignore
  return async (dispatch) => {
    const response = await authorizedGet<{
      email_list: { id: number; email: string }[];
    }>(`/mission/${mission}/email_list`);
    dispatch({
      type: "SET_EMAIL_LIST",
      payload: response.email_list,
    });
  };
}
