import { PreviousDefectImages } from "interfaces";

const zoomSpeed = localStorage.getItem("zoom_speed") // @ts-ignore
  ? JSON.parse(localStorage.getItem("zoom_speed"))
  : 20;

const distance =
  localStorage.getItem("cluster_distance") &&
  localStorage.getItem("cluster_distance") !== "NaN"
    ? localStorage.getItem("cluster_distance")
    : "5";

export interface IState {
  refreshAnnotations: boolean;
  showBBox: boolean;
  loadingImage: boolean;
  matrix: any;
  minTemp: number;
  maxTemp: number;
  clusterHidden: boolean;
  thermalData: any;
  zoomSpeed: number;
  clusterMethod: "pole" | "proximity" | "cluster";
  imageSortingMethod: "compass" | "timestamp";
  distance: string;
  lidarWindow: any;
  expandAnnotationsList: boolean;
  previousDefectImages: PreviousDefectImages[];
  selectedPreviousDefectImage: PreviousDefectImages | null;
}

const savedClusterMethod = localStorage.getItem("cluster_method");

let clusterMethod: IState["clusterMethod"] = "pole";

if (
  !!savedClusterMethod &&
  (savedClusterMethod === "pole" ||
    savedClusterMethod === "proximity" ||
    savedClusterMethod === "cluster")
) {
  clusterMethod = savedClusterMethod;
}

const savedImageSortingMethod = localStorage.getItem("image_sorting_method");

let imageSortingMethod: IState["imageSortingMethod"] = "compass";

if (
  !!savedImageSortingMethod &&
  (savedImageSortingMethod === "compass" ||
    savedImageSortingMethod === "timestamp")
) {
  imageSortingMethod = savedImageSortingMethod;
}

export const initialState: IState = {
  refreshAnnotations: false,
  showBBox: true,
  loadingImage: false,
  matrix: { a: 1.2, b: 0, c: 0, d: 1.2, e: -100, f: -50 },
  minTemp: -30,
  maxTemp: 30,
  clusterHidden: false,
  thermalData: {
    temperature: [],
    max: 0,
    min: 0,
    width: 0,
    height: 0,
  },
  zoomSpeed,
  clusterMethod,
  imageSortingMethod,
  // @ts-ignore
  distance,
  lidarWindow: null,
  expandAnnotationsList: false,
  previousDefectImages: [],
  selectedPreviousDefectImage: null,
};

// @ts-ignore
const imageViewerReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case "SET_REFRESH_ANNOTATIONS":
      return {
        ...state,
        refreshAnnotations: action.payload,
      };
    case "SET_LIDAR_WINDOW":
      return {
        ...state,
        lidarWindow: action.payload,
      };
    case "SET_EXPAND_ANNOTATIONS_LIST":
      return {
        ...state,
        expandAnnotationsList: action.payload,
      };
    case "RESET_REFRESH_ANNOTATIONS":
      return {
        ...state,
        refreshAnnotations: initialState.refreshAnnotations,
      };
    case "SHOW_IMAGE_VIEWER_BBOX":
      return {
        ...state,
        showBBox: action.payload,
      };
    case "SET_MATRIX":
      return {
        ...state,
        matrix: action.payload,
      };
    case "RESET_MATRIX":
      return {
        ...state,
        matrix: initialState.matrix,
      };
    case "LOADING_IMAGE":
      return {
        ...state,
        loadingImage: action.payload,
      };
    case "SET_THERMAL_DATA":
      return {
        ...state,
        thermalData: action.payload,
      };
    case "RESET_THERMAL_DATA":
      return {
        ...state,
        thermalData: {
          ...initialState.thermalData,
        },
      };
    case "SET_CLUSTER_HIDDEN":
      return {
        ...state,
        clusterHidden: action.payload,
      };
    case "SET_CLUSTER_METHOD":
      localStorage.setItem("cluster_method", action.payload);
      return {
        ...state,
        clusterMethod: action.payload,
      };
    case "SET_IMAGE_SORTING_METHOD":
      localStorage.setItem("image_sorting_method", action.payload);
      return {
        ...state,
        imageSortingMethod: action.payload,
      };
    case "SET_CLUSTER_DISTANCE":
      let value = action.payload;
      if (!!!value) {
        value = 0;
      }
      localStorage.setItem("cluster_distance", action.payload);
      return {
        ...state,
        distance: action.payload,
      };
    case "SET_ZOOM_SPEED":
      localStorage.setItem("zoom_speed", action.payload);
      return {
        ...state,
        zoomSpeed: action.payload,
      };
    case "SET_PREVIOUS_DEFECT_IMAGES":
      return {
        ...state,
        previousDefectImages: action.payload,
      };
    case "SET_SELECTED_PREVIOUS_DEFECT_IMAGE":
      return {
        ...state,
        selectedPreviousDefectImage: action.payload,
      };
    default:
      return state;
  }
};

export default imageViewerReducer;
