export const FilterKey = {
  FILTER_ACTIVE: "filterActive",
  ACTORS: "actors",
  AREA: "area",
  CLEARANCE_AXES: "clearanceAxes",
  CLEARANCE_RULES: "clearanceRules",
  DATE_DEFECT_ADDED: "dateDefectAdded",
  DATE_DEFECT_FIXED: "dateDefectFixed",
  DATE_DEFECT_PROCESSED: "dateDefectProcessed",
  DATE_DEFECT_REPORTED: "dateDefectReported",
  DATE_IMAGE_CAPTURE: "dateImageCapture",
  DATE_IMAGE_UPLOADED: "dateImageUploaded",
  FLAGGED: "flagged",
  FLIGHT: "flight",
  LIDAR_MARKERS: "showLidarMarkers",
  LIST: "list",
  NEW: "new",
  DETECTION: "detection",
  SEVERITY: "severity",
  DSO_TSO: "showDsoTso",
  SKYQRAFT_HIDDEN: "skyqraftHidden",
  WORKFLOW: "workflow",
  DEFECT_NOT_REPORTED: "defectNotReported",
  DEFECT_NOT_PROCESSED: "defectNotProcessed",
  FEED_BAY: "feedBay",
} as const;

export type FilterType = {
  FILTER_ACTIVE: boolean;
  ACTORS: (number | "Skyqraft" | "AI")[];
  AREA: string;
  CLEARANCE_AXES: number[];
  CLEARANCE_RULES: number[];
  DATE_DEFECT_ADDED: null | Date | [Date, Date];
  DATE_DEFECT_FIXED: null | Date | [Date, Date];
  DATE_DEFECT_PROCESSED: null | Date | [Date, Date];
  DATE_DEFECT_REPORTED: null | Date | [Date, Date];
  DATE_IMAGE_CAPTURE: null | Date | [Date, Date];
  DATE_IMAGE_UPLOADED: null | Date | [Date, Date];
  FLAGGED: boolean;
  FLIGHT: number[];
  LIDAR_MARKERS: boolean;
  LIST: number[];
  NEW: boolean;
  DETECTION: number[];
  SEVERITY: number[];
  DSO_TSO: boolean;
  SKYQRAFT_HIDDEN: "none" | "both" | "only";
  WORKFLOW: number[];
  DEFECT_NOT_REPORTED: boolean;
  DEFECT_NOT_PROCESSED: boolean;
  FEED_BAY: string[];
};
