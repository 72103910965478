import { connect } from "react-redux";
import { RootState } from "state/store";
import {
  setSupervisorMode,
  setInspectorMode,
  changeAnnotatorColor,
  toggleDemoMode,
} from "state/actions";
import Component from "./FlaggedImageList";

interface IStateProps {}

interface IDispatchProps {
  setSupervisorMode: (value: boolean) => void;
  setInspectorMode: (value: boolean) => void;
  changeAnnotatorColor: (value: boolean) => void;
  toggleDemoMode: (value: boolean) => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = {
  setSupervisorMode,
  setInspectorMode,
  changeAnnotatorColor,
  toggleDemoMode,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
