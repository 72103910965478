import { useState } from "react";
import {
  Stack,
  Paper,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import SummaryPage from "./SummaryPage";
import {
  useSearchParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useLanguage, useReportPreview, useSelector } from "hooks";
import Loading from "views/Loading";
import translations from "translations";
import Switch from "@mui/material/Switch";
import useMediaQuery from "@mui/material/useMediaQuery";
import { axiosInstance } from "utils/request";
import { FilterKey } from "hooks/filter/search_parameters/constants";

type Props = {
  projectID: number;
  mission: {
    value: number;
    label: string;
    fixable: boolean;
    processable_defects: boolean;
  };
};

export default function Preview({ projectID, mission }: Props) {
  const { language } = useLanguage();

  const [searchParams] = useSearchParams();
  const [selectedLanguage, setLanguage] = useState<string>(language);
  const [processDefects, setProcessDefects] = useState<boolean>(false);
  const context = useOutletContext<{ updateTrigger?: () => void }>();
  const updateTrigger = context?.updateTrigger;
  const [hideFixedDefects, setHideFixedDefects] = useState<boolean>(false);
  const demoMode = useSelector((state) => state.ui.demoMode);
  const [clientNaming, setClientNaming] = useState<boolean>(false);
  const navigate = useNavigate();

  const searchQuery = searchParams.toString();

  const [preview, loading] = useReportPreview(
    searchQuery.length > 0 ? `?${searchQuery}` : "",
    projectID,
    selectedLanguage,
    clientNaming,
    hideFixedDefects
  );

  const images = new Set();

  const isSmallScreen = useMediaQuery("(max-width:800px)");

  if (preview) {
    for (const d of preview.defects) {
      images.add(d.image.id);
    }
  }
  return (
    <>
      {loading && !preview ? (
        <Loading />
      ) : (
        <>
          <Paper sx={{ p: 2, display: isSmallScreen ? "none" : "" }}>
            <Typography variant="h6">
              {translations.Inbox.Reports.Preview[language]}
            </Typography>

            <SummaryPage
              totalImages={preview?.total_images}
              totalDefects={preview?.total_defects}
              defects={preview?.defects}
              mission={mission}
              language={selectedLanguage}
            />
          </Paper>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">
              {translations.Inbox.Reports.ReportContent[language]}
            </Typography>

            <Stack maxWidth={600}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>
                  {translations.Inbox.Reports.Images[language]}
                </Typography>
                <Typography>{images.size}</Typography>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>
                  {translations.Inbox.Reports.Defects[language]}
                </Typography>
                <Typography>{preview?.defects.length}</Typography>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>
                  {translations.Inbox.Reports.Language[language]}
                </Typography>
                <Select
                  margin="dense"
                  onChange={(e) => setLanguage(e.target.value)}
                  value={selectedLanguage}
                >
                  <MenuItem value={"EN"}>English</MenuItem>
                  <MenuItem value={"SE"}>Swedish</MenuItem>
                </Select>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>Client Naming</Typography>

                <Switch
                  checked={clientNaming}
                  onChange={() => setClientNaming((current) => !current)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Stack>
              {mission?.processable_defects && false && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>{"Mark defects as 'Processed'"}</Typography>

                  <Switch
                    checked={processDefects}
                    onChange={() => setProcessDefects((current) => !current)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Stack>
              )}
              {!!mission?.fixable && (
                <FormControlLabel
                  value={!hideFixedDefects}
                  control={<Checkbox />}
                  onChange={(_, checked) => setHideFixedDefects(!checked)}
                  label={translations.Inbox.Reports.IncludeFixed[language]}
                />
              )}
            </Stack>
          </Paper>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                const search = new URLSearchParams(window.location.search);
                // If the user has selected a list and not severity
                // then we include all severity levels
                if (
                  !search.has(FilterKey.SEVERITY) &&
                  search.has(FilterKey.LIST)
                ) {
                  search.set(FilterKey.SEVERITY, "1,2,3,4");
                }
                axiosInstance
                  .post<string>(
                    `/report/defect/generate?${search.toString()}`,
                    {
                      language: selectedLanguage,
                      clientNaming: clientNaming,
                      hideFixedDefects,
                      processDefects,
                      demoMode,
                    },
                    { headers: { MissionID: projectID } }
                  )
                  .then((response) => {
                    const newID = response.data;
                    updateTrigger?.();
                    navigate(`/${projectID}/inbox/${newID}`);
                  });
              }}
            >
              Create Report
            </Button>
          </Stack>
        </>
      )}
    </>
  );
}
