import { area } from "@turf/turf";
import { axiosInstance } from "utils/request";

interface IWorkflowCount {
  count: {
    id: number;
    count: number;
  }[];
}

export async function getWorkflowCount(
  projectID: number,
  flights: number[] = [],
  severities: number[] = [],
  detections: number[] = [],
  area: string = ""
) {
  const headers: Record<string, string> = {
    MissionID: `${projectID}`,
  };

  // Populate the search parameters in a clean way
  const params = new URLSearchParams();
  if (flights.length > 0) {
    params.append("flight", flights.join(","));
  }
  if (severities.length > 0) {
    params.append("severity", severities.join(","));
  }
  if (detections.length > 0) {
    params.append("detection", detections.join(","));
  }
  if (area.length > 0) {
    params.append("area", area);
  }

  // Make the request
  const response = await axiosInstance.get<IWorkflowCount>(
    "/object/filter/count/workflow_status",
    {
      headers,
      params,
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to fetch data");
  }
  return response.data.count;
}
