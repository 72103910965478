export interface IState {
  defectMetrics: Array<any>;
  timestamp: string;
}

export const initialState: IState = {
  defectMetrics: [],
  timestamp: "",
};

// @ts-ignore
const mapReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case "UPDATE_DEFECT_METRICS":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default mapReducer;
