import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { authorizedDelete, authorizedPost, authorizedPut } from "utils/request";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import { IUser } from "hooks";

type Props = {
  user: IUser;
  editing: boolean;
  updateUser: () => void;
};

export default function MFA({ user, editing, updateUser }: Props) {
  const [password, setPassword] = useState<string>("");
  const [secretCode, setSecretCode] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [mfaCode, setMFACode] = useState<string>("");

  function getCode() {
    authorizedPut<{ token: string }>(`/admin/user/${user.id}/mfa`, {
      password,
    })
      .then((response) => {
        setSecretCode(response.token);
      })
      .catch((e) => {
        toast.error("Failed to get MFA. Please contact support");
      });
  }

  function verifyCode() {
    authorizedPost(`/admin/user/${user.id}/mfa`, {
      mfa_code: mfaCode,
      password,
    })
      .then(() => {
        setOpenModal(false);
        updateUser();
      })
      .catch((e) => {
        toast.error(
          "Failed to verify MFA. Please try again or contact support"
        );
      });
  }

  function deleteMFA() {
    authorizedDelete(`/admin/user/${user.id}/mfa`)
      .then(() => {
        setOpenModal(false);
        updateUser();
      })
      .catch((e) => {
        toast.error("Failed to delete MFA. Please contact Arkion Support");
      });
  }

  return (
    <>
      {user.mfa_enabled ? (
        <Button
          color="success"
          variant="outlined"
          onClick={() => setOpenModal(!openModal)}
        >{`MFA Enabled${editing ? ". Click to disable" : ""}`}</Button>
      ) : (
        <Button
          color="error"
          variant="outlined"
          onClick={() => setOpenModal(!openModal)}
        >{`MFA Disabled${editing ? ". Click to enable" : ""}`}</Button>
      )}
      <Dialog
        open={openModal && user.mfa_enabled}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle component={Typography}>Disable MFA</DialogTitle>
        <DialogContent>
          <DialogContentText component={Typography}>
            You can disable MFA for this account. You can also change MFA type
            here. Please select action below.
          </DialogContentText>
          <DialogActions>
            <Button onClick={deleteMFA}>Disable MFA</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModal && !user.mfa_enabled}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle component={Typography}>Enable MFA</DialogTitle>
        <DialogContent>
          <Stack>
            <Typography variant="h6">Configure Authenticator App</Typography>
            {!secretCode && (
              <>
                <DialogContentText component={Typography}>
                  To configure your authenticator app, you first need to enter
                  your password to recieve a secret code for your app.
                </DialogContentText>

                <TextField
                  label="Password"
                  type="password"
                  variant="standard"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  sx={{ my: 2 }}
                />

                <Button variant="contained" onClick={getCode}>
                  Get QR code
                </Button>
              </>
            )}

            {secretCode && (
              <>
                <QRCode
                  value={`otpauth://totp/${window.location.host}?secret=${secretCode}&issuer=Arkion`}
                />
                <TextField
                  label="MFA Code"
                  variant="standard"
                  value={mfaCode}
                  onChange={(e) => setMFACode(e.target.value)}
                  sx={{ my: 2 }}
                />
                <Button variant="contained" onClick={verifyCode}>
                  Verify Authenticator
                </Button>
              </>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
