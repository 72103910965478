import { createContext, useEffect, useState } from "react";
import {
  getProjectGanttData,
  addGanttItem,
  setGanttItem,
  deleteGanttItem,
} from "api";

interface IGanttItem {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  display_order: number;
  temporary?: boolean;
}

interface IContext {
  items: IGanttItem[];
  loading: boolean;
  errorMessage: string | null;
  addItem: (name: string, start_date: string, end_date: string) => void;
  updateItem: (
    itemID: number,
    name?: string,
    start_date?: string,
    end_date?: string,
    display_order?: number,
    update?: boolean
  ) => void;
  deleteItem: (item: IGanttItem) => void;
  editing: boolean;
  setEditing: (editing: boolean) => void;
  moveItem: (item: IGanttItem, direction: 1 | -1) => void;
}

interface IProps {
  projectID: number;
  children?: React.ReactNode;
}

const context = createContext<IContext>({
  items: [],
  loading: false,
  errorMessage: null,
  addItem: () => {},
  updateItem: () => {},
  deleteItem: (item: IGanttItem) => {},
  editing: false,
  setEditing: (editing: boolean) => {},
  moveItem: (item: IGanttItem, direction: 1 | -1) => {},
});

function Provider({ children, projectID }: IProps) {
  // Set up the states
  const [items, setItems] = useState<IGanttItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function updateItems() {
    try {
      const response = await getProjectGanttData(projectID);
      setItems(response.gantt_data);
    } catch (err) {
      // @ts-ignore
      setErrorMessage(err);
    }
  }

  useEffect(() => {
    setLoading(true);
    const promise = updateItems();
    promise.finally(() => setLoading(false));
  }, [projectID]);

  function addItem(name: string, start_date: string, end_date: string) {
    setLoading(true);
    // Set the item temporarily
    setItems([
      ...items,
      { id: 0, name, start_date, end_date, display_order: 0, temporary: true },
    ]);
    const originalItems = [...items];

    addGanttItem(projectID, name, start_date, end_date, items.length + 1)
      .then(() => {
        updateItems();
      })
      .catch((err) => {
        setItems(originalItems);
        setErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function updateItem(
    itemID: number,
    name: null | string = null,
    start_date: null | string = null,
    end_date: null | string = null,
    display_order: null | number = null,
    update = true
  ) {
    setLoading(true);
    const originalItems = [...items];
    const index = items.findIndex((item) => item.id === itemID);
    if (index < 0) {
      return;
    }
    const item = items[index];
    if (name === null) {
      name = item.name;
    }
    if (start_date === null) {
      start_date = item.start_date;
    }
    if (end_date === null) {
      end_date = item.end_date;
    }
    const newItem = {
      ...item,
      name,
      start_date,
      end_date,
    };
    const newItems = [...items];
    newItems[index] = newItem;
    setItems(newItems);
    // @ts-ignore
    setGanttItem(projectID, itemID, name, start_date, end_date, display_order)
      .then(() => {
        if (!!update) {
          updateItems();
        }
      })
      .catch((err) => {
        setItems(originalItems);
        setErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function deleteItem(item: IGanttItem) {
    setLoading(true);
    deleteGanttItem(projectID, item.id)
      .then(() => {
        updateItems();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function moveItem(item: IGanttItem, direction: 1 | -1) {
    // 1 indicate move down the list
    // -1 indicate move up the list
    // Since 0 is the start of the list, we can't move up anymore

    const currentIndex = items.findIndex((i) => i.id === item.id);

    if (currentIndex === -1) {
      // Item not found
      return;
    }

    if (currentIndex === 0 && direction === -1) {
      // Can't move up anymore
      return;
    }

    if (currentIndex === items.length - 1 && direction === 1) {
      // Can't move down anymore
      return;
    }
    let newItems = [...items];
    const nextIndex = currentIndex + direction;

    // Swap the display order
    let temp = newItems[currentIndex].display_order;
    newItems[currentIndex].display_order = newItems[nextIndex].display_order;
    newItems[nextIndex].display_order = temp;

    let updatedItems = [newItems[currentIndex], newItems[nextIndex]];
    // Sort according to display order
    newItems = newItems.sort((a, b) => a.display_order - b.display_order);

    updateItem(
      newItems[currentIndex].id,
      null,
      null,
      null,
      newItems[currentIndex].display_order,
      false
    );
    updateItem(
      newItems[currentIndex].id,
      null,
      null,
      null,
      newItems[nextIndex].display_order,
      true
    );
  }

  // Create the payload
  const payload: IContext = {
    items,
    loading,
    errorMessage,
    addItem,
    updateItem,
    deleteItem,
    editing,
    setEditing,
    moveItem,
  };
  return <context.Provider value={payload}>{children}</context.Provider>;
}

export default Provider;
export type { IGanttItem };
export { context };
