import isEqual from "lodash/isEqual";
import get from "lodash/get";
import store from "state/store";
// @ts-ignore
import { asMutable } from "seamless-immutable";
import translations from "translations";
import { workflow_status } from "AppConstants";
import { getAnnotationsFromAPI } from "../../../api";
import { saveErrors } from "api/image/annotation";

export type ISpecialAnnotation = Awaited<
  ReturnType<typeof getAnnotationsFromAPI> // @ts-ignore
>[number];

const propertiesToCheck = [
  "h",
  "categories",
  "types",
  "severities",
  "w",
  "x",
  "y",
  "rotation",
  "is_defect",
  "workflow_status",
  "skyqraft_hidden",
  "isLocked",
  "properties",
  "objectHasNoDefect",
  "type_comment",
  "item_id",
  "type_id",
  "processed",
  "steelwork.id",
  "steelwork.item_id",
  "steelwork.pole_id",
  "steelwork.direction",
  "steelwork.second_direction",
  "steelwork.bolt_condition",
  "steelwork.steel_grading",
  "steelwork.parent_link",
  "steelwork.child_link",
];
// @ts-ignore
export const checkRegionUpdate = (region, regions, objectTypes) => {
  // @ts-ignore
  let oldRegion = regions.find((r) => r.id === region.id);
  if (!!!oldRegion) {
    // The region does not exist
    return {
      new: true,
      saved: false,
      canSave: canSaveObject(region, objectTypes)?.canSave,
    };
  }

  const identical = propertiesToCheck.every((property) => {
    return isEqual(get(region, property), get(oldRegion, property));
  });
  if (identical) {
    return {
      new: false,
      saved: true,
      canSave: canSaveObject(region, objectTypes)?.canSave,
    };
  } else {
    return {
      new: false,
      saved: false,
      canSave: canSaveObject(region, objectTypes)?.canSave,
    };
  }
};
// @ts-ignore
export const regionsAreEqual = (regionsA, regionsB) => {
  // Convert regions to mutable if necessary
  let extractedRegionsA = !!regionsA.asMutable ? asMutable(regionsA) : regionsA;
  let extractedRegionsB = !!regionsB.asMutable ? asMutable(regionsB) : regionsB;
  // @ts-ignore
  const prepareRegionsForComparison = (regions) => {
    // @ts-ignore
    return regions.map((region) => {
      const filteredRegion = {};
      propertiesToCheck.forEach((property) => {
        const value = get(region, property);
        if (value !== undefined) {
          // @ts-ignore
          filteredRegion[property] = value;
        }
      });
      return filteredRegion;
    });
  };

  // Prepare regions for comparison
  extractedRegionsA = prepareRegionsForComparison(extractedRegionsA);
  extractedRegionsB = prepareRegionsForComparison(extractedRegionsB);

  // Compare prepared regions
  const req = isEqual(
    // @ts-ignore
    extractedRegionsA.sort((a, b) => (a.id < b.id ? 1 : -1)),
    // @ts-ignore
    extractedRegionsB.sort((a, b) => (a.id < b.id ? 1 : -1))
  );

  return req;
}; // @ts-ignore
export const canSaveObject = (region, objectTypes) => {
  let language = store.getState().user.language;
  let canSave = true;
  let message = "";
  // @ts-ignore
  const detectionTypes = objectTypes.filter((o) => !o.issue).map((o) => o.id);

  // We cannot allow objects without definition
  if (region.types.length === 0 || region.severities.length === 0) {
    return {
      canSave: false,
      message: translations.AnnotationTool.NoDefinitionsSpecified[language],
    };
  }

  // Definition array length must be equal
  if (region.types.length !== region.severities.length) {
    return {
      canSave: false,
      message: translations.AnnotationTool.DefinitionError[language],
    };
  }

  // Type cannot be null
  if (region.types.includes(null)) {
    return {
      canSave: false,
      message: translations.AnnotationTool.TypeMissing[language],
    };
  }

  if (
    region?.steelwork?.save_error &&
    region.steelwork.save_error !== saveErrors.CHANGES_WILL_UNLINK
  ) {
    return {
      canSave: false,
      message: region.steelwork.save_error,
    };
  }

  // Verify each post
  for (let i = 0; i < region.types.length; i++) {
    if (detectionTypes.includes(region.types[i])) {
      canSave = canSave && Boolean(region.types[i]);
      // @ts-ignore
      message = !canSave && translations.AnnotationTool.TypeError[language];
    } else if (
      (region.workflow_status[i] === 2 || region.workflow_status[i] === 3) &&
      region.severities[i] > 4
    ) {
      canSave = false;
      message =
        !canSave && translations.AnnotationTool.SetASevrityLevel[language];
    } else {
      canSave = canSave && Boolean(region.severities[i]);
      // @ts-ignore
      message =
        !canSave && translations.AnnotationTool.SeverityMissing[language];
    }
  }

  return { canSave: canSave, message: message };
};
// @ts-ignore
export const checkBeforeSave = (region, objectTypes, language) => {
  // @ts-ignore
  const defectTypes = objectTypes.filter((o) => !!o.issue).map((o) => o.id);
  let message = null;

  if (region.severities) {
    //This is to check for FP changes that will remove the severity levels
    for (let i = 0; i < region.types.length; i++) {
      if (
        (region.workflow_status[i] === workflow_status.FALSE_POSITIVE ||
          region.workflow_status[i] ===
            workflow_status.SUPERVISOR_FALSE_POSITIVE) &&
        region.severities[i] < 5 &&
        defectTypes.includes(region.types[i])
      ) {
        message = // @ts-ignore
          translations.AnnotationTool
            .FalsePositivesWillAutomaticallyRemoveTheSeverityLevels[language];
      }
    }
  }

  if (region?.steelwork?.save_error === saveErrors.CHANGES_WILL_UNLINK) {
    message = region?.steelwork?.save_error;
  }

  return message;
};
