import axios from "axios";
import { useState, useEffect } from "react";
import { BASE_URI } from "config";

export interface IReportInboxItem {
  id: number;
  pending: boolean;
  name: string;
  filename: string;
  creator: {
    id: number;
    name: string;
  };
  user: {
    id: number;
    name: string;
  };
  accepted: boolean;
  starred: boolean;
  filter_used: string;
  project: {
    id: number;
    name: string;
  };
  type: {
    id: number;
    name: string;
  };
  timestamp: string;
  size: string;
  n_defects: number;
  n_images: number;
}

/**
 * A React hook that collects the Reports for all or one mission
 *
 * @param {number} [mission] - The mission ID to collect. Leave blank for all missions.
 * @example
 * // Collect all reports for all missions
 * useReportInbox()
 * // Collect all reports for mission 7
 * useReportInbox(7)
 *
 */
export function useReportInbox(
  mission?: number
): [IReportInboxItem[], Function, boolean] {
  const [reports, setReports] = useState<IReportInboxItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function updateTrigger() {
    updateReport(mission);
  }
  // @ts-ignore
  function updateReport(mission) {
    const endpoint = `${BASE_URI}/report/defect`;

    const headers = {};
    if (mission) {
      // @ts-ignore
      headers["MissionID"] = mission;
    }
    // Send a request
    setLoading(true);
    axios
      .get<{ reports: IReportInboxItem[] }>(endpoint, {
        headers,
        withCredentials: true,
      })
      .then((response) => {
        setLoading(false);
        setReports(response.data.reports);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Failed to collect reports", err);
      });
  }

  useEffect(() => {
    updateReport(mission);
  }, [mission]);

  return [reports, updateTrigger, loading];
}
