import { useState } from "react";
import { Card, Chip, Stack, Typography } from "@mui/material";
import { getCDNURL } from "authentication/apiDetails";
import type { IImageWithMeta } from "../types";
import { useThinImageAnnotations } from "../hooks";
import ImageAnnotation from "./ImageAnnotation";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { EventHint, captureException } from "@sentry/react";
import FlowDirectionArrow from "components/FlowDirectionArrow";

type Props = {
  image: IImageWithMeta;
  connected: boolean;
  onConnect: () => Promise<void>;
  onDisconnect: () => Promise<void>;
  currentImage: boolean;
};

export default function Image({
  image,
  connected,
  onConnect,
  onDisconnect,
  currentImage,
}: Props) {
  const [disabled, setDisabled] = useState(false);
  const { annotations } = useThinImageAnnotations(image.id);

  async function onClick() {
    if (disabled) return;
    setDisabled(true);
    try {
      if (connected) {
        await onDisconnect();
      } else {
        await onConnect();
      }
    } catch (e) {
      const hint: EventHint = {
        event_id: "image-connector-checkbox-error",
        originalException: e,
        data: {
          imageId: image.id,
          connected,
        },
      };
      captureException(e, hint);
    }
    setDisabled(false);
  }
  return (
    <Card
      sx={{
        height: "100%",
        position: "relative",
        boxShadow: currentImage
          ? "2px 2px 4px #5181DD, -2px -2px 4px #5181DD, 2px -2px 4px #5181DD, -2px 2px 4px #5181DD"
          : undefined,
      }}
    >
      <Stack direction="column" alignItems="flex-start" height="100%">
        <div className="imageWrapper" style={{ position: "relative" }}>
          <img
            data-testid={`image-connector-image-${image.id}`}
            src={
              image.src.length > 4
                ? encodeURI(`${getCDNURL()}/image/${image.src}`)
                : "/sample.JPG"
            }
            alt={`image_${image.id}`}
            style={{ width: "100%", height: "100%" }}
          />
          {annotations.map((annotation) => (
            <ImageAnnotation
              key={image.id}
              imageId={image.id}
              annotation={annotation}
            />
          ))}
          {image.flow_angle && (
            <FlowDirectionArrow angle={image.flow_angle} opacity={1} />
          )}
        </div>
        <Stack mx={1} my={1} spacing={1} justifyContent={"space-between"}>
          <Stack direction="row" spacing={1}>
            <Chip
              disabled={disabled}
              icon={
                connected ? (
                  <CheckBox htmlColor="#EFEFEF" />
                ) : (
                  <CheckBoxOutlineBlank htmlColor="#EFEFEF" />
                )
              }
              color="primary"
              onClick={onClick}
              data-testid={`image-connector-checkbox-${image.id}-${
                connected ? "connected" : "disconnected"
              }`}
              label="Connected"
              sx={{ my: 1, background: "#1D4385", color: "#EFEFEF" }}
            />
            {currentImage && (
              <Chip
                label="Current image"
                sx={{ my: 1, background: "#5181DD", color: "#EFEFEF" }}
              />
            )}
          </Stack>
          <Typography
            data-testid={`image-connector-image-name-${image.id}`}
            width="100%"
          >
            {image.name}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
