import React from "react";
import {
  Breadcrumbs,
  Button,
  Typography,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
} from "@mui/material";
import translations from "translations";
import { findCurrentMission } from "utils/utils";
// @ts-ignore
import md5 from "md5-hash";
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { groupBy } from "lodash";
import { getProjectName } from "utils/utils";
import { compareYearAndName } from "utils/utils";
import { useLanguage, useSelector } from "hooks";

export default function MissionBreadcrumbs() {
  const missions = useSelector((state) => state.user.missions);
  const demoMode = useSelector((state) => state.ui.demoMode);
  const { language } = useLanguage();
  const params = useParams();
  const location = useLocation();
  // @ts-ignore
  const currentMission = findCurrentMission(parseInt(params.mission), missions);
  const getProjectNameCurrentResult = getProjectName(currentMission?.id);
  let areaMissions = [];
  const groupMissions = [];
  const [regionAnchorEl, setRegionAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [groupAnchorEl, setGroupAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const regionOpen = Boolean(regionAnchorEl);
  const groupOpen = Boolean(groupAnchorEl);
  const handleClickRegion = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRegionAnchorEl(event.currentTarget);
  };
  const handleCloseRegion = () => {
    setRegionAnchorEl(null);
  };
  const handleClickGroup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setGroupAnchorEl(event.currentTarget);
  };
  const handleCloseGroup = () => {
    setGroupAnchorEl(null);
  };

  for (const mission of missions) {
    if (
      mission?.region?.id === currentMission?.region?.id &&
      !mission.deleted &&
      !!currentMission?.region?.id
    ) {
      areaMissions.push(mission);
    }
    if (
      mission?.group?.id === currentMission?.group?.id &&
      !mission.deleted &&
      !!currentMission?.group?.id
    ) {
      groupMissions.push(mission);
    }
  }

  areaMissions = areaMissions.sort((a, b) =>
    new Date(a.timestamp) < new Date(b.timestamp) ? 1 : -1
  );
  const groupRegions = groupBy(groupMissions, (p) => p.region?.name);
  const smallScreen = useMediaQuery("(max-width:600px)");

  return (
    <div>
      <Breadcrumbs separator=">" data-testid="project-breadcrumbs">
        {!!currentMission?.group && (
          <Button color="inherit" variant="text" onClick={handleClickGroup}>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "20vw",
                maxWidth: "min-content",
                whiteSpace: "nowrap",
              }}
            >
              {demoMode
                ? `${translations.words.Customer[language]} ${md5(
                    currentMission?.group.name
                  ).substring(0, 4)}`
                : currentMission?.group.name}
            </Typography>
          </Button>
        )}
        {!!currentMission?.region && !smallScreen && (
          <Button color="inherit" variant="text" onClick={handleClickRegion}>
            <Typography
              color="#404040"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "20vw",
                maxWidth: "min-content",
                whiteSpace: "nowrap",
              }}
            >
              {demoMode
                ? `${translations.words.Region[language]} ${md5(
                    currentMission?.region?.name
                  ).substring(0, 4)}`
                : currentMission?.region?.name}
            </Typography>
          </Button>
        )}
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          to={`/${currentMission?.id}${
            location.pathname.includes("/landing") ? "" : "/landing"
          }`}
          data-testid="project-breadcrumbs-project-link"
        >
          <Typography
            color="text.primary"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "20vw",
              maxWidth: "min-content",
              whiteSpace: "nowrap",
            }}
          >
            {getProjectNameCurrentResult.name}
          </Typography>
        </Link>
      </Breadcrumbs>
      <Menu
        anchorEl={regionAnchorEl}
        open={regionOpen}
        onClose={handleCloseRegion}
        onClick={handleCloseRegion}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            maxHeight: "50%",
            overflowY: "auto",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {areaMissions.sort(compareYearAndName).map((m) => {
          const getProjectNameReturn = getProjectName(m.id);
          return (
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              key={m.id}
              to={`/${m.id}${
                location.pathname.includes("/landing") ? "/landing" : ""
              }`}
            >
              <MenuItem>
                <Stack
                  flex={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography>{getProjectNameReturn.name}</Typography>
                  <Typography fontSize="small" color="text.secondary">
                    {getProjectNameReturn.year}
                  </Typography>
                </Stack>
              </MenuItem>
            </Link>
          );
        })}
      </Menu>
      <Menu
        anchorEl={groupAnchorEl}
        open={groupOpen}
        onClose={handleCloseGroup}
        onClick={handleCloseGroup}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            maxHeight: "50%",
            overflowY: "auto",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {Object.keys(groupRegions)
          .sort()
          .map((regionName, i) => (
            <div key={`${i}`}>
              <Typography sx={{ pl: 2, background: "black", color: "white" }}>
                {regionName === "undefined" ? "No region" : regionName}
              </Typography>

              {groupRegions[regionName].sort(compareYearAndName).map((m) => {
                const getProjectNameReturn = getProjectName(m.id);
                return (
                  <Link
                    key={m.id}
                    to={`/${m.id}${
                      location.pathname.includes("/landing") ? "/landing" : ""
                    }`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <MenuItem>
                      <Stack
                        flex={1}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Typography>{getProjectNameReturn.name}</Typography>
                        <Typography fontSize="small" color="text.secondary">
                          {getProjectNameReturn.year}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  </Link>
                );
              })}
            </div>
          ))}
      </Menu>
    </div>
  );
}
