import { useProjectImageLists } from "./hooks";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useLanguage } from "hooks";
import translations from "./translations";

type ProjectImageListType = ReturnType<
  typeof useProjectImageLists
>["list"][number];

type Props = {
  selectedList: ProjectImageListType | null;
  imageList: ProjectImageListType[];
  onSelect: (list: ProjectImageListType | null) => void;
};

export default function ImageListSelector({
  imageList,
  selectedList,
  onSelect,
}: Props) {
  const { language } = useLanguage();
  return (
    <FormControl>
      <FormLabel>{translations.ImageLists[language]}</FormLabel>
      <RadioGroup
        value={selectedList?.id}
        onChange={(e) => {
          const selected = imageList.find(
            (list) => list.id === Number(e.target.value)
          );
          // @ts-ignore
          onSelect(selected);
        }}
      >
        {imageList.map((list) => (
          <FormControlLabel
            data-testid={`imageList.${list.id}`}
            key={list.id}
            value={list.id}
            control={<Radio />}
            label={`${list.title} (${list.images.length} ${translations.Images[language]})`}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
