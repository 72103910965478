import React from "react";

type Props = {
  blok: any;
};

export default function CMSVideo({ blok }: Props) {
  return (
    <iframe
      style={{ aspectRatio: "1.7777777777" }}
      width="100%"
      height="auto"
      src={blok.src}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  );
}
