import React, { ReactElement } from "react";
import translations from "translations";
import { ResponsiveRadar } from "@nivo/radar";
import { Box } from "@mui/material";
import { useLanguage } from "hooks";

interface Props {
  issueCategoryCount: Array<any>;
  // @ts-ignore
  sx?;
  // @ts-ignore
  hideTitle?;
}
// @ts-ignore
const MyResponsiveRadar = ({ data, language }) => (
  <ResponsiveRadar
    data={data}
    keys={["items"]}
    indexBy={`${(language === "NO" ? "EN" : language).toLowerCase()}_name`}
    margin={{ top: 70, right: 80, bottom: 50, left: 80 }}
    borderColor={{ from: "color" }}
    gridLabelOffset={16}
    dotSize={10}
    dotColor={{ theme: "background" }}
    dotBorderWidth={2}
    colors={{ scheme: "nivo" }}
    blendMode="multiply"
    motionConfig="wobbly"
  />
);

export default function IssueCategories({
  issueCategoryCount,
  sx,
  hideTitle,
}: Props): ReactElement {
  const { language } = useLanguage();
  return (
    <>
      {issueCategoryCount.length > 0 && (
        <Box
          sx={{
            width: 500,
            height: 550,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 1,
            background: "#fff",
            borderColor: "rgba(0, 0, 0, 0.24)",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 20px 0 rgba(0, 0, 0, 0.12)",
            padding: 2.5,
            ...sx,
          }}
        >
          {!!!hideTitle && (
            <h2>
              {
                translations.Menu.Statistics.MachineLearning.IssueCategories[
                  language
                ]
              }
            </h2>
          )}
          <div style={{ height: sx?.height ?? 300 }}>
            <MyResponsiveRadar data={issueCategoryCount} language={language} />
          </div>
        </Box>
      )}
    </>
  );
}
