import React from "react";
import styled from "styled-components";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import ImageCounter from "./ImageCounter";
import TextBar from "./TextBar";

const Container = styled.div`
	display: inline-flex;
	flex-direction: column;
	margin-left: 14px;
	align-items: center;
	z-index: 50;
	margin-top: 15px;
	position: relative;
`;

const Controls = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 8px;

	&:after {
		content: "";
		position: absolute;
		height: 50px;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
		background: rgba(255, 255, 255, 0.72);
		border-radius: 8px;
		z-index: -1;
	}
`;

const ActionsWrapper = styled.div`
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	margin: 0 10px;
`;

const PrimaryActionsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
`;

const SecondaryActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 5px 8px;
	background: #006FEB;
	border-radius: 8px;
`;

const StyledNavigate = styled.div`
  align-self: center;
  transition: .3s;
  &:hover {
    cursor: pointer;
    transform: scale(1.08);
  }
`;

const StyledNavigateBefore = styled(StyledNavigate).attrs({
  as: NavigateBefore,
})``;

const StyledNavigateNext = styled(StyledNavigate).attrs({ as: NavigateNext })``;

interface IProps {
  currentIndex: number;
  navigateToNextImage: () => void;
  navigateToPrevImage: () => void;
  goToImageByIndex: (index: number) => void;
  imageIds: number[];
  primaryActions: React.ReactNode;
  secondaryActions: React.ReactNode;
  loading: boolean;
}
export default function ToolsContainer(props: IProps) {
  const {
    currentIndex,
    navigateToNextImage,
    navigateToPrevImage,
    goToImageByIndex,
    imageIds,
    primaryActions,
    secondaryActions,
    loading,
  } = props;

  if (loading) {
    return (
      <Container>
        <TextBar data-testid="reviewTools.loading">
          Loading images to review...
        </TextBar>
      </Container>
    );
  }
  if (!loading && !imageIds.length) {
    return (
      <Container>
        <TextBar data-testid="reviewTools.noImages">
          No images to review
        </TextBar>
      </Container>
    );
  }

  return (
    <Container>
      <Controls>
        <Tooltip title="Go to previous image" placement="left" arrow>
          <StyledNavigateBefore
            // @ts-ignore
            fontSize="large"
            sx={{ color: "#006FEB" }}
            onClick={navigateToPrevImage}
            data-testid="reviewTools.navigateToPrevImage"
          />
        </Tooltip>

        <ActionsWrapper>
          <PrimaryActionsWrapper>{primaryActions}</PrimaryActionsWrapper>
          <SecondaryActionsWrapper>{secondaryActions}</SecondaryActionsWrapper>
        </ActionsWrapper>

        <Tooltip title="Go to next image" placement="right" arrow>
          <StyledNavigateNext
            // @ts-ignore
            fontSize="large"
            sx={{ color: "#006FEB" }}
            onClick={navigateToNextImage}
            data-testid="reviewTools.navigateToNextImage"
          />
        </Tooltip>
      </Controls>
      <ImageCounter
        onClick={goToImageByIndex}
        currentIndex={currentIndex}
        count={imageIds.length}
      />
    </Container>
  );
}
