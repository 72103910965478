import React from "react";
import { Stack } from "@mui/material";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useLocation } from "react-router-dom";

type Props = {
  blok: any;
};

export default function CMSMenuGroup({ blok }: Props) {
  const location = useLocation();

  const active = location.pathname.includes(blok.to.cached_url);
  return (
    // @ts-ignore
    <details open={active}>
      <summary style={{ padding: 8, cursor: "pointer" }}>{blok.title}</summary>
      <Stack sx={{ ml: 2 }}>
        {blok.items.map(
          // @ts-ignore
          (item) => (
            <StoryblokComponent key={item._uid} blok={item} />
          )
        )}
      </Stack>
    </details>
  );
}
