import { connect } from "react-redux";
import Component from "./FlagImage";
import { RootState } from "state/store";
import { flagImageForReannotation } from "state/actions";
import { ImageMeta } from "interfaces";

interface IDispatchProps {
  flagImageForReannotation: (
    image: number,
    value: boolean,
    callback: Function
  ) => void;
}

interface IStateProps {
  imageMeta: ImageMeta;
}

interface IOwnProps {}

const mapStateToProps = (state: any, ownProps: IOwnProps) => {
  return {
    imageMeta: state.image.current,
  };
};

const mapDispatchToProps = {
  flagImageForReannotation,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps, // @ts-ignore
  mapDispatchToProps
)(Component);
