import { connect } from "react-redux";
import ImageLists from "./ImageLists";
import { RootState } from "state/store";
import { updateMarkers } from "state/actions";

interface IStateProps {
  image: number;
  lists: {
    id: number;
    title: string;
    images: number[];
  }[];
  userId: number;
}

interface IDispatchProps {
  updateMarkers: () => void;
}

interface IOwnProps {
  compact?: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    image: state.image?.current?.id || null,
    lists: state.image.lists,
    userId: state.user.id,
  };
};

const mapDispatchToProps = {
  updateMarkers,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(ImageLists);
