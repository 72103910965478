export default function ActiveDot({ active }: { active: boolean }) {
  if (!active) return null;

  return (
    <div
      style={{
        display: "block",
        position: "absolute",
        top: -5,
        right: -10,
        width: 8,
        height: 8,
        background: "green",
        borderRadius: "50%",
        zIndex: 100,
      }}
    />
  );
}
