import React from "react";

const NODE_HEADING = "heading";
const NODE_CODEBLOCK = "code_block";
const NODE_PARAGRAPH = "paragraph";
const NODE_QUOTE = "blockquote";
const NODE_OL = "ordered_list";
const NODE_UL = "bullet_list";
const NODE_LI = "list_item";
const NODE_HR = "horizontal_rule";
const NODE_BR = "hard_break";
const NODE_IMAGE = "image";
const MARK_BOLD = "bold";
const MARK_ITALIC = "italic";
const MARK_STRIKE = "strike";
const MARK_UNDERLINE = "underline";
const MARK_CODE = "code";
const MARK_LINK = "link";
const MARK_STYLED = "styled";
// @ts-ignore
function render(document, options: any = {}) {
  if (
    typeof document === "object" &&
    document.type === "doc" &&
    Array.isArray(document.content)
  ) {
    const {
      blokResolvers = {},
      defaultBlokResolver = () => null,
      nodeResolvers: customNodeResolvers = {},
      markResolvers: customMarkResolvers = {},
      // @ts-ignore
      textResolver = (str) => str,
    } = options;

    const nodeResolvers = {
      ...defaultNodeResolvers,
      ...customNodeResolvers,
    };

    const markResolvers = {
      ...defaultMarkResolvers,
      ...customMarkResolvers,
    };

    let currentKey = 0;
    // @ts-ignore
    const addKey = (element) =>
      React.isValidElement(element)
        ? React.cloneElement(element, { key: currentKey++ })
        : element;
    // @ts-ignore
    const renderNodes = (nodes) => {
      const elements = nodes // @ts-ignore
        ? nodes.map(renderNode).filter((node) => node !== null)
        : null;
      return Array.isArray(elements) && elements.length === 0 ? null : elements;
    };
    // @ts-ignore
    const renderNode = (node) => {
      if (node.type === "blok") {
        const { body } = node.attrs; // @ts-ignore
        return body.map(({ component, ...props }) => {
          const resolver = blokResolvers[component];
          const element = resolver
            ? resolver(props)
            : defaultBlokResolver(component, props);
          return addKey(element);
        });
      } else {
        // @ts-ignore
        let childNode;
        if (node.type === "text") {
          childNode = textResolver(node.text);
        } else {
          const resolver = nodeResolvers[node.type];
          childNode = resolver
            ? addKey(resolver(renderNodes(node.content), node.attrs))
            : null;
        }
        const marks = node.marks ?? []; // @ts-ignore
        return marks.reduceRight((children, mark) => {
          const resolver = markResolvers[mark.type];
          return resolver ? addKey(resolver(children, mark.attrs)) : children;
        }, childNode);
      }
    };

    return renderNodes(document.content);
  } else if (typeof document === "string") {
    const {
      // @ts-ignore
      defaultStringResolver = (str) => str, // @ts-ignore
      textResolver = (str) => str,
    } = options;
    return defaultStringResolver(textResolver(document));
  }
  return null;
}
// @ts-ignore
const simpleNodeResolver = (element) => (children) =>
  children !== null ? React.createElement(element, null, children) : null;
// @ts-ignore
const emptyNodeResolver = (element) => () => React.createElement(element);
// @ts-ignore
const headingNodeResolver = (children, props) =>
  React.createElement(`h${props.level}`, null, children);

const imageNodeResolver = (
  // @ts-ignore
  children,
  // @ts-ignore
  props // @ts-ignore
) => React.createElement("img", props, children);
// @ts-ignore
const codeblockNodeResolver = (children, props) => {
  const codeProps = { className: props.class };
  const code = React.createElement("code", codeProps, children);
  return React.createElement("pre", null, code);
};
// @ts-ignore
const simpleMarkResolver = (element) => (children) =>
  React.createElement(element, null, children);
// @ts-ignore
const linkMarkResolver = (children, { linktype, href, target }) => {
  const props = {
    href: linktype === "email" ? `mailto:${href}` : href,
    target,
  };
  return React.createElement("a", props, children);
};
// @ts-ignore
const styledMarkResolver = (children, props) =>
  React.createElement("span", { className: props.class }, children);

const defaultNodeResolvers = {
  [NODE_HEADING]: headingNodeResolver,
  [NODE_CODEBLOCK]: codeblockNodeResolver,
  [NODE_IMAGE]: imageNodeResolver,
  [NODE_PARAGRAPH]: simpleNodeResolver("p"),
  [NODE_QUOTE]: simpleNodeResolver("blockquote"),
  [NODE_OL]: simpleNodeResolver("ol"),
  [NODE_UL]: simpleNodeResolver("ul"),
  [NODE_LI]: simpleNodeResolver("li"),
  [NODE_HR]: emptyNodeResolver("hr"),
  [NODE_BR]: emptyNodeResolver("br"),
};

const defaultMarkResolvers = {
  [MARK_LINK]: linkMarkResolver,
  [MARK_STYLED]: styledMarkResolver,
  [MARK_BOLD]: simpleMarkResolver("b"),
  [MARK_ITALIC]: simpleMarkResolver("i"),
  [MARK_STRIKE]: simpleMarkResolver("s"),
  [MARK_UNDERLINE]: simpleMarkResolver("u"),
  [MARK_CODE]: simpleMarkResolver("code"),
};
// @ts-ignore
function Content({ blok }) {
  return (
    <div className="bg-white w-full" key={blok._uid}>
      <div className="container mx-auto py-12">{render(blok.rich_text)}</div>
    </div>
  );
}

export default Content;
