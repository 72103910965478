import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  Select as MuiSelect,
  FormControl,
} from "@mui/material";
import { useAdminProjects, useLanguage } from "hooks";
import { useAdminGroups } from "hooks/projects/useAdminGroups";
import Select from "react-select";
import React, { useState } from "react";
import { Add, Delete, Save } from "@mui/icons-material";
import { authorizedPost, authorizedPut } from "utils/request";
import { toast } from "react-toastify";
import Loading from "views/Loading";
import { groupBy } from "lodash";
import { useOutletContext, useNavigate } from "react-router-dom";
import RoleWrapper from "components/RoleHOC/wrapper";
import translations from "translations";
import { accessLevels, accessLevelsHidden } from "./accessLevels";
import ProjectDropdown from "./ProjectDropdown";

interface IOption {
  value: number;
  label: string;
}

interface IAccess {
  target: IOption;
  group: boolean;
  accessLevel: number;
}

const emailRegex = // eslint-disable-next-line no-control-regex
  /^(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export default function NewUser() {
  const { language } = useLanguage();
  const [projects] = useAdminProjects();
  const { groups } = useAdminGroups();
  const [newEmail, setEmail] = useState<string>("");
  const [newName, setName] = useState<string>("");
  const { updateUsers } = useOutletContext<{ updateUsers: () => void }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [newAccess, setNewAccess] = useState<IAccess[]>([
    {
      // @ts-ignore
      target: null,
      group: false,
      accessLevel: 8,
    },
  ]);
  const navigate = useNavigate();

  async function addUser() {
    const email = newEmail.toLowerCase();
    // Verify that the input is correct
    if (!emailRegex.test(email.toLowerCase())) {
      toast.error(translations.UserAdmin.EmailInvalid[language]);
      return;
    }
    if (newName.length < 3) {
      toast.error(translations.UserAdmin.NameInvalid[language]);
      return;
    }
    // Notify the user that we are starting a request
    setLoading(true);

    // Add the user (nothing happens if they exist)
    try {
      const newUser = await authorizedPost<{
        id: number;
        email: string;
        name: string;
      }>("/admin/user/new", {
        email: newEmail.toLowerCase(),
        name: newName,
      });
      // Loop over the access levels

      for (let i = 0; i < newAccess.length; i++) {
        try {
          const access = newAccess[i];
          // Extract if we are working with a group or project
          const entity = access.group ? "group" : "project";

          // Prepare the API call
          const endpoint = `/admin/user/${newUser.id}/invite/${entity}`;
          const payload: Record<string, number> = {
            accessLevel: access.accessLevel,
          };
          if (access.group) {
            payload.group = access.target.value;
          } else {
            payload.project = access.target.value;
          }

          // Give user access
          await authorizedPut(endpoint, payload);
        } catch (err) {
          console.error("Failed to invite user");
        }
      }
      // Give the new user access
      updateUsers();
      setLoading(false);
      navigate(`/users/${newUser.id}`);
    } catch (err) {
      console.error(err);
      toast.error(
        `${translations.UserAdmin.FailedToInvite[language]} ${newName}`
      );
    }
  }

  const emailValid = emailRegex.test(newEmail.toLowerCase());
  const groupedProjects = groupBy(projects, (p) => p.group?.name);
  return (
    <RoleWrapper keyName="userAdmin">
      <Stack sx={{ p: 2 }} spacing={2}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5" component="h2">
            {translations.UserAdmin.InviteNewUser[language]}
          </Typography>
          <Typography color="text.secondary">
            {translations.UserAdmin.InviteDescription[language]}
          </Typography>
          <Stack>
            <TextField
              type="text"
              variant="standard"
              label={translations.words.Name[language]}
              required
              sx={{ my: 2, width: 400 }}
              value={newName}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              type="email"
              variant="standard"
              label={translations.words.Email[language]}
              required
              error={newEmail.length > 3 && !emailValid}
              sx={{ my: 2, width: 400 }}
              value={newEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Stack>
        </Paper>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5" component="h2">
            {translations.UserAdmin.GiveAccess[language]}
          </Typography>
          <Typography color="text.secondary">
            {translations.UserAdmin.SelectMissions[language]}{" "}
            {newName.length > 0
              ? newName
              : translations.words.TheUser[language]}{" "}
            {translations.UserAdmin.AccessTo[language]}.
          </Typography>
          <Grid container sx={{ mt: 2 }} columnSpacing={2} rowSpacing={1}>
            <Grid item xs={2} alignItems="center" display="flex">
              {translations.UserAdmin.GroupProject[language]}
            </Grid>
            <Grid item xs={5} alignItems="center" display="flex">
              {translations.UserAdmin.AccessTarget[language]}
            </Grid>
            <Grid item xs={5} alignItems="center" display="flex">
              {translations.words.AccessLevel[language]}
            </Grid>
            {newAccess.map((a, i) => (
              <React.Fragment key={`${i}`}>
                <Grid item xs={2} alignItems="center" display="flex">
                  <Checkbox
                    checked={a.group}
                    onChange={(e) => {
                      const updatedAccess = [...newAccess];
                      //@ts-ignore
                      updatedAccess[i].group = e.target.checked;
                      // @ts-ignore
                      updatedAccess[i].target = null;
                      setNewAccess(updatedAccess);
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControl sx={{ m: 1, minWidth: 280 }}>
                    {a.group ? (
                      <Select
                        options={groups
                          .map((g) => ({
                            label: g.name,
                            value: g.id,
                          }))
                          .sort((a, b) => (a.label > b.label ? 1 : -1))}
                        value={a.target}
                        onChange={(e) => {
                          const updatedAccess = [...newAccess];
                          // @ts-ignore
                          updatedAccess[i].target = e;
                          setNewAccess(updatedAccess);
                        }}
                      />
                    ) : (
                      <MuiSelect
                        native
                        defaultValue=""
                        id="grouped-native-select"
                        onChange={(e) => {
                          const name = projects.find(
                            (project) => project.id === parseInt(e.target.value)
                          )?.name;

                          const updatedAccess = [...newAccess];
                          updatedAccess[i].target = {
                            // @ts-ignore
                            label: name,
                            value: parseInt(e.target.value),
                          };
                          setNewAccess(updatedAccess);
                        }}
                      >
                        {Object.keys(groupedProjects)
                          .sort()
                          .map((groupName) => (
                            <optgroup
                              label={
                                groupName === "undefined"
                                  ? translations.UserAdmin.NoGroup[language]
                                  : groupName
                              }
                            >
                              {groupedProjects[groupName]
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((project) => (
                                  <ProjectDropdown projectID={project.id} />
                                ))}
                            </optgroup>
                          ))}
                      </MuiSelect>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4} mt={1}>
                  <Select
                    value={{
                      // @ts-ignore
                      label: accessLevelsHidden[a.accessLevel][language],
                      value: a.accessLevel,
                    }}
                    onChange={(e) => {
                      const updatedAccess = [...newAccess];
                      // @ts-ignore
                      updatedAccess[i].accessLevel = e.value;
                      setNewAccess(updatedAccess);
                    }}
                    options={Object.keys(accessLevels).map((key) => ({
                      // @ts-ignore
                      label: accessLevels[parseInt(key)][language],
                      value: parseInt(key),
                    }))}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      setNewAccess(newAccess.filter((a, j) => j !== i));
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setNewAccess([
                    // @ts-ignore
                    ...newAccess,
                    // @ts-ignore
                    { group: false, target: null, accessLevel: 8 },
                  ]);
                }}
              >
                <Add />
                <Typography>
                  {translations.UserAdmin.AddAccessLevel[language]}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5" component="h2">
            {translations.UserAdmin.SendInvite[language]}
          </Typography>
          <Typography sx={{ mb: 1 }}>
            {translations.UserAdmin.EmailWillBeSent[language]}{" "}
            <b>{newEmail.toLowerCase()}</b>{" "}
            {translations.UserAdmin.PasswordWillBeSent[language]}
          </Typography>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              color="success"
              onClick={addUser}
              endIcon={loading ? <Loading relative size={20} /> : <Save />}
              sx={{ alignSelf: "right" }}
              disabled={
                !!(newName.length < 3) ||
                !emailValid ||
                loading ||
                !newAccess.every((a) => !!a.target && a.accessLevel)
              }
            >
              {translations.UserAdmin.SendInvite[language]}
            </Button>
          </Stack>
        </Paper>
      </Stack>
    </RoleWrapper>
  );
}
