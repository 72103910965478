import { axiosInstance } from "utils/request";
import { IClearanceAxis } from "./clearanceAxis";

interface IClearanceSystem {
  axes: IClearanceAxis[];
  global: boolean;
  id: number;
  name: string;
}

interface IGetClearanceSystem {
  systems: IClearanceSystem[];
}

export async function getClearanceSystems(projectID: number) {
  const endpoint = `/lidar/clearance/system/${projectID}`;
  const response = await axiosInstance.get<IGetClearanceSystem>(endpoint);

  if (response.status !== 200) {
    throw new Error(
      `Failed to collect clearance axis for project ${projectID}`
    );
  }

  return response.data.systems;
}

export default {
  getClearanceSystems,
} as const;
