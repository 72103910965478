import { bbox, length, lineString } from "@turf/turf";
import axios from "axios";
import { axiosInstance } from "utils/request";
import { toGmapBounds } from "utils/utils";

export async function getPowerlineUploadSignedURL(
  // @ts-ignore
  projectID: number = null,
  filetype: "kml" | "geojson" | "zip" = "kml"
) {
  const endpoint = `/project/${
    projectID ?? 0
  }/powerline/parse/signed_url?filetype=${filetype}`;
  const response = await axiosInstance.get<string>(endpoint);
  return response.data;
}

export async function uploadFileToS3(
  files: FileList,
  projectID: number | null
) {
  const image_data = new FormData();
  const urlLocations = [];
  for (let i = 0; i < files.length; i++) {
    const file = files.item(i); // @ts-ignore
    const fileExtension = file.name.split(".").pop();
    if (
      fileExtension !== "kml" &&
      fileExtension !== "geojson" &&
      fileExtension !== "zip"
    ) {
      throw new Error(`Invalid file type: ${fileExtension}`);
    }
    const signedURL = await getPowerlineUploadSignedURL(
      // @ts-ignore
      projectID,
      fileExtension
    );
    urlLocations.push(signedURL); // @ts-ignore
    image_data.append("file", file);
    await axios.put(signedURL, file);
  }
  return urlLocations;
}

export async function startPowerlineParse(
  projectId: number,
  urlLocations: string[]
) {
  const response = await axiosInstance.post<{ task_id: string }>(
    `/project/${projectId ?? 0}/powerline/parse`,
    {
      s3_link: urlLocations[0],
    }
  );
  if (response.status < 400) {
    return response.data;
  } else {
    throw new Error(`Failed to process KML: ${response.statusText}`);
  }
}
export async function getGeoJson(projectId: number, taskId: string) {
  const response = await axiosInstance.get(
    `/project/${projectId ?? 0}/powerline/results/${taskId}`
  );
  const signedUrl = response.data;

  const geoJson = await axios.get(signedUrl);
  return geoJson.data;
}

export async function checkPowerlineParseStatus(
  projectId: number,
  taskID: string
) {
  const response = await axiosInstance.get<{
    status: "WAITING" | "IN PROGRESS" | "SUCCESS" | "FAILURE";
    progress: string;
  }>(`/project/${projectId ?? 0}/powerline/parse/${taskID}`);
  return response.data;
}

export async function waitForKmlProcessed(
  projectId: number,
  urlLocations: string[],
  onProgress: (progress: number) => void
) {
  const { task_id } = await startPowerlineParse(projectId, urlLocations);

  // Test if the task is complete every 5 seconds
  while (true) {
    const { status, progress } = await checkPowerlineParseStatus(
      projectId,
      task_id
    );
    const progressNumber = Number.parseFloat(progress);
    onProgress(progressNumber);
    if (status === "SUCCESS") {
      return task_id;
    } else if (status === "FAILURE") {
      throw new Error("Failed to process KML");
    }
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }
}
// @ts-ignore
export async function loadPowerlineFile(gmap: google.maps.Map, geojson) {
  const mapBounds = toGmapBounds(bbox(geojson));
  if (!gmap) return;
  // biome-ignore lint/complexity/noForEach: Google maps isnt iterative
  gmap.data.forEach((feature) => {
    gmap.data.remove(feature);
  });
  gmap.fitBounds(mapBounds);
  gmap.data.addGeoJson(geojson);

  // Hide all markers and poles
  gmap.data.setStyle((feature) => {
    // @ts-ignore
    if (feature.getGeometry().getType() === "Point") {
      return {
        visible: false,
      };
    } else {
      return { strokeColor: "red", strokeWeight: 1 };
    }
  });
}

export async function getFileLength(
  gmap: google.maps.Map,
  propertyTag: string
) {
  const files = {};

  if (!gmap) return;
  // biome-ignore lint/complexity/noForEach: google maps isnt iterative
  gmap.data.forEach((feature: google.maps.Data.Feature) => {
    const fileKey = feature.getProperty(propertyTag);
    if (!fileKey) {
      return;
    } // @ts-ignore
    const geometryType = feature.getGeometry().getType();

    if (geometryType === "Point") {
      const fileKey = feature.getProperty(propertyTag);
      if (fileKey in files) {
        // @ts-ignore
        files[fileKey].poles += 1;
      } else {
        // @ts-ignore
        files[fileKey] = {
          poles: 1,
          powerlines: 0,
        };
      }
    } else if (geometryType === "LineString") {
      const geometry = feature.getGeometry(); // @ts-ignore
      const lineCoordinates = []; // @ts-ignore
      geometry.forEachLatLng((latlng) => {
        lineCoordinates.push([latlng.lng(), latlng.lat()]);
      }); // @ts-ignore
      const line = lineString(lineCoordinates);
      const featureLength = length(line, { units: "kilometers" });
      if (fileKey in files) {
        // @ts-ignore
        files[fileKey].powerlines += featureLength;
      } else {
        // @ts-ignore
        files[fileKey] = {
          poles: 0,
          powerlines: featureLength,
        };
      }
    }
  });

  const returnList: {
    name: string;
    poles: number;
    distance: number;
  }[] = [];

  for (const key of Object.keys(files)) {
    returnList.push({
      name: key, // @ts-ignore
      poles: files[key].poles, // @ts-ignore
      distance: files[key].powerlines,
    });
  }
  return returnList;
}

export async function getFeatureKeys(gmap: google.maps.Map) {
  const poleKeys: Record<string, string[]> = {};
  const powerlineKeys: Record<string, string[]> = {};

  if (!gmap) return;
  // biome-ignore lint/complexity/noForEach: google maps isnt iterative
  gmap.data.forEach((feature: google.maps.Data.Feature) => {
    const geometry = feature.getGeometry(); // @ts-ignore
    const geometryType = geometry.getType();
    const isPole = geometryType === "Point";
    const isPowerline = geometryType === "LineString";

    feature.forEachProperty((value, key) => {
      if (value) {
        if (isPole) {
          if (key in poleKeys && poleKeys[key].length < 3) {
            poleKeys[key] = [...poleKeys[key], value];
          } else {
            poleKeys[key] = [value];
          }
        }
        if (isPowerline) {
          if (key in powerlineKeys && powerlineKeys[key].length < 3) {
            powerlineKeys[key] = [...powerlineKeys[key], value];
          } else {
            powerlineKeys[key] = [value];
          }
        }
      }
    });
  });

  return { poleKeys, powerlineKeys };
}

export function highlightFile(
  gmap: google.maps.Map,
  filename: string,
  propertyTag: string
) {
  gmap.data.setStyle((feature) => {
    if (feature.getProperty(propertyTag) === filename) {
      // @ts-ignore
      if (feature.getGeometry().getType() === "Point") {
        return { zIndex: 2, visible: true };
      } else {
        return { strokeColor: "white", strokeWeight: 2, zIndex: 5 };
      }
    } else {
      // @ts-ignore
      if (feature.getGeometry().getType() === "Point") {
        return { zIndex: 2, visible: false };
      } else {
        return { strokeColor: "red", strokeWeight: 1, zIndex: 5 };
      }
    }
  });
}

export function removeFile(
  gmap: google.maps.Map,
  filename: string,
  propertyTag: string
) {
  if (!gmap) return;
  // biome-ignore lint/complexity/noForEach: Google maps is not iterative
  gmap.data.forEach((feature) => {
    if (feature.getProperty(propertyTag) === filename) {
      gmap.data.remove(feature);
    }
  });
}
