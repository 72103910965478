import { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import {
  useDefectCount,
  useSelector,
  useObjectTypeFilter,
  useDetectionCount,
} from "hooks";
import ExpandedSelect, { IOptionCategory } from "../ExpandedSelect";
import { getClientObjectTypeName } from "utils/utils";

type TypeSelectFilterProps = {
  issue: boolean;
  vegetation: boolean;
};

function useConditionalCount(issue: boolean) {
  const defectCount = useDefectCount();
  const detectionCount = useDetectionCount();

  return issue ? defectCount : detectionCount;
}

export default function TypeSelectFilter({
  issue,
  vegetation,
}: TypeSelectFilterProps) {
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const vegetationCategories = useSelector(
    (state) => state.objects.vegetationCategories
  );
  const issueCategories = useSelector((state) => state.objects.issueCategories);
  const clientNaming = useSelector((state) => state.user.client_naming);
  const detectedCategories = useSelector(
    (state) => state.objects.detectedCategories
  );
  const { objectTypeFilter, setObjectTypes } = useObjectTypeFilter();
  const { counts, loading } = useConditionalCount(issue);

  // Filter the object types based on vegetation or issue flag
  const filteredObjectTypes = useMemo(() => {
    let categoryType: typeof issueCategories;
    if (vegetation) {
      categoryType = vegetationCategories;
    } else if (issue) {
      categoryType = issueCategories;
    } else {
      categoryType = detectedCategories;
    }
    return objectTypes.filter((objectType) =>
      categoryType.some((category) => category.id === objectType.category)
    );
  }, [
    vegetation,
    issue,
    objectTypes,
    vegetationCategories,
    issueCategories,
    detectedCategories,
    clientNaming,
  ]);

  // Combine categories and objects to be used in the select component (needed for client naming)
  const combinedCategorysResult = useMemo(() => {
    return filteredObjectTypes.reduce((acc: IOptionCategory[], objectType) => {
      const objectTypeCount = counts.find((c) => c.id === objectType.id);
      const objectTypeClientData = getClientObjectTypeName(objectType.id);

      // Try to find the category
      let category = acc.find(
        (cat) => cat.id === objectTypeClientData.category_id
      );

      // If the category doesn't exist, add it
      if (!category) {
        category = {
          id: objectTypeClientData.category_id,
          name: objectTypeClientData.category_name,
          count: 0,
          options: [],
          sorting_order: objectTypeClientData.category_sorting,
        };
        acc.push(category);
      }

      // Try to find the object type within the category
      const objectTypeExists = category.options.find(
        (opt) => opt.id === objectTypeClientData.client_object_type_id
      );

      if (objectTypeExists) {
        // If the object type exists, update it
        objectTypeExists.count += objectTypeCount?.count ?? 0;
        objectTypeExists.selectIds.push(objectTypeClientData.id);
      } else {
        // Otherwise, add a new object type to the category
        category.options.push({
          id: objectTypeClientData.client_object_type_id,
          name: objectTypeClientData.name,
          count: objectTypeCount?.count ?? 0,
          selectIds: [objectTypeClientData.id],
        });
      }

      return acc;
    }, []);
  }, [filteredObjectTypes, counts]);

  return (
    <Stack mx={2} borderRadius={1}>
      <ExpandedSelect
        categories={combinedCategorysResult}
        value={objectTypeFilter}
        onChange={setObjectTypes}
        loading={loading}
      />
    </Stack>
  );
}
