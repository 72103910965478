import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addDays,
  isSameMonth,
  isSameDay,
} from "date-fns";
import { Stack, Typography } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import translations from "translations";
import { useLanguage } from "hooks";

const monthMap = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface IDateSelectorProps {
  /**
   * Indicate if the date picker should be a range picker
   */
  isRange?: boolean;
  /**
   * The color of the selected date or dates
   */
  selectedColor?: string;
  /**
   * The border color of today's date
   */
  todayColor?: string;
  /**
   * The color of the text in the visible month
   */
  activeTextColor?: string;
  /**
   * The color of the text in the inactive months
   */
  inactiveTextColor?: string;
  /**
   * The function to call when the date is changed
   */
  onChange?: (startDate: Date, endDate: Date | null) => void;
  /**
   * The value of the date picker
   */
  value?: Date | [Date, Date];
}

/**
 * A date picker component that allows to select either single date or a range of dates
 */
function DatePicker({
  isRange,
  selectedColor = "#77ddff",
  todayColor = "#0066cc",
  activeTextColor = "#404040",
  inactiveTextColor = "#a0a0a0",
  onChange,
  value,
}: IDateSelectorProps) {
  const { language } = useLanguage();
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [lastHoveredDate, setLastHoveredDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        setSelectedStartDate(value[0]);
        setSelectedEndDate(value[1]);
      } else {
        setSelectedStartDate(value);
      }
    }
  }, [value]);

  const startDateString = selectedStartDate?.toISOString() ?? "";
  const endDateString = selectedEndDate?.toISOString() ?? "";

  useEffect(() => {
    if (onChange) {
      // @ts-ignore
      onChange(selectedStartDate, selectedEndDate);
    }
  }, [startDateString, endDateString]);
  // @ts-ignore
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // @ts-ignore
  const handleYearChange = (increment) => {
    setSelectedYear(selectedYear + increment);
  };
  // @ts-ignore
  const handleMonthChange = (increment) => {
    const newMonth = selectedMonth + increment;
    setSelectedMonth(newMonth < 0 ? 11 : newMonth % 12);
  };
  // @ts-ignore
  function onDateClicked(date) {
    if (isRange) {
      if (
        // @ts-ignore
        date < selectedStartDate ||
        selectedStartDate === null ||
        selectedEndDate
      ) {
        setSelectedStartDate(date);
        setSelectedEndDate(null);
      } else if (date > selectedStartDate) {
        setSelectedEndDate(date);
      }
    } else {
      setSelectedStartDate(date);
    }
  }

  const firstDayOfMonth = startOfMonth(
    new Date(selectedYear, selectedMonth, 1)
  );
  const today = new Date();
  const lastDayOfMonth = endOfMonth(new Date(selectedYear, selectedMonth, 1));
  const startDate = startOfWeek(firstDayOfMonth);
  const endDate = endOfWeek(lastDayOfMonth);
  const days = [];

  let currentDate = startDate;
  while (currentDate <= endDate) {
    days.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }

  const selectedStartDateIsValid = selectedStartDate instanceof Date;
  const selectedEndDateIsValid = selectedEndDate instanceof Date;

  return (
    <div>
      <Button
        startIcon={<CalendarMonth />}
        onClick={handleClick}
        variant="outlined"
      >
        {isRange && selectedStartDateIsValid
          ? `${selectedStartDate.toDateString()} - ${
              selectedEndDateIsValid ? selectedEndDate.toDateString() : ""
            }`
          : selectedStartDate
            ? selectedStartDate.toDateString()
            : translations.Filter.SelectDate[language]}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <IconButton onClick={() => handleYearChange(-1)}>
            <KeyboardDoubleArrowLeft />
          </IconButton>
          {selectedYear}
          <IconButton onClick={() => handleYearChange(1)}>
            <KeyboardDoubleArrowRight />
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <IconButton onClick={() => handleMonthChange(-1)}>
            <KeyboardArrowLeft />
          </IconButton>
          {monthMap[selectedMonth]}
          <IconButton onClick={() => handleMonthChange(1)}>
            <KeyboardArrowRight />
          </IconButton>
        </Stack>
        {/* Limit the days to only 7 items per row*/}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)",
            gap: 0.5,
          }}
        >
          {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
            <Typography
              key={day}
              sx={{
                textAlign: "center",
                color: activeTextColor,
                fontWeight: "bold",
              }}
            >
              {day}
            </Typography>
          ))}
          {days.map((day) => {
            const isHovered =
              lastHoveredDate && // @ts-ignore
              day <= lastHoveredDate && // @ts-ignore
              day >= selectedStartDate;
            const isInRange =
              selectedStartDate &&
              selectedEndDate &&
              day >= selectedStartDate &&
              day <= selectedEndDate;

            const isToday = isSameDay(day, today);

            // If a start date has been set and the isRange is true,
            // then the end date should be the last hovered date
            // if it is not set.
            let isSelected = false;
            if (isRange && selectedStartDate && !selectedEndDate && isHovered) {
              isSelected = true;
            } // @ts-ignore
            if (isSameDay(day, selectedStartDate)) {
              isSelected = true;
            }
            if (isInRange) {
              isSelected = true;
            }

            return (
              <Button
                // @ts-ignore
                key={day}
                onClick={() => onDateClicked(day)}
                variant={"text"} // @ts-ignore
                onMouseEnter={() => setLastHoveredDate(day)}
                sx={{
                  // Color if it is the shown month
                  color: isSameMonth(day, firstDayOfMonth)
                    ? activeTextColor
                    : inactiveTextColor,
                  width: 10,
                  p: 0,
                  m: 0,
                  // Color it light blue if it is the selected day.
                  // Color it dark blue if it is today
                  // The selected value is higher priority than today
                  // Other days are transparent
                  backgroundColor: isSelected ? selectedColor : "transparent",
                  borderWidth: 2,
                  borderColor: isToday ? todayColor : "transparent",
                  borderStyle: "solid",
                  "&:hover": {
                    backgroundColor: isSelected ? selectedColor : "transparent",
                    borderWidth: 2,
                    borderColor: isToday ? todayColor : "transparent",
                    borderStyle: "solid",
                  },
                }}
              >
                {day.getDate()}
              </Button>
            );
          })}
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={1}
          m={1}
        >
          <Button
            onClick={() => {
              setSelectedStartDate(null);
              setSelectedEndDate(null);
            }}
          >
            Clear
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </Stack>
      </Popover>
    </div>
  );
}

export default DatePicker;
