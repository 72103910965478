import React from "react";
import RoleWrapper from "components/RoleHOC/wrapper";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { Flag } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { ImageMeta } from "interfaces";
import { useLanguage } from "hooks";
type Props = {
  imageMeta: ImageMeta;
  flagImageForReannotation: (
    image: number,
    value: boolean,
    callback: Function
  ) => void;
};

export default function FlagImage({
  flagImageForReannotation,
  imageMeta,
}: Props) {
  const { language } = useLanguage();
  const params = useParams();
  // @ts-ignore
  const image = parseInt(params.image);
  return !!imageMeta ? (
    <RoleWrapper keyName="flagImageForReannotation">
      <MenuItem
        tooltip={translations.ImageViewer.Flag[language]}
        onClick={() => flagImageForReannotation(image, true, () => {})}
        id="image-flag-for-reannotation"
      >
        <Flag
          style={{
            color: imageMeta.flagged ? "red" : undefined,
          }}
        />
      </MenuItem>
    </RoleWrapper>
  ) : null;
}
