import { Stack } from "@mui/material";
import {
  useDefectCount,
  useSelector,
  useObjectTypeFilter,
  useLanguage,
  useDetectionCount,
} from "hooks";
import ExpandedSelect, { IOptionCategory } from "../ExpandedSelect";
import { getClientObjectTypeName } from "utils/utils";

type TypeSelectFilterProps = {
  issue: boolean;
  vegetation: boolean;
};

// @ts-ignore
function useConditionalCount(issue) {
  // Hooks are called at the top level, but their results are conditionally returned
  const defectCount = useDefectCount();
  const detectionCount = useDetectionCount();

  return issue ? defectCount : detectionCount;
}

export default function TypeSelectFilter({
  issue,
  vegetation,
}: TypeSelectFilterProps) {
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const vegetationCategories = useSelector(
    (state) => state.objects.vegetationCategories
  );
  const issueCategories = useSelector((state) => state.objects.issueCategories);
  const detectedCategories = useSelector(
    (state) => state.objects.detectedCategories
  );

  let categoryType = [];

  if (vegetation) {
    categoryType = vegetationCategories;
  } else {
    categoryType = issue ? issueCategories : detectedCategories;
  }

  const { language } = useLanguage();
  const { objectTypeFilter, setObjectTypes } = useObjectTypeFilter();
  const { counts, loading } = useConditionalCount(issue);

  const options: IOptionCategory[] = [];
  for (const category of categoryType) {
    const categoryOption = {
      id: category.id,
      // @ts-ignore
      name: category[
        `${(language === "NO" ? "EN" : language).toLowerCase()}_name`
      ],
      count: 0,
      options: [],
      sorting_order: category.sorting_order,
    };

    for (const objectType of objectTypes.filter(
      (ot) => ot.category === category.id
    )) {
      const count = counts.find((c) => c.id === objectType.id);
      const clientName = getClientObjectTypeName(objectType.id);
      //Negative category id means it is a client category
      if (clientName.category_id < 0) {
        //Check if the category is already added
        const clientCategoryOption = options.find(
          (o) => o.id === clientName.category_id
        );
        //Add the category if it does not exist
        if (!clientCategoryOption) {
          options.push({
            id: clientName.category_id,
            name: clientName.category_name,
            count: 0,
            options: [
              {
                id: objectType.id,
                name: clientName.name,
                count: count?.count ?? 0,
              },
            ],
            sorting_order: category.sorting_order,
          });
        } else {
          //Add the type to the existing category
          clientCategoryOption.options.push({
            id: objectType.id,
            name: clientName.name,
            count: count?.count ?? 0,
          });
          const index = options.findIndex(
            (o) => o.id === clientName.category_id
          );
          options[index] = clientCategoryOption;
        }
      } else {
        // @ts-ignore
        categoryOption.options.push({
          id: objectType.id,
          name: clientName.name,
          count: count?.count ?? 0,
        });
      }
    }
    options.push(categoryOption);
  }

  return (
    <Stack mx={2} borderRadius={1}>
      <ExpandedSelect
        categories={options}
        value={objectTypeFilter}
        onChange={setObjectTypes}
        loading={loading}
      />
    </Stack>
  );
}
