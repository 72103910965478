import React, { useContext, useMemo, useState } from "react";
import { context } from "../provider";
import { Stack } from "@mui/material";
import TimeSeparator from "./TimeSeparator";
import Task from "./Task";
import EditTask from "./EditTask";

type Props = {};

export default function Chart({}: Props) {
  const sidebarWidth = 150;
  const { items } = useContext(context);
  const [selectedItem, selectItem] = useState<(typeof items)[number] | null>(
    null
  );
  const startDate = useMemo(() => {
    const dateStrings = items.map((item) => item.start_date);
    const dates = dateStrings.map((dateString) => new Date(dateString));
    const dateEpochs = dates.map((date) => date.getTime());
    return Math.min(...dateEpochs);
  }, [items]);

  const endDate = useMemo(() => {
    const dateStrings = items.map((item) => item.end_date);
    const dates = dateStrings.map((dateString) => new Date(dateString));
    const dateEpochs = dates.map((date) => date.getTime());
    return Math.max(...dateEpochs);
  }, [items]);

  // Iterate between start date to end date
  // and create an array of months and days
  // @ts-ignore
  let years = [];
  // @ts-ignore
  let months = [];
  // @ts-ignore
  let days = [];

  for (let d = new Date(startDate).getTime(); d <= endDate; d += 86400000) {
    // Check if d is the first day of a month

    if (
      (new Date(d).getDate() === 1 && new Date(d).getMonth() === 0) ||
      years.length === 0
    ) {
      years.push({
        epoch: d,
        label: new Date(d).toLocaleDateString("en-US", { year: "numeric" }),
      });
    }
    if (new Date(d).getDate() === 1 || months.length === 0) {
      months.push({
        epoch: d,
        label: new Date(d).toLocaleDateString("en-US", { month: "long" }),
      });
    }

    // Add everyday
    if (
      new Date(d).getDate() !== new Date(d - 86400000).getDate() ||
      days.length === 0
    ) {
      days.push({
        epoch: d,
        label: new Date(d).toLocaleDateString("en-US", {
          day: "numeric",
        }),
      });
    }
  }

  return (
    <Stack width={"100%"}>
      <div
        style={{
          position: "relative",
          paddingBottom: 8,
        }}
      >
        {items
          .sort((a, b) => a.display_order - b.display_order)
          .map((item) => {
            return (
              <Task
                item={item}
                startEpoch={startDate}
                endEpoch={endDate}
                width={sidebarWidth}
                onClick={selectItem}
                selected={selectedItem?.id === item.id}
              />
            );
          })}

        <div
          style={{
            height: 60,
            pointerEvents: "none",
          }}
        />
        {days.map((day, i) => {
          if (days.length > 50) {
            // Only show first day of the week
            if (new Date(day.epoch).getDay() !== 1) {
              return null;
            }
          }
          if (days.length > 100) {
            return null;
          }
          return (
            <TimeSeparator
              label={day.label}
              epoch={day.epoch}
              sidebarWidth={sidebarWidth}
              startEpoch={startDate}
              endEpoch={endDate}
              borderStyle="dotted"
              marginBottom={40}
            />
          );
        })}
        {months.map((month) => {
          if (months.length > 18) {
            // Only show every third month
            if (new Date(month.epoch).getMonth() % 3 !== 0) {
              return null;
            }
          }
          return (
            <TimeSeparator
              label={month.label}
              epoch={month.epoch}
              sidebarWidth={sidebarWidth}
              startEpoch={startDate}
              endEpoch={endDate}
              borderStyle="dashed"
              marginBottom={20}
            />
          );
        })}
        {years.map((year) => {
          // Add a vertical line and a labeln underneath the line
          return (
            <TimeSeparator
              label={year.label}
              epoch={year.epoch}
              sidebarWidth={sidebarWidth}
              startEpoch={startDate}
              endEpoch={endDate}
              borderStyle="solid"
              marginBottom={0}
            />
          );
        })}
      </div>
      <EditTask
        open={!!selectedItem}
        // @ts-ignore
        item={selectedItem}
        onClose={() => {
          selectItem(null);
        }}
      />
    </Stack>
  );
}
