const R = 6371000; // m
// @ts-ignore
function hav(coords1, coords2) {
  // @ts-ignore
  function toRad(x) {
    return (x * Math.PI) / 180;
  }
  var lon1 = coords1[0];
  var lat1 = coords1[1];

  var lon2 = coords2[0];
  var lat2 = coords2[1];

  var x1 = lat2 - lat1;
  var dLat = toRad(x1);
  var x2 = lon2 - lon1;
  var dLon = toRad(x2);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}
// @ts-ignore
export function get_new_lat(ref, x_diff, y_diff) {
  let t_lng = Math.tan(x_diff / (2 * R)) * Math.tan(x_diff / (2 * R));
  let c_lng =
    Math.cos((Math.PI / 180) * ref[1]) * Math.cos((Math.PI / 180) * ref[1]);
  let diff_lng =
    2 * Math.asin(Math.sqrt(t_lng / (c_lng * (1 + t_lng)))) * (180 / Math.PI);

  let t_lat = Math.tan(y_diff / (2 * R)) * Math.tan(y_diff / (2 * R));
  let diff_lat =
    (2 * Math.asin(Math.sqrt(t_lat / (1 + t_lat))) * 180) / Math.PI;

  return [
    ref[0] + Math.sign(x_diff) * diff_lng,
    ref[1] + Math.sign(y_diff) * diff_lat,
  ];
}
// @ts-ignore
function differenceInMeters(nodeA, nodeB) {
  let lat_mean = (nodeA[1] + nodeB[1]) / 2;
  let lng_mean = (nodeA[0] + nodeB[0]) / 2;
  let dx = hav([nodeA[0], lat_mean], [nodeB[0], lat_mean]);
  let dy = hav([lng_mean, nodeA[1]], [lng_mean, nodeB[1]]);
  return [
    dx * Math.sign(nodeB[0] - nodeA[0]),
    dy * Math.sign(nodeB[1] - nodeA[1]),
  ];
}
// @ts-ignore
function distanceInMeters(nodeA, nodeB) {
  let lat_mean = (nodeA[1] + nodeB[1]) / 2;
  let lng_mean = (nodeA[0] + nodeB[0]) / 2;
  let dx = hav([nodeA[0], lat_mean], [nodeB[0], lat_mean]);
  let dy = hav([lng_mean, nodeA[1]], [lng_mean, nodeB[1]]);
  return Math.sqrt(dx * dx + dy * dy);
}
// @ts-ignore
const moveNode = (node, move) => {
  let x = move[0];
  let y = move[1];
  let totalDistance = Math.sqrt(x * x + y * y);

  if (!totalDistance) {
    return node; // Otherwise the new node position will become NaN (0/0) and followup algorithms will fail
  }

  let normalizedDistance = Math.min(totalDistance, 10);
  let newNode = [
    ...get_new_lat(
      node,
      (x * normalizedDistance) / totalDistance,
      (y * normalizedDistance) / totalDistance
    ),
    node[2],
  ];
  return newNode;
};
// @ts-ignore
const bearingToDegrees = (bearing) => {
  let newAngle = 90 - bearing;
  if (newAngle < 0) {
    return newAngle + 360 * Math.abs(Math.floor(newAngle / 360));
  } else if (newAngle > 360) {
    return newAngle - 360 * Math.abs(Math.floor(newAngle / 360));
  } else {
    return newAngle;
  }
};
// @ts-ignore
const moveBackwards = (originalNodes, newNodes, radius) => {
  let newNewNodes = [...newNodes];

  const REPELLING_MULTIPLIER = 1;
  const RETURNING_MULTIPLIER = 0.5;

  for (var i = 0; i < newNodes.length; i++) {
    let totalForce = [0, 0];
    const currentNode = newNodes[i];
    const oldNode = originalNodes[i];
    const movedVector = differenceInMeters(currentNode, oldNode);
    const movedDistance = distanceInMeters(currentNode, oldNode);
    if (movedDistance < 15) {
      // Compute the repelling force
      for (var j = 0; j < newNodes.length; j++) {
        if (i !== j) {
          const otherNode = newNodes[j];
          const distanceChange = distanceInMeters(currentNode, otherNode);
          if (distanceChange < radius) {
            const deltaVector = differenceInMeters(currentNode, otherNode);
            totalForce[0] -= deltaVector[0] * REPELLING_MULTIPLIER;
            totalForce[1] -= deltaVector[1] * REPELLING_MULTIPLIER;
          }
        }
      }
    }

    // Compute the returning force
    totalForce[0] -= movedVector[0] * RETURNING_MULTIPLIER;
    totalForce[1] -= movedVector[0] * RETURNING_MULTIPLIER;

    const angle = (bearingToDegrees(currentNode[2]) * Math.PI) / 180;
    const xDir = Math.cos(angle);
    const yDir = Math.sin(angle);
    const projectedForce = [
      (xDir * totalForce[0] + yDir * totalForce[1]) * xDir,
      (xDir * totalForce[0] + yDir * totalForce[1]) * yDir,
    ];
    if (
      Math.sign(projectedForce[0]) === Math.sign(xDir) &&
      Math.sign(projectedForce[1]) === Math.sign(yDir)
    ) {
      newNewNodes[i] = moveNode(currentNode, [
        -projectedForce[0],
        -projectedForce[1],
      ]);
    } else {
      newNewNodes[i] = moveNode(currentNode, projectedForce);
    }
  }
  return newNewNodes;
};
// @ts-ignore
const splitIdenticalNodes = (nodes, radius) => {
  const newNodes = [...nodes];
  const identicalGroups = {};
  // @ts-ignore
  nodes.forEach((node, i) => {
    const nodeWithoutAngleJson = JSON.stringify([node[0], node[1]]);
    // @ts-ignore
    if (identicalGroups[nodeWithoutAngleJson]) {
      // @ts-ignore
      identicalGroups[nodeWithoutAngleJson].push(i);
    } else {
      // @ts-ignore
      identicalGroups[nodeWithoutAngleJson] = [i];
    }
  });

  for (const group of Object.keys(identicalGroups)) {
    // @ts-ignore
    const length = identicalGroups[group].length;

    if (length > 1) {
      let degree = 0;
      // @ts-ignore
      identicalGroups[group].forEach((nodeIndex, i) => {
        const moveDirection = [Math.cos(degree), Math.sin(degree)];

        newNodes[nodeIndex] = moveNode(nodes[nodeIndex], [
          moveDirection[0] * radius,
          moveDirection[1] * radius,
        ]);
        degree = degree + (2 * Math.PI) / length;
      });
    }
  }
  return newNodes;
};
// @ts-ignore
const spreadNodes = (newNodes, radius) => {
  let newNewNodes = [...newNodes];
  let diff;
  let distanceChange;
  for (var i = 0; i < newNodes.length; i++) {
    for (var j = 0; j < newNodes.length; j++) {
      if (i !== j) {
        // This is the logic that determins the movements of the nodes
        diff = differenceInMeters(newNewNodes[i], newNewNodes[j]);
        distanceChange = distanceInMeters(newNewNodes[i], newNewNodes[j]);

        if (distanceChange < radius) {
          const force = 0.3 / distanceChange;
          newNewNodes[i] = moveNode(newNewNodes[i], [
            -force * diff[0],
            -force * diff[1],
          ]);
          newNewNodes[j] = moveNode(newNewNodes[j], [
            force * diff[0],
            force * diff[1],
          ]);
        }
      }
    }
  }
  return newNewNodes;
};

export const spreadOutAlgorithm = (nodes: number[][], radius: number) => {
  let newNodes = [...nodes];
  newNodes = moveBackwards(nodes, newNodes, radius);
  newNodes = splitIdenticalNodes(newNodes, radius);
  // Break away nodes
  newNodes = spreadNodes(newNodes, radius);
  // Adjust the nodes
  newNodes = spreadNodes(newNodes, radius);
  // Finetune the nodes
  newNodes = spreadNodes(newNodes, radius);
  newNodes = spreadNodes(newNodes, radius);

  return newNodes;
};
